import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { CurrentStrategiesToolbar } from './CurrentStrategiesToolbar';
import { StrategiesCustomDataTree } from '../StrategiesCustomDataTree';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { store } from 'store';
import {
	updateCurrentStrategiesColumnsSize,
	updateCurrentStrategiesUserPreferences,
	updateCurrentStrategiesColumnsPreferences,
	addStrategy,
	setCurrentStrategiesRows,
} from 'store/modules/currentStrategies/actions';
import { apiExec } from 'services/api';
import { PRODUCT_TYPES } from 'utils/constants';
import { formatSnapshot } from 'utils/formatExecution';
import { getResizedColumns } from 'utils/Portfolio/columnsResize';
import { currentStrategiesColumns } from 'utils/tablesColumns/currentStrategies';
import { currentStrategiesInitialState } from 'store/modules/currentStrategies/reducer';
import { postCurrentStrategiesPreferences } from 'services/userPreferences/currentStrategies';
import { handleSubscription } from 'utils/actionListener';
import { formatDefaultStrategies } from 'utils/strategiesHelpers';
import { setIsRefreshStrategies } from 'store/modules/panelConfig/actions';

export const CurrentStrategies = ({ registerCallback, unRegisterCallback }) => {
	document.title = 'Acomp. Estratégias | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const soundOn = useSelector(state => state.configs.alerts) === 'on';
	const activeProduct = useSelector(state => state.products.activeProduct);
	const { rows, preferences } = useSelector(state => state.currentStrategies);
	const {
		username,
		contigency: contingency,
		profile,
	} = useSelector(state => state.auth);
	const viewContinousStrategies = useSelector(
		state =>
			state.configs.globalSettings.userSettings.bullet
				.viewContinousStrategies
	);
	const refreshStrategiesNeeded = useSelector(
		state => state.configs.globalSettings.refreshStrategiesNeeded
	);

	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'success',
		autoHide: null,
	});

	useEffect(() => {
		if (activeProduct.code !== PRODUCT_TYPES.FLEX_ANALYTIC) {
			let snapshotId;
			let executingOnlyContingencyInterval;
			let allStrategiesContingencyInterval;

			if (contingency) {
				executingOnlyContingencyInterval = setInterval(
					() => getCurrentStrategies(true),
					3000
				);
				allStrategiesContingencyInterval = setInterval(
					() => getCurrentStrategies(),
					60 * 1000
				);
				unsubscribeSnapshot(snapshotId);
			} else {
				clearInterval(executingOnlyContingencyInterval);
				clearInterval(allStrategiesContingencyInterval);

				const authToken = store.getState().auth.token;

				if (authToken) {
					try {
						getCurrentStrategies();
					} catch (error) {
						console.error(error);
					} finally {
						snapshotId = subscribeSnapshot();
					}
				}
			}

			return () => {
				clearInterval(executingOnlyContingencyInterval);
				clearInterval(allStrategiesContingencyInterval);
				unsubscribeSnapshot(snapshotId);
			};
		}
	}, [contingency, soundOn]);

	function getCurrentStrategies(executingOnly = false, notify = false) {
		setIsLoading(true);

		const queryParams = {
			params: {
				detailed: 1,
				executingOnly,
			},
		};

		apiExec
			.get('algos', queryParams)
			.then(response => {
				if (response.data?.records.length > 0) {
					if (!viewContinousStrategies) {
						const records = [...response.data.records];

						const filteredRecords = records.filter(
							row => !row.ParentStrategyId
						);

						response.data.records = filteredRecords;
					}

					if (contingency) {
						response.data.records.forEach(row => {
							const formattedRow = {
								...row,
								id: row.ClOrdID,
								hierarchy: [row.ClOrdID],
							};

							dispatch(addStrategy(formattedRow));
						});
					} else {
						dispatch(
							setCurrentStrategiesRows(
								formatDefaultStrategies(
									response.data.records,
									'getStrategies'
								)
							)
						);
					}

					if (notify && !contingency) {
						setSnackbarState(state => ({
							...state,
							isOpen: true,
							autoHide: 2000,
							severity: 'success',
							message: 'Estratégias atualizadas com sucesso.',
						}));
					}
				} else if (notify) {
					setSnackbarState(state => ({
						...state,
						isOpen: true,
						autoHide: 2000,
						severity: 'info',
						message:
							'Parece que você não enviou nenhuma estratégia hoje.',
					}));
				}
			})
			.catch(error => {
				console.error(error);

				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'error',
					message: 'Não foi possível atualizar as estratégias.',
				}));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function subscribeSnapshot() {
		const props = {
			action: 'subscribe',
			type: 'snapshot',
			key: 'subscribe',
			callbackFunction: data => formatSnapshot(data, apiRef, soundOn),
		};

		return handleSubscription(props, registerCallback, unRegisterCallback);
	}

	function unsubscribeSnapshot(id) {
		const props = {
			action: 'unsubscribe',
			id: id,
		};

		handleSubscription(props, registerCallback, unRegisterCallback);
	}

	const groupingColDef = {
		headerName: 'Ações',
		type: 'actions',
		flex: 0.8,
		renderCell: params => (
			<StrategiesCustomDataTree
				isLoading={isLoading}
				isDashboard
				{...params}
			/>
		),
	};

	function handleRefresh() {
		getCurrentStrategies(false, true);
	}

	function onTableStateChange(event) {
		if (event.density.value !== preferences.density) {
			const density = { density: event.density.value };

			dispatch(updateCurrentStrategiesUserPreferences(density));
			postCurrentStrategiesPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateCurrentStrategiesColumnsPreferences({
				columnVisibilityModel: { ...event },
			})
		);
		postCurrentStrategiesPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	function onFilterModelChange(event) {
		dispatch(
			updateCurrentStrategiesUserPreferences({
				filter: { filterModel: { ...event } },
			})
		);
	}

	function onSortModelChange(event) {
		const sortModel =
			event.length === 0
				? currentStrategiesInitialState.preferences.sorting.sortModel
				: [...event];

		const sorting = { sorting: { sortModel } };

		dispatch(updateCurrentStrategiesUserPreferences(sorting));
		postCurrentStrategiesPreferences(username, sorting);
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateCurrentStrategiesColumnsPreferences({
				orderedFields: [...columnsOrder],
			})
		);

		postCurrentStrategiesPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	function onColumnWidthChange(event) {
		if (event?.colDef?.field && event?.width) {
			dispatch(
				updateCurrentStrategiesColumnsSize(
					event.colDef.field,
					event.width
				)
			);
		}
	}

	const resizedColumns = getResizedColumns(
		currentStrategiesColumns,
		preferences?.columns.columnsSize
	);

	useEffect(() => {
		if (refreshStrategiesNeeded) {
			if (rows.length > 0) {
				handleRefresh();
			}

			dispatch(setIsRefreshStrategies(false));
		}
	}, [viewContinousStrategies]);

	return (
		<>
			<Grid
				container
				sx={{
					gap: 0.5,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					p: '1.5rem 0 0.5rem',
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexGrow: 1,
					}}
				>
					<StyledDataGridPro
						rows={rows}
						apiRef={apiRef}
						loading={isLoading}
						treeData
						groupingColDef={groupingColDef}
						getTreeDataPath={row => row.hierarchy}
						columns={resizedColumns}
						components={{
							Toolbar: () =>
								CurrentStrategiesToolbar(
									isLoading,
									setIsLoading,
									apiRef,
									setSnackbarState,
									handleRefresh,
									profile
								),
							LoadingOverlay: LinearProgress,
							NoRowsOverlay: () =>
								NoRowsOverlay('Nenhuma execução em andamento'),
						}}
						getRowClassName={params =>
							params.row.hierarchy.length === 1
								? 'main-strategy'
								: 'strategy-legs'
						}
						initialState={{
							columns: preferences?.columns,
							sorting: preferences?.sorting,
							filter: preferences?.filter,
						}}
						density={preferences?.density || 'standard'}
						disableSelectionOnClick
						disableChildrenFiltering
						disableChildrenSorting
						hideFooter
						onStateChange={event => onTableStateChange(event)}
						onSortModelChange={event => onSortModelChange(event)}
						onFilterModelChange={event =>
							onFilterModelChange(event)
						}
						onColumnOrderChange={event =>
							onColumnOrderChange(event)
						}
						onColumnWidthChange={event =>
							onColumnWidthChange(event)
						}
						onColumnVisibilityModelChange={event =>
							onColumnVisibilityModelChange(event)
						}
					/>
				</Box>
			</Grid>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				autoHide={snackbarState.autoHide}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
