import React, { useEffect, useState } from 'react';

import { Box, colors, Typography } from '@mui/material';

import { apiExec } from 'services/api';

export const MarketMarking = () => {
	const [markingValue, setMarkingValue] = useState(0);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		const controller = new AbortController();

		function getMarkingValue() {
			const configs = {
				params: {
					financial_calc: 1,
					limit: 500,
					offset: 0,
				},
				signal: controller.signal,
			};

			apiExec
				.get('/positions', configs)
				.then(response => {
					if (response.data.pos_marcacao_mercado) {
						setMarkingValue(response.data.pos_marcacao_mercado);
					} else {
						setMarkingValue(0);
					}

					if (isError) {
						setIsError(false);
					}
				})
				.catch(error => {
					console.error(error);
					setIsError(true);
				});
		}

		getMarkingValue();

		const interval = setInterval(() => {
			getMarkingValue();
		}, 5000);

		return () => {
			controller.abort();
			clearInterval(interval);
		};
	}, []);

	function getColors(markingValue) {
		if (markingValue >= 0) {
			return colors.green[700];
		}

		return colors.red[700];
	}

	const backgroundColor = getColors(markingValue);

	const formattedMarking = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	}).format(markingValue);

	const markingLabel = isError
		? 'Temporariamente Indisponível'
		: formattedMarking;

	return (
		<Box
			sx={{
				bgcolor: backgroundColor,
				color: 'white',
				padding: ' 0.125rem 0.5rem',
				borderRadius: '5px',
				textTransform: 'uppercase',
			}}
		>
			<Typography variant="h6">
				Resultado (daytrade): {markingLabel}
			</Typography>
		</Box>
	);
};
