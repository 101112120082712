import { format } from 'date-fns';
import { toast } from 'react-toastify';

export function getEntranceMoment(customParameters, strategyLegs) {
	const { RelativeType, RelativeDirection } = customParameters;
	const legSide = strategyLegs[0].LegSide;

	const entranceMomentValues = {
		start: 'S',
		limitedImmediate: 'I',
		limitedNextToTarget: 'N',
		openAuction: 'A',
	};

	const relativeTypeValues = {
		openingCurrentDay: 'O',
		prevClosePrice: 'C',
		minCurrentDay: 'L',
		maxCurrentDay: 'H',
	};

	const openingOrPrevClose = [
		relativeTypeValues.openingCurrentDay,
		relativeTypeValues.prevClosePrice,
	].includes(RelativeType);

	if (openingOrPrevClose && RelativeDirection === 'D') {
		if (legSide === '2') {
			return entranceMomentValues.start;
		}

		return entranceMomentValues.limitedImmediate;
	}

	if (openingOrPrevClose && RelativeDirection === 'U') {
		if (legSide === '1') {
			return entranceMomentValues.start;
		}

		return entranceMomentValues.limitedImmediate;
	}

	return entranceMomentValues.limitedImmediate;
}

function getFormattedCustomParameters(customParameters, strategyLegs) {
	const formattedCustomParameters = {};

	let entryConditionalFields = {};
	let timeConditionalFields = {};
	let exitConditionalFields = {};

	const entranceMoment = getEntranceMoment(customParameters, strategyLegs);

	for (const [key, value] of Object.entries(customParameters)) {
		if (
			![null, undefined, NaN].includes(customParameters[key]) &&
			!String(key).startsWith('_')
		) {
			formattedCustomParameters[key] = value;
		}
	}

	if (entranceMoment === 'S') {
		entryConditionalFields.PxRelSlippagePts =
			formattedCustomParameters.PxRelSlippagePts;
	}

	if (formattedCustomParameters.PxInitTimeMonitoring) {
		timeConditionalFields.PxInitTimeMonitoring = format(
			formattedCustomParameters.PxInitTimeMonitoring,
			'HH:mm:ss'
		);

		if (formattedCustomParameters.PxEndTimeMonitoring) {
			timeConditionalFields.PxEndTimeMonitoring = format(
				formattedCustomParameters.PxEndTimeMonitoring,
				'HH:mm:ss'
			);
		}
	}

	if (formattedCustomParameters.ExitPxRelLoss) {
		const formattedLoss = parseFloat(
			new Intl.NumberFormat('en-US', {
				maximumFractionDigits: 2,
			}).format(formattedCustomParameters.ExitPxRelLoss)
		);

		exitConditionalFields.ExitPxRelLoss = formattedLoss;
		exitConditionalFields.ExitUnit = formattedCustomParameters.ExitUnit;
		exitConditionalFields.ExitPxSlippagePts =
			formattedCustomParameters.ExitPxSlippagePts;
	}

	if (formattedCustomParameters.ExitPxRelGain) {
		const formattedGain = parseFloat(
			new Intl.NumberFormat('en-US', {
				maximumFractionDigits: 2,
			}).format(formattedCustomParameters.ExitPxRelGain)
		);

		exitConditionalFields.ExitPxRelGain = formattedGain;
		exitConditionalFields.ExitUnit = formattedCustomParameters.ExitUnit;
	}

	if (formattedCustomParameters.ExitOnEndTime === 'Y') {
		exitConditionalFields.ExitOnEndTime =
			formattedCustomParameters.ExitOnEndTime;
	}

	if (formattedCustomParameters.ExitOnEndDayAuction === 'Y') {
		exitConditionalFields.ExitOnEndDayAuction =
			formattedCustomParameters.ExitOnEndDayAuction;
	}

	const formattedRelativePxRel = parseFloat(
		new Intl.NumberFormat('en-US', {
			maximumFractionDigits: 2,
		}).format(formattedCustomParameters.RelativePxRel)
	);

	return {
		TargetPrice: formattedCustomParameters.TargetPrice,
		RelativeType: formattedCustomParameters.RelativeType,
		RelativeDirection: formattedCustomParameters.RelativeDirection,
		RelativeUnit: formattedCustomParameters.RelativeUnit,
		RelativePxRel: formattedRelativePxRel,
		EntranceMoment: entranceMoment,
		...entryConditionalFields,
		...timeConditionalFields,
		...exitConditionalFields,
	};
}

function verifyEmptyMandatoryFields(objectToVerify) {
	const possibleMandatoryFieldsMapping = {
		TargetPrice: 'Preço alvo',
		ExitPxSlippagePts: 'Slippage de saída',
		ExitPxRelLoss: 'Loss',
		ExitUnit: 'Seletor de pontos ou porcentagem da saída',
		ExitPxRelGain: 'Gain',
		RelativeDirection: 'Direção relativa',
		RelativeType: 'Tipo relativo',
		RelativeUnit: 'Unidade relativa',
		RelativePxRel: 'Valor (%/pontos)',
		PxInitTimeMonitoring: 'horário de inicial para envio',
		PxEndTimeMonitoring: 'horário de final para envio',
		PxRelSlippagePts: 'Slippage de entrada',
		ExitOnEndDayAuction: 'Termina em',
		ExitOnEndTime: 'Termina em',
	};

	let emptyFieldForMessage;

	const entryMandatoryParams = [];
	const exitMandatoryParams = [];
	const timeMandatoryParams = [];

	if (objectToVerify.ExitPxRelLoss || objectToVerify._useStopLoss) {
		exitMandatoryParams.push(
			'ExitPxRelLoss',
			'ExitUnit',
			'ExitPxSlippagePts'
		);
	}

	if (objectToVerify.ExitPxRelGain || objectToVerify._useStopGain) {
		exitMandatoryParams.push('ExitPxRelGain', 'ExitUnit');
	}

	if (objectToVerify._relativePxInitTimeOption === 'specified') {
		timeMandatoryParams.push('PxInitTimeMonitoring');
	}

	if (objectToVerify._relativePxInitTimeOption === 'interval') {
		timeMandatoryParams.push('PxInitTimeMonitoring', 'PxEndTimeMonitoring');
	}

	if (objectToVerify.EntranceMoment === 'S') {
		entryMandatoryParams.push('PxRelSlippagePts');
	}

	const mandatoryCustomParameterFields = [
		'RelativeDirection',
		'RelativeType',
		'RelativeUnit',
		'RelativePxRel',
		'ExitOnEndTime',
		'ExitOnEndDayAuction',
		...entryMandatoryParams,
		...exitMandatoryParams,
		...timeMandatoryParams,
	];

	const hasCustomParameterFieldEmpty = mandatoryCustomParameterFields.some(
		field => {
			if ([null, undefined, ''].includes(objectToVerify[field])) {
				emptyFieldForMessage = field;
				return true;
			}

			return false;
		}
	);

	if (hasCustomParameterFieldEmpty) {
		toast.error(
			`Campo obrigatório de "${possibleMandatoryFieldsMapping[emptyFieldForMessage]}" não preenchido`
		);

		return true;
	}

	return false;
}

function getFormattedStrategyLegs(bullet, account, useApparentQuantity) {
	const strategyLeg = bullet.StrategyLegs[0];

	const legMinClipSize = useApparentQuantity
		? { LegMinClipSize: strategyLeg.LegMinClipSize }
		: {};

	return [
		{
			ILegAllocAccount: account,
			LegQuantity: strategyLeg.LegQuantity,
			LegSecurityExchange: strategyLeg.LegSecurityExchange,
			LegSide: strategyLeg.LegSide,
			LegSymbol: strategyLeg.LegSymbol,
			...legMinClipSize,
		},
	];
}

export function gapClear(bullet, account) {
	const { content } = bullet;

	const hasMandatoryFieldEmpty = verifyEmptyMandatoryFields(
		content.CustomParameters
	);

	if (hasMandatoryFieldEmpty) {
		return;
	}

	const accountToBeUsed = account();

	const strategyLegs = getFormattedStrategyLegs(
		content,
		accountToBeUsed,
		content.CustomParameters._useApparentQuantity
	);

	const formattedCustomParameters = getFormattedCustomParameters(
		content.CustomParameters,
		strategyLegs
	);

	return {
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: formattedCustomParameters,
		StrategyLegs: strategyLegs,
		Signature: content.Signature,
	};
}

function getEditedLegs(strategyLeg, useApparentQuantity) {
	const legMinClipSize = useApparentQuantity
		? { LegMinClipSize: strategyLeg.LegMinClipSize }
		: {};

	return [
		{
			...strategyLeg,
			...legMinClipSize,
		},
	];
}

export function gapEdit(bullet) {
	const { content } = bullet;

	const hasMandatoryFieldEmpty = verifyEmptyMandatoryFields(
		content.CustomParameters
	);

	if (hasMandatoryFieldEmpty) {
		return;
	}

	const strategyLegs = getEditedLegs(
		bullet.content.StrategyLegs[0],
		bullet.content.CustomParameters._useApparentQuantity
	);

	const formattedCustomParameters = getFormattedCustomParameters(
		content.CustomParameters,
		strategyLegs
	);

	delete formattedCustomParameters.TargetPrice;

	return {
		ClOrdID: bullet.content.ClOrdID,
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: formattedCustomParameters,
		StrategyLegs: strategyLegs,
		Signature: content.Signature,
	};
}
