import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { apiAWS } from 'services/api';
import {
	setContractsData,
	setContractToAccept,
	signContract,
} from 'store/modules/products/actions';

export const AcceptableContract = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();

	const { username, token } = useSelector(state => state.auth);
	const pendingContract = useSelector(
		state => state.products.userContracts.contractToAccept
	);

	const [isLoading, setIsLoading] = useState(false);

	function handleClose() {
		dispatch(setContractToAccept(null));
		onClose();
	}

	function getContracts() {
		apiAWS
			.get(`/users/${username}/contracts`)
			.then(response => dispatch(setContractsData(response.data.records)))
			.catch(error => console.error(error));
	}

	function handleSubmit() {
		setIsLoading(true);

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiAWS
			.post(`/users/${username}/contracts`, pendingContract, headers)
			.then(() => {
				dispatch(signContract(pendingContract));
				onClose();
				toast.success('Contrato assinado com sucesso.');
				getContracts();
			})
			.catch(error => {
				console.error(error);
				toast.error(
					'Problema durante a assinatura do contrato. Tente novamente mais tarde.'
				);
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="lg"
			sx={{
				'& .MuiDialog-paper': {
					height: '80vh',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<DialogTitle>Termos de uso</DialogTitle>

			<DialogContent
				sx={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
					'& iframe': {
						flex: 1,
						width: '100%',
						height: '100%',
					},
				}}
			>
				<DialogContentText sx={{ mb: 2 }}>
					Você precisa aceitar os termos de uso para utilizar este
					produto.
				</DialogContentText>

				<iframe src={pendingContract?.file} />
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} variant="contained" color="error">
					Fechar
				</Button>

				<LoadingButton
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					loading={isLoading}
				>
					Aceitar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
