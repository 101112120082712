import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button } from '@mui/material';

import { apiAWS } from 'services/api';

import { Container } from './styles';
import { ForgotPasswordForm } from '../forms/ForgotPassword';
import ResetTokenValidator from 'components/ResetTokenValidator';
import { NewPasswordForm } from 'components/forms/NewPasswordForm';
import ListaUserNamesLogin from '../MaterialComponents/ListaUserNamesLogin';

export default function ResetPassword(props) {
	const { startStep, handleComponentChange, adjustLoginInformation } = props;
	const { t } = useTranslation();
	const [step, setStep] = useState(startStep ?? 1);
	const [userName, setUserName] = useState(null);
	const [loginList, setLoginList] = useState([]);
	const [isItemSelected, setIsItemSelected] = useState(false);
	const [timeout, setTimeout] = useState();
	const [transactionalToken, setTransactionalToken] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		adjustLoginInformation(step);
	}, [step, adjustLoginInformation]);

	// ETAPA 1
	const handleResetPasswordForm = async values => {
		setIsLoading(true);

		const body = {
			user_input: values.username,
			service: 'reset_password',
		};

		const headers = {
			headers: { 'x-authorization': process.env.REACT_APP_AWS_API_TOKEN },
		};

		const uri = isItemSelected ? '?type=user_name' : '';

		axios
			.post(
				`${process.env.REACT_APP_CLOUDFRONT_URL}/credentials/tokens${uri}`,
				body,
				headers
			)
			.then(response => {
				if (
					Object.prototype.hasOwnProperty.call(
						response.data,
						'user_list'
					) &&
					response.data.user_list.length > 0
				) {
					setLoginList(response.data.user_list);
					setStep(4);
				} else {
					toast.success('E-mail de recuperação enviado com sucesso.');
					setTransactionalToken(response.data.transactional_token);
					setStep(2);
					setTimeout(response.data.validate_time_text);
				}
			})
			.catch(error => console.log(error))
			.finally(() => setIsLoading(false));
	};

	// ETAPA 2
	const handleTokenValidator = async values => {
		setIsLoading(true);

		const headers = { headers: { 'x-authorization': transactionalToken } };

		apiAWS
			.get(`/credentials/tokens/${values.token?.toUpperCase()}`, headers)
			.then(response => {
				toast.success('Código correto. Favor digitar sua nova senha');
				setTransactionalToken(response.data.transactional_token);
				setStep(3);
			})
			.catch(error => {
				console.log(error);
				toast.error('Código incorreto');
			})
			.finally(() => setIsLoading(false));
	};

	const callbackItemSelected = itemSelected => {
		setUserName(itemSelected);
		setIsItemSelected(true);
	};

	// ETAPA 3
	const handleNewPassword = async (values, transacToken) => {
		if (values.password !== values.password_confirmation) {
			toast.error('Senhas não conferem');
			return;
		}

		const body = {
			password: values.password,
			service: 'reset_password',
		};

		const headers = { headers: { 'x-authorization': transacToken } };

		apiAWS
			.post('/credentials/commands', body, headers)
			.then(() => {
				toast.success('Senha alterada com sucesso.');
				handleComponentChange(false);
			})
			.catch(error => {
				console.log(error);
				toast.error(
					'Erro ao processar requisição. Favor entrar em contato com a equipe de suporte.'
				);
			});
	};

	const onSubmitInterceptor = (event, values) => {
		event.preventDefault();
		handleNewPassword(values, transactionalToken);
	};

	return (
		<Container>
			{step === 1 && (
				<ForgotPasswordForm
					onSubmit={handleResetPasswordForm}
					isLoading={isLoading}
				/>
			)}
			{step === 2 && transactionalToken && (
				<ResetTokenValidator
					onSubmit={handleTokenValidator}
					timeout={timeout}
					isLoading={isLoading}
				/>
			)}
			{step === 3 && transactionalToken && (
				<NewPasswordForm handleSubmit={onSubmitInterceptor} />
			)}

			{step === 4 && (
				<ListaUserNamesLogin
					callbackItemSelected={callbackItemSelected}
					loginList={loginList}
				/>
			)}
			{step === 4 && (
				<Button
					variant="contained"
					color="primary"
					fullWidth
					name="2"
					type="button"
					style={{ marginTop: '20px' }}
					onClick={() =>
						handleResetPasswordForm({ username: userName })
					}
					size="large"
					disabled={!isItemSelected}
				>
					{t('login.passwordForgetButton')}
				</Button>
			)}
		</Container>
	);
}
