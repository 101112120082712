import styled from 'styled-components';
import { Grid, Button } from '@mui/material';

function getPriceColor({ volatility, marketPrice, disabled, isCvvol }) {
	if (disabled) {
		return '#ffffff';
	} else {
		if (volatility) {
			if (volatility === '--') {
				return '#ffffff';
			}

			if (isCvvol) {
				return '#4cac4b';
			} else {
				if (volatility.replace(',', '.') >= 0) {
					return '#4cac4b';
				} else {
					return '#ff6961';
				}
			}
		} else {
			if (marketPrice) {
				if (marketPrice >= 0) {
					return '#4cac4b';
				} else {
					return '#ff6961';
				}
			} else {
				return '#ffffff';
			}
		}
	}
}

export const Container = styled(Grid)`
	margin-left: 16px;

	h6 {
		min-width: 110px;
		margin-left: 7px;
	}
`;

export const PriceButton = styled(Button)`
	display: flex;
	flex-direction: column;

	background-color: transparent !important;
	min-width: fit-content !important;
	
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;

	&:hover {
		transition: 0.3s;
		background: #505050 !important;
	}

	span {
		display: flex !important;
		flex-direction: column !important;
		justify-content: flex-start !important;
		align-items: flex-start !important;
		color: white !important;
		width: 100%;
	}

	p {
		color: ${props => getPriceColor(props)};
	}
`;
