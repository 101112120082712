import { format } from 'date-fns';

import { ChatBotActionTypes } from './actionTypes';

const INITIAL_STATE = {
	isOpen: false,
	messages: [],
	botIsTyping: false,
};

export function chatBotReducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case ChatBotActionTypes.RESET_STATE:
			return INITIAL_STATE;
		case ChatBotActionTypes.SET_IS_CHAT_OPEN:
			return { ...state, isOpen: payload.isOpen };
		case ChatBotActionTypes.ADD_USER_MESSAGE: {
			const formattedDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS');

			const formattedMessage = {
				content: payload.message,
				from: 'user',
				timestamp: formattedDate,
			};

			return {
				...state,
				messages: [...state.messages, formattedMessage],
			};
		}
		case ChatBotActionTypes.ADD_BOT_MESSAGE: {
			const formattedDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS');

			const formattedMessage = {
				content: payload.message,
				from: 'bot',
				timestamp: formattedDate,
			};

			return {
				...state,
				messages: [...state.messages, formattedMessage],
			};
		}
		case ChatBotActionTypes.SET_BOT_IS_TYPING:
			return { ...state, botIsTyping: payload.botIsTyping };
		case ChatBotActionTypes.ADD_BOT_ERROR_MESSAGE: {
			const formattedDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS');

			const errorMessage = {
				content: 'An error occurred while processing your request.',
				from: 'bot',
				timestamp: formattedDate,
				isError: true,
			};

			return {
				...state,
				messages: [...state.messages, errorMessage],
			};
		}
		default:
			return state;
	}
}
