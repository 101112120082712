import React from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { COMPONENT_NUMERIC_INPUT_HEIGHT } from 'utils/constants';

export const FinantialButton = ({
	disabled,
	isLoading = false,
	height = null,
	isCreditFinantialButton = false,
	onChangeFinantialButton = null,
}) => {
	const defaultStyledFinantialButton = {
		minWidth: 0,
		borderRadius: '4px 0 0 4px',
		height: height ?? COMPONENT_NUMERIC_INPUT_HEIGHT,
		cursor: disabled ? 'not-allowed' : 'pointer',
	};

	const creditStyleButton = {
		...defaultStyledFinantialButton,
		backgroundColor: '#388e3c',
		color: '#fff',
	};

	const styledFinantialButton =
		isCreditFinantialButton && !disabled && !isLoading
			? creditStyleButton
			: defaultStyledFinantialButton;

	function handleFinantialButton() {
		if (onChangeFinantialButton) {
			onChangeFinantialButton(!isCreditFinantialButton);
		}
	}

	return (
		<>
			<Grid xs={2}>
				<LoadingButton
					size="small"
					variant="contained"
					disabled={disabled || isLoading}
					loading={isLoading}
					color={
						isCreditFinantialButton && !isLoading
							? 'inherit'
							: 'secondary'
					}
					style={styledFinantialButton}
					onClick={handleFinantialButton}
					fullWidth
				>
					{isCreditFinantialButton ? 'CR' : 'DB'}
				</LoadingButton>
			</Grid>
		</>
	);
};
