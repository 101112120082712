import React from 'react';

import { colors, Grid } from '@mui/material';

import { ContinousExecutionParams } from './ContinousExecutionParams';
import { ContinousEntryParams } from './ContinousEntryParams';

export const SpreadContinous = ({ execType, setPaperInfo }) => {
	const isAdvancedBullet = execType === 'A';

	return (
		<>
			<Grid
				id="continous-spread-container"
				item
				container
				xs={12}
				spacing={1}
				gap={isAdvancedBullet ? 0 : 2}
				sx={{
					bgcolor: colors.grey[900],
					borderRadius: 1,
					p: 1,
				}}
			>
				{/* Parametros de ativos, quantidade e etc.. */}
				<ContinousEntryParams
					setPaperInfo={setPaperInfo}
					isAdvancedBullet={isAdvancedBullet}
				/>

				{/* Além de conter os campos de execução, também contém a area spread de mercado */}
				<ContinousExecutionParams isAdvancedBullet={isAdvancedBullet} />
			</Grid>
		</>
	);
};
