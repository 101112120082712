import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import DecimalInput from 'components/MaterialComponents/DecimalInput';
import paperValidator from 'utils/paperValidation';
import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';
import SelectInput from 'components/MaterialComponents/SelectInput';
import IconWithTooltip from 'components/MaterialComponents/IconTooltip';

import { CheckboxContainer, CheckboxItem } from './styles';

const PovExecutionParams = ({ stepFunction, handleChange }) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const { t } = useTranslation();

	const widgetOpen = useSelector(state => state.configs.widgetBar);

	return (
		<>
			<Grid item xs={5}>
				<Grid container spacing={1} direction="row">
					<Grid item xs={12} style={{ marginLeft: '3px' }}>
						<Typography variant="subtitle1">
							Parâmetros de execução
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<DecimalInput
							name="TargetPercent"
							label="Participação(%)"
							iconTooltip={t(
								'bullets.participatory.pov.target_percent'
							)}
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet,
								'isPrice'
							)}
							minVal={0.01}
							maxVal={100}
							minDecScale={2} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="TargetPercent" // subtype
							stepSize={0.01} // incremento
							valueToStep={
								bullet.content.CustomParameters.TargetPercent
							} // valor a ser modificado
							value={
								bullet.content.CustomParameters.TargetPercent
							}
							onChange={e =>
								handleChange(
									'Custom',
									'TargetPercent',
									parseFloat(e.value),
									0
								)
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<DecimalInput
							name="LegSecondTimeOut"
							label="Espera(seg)"
							iconTooltip={t(
								'bullets.participatory.pov.time_out'
							)}
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet,
								'isPrice'
							)}
							minVal={1}
							maxVal={60}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegSecondTimeOut" // subtype
							stepSize={1} // incremento
							valueToStep={
								bullet.content.StrategyLegs[0].LegSecondTimeOut
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[0].LegSecondTimeOut
							}
							onChange={e =>
								handleChange(
									'Leg',
									'LegSecondTimeOut',
									parseInt(e.value, 10),
									0
								)
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectInput
							name="AgressionLevel"
							label="Modo de agressão"
							iconTooltip={t(
								'bullets.participatory.agression_mode'
							)}
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet,
								'isPrice'
							)}
							onChange={e =>
								handleChange(
									'Custom',
									'AgressionLevel',
									parseInt(e.target.value, 10),
									0
								)
							}
							defaultValue={0}
							value={
								bullet.content.CustomParameters
									.AgressionLevel ?? 0
							}
							selectOptions={[
								{
									value: 0,
									label: 'Contra-Parte',
								},
								{
									value: 2,
									label: 'Parte',
								},
							]}
						/>
					</Grid>
				</Grid>
				<CheckboxContainer>
					<CheckboxItem handleWidth={widgetOpen && '75px'}>
						<MaterialCheckBox
							name="ConsiderCrossOrders"
							label="Cons. diretas"
							disabled={
								bullet.content.ClOrdID ||
								paperValidator(
									bullet.StrategyInfos[0],
									bullet,
									'isPrice'
								)
							}
							onChange={() =>
								handleChange(
									'Custom',
									'ConsiderCrossOrders',
									bullet.content.CustomParameters
										.ConsiderCrossOrders === 'Y'
										? 'N'
										: 'Y',
									0
								)
							}
							checked={
								bullet.content.CustomParameters
									.ConsiderCrossOrders === 'Y'
							}
							smallLabel
						/>
						<Box ml={0.5}>
							<IconWithTooltip
								sizeFont="12px"
								title={t(
									'bullets.participatory.consider_cross_orders'
								)}
							/>
						</Box>
					</CheckboxItem>
					<CheckboxItem handleWidth={widgetOpen && '100px'}>
						<MaterialCheckBox
							name="ConsiderCrossOrdersNotIntentional"
							label="Cons. não intencionais"
							disabled={
								bullet.content.ClOrdID ||
								paperValidator(
									bullet.StrategyInfos[0],
									bullet,
									'isPrice'
								)
							}
							onChange={() =>
								handleChange(
									'Custom',
									'ConsiderCrossOrdersNotIntentional',
									bullet.content.CustomParameters
										.ConsiderCrossOrdersNotIntentional ===
										'Y'
										? 'N'
										: 'Y',
									0
								)
							}
							checked={
								bullet.content.CustomParameters
									.ConsiderCrossOrdersNotIntentional === 'Y'
							}
							smallLabel
						/>
						<Box ml={0.5}>
							<IconWithTooltip
								sizeFont="12px"
								title={t(
									'bullets.participatory.pov.consider_cross_orders_not_intentional'
								)}
							/>
						</Box>
					</CheckboxItem>
					<CheckboxItem handleWidth={widgetOpen && '90px'}>
						<MaterialCheckBox
							name="ConsiderPriceLimitInVolume"
							label="Ac. fora do preço"
							disabled={
								bullet.content.ClOrdID ||
								paperValidator(
									bullet.StrategyInfos[0],
									bullet,
									'isPrice'
								)
							}
							onChange={() =>
								handleChange(
									'Custom',
									'ConsiderPriceLimitInVolume',
									bullet.content.CustomParameters
										.ConsiderPriceLimitInVolume === 'Y'
										? 'N'
										: 'Y',
									0
								)
							}
							checked={
								bullet.content.CustomParameters
									.ConsiderPriceLimitInVolume === 'Y'
							}
							smallLabel
						/>
						<Box ml={0.5}>
							<IconWithTooltip
								sizeFont="12px"
								title={t(
									'bullets.participatory.pov.consider_price_limit_volume'
								)}
							/>
						</Box>
					</CheckboxItem>
					<CheckboxItem>
						<MaterialCheckBox
							name="ParticipateOwnVolume"
							label="Part. próprio volume"
							disabled={
								bullet.content.ClOrdID ||
								paperValidator(
									bullet.StrategyInfos[0],
									bullet,
									'isPrice'
								)
							}
							onChange={() =>
								handleChange(
									'Custom',
									'ParticipateOwnVolume',
									bullet.content.CustomParameters
										.ParticipateOwnVolume === 'Y'
										? 'N'
										: 'Y',
									0
								)
							}
							checked={
								bullet.content.CustomParameters
									.ParticipateOwnVolume === 'Y'
							}
							smallLabel
						/>
						<Box ml={0.5} mt={0.5}>
							<IconWithTooltip
								sizeFont="12px"
								title={t(
									'bullets.participatory.pov.participate_volume'
								)}
							/>
						</Box>
					</CheckboxItem>
				</CheckboxContainer>
			</Grid>
		</>
	);
};

export default PovExecutionParams;
