import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

// Pages
import Login from 'pages/Login/index';
import Logout from 'pages/Logout';
import { NotFound } from 'pages/NotFound';
import VolStudioCharts from 'pages/VolStudioCharts';
import Scanner from 'pages/Scanner';
import OptionsChain from 'pages/OptionsChain';
import { StrategiesUpload } from 'pages/StrategiesUpload';
import { Strategies } from 'pages/Strategies';
import { Positions } from 'pages/Positions';
import { Risk } from 'pages/Risk';
import { IdxLiveCharts } from 'pages/IdxLiveCharts';

// Components
import PrivateRoute from 'components/PrivateRoute';
import AppHeader from 'components/AppHeader';
import WidgetBar from './components/WidgetBar';
import { Bullet } from 'components/Bullet';

import { MainContent } from './styles';

import { NewSimulator } from 'pages/NewSimulator';
import { PartnerProduct } from 'pages/PartnerProduct';
import { Radar } from 'pages/Radar';
import { HistoricalData } from 'pages/HistoricalData';

function Routes({ registerCallback, unRegisterCallback }) {
	const isAuthenticated = useSelector(state => state.auth.token);

	/* const accountType = useSelector(state => state.auth.accountType);
	const authToken = useSelector(state => state.auth.token); */

	/* useEffect(() => {
		if (isAuthenticated) {
			const baseUrl =
				accountType === 'real'
					? process.env.REACT_APP_USR_DMA_API_URL
					: process.env.REACT_APP_USR_SIM_API_URL;
			api.defaults.baseURL = baseUrl;

			apiExec.defaults.baseURL = baseUrl;

			api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiExec.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiEls.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiMarketData.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiAWS.defaults.headers.common.Authorization = `Bearer ${authToken}`;
		}
	}, [isAuthenticated, accountType, authToken]); */

	return (
		<div style={{ width: '100%', display: 'flex' }}>
			{isAuthenticated && <AppHeader />}
			<div
				style={{
					paddingTop: `${isAuthenticated ? '41px' : '0px'}`,
					width: '100%',
					display: 'flex',
					flex: 1,
					flexDirection: 'column-reverse',
				}}
			>
				{isAuthenticated && <Bullet />}
				<MainContent>
					<Switch>
						<PrivateRoute exact path="/">
							<Strategies
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/dashboard">
							<Strategies
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/external-product/*">
							<PartnerProduct />
						</PrivateRoute>
						<PrivateRoute exact path="/posicoes">
							<Positions />
						</PrivateRoute>
						<PrivateRoute exact path="/simulator">
							<NewSimulator
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/riscos">
							<Risk />
						</PrivateRoute>
						<PrivateRoute exact path="/cadeia-opcoes">
							<OptionsChain
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/volstudio">
							<VolStudioCharts />
						</PrivateRoute>
						<PrivateRoute exact path="/scanner">
							<Scanner
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/upload-estrategias">
							<StrategiesUpload />
						</PrivateRoute>
						<PrivateRoute exact path="/idxlivecharts">
							<IdxLiveCharts
								registerCallback={registerCallback}
								unRegisterCallback={unRegisterCallback}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/radar-dividendos">
							<Radar />
						</PrivateRoute>
						<PrivateRoute exact path="/logout">
							<Logout />
						</PrivateRoute>
						<PrivateRoute exact path="/historical-data">
							<HistoricalData />
						</PrivateRoute>

						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/login/product/:pathProduct">
							<Login />
						</Route>
						<Route exact path="/novo-usuario/:newUserToken">
							<Login newUser />
						</Route>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>
				</MainContent>
			</div>

			{isAuthenticated && (
				<WidgetBar
					registerCallback={registerCallback}
					unRegisterCallback={unRegisterCallback}
				/>
			)}
		</div>
	);
}

export default Routes;
