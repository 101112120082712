import React from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IconWithTooltip from '../IconTooltip';

export const TextFieldPortBalance = (props) => {
	const { bullet, symbolList, initialList, desiredList, handleChange } = props;
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				maxWidth: '100%',
			}}
			component="form"
			noValidate
			autoComplete="off"
		>
			<TextField 
				label={
					<Box display="flex" width="auto">
						Lista de Ativos
						<Box ml={0.5} mt={-0.4}>
							<IconWithTooltip title={t('bullets.portbalance.symbol_list')} fixedTooltip="top-start" />
						</Box>
					</Box>
				}
				size="small"
				fullWidth
				variant="outlined"
				InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
				value={symbolList}
				onChange={(e) => handleChange('Custom', 'SymbolList', e.target.value.toUpperCase())}
				disabled={bullet.paramsView}
				style={{ marginTop: '5px' }}
			/>
			<TextField 
				label={
					<Box display="flex" width="auto">
						Posição inicial
						<Box ml={0.5} mt={-0.4}>
							<IconWithTooltip title={t('bullets.portbalance.initial_pos_list')} fixedTooltip="top-start" />
						</Box>
					</Box>
				}
				size="small"
				fullWidth
				variant="outlined"
				InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
				value={initialList}
				onChange={(e) => handleChange('Custom', 'InitialPosList', e.target.value)}
				disabled={bullet.paramsView}
				style={{ marginTop: '10px' }}
			/>
			<TextField 
				label={
					<Box display="flex" width="auto">
						Posição desejada (%)
						<Box ml={0.5} mt={-0.4}>
							<IconWithTooltip title={t('bullets.portbalance.desired_perc_list')} fixedTooltip="top-start" />
						</Box>
					</Box>
				}
				size="small"
				fullWidth
				variant="outlined"
				InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
				value={desiredList}
				onChange={(e) => handleChange('Custom', 'DesiredPosPercList', e.target.value)}
				disabled={bullet.paramsView}
				style={{ marginTop: '10px' }}
			/>
		</Box>
	);
};
