import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { ViewExecutionsToolbar } from './ViewExecutionsToolbar';
import { ViewExecutionsSearchForm } from './ViewExecutionsSearchForm';
import { ViewExecutionsCustomPagination } from './ViewExecutionsCustomPagination';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { apiAWS, apiCadastro, apiExec } from 'services/api';
import { postExecutionsPreferences } from 'services/userPreferences/executions';
import {
	setRows,
	setStateValues,
	updateOrdersFormValues,
	updatePaginationInfo,
	updateSearchPaginate,
	updateUserPreferences,
} from 'store/modules/executions/actions';
import { executionsInitialState } from 'store/modules/executions/reducer';
import { refreshUpdateAt } from 'utils/executionsHelpers';
import { getGroupAccountsOptions } from 'utils/getGroupAccounts';
import { executionsColumns } from 'utils/tablesColumns/executions';
import { PRODUCT_TYPES, USER_TYPES } from 'utils/constants';
import { setIsRefreshStrategies } from 'store/modules/panelConfig/actions';

export const ViewExecutions = ({ activeProduct }) => {
	document.title = 'Ordens | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const {
		rows,
		searchForm,
		paginationInfo,
		searchPaginate,
		tablePreferences,
		filtersTransactionTabs,
		updatedAt,
	} = useSelector(state => state.executions);
	const { token, username, accounts, userType } = useSelector(
		state => state.auth
	);
	const viewContinousStrategies = useSelector(
		state =>
			state.configs.globalSettings.userSettings.bullet
				.viewContinousStrategies
	);
	const refreshStrategiesNeeded = useSelector(
		state => state.configs.globalSettings.refreshStrategiesNeeded
	);

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'warning',
	});

	const [exportType, setExportType] = useState(
		searchForm.status === 'F' ? 'reports' : 'default'
	);

	const groupAccounts = getGroupAccountsOptions(accounts);

	const [isLoading, setIsLoading] = useState(false);

	function verifyIsStatusExecution(status) {
		if (status === 'F') {
			return true;
		}

		return false;
	}

	const isStatusExecution = verifyIsStatusExecution(searchForm.status);

	function getQueryParams(filters, isStatusExecution) {
		const queryParams = {
			...filters,
			viewContinousStrategies,
			detailed: 1,
		};

		/* Caso o usuário não selecione a data nenhuma será colocado a data de hoje por padrão */
		if (
			!queryParams.strategyId &&
			(!('dates' in queryParams) ||
				queryParams.dates.every(item => !item || !isValid(item)))
		) {
			const today = new Date();

			queryParams.dates = [today, today];
		}

		Object.keys(queryParams).forEach(key => {
			if (['all', '', null, undefined].includes(queryParams[key])) {
				delete queryParams[key];
			}

			if (key === 'active') {
				if (queryParams[key]) {
					queryParams.symbol = queryParams[key]?.trim();
				}

				delete queryParams[key];
			}

			if (
				key == 'dates' &&
				queryParams[key].every(item => !item || !isValid(item))
			) {
				delete queryParams[key];
			}
		});

		if (!isStatusExecution) {
			delete queryParams.dates;
		} else if (
			'dates' in queryParams &&
			Array.isArray(queryParams.dates) &&
			queryParams.dates.length === 2 &&
			isValid(queryParams.dates[0]) &&
			isValid(queryParams.dates[1])
		) {
			const [startDate, endDate] = queryParams.dates;

			queryParams['date_start'] = format(startDate, 'yyyy-MM-dd');

			queryParams['date_end'] = format(endDate, 'yyyy-MM-dd');

			delete queryParams.dates;
		}

		return queryParams;
	}

	const validateSearchForm = searchForm => {
		if (!searchForm.cblc) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe uma conta pra realizar a pesquisa!',
			});
			return;
		}

		return true;
	};

	async function handleSearch(
		paginationInfo = executionsInitialState.paginationInfo,
		searchForm,
		event
	) {
		if (event) event.preventDefault();

		if (validateSearchForm(searchForm)) {
			setIsLoading(true);

			const isStatusExecution = verifyIsStatusExecution(
				searchForm.status
			);

			try {
				const queryParams = {
					params: {
						...getQueryParams(searchForm, isStatusExecution),
						limit: paginationInfo.limit,
						offset: paginationInfo.offset,
					},
				};

				const api = isStatusExecution ? apiAWS : apiExec;
				const url = isStatusExecution
					? '/executions'
					: '/orders/filter';

				const { data } = await api.get(url, queryParams);

				const dataToMap = isStatusExecution
					? data.result
					: data.records;

				const formattedExecutions = dataToMap.map(element => {
					element.id = `${element.symbol}${element.strategyId}${element.orderId}${element.execId}`;

					return element;
				});

				const formattedRowsLength = formattedExecutions.length;

				if (formattedRowsLength >= 26) {
					formattedExecutions.pop();
				}

				const newPageInfo = {
					...executionsInitialState.paginationInfo,
					num_records: formattedRowsLength,
					page: paginationInfo.page,
					offset: paginationInfo.offset,
					total: paginationInfo.total
						? paginationInfo.total + 25
						: executionsInitialState.paginationInfo.total,
				};

				dispatch(setStateValues('filtersTransactionTabs', false));
				dispatch(setStateValues('updatedAt', refreshUpdateAt()));
				dispatch(setRows(formattedExecutions));
				dispatch(updatePaginationInfo(newPageInfo));
				dispatch(updateSearchPaginate(searchForm));
				setExportType(
					searchForm.status === 'F' ? 'reports' : 'default'
				);
			} catch (error) {
				console.log(error);

				dispatch(setRows([]));
				dispatch(setStateValues('updatedAt', refreshUpdateAt()));
				dispatch(setStateValues('filtersTransactionTabs', false));
				dispatch(
					updatePaginationInfo(executionsInitialState.paginationInfo)
				);
				dispatch(updateSearchPaginate(searchForm));

				setSnackbarState({
					isOpen: true,
					severity: 'error',
					message:
						'Problema ao processar solicitação! Tente novamente em alguns instantes.',
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	async function exportExecutionsReport() {
		if (exportType === 'default') {
			apiRef.current.exportDataAsCsv();
			return;
		}

		setIsLoading(true);

		const params = getQueryParams(searchPaginate, isStatusExecution);

		if (params.detailed) {
			delete params.detailed;
		}

		try {
			const body = {
				...params,
				report: 'trades',
			};

			const headers = {
				headers: { Authorization: `Bearer ${token}` },
			};

			const { data } = await apiCadastro.post('/reports', body, headers);

			window.open(data.url, '_self');
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	// Associonada  Quando há algum evento na seção de filtros do toolbar
	function onFilterModelChange(event) {
		dispatch(
			updateUserPreferences({ filter: { filterModel: { ...event } } })
		);

		postExecutionsPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	// Associonada quando mudamos uma coluna de posição (quando trocamos elas ao arrasta-la)
	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateUserPreferences({
				columns: { orderedFields: [...columnsOrder] },
			})
		);

		postExecutionsPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	//Associonada  quando mudamos a visibilidade de uma coluna
	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateUserPreferences({
				columns: { columnVisibilityModel: { ...event } },
			})
		);

		postExecutionsPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	// Associonado quando clicamos para realizar o sorte de uma coluna
	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };

		dispatch(updateUserPreferences(sorting));

		postExecutionsPreferences(username, sorting);
	}

	// Associonada a qualquer evento da tabela
	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updateUserPreferences(density));
			postExecutionsPreferences(username, density);
		}
	}

	useEffect(() => {
		if (activeProduct.code !== PRODUCT_TYPES.FLEX_ANALYTIC) {
			let formSearch = { ...searchForm };

			if (
				userType.id === USER_TYPES.FINAL_CLIENT &&
				!searchForm.cblc &&
				groupAccounts.length > 0
			) {
				const [firstCblc] = groupAccounts;

				formSearch.cblc = firstCblc;

				dispatch(updateOrdersFormValues({ cblc: firstCblc }));
			}

			if (formSearch.cblc) {
				handleSearch(paginationInfo, formSearch, null);
			}
		}
	}, []);

	useEffect(() => {
		if (activeProduct.code !== PRODUCT_TYPES.FLEX_ANALYTIC) {
			if (filtersTransactionTabs) {
				handleSearch(
					{
						...paginationInfo,
						page: 0,
						offset: 0,
						total: null,
					},
					searchForm,
					null
				);
			}
		}
	}, [filtersTransactionTabs]);

	useEffect(() => {
		if (refreshStrategiesNeeded) {
			handleSearch(paginationInfo, searchForm, null);

			dispatch(setIsRefreshStrategies(false));
		}
	}, [viewContinousStrategies]);

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 0 0.5rem',
			}}
		>
			<ViewExecutionsSearchForm
				isLoading={isLoading}
				handleSearch={handleSearch}
				groupAccounts={groupAccounts}
				setSnackbarState={setSnackbarState}
				activeProduct={activeProduct}
				isStatusExecution={isStatusExecution}
			/>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<StyledDataGridPro
					apiRef={apiRef}
					rows={rows}
					loading={isLoading}
					columns={executionsColumns}
					components={{
						Pagination: () =>
							ViewExecutionsCustomPagination(
								apiRef,
								paginationInfo
							),
						Toolbar: () =>
							ViewExecutionsToolbar(
								updatedAt,
								exportExecutionsReport,
								activeProduct,
								isLoading,
								exportType
							),
					}}
					initialState={{
						columns: tablePreferences?.columns,
						sorting: tablePreferences?.sorting,
						filter: tablePreferences?.filter,
						pagination: {
							paginationModel: { pageSize: 25, page: 0 },
						},
					}}
					density={tablePreferences?.density ?? 'standard'}
					pagination
					paginationMode="server"
					rowsPerPageOptions={[25]}
					page={paginationInfo.page}
					rowCount={paginationInfo.total}
					pageSize={25}
					onPageChange={newPage =>
						handleSearch(
							{
								...paginationInfo,
								page: newPage,
								offset: newPage * 25,
							},
							searchForm
						)
					}
					onFilterModelChange={event => onFilterModelChange(event)}
					onColumnOrderChange={event => onColumnOrderChange(event)}
					onColumnVisibilityModelChange={event =>
						onColumnVisibilityModelChange(event)
					}
					onSortModelChange={event => onSortModelChange(event)}
					onStateChange={event => onTableStateChange(event)}
				/>
			</Box>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'warning'}
				handleClose={() =>
					setSnackbarState({
						isOpen: false,
						message: '',
					})
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Grid>
	);
};
