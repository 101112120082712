export const continousEdit = bullet => {
	const strategyLegs = bullet.content.StrategyLegs.map(leg => {
		const currentStrategyLegs = {
			LegRefID: leg.LegRefID,
			LegQuantity: leg.LegQuantity,
			LegFirstTimeOut: leg.LegFirstTimeOut,
		};

		if (leg.LegMaxClipSize) {
			currentStrategyLegs.LegMaxClipSize = leg.LegMaxClipSize;
		}

		if (leg.LegResting) {
			currentStrategyLegs.LegResting = leg.LegResting;
		}

		return currentStrategyLegs;
	});

	const { content } = bullet;

	const editCustomParameters = {
		ContinuousExecMode: 'Y',
		EntryTriggerValue: content.CustomParameters.EntryTriggerValue,
		ExitTriggerValue: content.CustomParameters.ExitTriggerValue,
	};

	if (
		content.CustomParameters.BookDepth &&
		!isNaN(content.CustomParameters.BookDepth)
	) {
		editCustomParameters.BookDepth = parseInt(
			content.CustomParameters.BookDepth
		);
	}

	if (
		content.CustomParameters.IgnoreOffersLT &&
		!isNaN(content.CustomParameters.IgnoreOffersLT)
	) {
		editCustomParameters.IgnoreOffersLT = parseInt(
			content.CustomParameters.IgnoreOffersLT
		);
	}

	const bulletEdit = {
		StrategyCode: content.StrategyCode,
		CustomParameters: editCustomParameters,
		StrategyLegs: strategyLegs,
		ClOrdID: bullet.content.ClOrdID,
		EndTime: bullet.content.EndTime || bullet.values.EndTime,
	};

	if (bulletEdit.CustomParameters.EntryTriggerType === 11) {
		bulletEdit.CustomParameters.EntryTriggerValue = parseInt(
			bulletEdit.CustomParameters.EntryTriggerValue
		);
	}

	// Fazendo a validação do gatilho de saida para tipo Dif.Financeiro que não aceita valores fracionados
	if (bulletEdit.CustomParameters.ExitTriggerType === 11) {
		bulletEdit.CustomParameters.ExitTriggerValue = parseInt(
			bulletEdit.CustomParameters.ExitTriggerValue
		);
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('bulletEdit continous', bulletEdit);
	}

	return bulletEdit;
};
