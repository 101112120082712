import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	AccountCircle,
	Api,
	ExpandLess,
	ExpandMore,
	Key,
	LockReset,
	Logout,
	Password,
	Settings,
	Workspaces,
	VpnKey,
} from '@mui/icons-material';
import {
	Box,
	Collapse,
	colors,
	Divider,
	Grid,
	List,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';

import { UserAvatar } from './UserAvatar';
import { LogoutDialog } from 'components/LogoutDialog';

import { doLogout } from 'store/modules/auth/actions';
import { changeActiveProduct } from 'utils/changeActiveProduct';
import { strategyInitial } from 'store/modules/strategyFloating/actions';
import { setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';

const StyledMenuItem = ({ children, sx = {}, ...rest }) => {
	return (
		<MenuItem
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: 1.5,
				p: 0.75,
				borderRadius: 1,
				...sx,
			}}
			{...rest}
		>
			{children}
		</MenuItem>
	);
};

export const UserMenu = ({ open, anchorEl, onClose, handleOpenModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { username, name, email, sponsorName, portalAccess } = useSelector(
		state => state.auth
	);

	const { userSignatures, activeProduct } = useSelector(
		state => state.products
	);

	const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
	const [isPasswordActionsOpen, setIsPasswordActionsOpen] = useState(false);

	function handleToddlePasswordActions() {
		setIsPasswordActionsOpen(state => !state);
	}

	function handleLogout() {
		dispatch(strategyInitial());
		dispatch(doLogout(history));
		onClose();
	}

	function handleOpenLogoutDialog() {
		setIsLogoutDialogOpen(true);
	}

	function handleCloseModal() {
		setIsLogoutDialogOpen(false);
	}

	const menuActions = [
		{
			label: 'Perfil',
			isVisible: true,
			icon: <AccountCircle />,
			action: () => handleOpenModal('profile'),
		},
		{
			label: 'Configurações',
			isVisible: true,
			icon: <Settings />,
			action: () => dispatch(setIsGlobalSettingsOpen(true, 'bullet')),
		},
		{
			label: (
				<>
					<span>Senha e assinatura eletrônica</span>
					{isPasswordActionsOpen ? <ExpandLess /> : <ExpandMore />}
				</>
			),
			isVisible: true,
			icon: <Key />,
			action: () => handleToddlePasswordActions(),
		},
	];

	const passwordActions = [
		{
			label: 'Trocar senha',
			isVisible: !portalAccess,
			disabled: portalAccess,
			icon: <VpnKey />,
			action: () => handleOpenModal('change-password'),
		},
		{
			label: 'Trocar assinatura eletrônica',
			isVisible: true,
			icon: <Password />,
			action: () => handleOpenModal('change-signature'),
		},
		{
			label: 'Esqueci ou não tenho assinatura',
			isVisible: true,
			icon: <LockReset />,
			action: () => handleOpenModal('send-email'),
		},
		{
			label: 'Gerar token para integração',
			isVisible: portalAccess,
			disabled: !portalAccess,
			icon: <Api />,
			action: () => handleOpenModal('access-token'),
		},
	];

	const formattedSponsorName =
		String(sponsorName).charAt(0).toUpperCase() +
		String(sponsorName).slice(1);

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				className="user-menu"
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Box sx={{ mt: 1 }}>
					<Grid
						item
						container
						sx={{
							p: '1rem 0.5rem',
							flexDirection: 'column',
							overflowY: 'scroll',
							'::-webkit-scrollbar': {
								display: 'none',
							}, //  Chrome e Safari
							'-ms-overflow-style': 'none', // Edge
							'scrollbar-width': 'none', // Firefox
						}}
					>
						<Grid
							container
							item
							sx={{
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 0.5,
							}}
						>
							<UserAvatar
								sx={{
									width: 56,
									height: 56,
									alignSelf: 'center',
									border: '2px solid white',
								}}
							/>

							<Typography
								variant="subtitle1"
								sx={{ fontSize: '1.1rem' }}
							>
								<strong>{name}</strong>
							</Typography>

							<Typography
								variant="subtitle1"
								sx={{
									color: colors.grey[500],
									fontSize: '1.1rem',
								}}
							>
								{username} | {formattedSponsorName}
							</Typography>

							<Typography
								variant="subtitle1"
								sx={{ fontSize: '1.1rem' }}
							>
								{email}
							</Typography>
						</Grid>

						<Divider sx={{ mt: 1 }} />

						<List>
							{menuActions
								.filter(item => item.isVisible)
								.map(item => (
									<StyledMenuItem
										key={item.label}
										onClick={item.action}
									>
										{item.icon}

										{item.label}
									</StyledMenuItem>
								))}

							<Collapse
								in={isPasswordActionsOpen}
								timeout="auto"
								unmountOnExit
							>
								<List disablePadding>
									{passwordActions
										.filter(item => item.isVisible)
										.map(item => (
											<StyledMenuItem
												key={item.label}
												onClick={item.action}
												disabled={item.disabled}
												sx={{ pl: 2 }}
											>
												{item.icon}

												{item.label}
											</StyledMenuItem>
										))}
								</List>
							</Collapse>
						</List>

						<Divider />

						<List sx={{ pb: 0 }}>
							<StyledMenuItem
								key="logout"
								onClick={handleOpenLogoutDialog}
							>
								<Logout />
								Sair
							</StyledMenuItem>
						</List>

						{userSignatures.length > 0 && (
							<>
								<Divider sx={{ mt: 1 }} />

								<Typography
									variant="body1"
									sx={{
										mt: 1,
										ml: 0.625,
										color: colors.grey[300],
									}}
								>
									Assinaturas
								</Typography>

								<List sx={{ mt: -0.5, pb: 0 }}>
									{userSignatures.map(signature => (
										<StyledMenuItem
											key={signature.id}
											onClick={() =>
												changeActiveProduct(
													signature,
													history
												)
											}
											disabled={
												signature.product.code ===
												activeProduct.code
											}
										>
											<Workspaces />

											{signature.product.name}
										</StyledMenuItem>
									))}
								</List>
							</>
						)}
					</Grid>
				</Box>
			</Menu>

			<LogoutDialog
				isOpen={isLogoutDialogOpen}
				handleClose={handleCloseModal}
				handleLogout={handleLogout}
			/>
		</>
	);
};
