import { historicalDataActionTypes } from './actionTypes';

export const resetHistoricalDataState = () => ({
	type: historicalDataActionTypes.RESET_STATE,
});

export const setHistoricalData = historicalData => ({
	type: historicalDataActionTypes.SET_HISTORICAL_DATA,
	payload: { historicalData },
});

export const updateHistoricalDataExportForm = newValues => ({
	type: historicalDataActionTypes.UPDATE_EXPORT_FORM,
	payload: { newValues },
});
