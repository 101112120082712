import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';
import {
	Grid,
	Typography,
	Box,
	Divider,
	CircularProgress,
} from '@mui/material';

import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';
import { StyledDataGridPro } from 'components/Scanner/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { RadarSearchForm } from './RadarSearchForm';
import { RadarToolbar } from './RadarToolbar';
import { ChartBarLayer } from './ChartBarLayer';
import { ChartMaxHistory } from './ChartMaxHistory';

import { getFormmatedData, getRadarColumns } from 'utils/tablesColumns/radar';
import { setStateValues } from 'store/modules/radar/actions';
import { radarInitialState } from 'store/modules/radar/reducer';

import { apiAWS } from 'services/api';

const OverlayNoRows = () => NoRowsOverlay('Nenhuma pesquisa encontrada!');

export const Radar = () => {
	document.title = 'Radar Dividendos | Flexscan';

	const dispatch = useDispatch();

	const { historyData, searchApplied, layersData } = useSelector(
		state => state.radarModule
	);

	const [isLoading, setIsLoading] = useState(false);

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'warning',
	});

	function getQueryParams(filters) {
		const queryParams = { ...filters };

		Object.keys(queryParams).forEach(key => {
			if (['all', ''].includes(queryParams[key])) {
				delete queryParams[key];
			}

			if (key === 'layer') {
				const layerValue = queryParams[key];

				delete queryParams[key];

				queryParams.camadas = layerValue;
			}

			if (
				['init_date', 'end_date'].includes(key) &&
				queryParams[key] !== ''
			) {
				const formattedDate = format(queryParams[key], 'yyyy-MM-dd');
				queryParams[key] = formattedDate;
			}
		});

		return queryParams;
	}

	const validateSearchForm = searchForm => {
		if (!searchForm.symbol1) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'Informe o primeiro instrumento para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.symbol2) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'Informe o segundo instrumento para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.symbol1_prop) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'Informe a primeira composição para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.symbol2_prop) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'Informe a segunda composição para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.init_date) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe a data de inicio para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.end_date) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe a data de fim para realizar a pesquisa!',
			});
			return;
		}

		if (!isValid(searchForm.init_date) || !isValid(searchForm.init_date)) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe uma data valida para realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.layer) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe as camadas para realizar a pesquisa!',
			});
			return;
		}

		return true;
	};

	async function handleSearch(event, searchForm) {
		if (event) event.preventDefault();

		if (validateSearchForm(searchForm)) {
			setIsLoading(true);

			try {
				const queryParams = {
					params: {
						...getQueryParams(searchForm),
					},
				};

				const { data } = await apiAWS.get(
					'/radar/pair-history',
					queryParams
				);

				const dataFormatted = getFormmatedData(data);

				const newSearchApplied = {
					symbol1: searchForm.symbol1,
					symbol2: searchForm.symbol2,
					symbol1_prop: searchForm.symbol1_prop,
					symbol2_prop: searchForm.symbol2_prop,
					layer: searchForm.layer,
					init_date: searchForm.init_date,
					end_date: searchForm.end_date,
					percentSymbol1: dataFormatted.percentSymbol1,
					percentSymbol2: dataFormatted.percentSymbol2,
				};

				dispatch(setStateValues('searchApplied', newSearchApplied));
				dispatch(
					setStateValues('historyData', dataFormatted.historyData)
				);
				dispatch(setStateValues('layersData', dataFormatted.layers));
			} catch (error) {
				console.log(error);

				dispatch(
					setStateValues(
						'searchApplied',
						radarInitialState.searchApplied
					)
				);
				dispatch(
					setStateValues('historyData', radarInitialState.historyData)
				);
				dispatch(
					setStateValues('layersData', radarInitialState.layers)
				);

				setSnackbarState({
					isOpen: true,
					severity: 'error',
					message:
						'Problema ao processar solicitação! Tente novamente em alguns instantes.',
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const radarColumns = getRadarColumns(searchApplied);

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 1rem',
			}}
		>
			<Typography variant="h5">
				<strong>Radar Dividendos</strong>
			</Typography>

			<Divider sx={{ mt: 1 }} />

			<RadarSearchForm
				isLoading={isLoading}
				handleSearch={handleSearch}
			/>

			{isLoading ? (
				<Box
					sx={{
						width: '100%',
						height: '50vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					{layersData && historyData.length > 0 ? (
						<>
							<ChartBarLayer loading={isLoading} />

							<Divider sx={{ my: 1 }} />

							<ChartMaxHistory loading={isLoading} />

							<Divider sx={{ my: 1 }} />

							<Box
								my={5}
								sx={{
									width: '100%',
									height: '50vh',
								}}
							>
								<Typography
									variant="h4"
									fontWeight="bold"
									mb={2}
								>
									Tabela de Dados
								</Typography>
								<StyledDataGridPro
									loading={isLoading}
									rows={historyData}
									columns={radarColumns}
									components={{
										Toolbar: () => RadarToolbar(),
									}}
								/>
							</Box>
						</>
					) : (
						<Box
							sx={{
								width: '100%',
								height: '50vh',
							}}
						>
							<OverlayNoRows />
						</Box>
					)}
				</>
			)}

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'warning'}
				handleClose={() =>
					setSnackbarState({
						isOpen: false,
						message: '',
					})
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Grid>
	);
};
