import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { Box, Grid, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

import { ContinousLeg } from './ContinousLeg';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

export const ContinousEntryParams = ({ isAdvancedBullet, setPaperInfo }) => {
	const bullet = useSelector(state => state.bottomBullet.bullet);

	return (
		<>
			<Grid
				item
				container
				xs={isAdvancedBullet ? 6 : 5}
				gap={isAdvancedBullet ? 1.5 : 2.5}
			>
				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Typography variant="subtitle1">
						Parâmetros de execução
					</Typography>

					<LightHTMLTooltip
						element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
					>
						<DescriptionTooltip
							label="Compra"
							description={t('bullets.continous.buy_leg_side')}
						/>

						<DescriptionTooltip
							label="Venda"
							description={t('bullets.continous.sell_leg_side')}
						/>

						<DescriptionTooltip
							label="Clip Máximo"
							description={t('bullets.continous.clip_size')}
						/>

						<DescriptionTooltip
							label="Profundidade do Book"
							description={t('bullets.continous.book_depth')}
						/>

						<DescriptionTooltip
							label="Compensação"
							description={t('bullets.continous.compensate_exec')}
						/>

						<DescriptionTooltip
							label="Ignorar Múltiplo de Lote"
							description={t('bullets.continous.ignore_offers')}
						/>

						<DescriptionTooltip
							label="Entrada"
							description={t('bullets.continous.entry_value')}
						/>

						<DescriptionTooltip
							label="Sáida"
							description={t('bullets.continous.exit_value')}
						/>
					</LightHTMLTooltip>
				</Box>

				{bullet.content.StrategyLegs.map((_, index) => (
					<ContinousLeg
						key={index}
						currentIndex={index}
						setPaperInfo={setPaperInfo}
						isAdvancedBullet={isAdvancedBullet}
					/>
				))}
			</Grid>
		</>
	);
};
