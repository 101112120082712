import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
	Box,
	Chip,
	Dialog,
	Divider,
	Typography,
	DialogContent,
	DialogContentText,
	Button,
	Grid,
	colors,
	DialogTitle,
} from '@mui/material';

import { UserAvatar } from './UserAvatar';
import { getFormattedDate } from 'utils/getParsedDate';

const AccountsList = ({ isOpen, onClose, accounts, ...rest }) => {
	return (
		<Dialog open={isOpen} onClose={onClose} {...rest}>
			<DialogTitle>Todas as contas associadas</DialogTitle>

			<DialogContent>
				<Grid
					container
					item
					spacing={1}
					sx={{ maxWidth: 400, maxHeight: 400 }}
				>
					{accounts.map(account => (
						<Grid key={account} item>
							<Chip label={account} sx={{ width: 90 }} />
						</Grid>
					))}
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export const UserProfile = ({ isOpen, handleClose, handleOpenContract }) => {
	const {
		username,
		name,
		email,
		cpf,
		phoneCel,
		accounts,
		operatorCode,
		userType,
		registrationDate,
	} = useSelector(state => state.auth);
	const { userSignatures, activeProduct, userContracts } = useSelector(
		state => state.products
	);

	const [isAccountListOpen, setIsAccountListOpen] = useState(false);

	const filteredAccounts = [
		...new Set(accounts.map(account => account.code)),
	];

	const hasTooMuchAccounts = filteredAccounts.length > 3;

	function getAccountsLabel(accounts) {
		let accountsToRender = accounts;

		if (hasTooMuchAccounts) {
			accountsToRender = accounts.slice(0, 3);
		}

		return accountsToRender.map(
			(account, index) => `${account}
			${index + 1 < accountsToRender.length ? ', ' : ''}`
		);
	}

	const accountsLabel = getAccountsLabel(filteredAccounts);
	const formattedRegistrationDate = getFormattedDate(
		registrationDate,
		'dd/MM/yyyy'
	);

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogContent>
					<Box
						sx={{
							gap: 1,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Box
								sx={{
									gap: 2,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<UserAvatar />

								<Box
									sx={{
										mr: 2,
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Typography variant="h5">{name}</Typography>

									<DialogContentText
										sx={{ fontSize: '1.1rem' }}
										variant="subtitle1"
									>
										{activeProduct.name}
									</DialogContentText>

									<Typography
										sx={{ fontSize: '1.1rem' }}
										variant="subtitle1"
									>
										{username}
									</Typography>
								</Box>
							</Box>

							<Chip
								label={userType.name}
								variant="outlined"
								sx={{ fontSize: '1rem', px: 2 }}
							/>
						</Box>

						<Divider />

						<Box
							sx={{
								gap: 0.5,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{ fontSize: '1.1rem' }}
							>
								<strong>E-mail:</strong> {email}
							</Typography>

							<Typography
								variant="subtitle1"
								sx={{ fontSize: '1.1rem' }}
							>
								<strong>CPF:</strong> {cpf}
							</Typography>

							{phoneCel && (
								<Typography
									variant="subtitle1"
									sx={{ fontSize: '1.1rem' }}
								>
									<strong>Telefone:</strong> {phoneCel}
								</Typography>
							)}

							{operatorCode && (
								<Typography
									variant="subtitle1"
									sx={{ fontSize: '1.1rem' }}
								>
									<strong>Código operador:</strong>{' '}
									{operatorCode}
								</Typography>
							)}

							<Box sx={{ display: 'flex', gap: 1 }}>
								<Typography
									variant="subtitle1"
									sx={{ fontSize: '1.1rem' }}
								>
									<strong>Conta(s):</strong> {accountsLabel}
								</Typography>

								{hasTooMuchAccounts && (
									<Typography
										variant="subtitle1"
										onClick={() =>
											setIsAccountListOpen(true)
										}
										sx={{
											color: colors.blue[500],
											cursor: 'pointer',
											fontSize: '1.1rem',
										}}
									>
										...Ver todas
									</Typography>
								)}
							</Box>
						</Box>

						{userSignatures.length > 0 && (
							<>
								<Divider />
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											flex: 1,
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												flex: 1,
											}}
										>
											<Typography
												variant="h6"
												sx={{ mb: -0.5 }}
											>
												Assinaturas
											</Typography>
										</Box>

										{userSignatures.map(signature => {
											const signatureContract =
												userContracts.contractsData.find(
													contract =>
														contract.product ===
														signature.product.code
												);

											const isContractPending =
												signatureContract?.have_pendencies;

											const contractLabelColor =
												isContractPending
													? colors.red[400]
													: colors.green[500];

											const formattedSignatureDate =
												getFormattedDate(
													signature.createDate,
													'dd/MM/yyyy'
												);

											return (
												<Box key={signature.id}>
													<Typography
														variant="subtitle1"
														sx={{
															fontSize: '1.1rem',
															mt: 1,
														}}
													>
														{signature.product.name}
													</Typography>

													<DialogContentText variant="subtitle1">
														Desde de{' '}
														{formattedSignatureDate}
													</DialogContentText>

													<Box
														sx={{
															display: 'flex',
															alignItems:
																'center',
														}}
													>
														<Button
															sx={{ ml: -1 }}
															onClick={() =>
																handleOpenContract(
																	signature
																		.product
																		.code
																)
															}
														>
															Termos de uso
														</Button>

														<Typography
															variant="subtitle2"
															color={
																contractLabelColor
															}
														>
															{isContractPending
																? '(PENDENTE)'
																: '(ASSINADO)'}
														</Typography>
													</Box>
												</Box>
											);
										})}
									</Box>
								</Box>
							</>
						)}
					</Box>

					<Box sx={{ mt: 2 }}>
						<DialogContentText>
							Usuário desde {formattedRegistrationDate}
						</DialogContentText>
					</Box>
				</DialogContent>
			</Dialog>

			{isAccountListOpen && (
				<AccountsList
					accounts={filteredAccounts}
					isOpen={isAccountListOpen}
					onClose={() => setIsAccountListOpen(false)}
				/>
			)}
		</>
	);
};
