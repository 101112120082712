import React from 'react';

import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';

import { ChatLog } from './ChatLog';
import { ChatUserInputForm } from './ChatUserInputForm';
import { ChatActionsBar } from './ChatActionsBar';

export const Chat = ({ isOpen, onClose }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Drawer
				anchor="left"
				open={isOpen}
				onClose={onClose}
				PaperProps={{
					sx: {
						position: 'fixed',
						bottom: 64,
						top: 'auto',
						width: isSmallScreen ? '95dvw' : '64em',
						height: '45rem',
						borderTopRightRadius: '8px',
						boxShadow: 3,
						left: isSmallScreen ? 10 : 56,
						borderRadius: 2,
					},
				}}
				ModalProps={{ keepMounted: true }}
			>
				<Box
					sx={{
						display: 'grid',
						flex: 1,
						gridTemplateColumns: '1fr auto',
					}}
				>
					<ChatLog />

					<ChatActionsBar onClose={onClose} />
				</Box>

				<ChatUserInputForm />
			</Drawer>
		</>
	);
};
