import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getStrategy } from '../utils';
import { roundNumber } from 'utils/numberFormat';
import { calculateSpread } from '../../../pages/Simulator/handleBullet';

export const CellFooter = memo(function CellFooter({ accessor, className }) {
	const strategy = useSelector(getStrategy);

	const [value, setValue] = useState(0);

	const isPxAccessor = ['exitPx', 'entryPx', 'theorPx'].includes(accessor);

	useEffect(() => {
		const newValue = getValue(accessor);

		if (newValue === value) {
			return;
		}

		setValue(newValue);
	}, [strategy?.positions, accessor, value]);

	function getValue(accessor) {
		const priceAccessors = ['exitPx', 'entryPx', 'theorPx'];
		const greeksAccessors = ['rho', 'vega', 'theta', 'gamma', 'delta'];

		if (greeksAccessors.includes(accessor)) {
			if (
				strategy?.positions.find(
					i =>
						i.checked &&
						i[strategy?.showTypeGreeks + 'Greeks'][accessor] ===
							null
				)
			) {
				return null;
			}

			return strategy?.positions
				.filter(i => i.checked)
				.reduce(
					(acc, item) =>
						acc +
						item[strategy?.showTypeGreeks + 'Greeks'][accessor] *
							(item?.qtty || 1),
					0
				);
		} else if (priceAccessors.includes(accessor)) {
			if (accessor !== 'theorPx') {
				if (
					strategy?.positions.some(
						i =>
							i.checked && !i[accessor] && !i[`${accessor}Locked`]
					)
				) {
					return null;
				}
			}

			return calculateSpread(strategy?.positions, accessor, true);
		} else {
			if (
				strategy?.positions.find(
					i =>
						i.checked &&
						!i[accessor] &&
						i[accessor] !== 0 &&
						(!i.entryPxLocked || !i.exitPxLocked)
				)
			) {
				return null;
			}

			return strategy?.positions
				.filter(i => i.checked && !!i[accessor])
				.reduce((acc, item) => acc + item[accessor], 0);
		}
	}

	function getLabel(accessor, value) {
		if (!value) {
			if (accessor === 'theorPx') {
				return '0,0000';
			}

			if (accessor === 'result' && value === 0) {
				return '0,00';
			}

			return 'N/D';
		}

		if (isPxAccessor) {
			return `
				${value > 0 ? 'Cr' : 'Db'}
				${roundNumber(Math.abs(value), 4)}
			`;
		}

		return roundNumber(value) || 'N/D';
	}

	const label = getLabel(accessor, value);
	const labelColor = value > 0 ? '#31C593' : value < 0 ? '#ff5a5a' : null;
	const textAlignment =
		accessor === 'result' || isPxAccessor ? 'right' : 'left';

	return (
		<div
			className={className}
			style={{
				padding: '4px',
				fontSize: '11px',
				width: '100%',
				color: isPxAccessor && labelColor,
				textAlign: textAlignment,
				paddingRight: ['exitPx', 'entryPx'].includes(accessor)
					? '35px'
					: '5px',
			}}
		>
			{label}
		</div>
	);
});
