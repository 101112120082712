import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { Book, SwapVert } from '@mui/icons-material';

import {
	setMarketPriceLoading,
	strategyUpdate,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import ComboBoxInput from '../ComboBoxInput';

import { ButtonBuySell } from '../MaterialStyles/style';
import { additionalUpdate } from '../../../store/modules/bottomBullet/actions';
import DecimalInput from '../DecimalInput';
import paperValidator from 'utils/paperValidation';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import { apiEls } from 'services/api';

const VolLeg = ({
	onSuggestionsFetchRequested,
	setPaperInfo,
	index,
	onlyCall,
	onlyOptions,
	callbackMarketPrice,
	stepFunction,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const [legResting, setLegResting] = useState(false);
	const [updateMarketPrice, setUpdateMarketPrice] = useState(false);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const { t } = useTranslation();

	if (bullet.editing || bullet.clone) {
		if (!updateMarketPrice) {
			setUpdateMarketPrice(true);
			callbackMarketPrice(
				bullet.content.StrategyLegs,
				bullet.content.CustomParameters.Trigger
			);
		}

		const tempLegResting =
			(bullet.content.StrategyLegs[index].LegResting ?? 'N') === 'Y';
		if (legResting !== tempLegResting) {
			setLegResting(tempLegResting);
		}
	}

	const handleChange = (type, subType, value, indexLeg) => {
		const hash = { ...bullet };
		if (type === 'Leg') {
			hash.content.StrategyLegs[indexLeg][subType] = value;
		} else if (type === 'Custom') {
			hash.content.CustomParameters[subType] = value;
		}

		if (subType === 'LegSymbol') {
			callbackMarketPrice(
				hash.content.StrategyLegs,
				hash.content.CustomParameters.Trigger
			);
		}

		if (subType === 'LegQuantity' || subType === 'LegSymbol') {
			hash.content.CustomParameters.TriggerValue = null;
			dispatch(setMarketPriceLoading(true));
		}

		dispatch(additionalUpdate('StrategyInfos', hash.StrategyInfos));
		dispatch(strategyUpdate(hash.content));
	};

	const setPaperInfoInterceptor = (tempIndex, data) => {
		if (data.optionType !== 'NOT A OPTION') {
			setPaperInfo(tempIndex, data);
			apiEls.get(`/search/${data.underlyingSymbol}`).then(response => {
				if (response && response.data._source) {
					// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
					if (['DOL', 'WDO'].includes(response.data._source.asset)) {
						response.data._source.tickSizeDenominator = 2;
					}
					if (response.data._source.isContinuous) {
						response.data._source.symbol = response.data._source.continuousRef;
					}

					setPaperInfo(tempIndex + 1, {
						...response.data._source,
					});
				}
				callbackMarketPrice(
					bullet.content.StrategyLegs,
					bullet.content.CustomParameters.Trigger
				);
			});
		}
	};

	const getSymbolLabel = legIndex => {
		return legIndex === 0 ? 'Opção' : 'Ativo';
	};

	const isFieldDisableBySymbol = () => {
		return isDisableBySymbol(bullet, index);
	};

	const changeLegSide = () => {
		bullet.content.CustomParameters.TriggerValue = null;

		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);

		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[1].LegSide === '1'
							? '2'
							: '1',
				},
				1
			)
		);
	};

	return (
		<>
			<Grid item xs={3}>
				<Box display="flex">
					<Box pb={0.5}>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(
										bullet.content.StrategyLegs[index]
											.LegSymbol
									)
								}
								disabled={
									symbolOpenBook(
										bullet.StrategyInfos[0],
										bullet
									) || lockBook
								}
							>
								<Book />
							</IconButton>
						</Tooltip>
					</Box>
					<ComboBoxInput
						name="paperField"
						label={getSymbolLabel(index)}
						setPaperInfo={setPaperInfoInterceptor}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								index
							)
						}
						index={index}
						value={bullet.content.StrategyLegs[index].LegSymbol}
						disabled={index === 1 || bullet.content.ClOrdID}
						isPaper
					/>
				</Box>
			</Grid>
			<Grid item xs={2}>
				<ButtonBuySell
					name="singleSideButtom"
					variant="contained"
					fullWidth
					size="small"
					color={
						bullet.content.StrategyLegs[index].LegSide === '1'
							? 'primary'
							: 'secondary'
					}
					startIcon={<SwapVert />}
					disabled={paperValidator(
						bullet.StrategyInfos[index],
						bullet,
						'singleSideButtom'
					)}
					onClick={() => changeLegSide()}
				>
					{bullet.content.StrategyLegs[index].LegSide === '1'
						? 'Compra'
						: 'Venda'}
				</ButtonBuySell>
			</Grid>
			{index === 0 && (
				<>
					<Grid item xs={3}>
						<DecimalInput
							name="qtyField"
							disabled={isFieldDisableBySymbol() || index === 1}
							label="Quantidade"
							minVal={
								bullet.StrategyInfos[index].minOrderQty || 0
							}
							maxVal={
								bullet.StrategyInfos[index].maxOrderQty ||
								99999999
							}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegQuantity" // subtype
							stepSize={
								bullet.StrategyInfos[index].roundLot ||
								bullet.StrategyInfos[index].Cst_MinOrderQuantity
							} // incremento
							valueToStep={
								bullet.content.StrategyLegs[index].LegQuantity
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[index].LegQuantity
							}
							legIndex={index}
							onChange={e =>
								handleChange(
									'Leg',
									'LegQuantity',
									parseInt(e.value),
									index
								)
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<DecimalInput
							name="clipField"
							disabled={isFieldDisableBySymbol()}
							label="Clip"
							iconTooltip={t('bullets.general.clip')}
							minVal={
								bullet.StrategyInfos[index].minOrderQty || 0
							}
							maxVal={
								bullet.StrategyInfos[index].maxOrderQty ||
								99999999
							}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegMaxClipSize" // subtype
							stepSize={
								bullet.StrategyInfos[index].roundLot ||
								bullet.StrategyInfos[index].Cst_MinOrderQuantity
							} // incremento
							valueToStep={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							}
							legIndex={index}
							onChange={e =>
								handleChange(
									'Leg',
									'LegMaxClipSize',
									parseInt(e.value),
									index
								)
							}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default VolLeg;
