import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress } from '@mui/material';
import {
	Container,
	MetricsContainer,
	PositionListContainer,
	ChartContainer,
	CustomSplitPane,
} from './styles';
import './styles.css';
import Metrics from './Metrics';
import TableTools from './ToolsTable';
import Chart from './components/Chart';
import PositionList from './PositionList';

import { getStrategies, setTimerUpdate } from 'store/modules/simulator/actions';

export default function Simulator({ registerCallback, unRegisterCallback }) {
	//Workaround to force rerender
	const [showCircularProgress, setShowCircularProgress] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	document.title = t('pageTitle.simulator');
	const activeStrategy = useSelector(
		state => state.simulator?.activeStrategy
	);
	const workspaceCreated = useSelector(
		state => state.simulator?.workspaceCreated
	);

	const underSymbol = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy]
				?.underSymbol
	);

	const [addedLeg, setAddedLeg] = useState(0);

	function addLegCB_fn(numOfLegs) {
		setAddedLeg(numOfLegs);
	}

	useEffect(() => {
		setAddedLeg(0);
	}, [activeStrategy]);

	useEffect(() => {
		if (!workspaceCreated) {
			const strats = getStrategies();
			if (strats) dispatch(strats);
		}
	}, []);

	return (
		<Container id="container-split">
			<CustomSplitPane
				key={`${underSymbol}-${activeStrategy}`}
				direction="vertical"
				sizes={[50, 50]}
				minSize={80}
				gutterSize={8}
			>
				<PositionListContainer>
					<TableTools
						registerCallback={registerCallback}
						unRegisterCallback={unRegisterCallback}
						addLeg={addLegCB_fn}
						setShowCircularProgressCallback={
							setShowCircularProgress
						}
					/>

					{underSymbol && (
						<PositionList
							registerCallback={registerCallback}
							unRegisterCallback={unRegisterCallback}
						/>
					)}
					{showCircularProgress && (
						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'center',
								aligntems: 'center',
								zIndex: 10,
								verticalAlign: 'middle',
							}}
						>
							<CircularProgress />
						</div>
					)}
				</PositionListContainer>
				{activeStrategy !== null && underSymbol ? (
					<Box
						sx={{
							display: 'flex',
							flex: 1,
							borderTop: '1px solid #4d4d4d',
							background: '#212121',
						}}
					>
						<ChartContainer id="chart-container">
							<Chart addedLeg={addedLeg} />
							<TimerComponent />
						</ChartContainer>
						<MetricsContainer id="metrics-container">
							<Metrics />
						</MetricsContainer>
					</Box>
				) : (
					<div />
				)}
			</CustomSplitPane>
		</Container>
	);
}

function TimerComponent() {
	const dispatch = useDispatch();
	const UPDATE_EVERY = 1000;

	useEffect(() => {
		const intervalHandleUpdate = setInterval(() => {
			dispatch(setTimerUpdate(new Date().getTime()));
		}, UPDATE_EVERY);
		return () => clearInterval(intervalHandleUpdate);
	}, []);

	return '';
}
