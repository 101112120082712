import React from 'react';
import { format, isValid, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { Container } from './styles';
import { ModalTemplate } from '../Template';

import {
	setContractToAccept,
	setContractVisibility,
} from 'store/modules/products/actions';

export const Contract = ({ open, contractCode, handleClose }) => {
	const dispatch = useDispatch();

	const contracts = useSelector(
		state => state.products.userContracts.contractsData
	);

	const contract = contracts.find(
		contract => contract.product === contractCode
	);

	const { file, datetime, have_pendencies } = contract;

	if (have_pendencies) {
		dispatch(setContractToAccept(contract));
		dispatch(setContractVisibility(true));
		handleClose();
		return;
	}

	function getContractDate(date) {
		const parsedDate = parse(
			date,
			'yyyy-MM-dd HH:mm:ss.SSSSSS',
			new Date()
		);

		if (isValid(parsedDate)) {
			const formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm:ss');

			return formattedDate;
		}

		return null;
	}

	const contractDate = getContractDate(datetime);

	return (
		<ModalTemplate
			open={open}
			closeButton={handleClose}
			title="Contrato de termo de uso do serviço"
		>
			<Container>
				<iframe src={file} />

				{contractDate && (
					<Typography variant="h6">
						Assinado em: {contractDate}
					</Typography>
				)}
			</Container>
		</ModalTemplate>
	);
};
