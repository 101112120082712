import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Book } from '@mui/icons-material';

import addWidgetFromButton from 'utils/addWidgetFromButton';
import paperValidator from 'utils/paperValidation';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import DecimalInput from '../DecimalInput';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const MarketMakerLeg = ({
	setPaperInfo,
	onSuggestionsFetchRequested,
	onlyOptions,
	onlyCall,
	stepFunction,
	handleChange,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const { t } = useTranslation();

	const setPaperInfoInterceptor = (tempIndex, data) => {
		setPaperInfo(tempIndex, data);
		setPaperInfo(1, {
			...data,
		});
	};

	return (
		<Grid item xs={6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12} style={{ marginLeft: '2px' }}>
					<Typography variant="subtitle1">Entrada</Typography>
				</Grid>
				<Box pb={0.5}>
					<Tooltip title="Abrir book">
						<IconButton
							size="small"
							onClick={() =>
								addWidgetFromButton(
									bullet.content.StrategyLegs[0].LegSymbol
								)
							}
							disabled={
								symbolOpenBook(
									bullet.StrategyInfos[0],
									bullet
								) || lockBook
							}
						>
							<Book />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid item xs={3}>
					<ComboBoxInput
						name="paperField"
						label="Ativo"
						setPaperInfo={setPaperInfoInterceptor}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								0
							)
						}
						value={
							bullet.content.StrategyLegs[0].LegSymbol
								? bullet.content.StrategyLegs[0].LegSymbol.toUpperCase()
								: ''
						}
						disabled={bullet.content.ClOrdID}
						isPaper
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						name="LegQuantity"
						label="Quantidade máxima"
						iconTooltip={t('bullets.marketmaker.quantity')}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Leg" // type
						stepSubType="LegQuantity" // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={bullet.content.StrategyLegs[0].LegQuantity} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegQuantity}
						onChange={e =>
							handleChange(
								'Leg',
								'LegQuantity',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						name="LegMaxClipSize"
						label="Clip máximo"
						iconTooltip={t('bullets.marketmaker.max_clip')}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType={'Leg'} // type
						stepSubType={'LegMaxClipSize'} // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.StrategyLegs[0].LegMaxClipSize
						} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegMaxClipSize}
						onChange={e =>
							handleChange(
								'Leg',
								'LegMaxClipSize',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MarketMakerLeg;
