import styled from 'styled-components';

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	padding-top: 5px;

	div:first-child {
		label > span > span {
			margin-left: -4px;
		}
	}

	@media screen and (min-width: 1440px) {
		max-width: 600px;
	}
`;

export const CheckboxItem = styled.div`
	display: flex;
	align-items: center;
	margin-left: -4px; /* Rever isso posteriormente */

	@media screen and (max-width: 1440px) {
		width: ${props => props.handleWidth};
	}
`;
