import React, { useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';

import { apiEls } from 'services/api';

export const AutoCompleteSymbolSearchForm = ({
	fieldName,
	inputValue,
	options,
	setSuggestions,
	handleChangeSearchForm,
	removeOptions,
	...rest
}) => {
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	async function handleOnChange(value, reason = null) {
		if (reason && reason === 'clear') {
			handleChangeSearchForm(fieldName, '');
		} else {
			if (!value) {
				return;
			}

			const symbol = value?.source?.symbol ?? value;

			if (symbol === undefined) {
				return;
			}

			setIsLoading(true);

			try {
				const { data } = await apiEls.get(`/search/${symbol}`);

				if (
					!data._source ||
					(removeOptions && data._source.securityType === 'OPT')
				) {
					setError(true);
				} else {
					if (['DOL', 'WDO'].includes(data._source.asset)) {
						data._source.tickSizeDenominator = 2;
					}

					if (data._source.isContinuous) {
						data._source.symbol = data._source.continuousRef;
					}

					setError(false);
					handleChangeSearchForm(fieldName, data._source.symbol);
				}
			} catch {
				setError(false);
			} finally {
				setIsLoading(false);
			}
		}
	}

	async function onAutoCompleteInputChange(event) {
		const value = event.target.value.toUpperCase();

		handleChangeSearchForm(fieldName, value);

		const suggestionsResponse = await onSuggestionsFetchRequested(
			value,
			false,
			false,
			0,
			'radar'
		);

		setSuggestions(suggestionsResponse);
	}

	return (
		<Autocomplete
			id="instrument-autocomplete"
			size="small"
			loadingText="Digite um ativo"
			noOptionsText="Ativo não encontrado"
			inputValue={inputValue}
			options={options?.map(option => option.source.symbol) ?? []}
			renderInput={params => (
				<TextField
					{...params}
					error={inputValue && error}
					InputProps={{
						...params.InputProps,
						type: 'search',
						endAdornment: (
							<>
								{isLoading && (
									<CircularProgress
										color="primary"
										size={15}
										style={{ marginRight: '30px' }}
									/>
								)}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			onChange={(_, newInstrument, reason) =>
				handleOnChange(newInstrument, reason)
			}
			onInput={onAutoCompleteInputChange}
			onKeyDown={e => {
				if (e.key === 'Enter') {
					e.target.blur();
				}
			}}
			onBlur={event => handleOnChange(event.target.value)}
			clearOnEscape
			selectOnFocus
			disablePortal
			fullWidth
			{...rest}
		/>
	);
};
