export function calculateBasePositionsQtty(positions, onlyPosChecked = true) {
	let aggQttyContMultiplier = [];
	positions
		.filter(i => !onlyPosChecked || i.checked)
		.forEach(function (d) {
			if (
				Object.prototype.hasOwnProperty.call(
					aggQttyContMultiplier,
					d.symbol
				)
			) {
				aggQttyContMultiplier[d.symbol] =
					aggQttyContMultiplier[d.symbol] +
					d.qtty * d.contractMultiplier;
			} else {
				aggQttyContMultiplier[d.symbol] = d.qtty * d.contractMultiplier;
			}
		});
	let baseContratQtty = Number.MAX_VALUE;
	let zeroQttySymbols = [];
	for (let [key, value] of Object.entries(aggQttyContMultiplier)) {
		if (value != 0) {
			baseContratQtty = Math.min(Math.abs(value), baseContratQtty);
		} else {
			zeroQttySymbols.push(key);
		}
	}
	return { baseContratQtty, zeroQttySymbols };
}

// Accessor is the name of the position item containing price (exitPx, entryPx or theorPx)
export function calculateSpread(positions, accessor, onlyPosChecked = true) {
	const c = calculateBasePositionsQtty(positions, onlyPosChecked);
	return positions
		.filter(
			i =>
				(!onlyPosChecked || i.checked) &&
				!c.zeroQttySymbols.includes(i.symbol)
		)
		.reduce((acc, item) => {
			return (
				acc +
				((item.qtty * item.contractMultiplier) / c.baseContratQtty) *
					item[accessor] *
					(accessor === 'exitPx' ? 1 : -1)
			);
		}, 0);
}

function getJSONSpread(strategy, definitionLegs, reverse, format) {
	const legs = definitionLegs.map((leg, index) => {
		let LegSide = leg.qty > 0 ? '1' : '2';
		LegSide = reverse ? (LegSide === '1' ? '2' : '1') : LegSide;
		return {
			LegSymbol: strategy.positions[index].symbol,
			ILegAllocAccount: strategy.account,
			LegSecurityExchange: strategy.subExchange || '', // XBSP
			LegSide,
			LegQuantity: Math.abs(leg.qty),
			LegResting: 'Y', // index <= 1 ? "Y": "N",
			LegMaxClipSize: leg.minOrderQty,
		};
	});

	const formattedStrategyCode =
		definitionLegs.length === 2
			? 'spread'
			: `spread${definitionLegs.length}p`;

	return {
		BasketId: 'fxs',
		Name: strategy.name,
		StrategyCode: formattedStrategyCode,
		InitTime: '09:00:00',
		ExpireDate: format(new Date(), 'yyyyMMdd'), // data de hoje
		CustomParameters: {
			BandPriceExchange: '',
			BandPriceHigh: 0.01,
			BandPriceLow: 0.01,
			BandPriceRef: 'N',
			BandPriceSymbol: '',
			BookDepth: 1,
			CompensateExec: 2,
			ExecutionType: 0,
			ToMarkedType: 1,
			ToMarketTriggerValue: null,
			ToMarketTriggerValueEnabled: 'N',
			Trigger: 1,
			TriggerType: 1,
			TriggerValue: strategy.spreadTriggerValue, // calculateSpread(strategy.positions, (reverse?"exitPx":"entryPx"), true)
		},
		StrategyLegs: legs,
		StrategyInfos: definitionLegs.map(definitionLeg => ({
			asset: definitionLeg.asset,
			contractMultiplier: definitionLeg.contractMultiplier,
			maturityDate: definitionLeg.maturityDate,
			maxOrderQty: definitionLeg.maxOrderQty,
			minOrderQty: definitionLeg.minOrderQty,
			minPriceIncrement: definitionLeg.minPriceIncrement,
			roundLot: definitionLeg.roundLot,
			securityType: definitionLeg.securityType,
			optionType: definitionLeg.optionType,
			subExchange: strategy.subExchange,
			symbol: definitionLeg.symbol,
		})),
	};
}

function getJSONSingleOrder(strategy, definitionLegs, reverse = false, format) {
	const legs = strategy.positions.map((leg, index) => {
		let LegSide = definitionLegs[index].qty > 0 ? '1' : '2';
		LegSide = reverse ? (LegSide === '1' ? '2' : '1') : LegSide;
		return {
			LegSymbol: leg.symbol,
			ILegAllocAccount: strategy.account,
			LegSecurityExchange: strategy.subExchange || '', // XBSP
			LegSide,
			LegQuantity: Math.abs(definitionLegs[index].qty),
			LegMaxClipSize: definitionLegs[index].minOrderQty,
			LegOrdType: '2',
		};
	});
	return {
		BasketId: 'fxs',
		Name: strategy.name,
		StrategyCode: 'sorder',
		InitTime: '09:00:00',
		ExpireDate: format(new Date(), 'yyyyMMdd'), // data de hoje
		Text: '',
		CustomParameters: {
			PriceLimit: reverse
				? strategy.positions[0].exitPx
				: strategy.positions[0].entryPx,
		},
		StrategyLegs: legs,
		StrategyInfos: definitionLegs.map(definitionLeg => ({
			asset: definitionLeg.asset,
			contractMultiplier: definitionLeg.contractMultiplier,
			maturityDate: definitionLeg.maturityDate,
			maxOrderQty: definitionLeg.maxOrderQty,
			minOrderQty: definitionLeg.minOrderQty,
			minPriceIncrement: definitionLeg.minPriceIncrement,
			roundLot: definitionLeg.roundLot,
			securityType: definitionLeg.securityType,
			subExchange: strategy.subExchange,
			symbol: definitionLeg.symbol,
		})),
	};
}

export function getPositionsAvailable(strategyObject) {
	const positionsAvailable = strategyObject.positions.filter(position => {
		return position.checked && !position.exitPxLocked && !position.expired;
	});
	return positionsAvailable;
}

export function handleStrategyBullet(
	reverse = false,
	handleStrategy,
	paper,
	options,
	format
) {
	const getType = optionType =>
		({
			C: 'CALL',
			P: 'PUT',
		}[optionType]);
	const positionsAvailable = getPositionsAvailable(handleStrategy);

	if (positionsAvailable.length === 0) return 0;
	if (positionsAvailable.length > 6) return 7;

	// Como a condensação de pernas abaixo não permite calcular o spread visto no simulador. Calculo a parte antes.
	const spreadTriggerValue = calculateSpread(
		positionsAvailable,
		reverse ? 'exitPx' : 'entryPx',
		true
	);

	const strategyToSendBullet = {
		...handleStrategy,
		positions: positionsAvailable,
	};

	const definitionLegs = [];

	// Refazer isso!! O objetivo é consolidar em um novo position as pernas de mesmo symbol com quantidades iguais.
	// O codigo está consolidando a quantidade de cada perna no position (variavel qtty) em qty
	// Está inclusive fazendo dispatch do qty no objeto paper!
	const newPositions = strategyToSendBullet.positions.map(position => {
		if (!definitionLegs.find(a => a.symbol === position.symbol)) {
			if (!position.optionType) {
				paper.qty = position.qtty;
				definitionLegs.push(paper);
				return paper;
			}
			const type = getType(position.optionType);
			const p = options.find(
				i => i.symbol === position.symbol && i.optionType === type
			);
			const option = {
				...position,
				qty: position.qtty,
				minOrderQty: p.minOrderQty,
				maxOrderQty: p.maxOrderQty,
				roundLot: p.roundLot,
			};
			definitionLegs.push(option);
			return option;
		} else {
			definitionLegs.find(i => {
				if (i.symbol === position.symbol) i.qty += position.qtty;
			});
		}
	});
	const filteredPositions = newPositions.filter(function (el, index) {
		if (el && !el?.minOrderQty) {
			el.minOrderQty = paper.minOrderQty;
		}
		return el != undefined && definitionLegs[index]?.qty !== 0;
	});

	const newStrategyToSendBullet = {
		...strategyToSendBullet,
		positions: filteredPositions,
		spreadTriggerValue,
	};
	const getJSONStrategyBullet =
		filteredPositions.length === 1 ? getJSONSingleOrder : getJSONSpread;
	const strategy = getJSONStrategyBullet(
		newStrategyToSendBullet,
		filteredPositions,
		reverse,
		format
	);

	if (
		positionsAvailable.length > 1 &&
		isNaN(strategy.CustomParameters.TriggerValue)
	) {
		strategy.CustomParameters.TriggerValue = 0;
	}

	return strategy;
}
