import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { Box, colors, Grid, Typography } from '@mui/material';

import { MarketPriceLogo } from 'components/MarketPrice/MarketPriceLogo';

import { apiMarketData } from 'services/api';

export const GapMarketPrice = () => {
	const symbol = useSelector(
		state => state.bottomBullet.bullet.content.StrategyLegs[0].LegSymbol
	);
	const strategyInfos = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0]
	);

	const [marketValues, setMarketValues] = useState({});
	const [updatedAt, setUpdatedAt] = useState(null);

	useEffect(() => {
		const controller = new AbortController();

		function getSymbolQuotes() {
			if (symbol && strategyInfos) {
				const queryParams = {
					params: { detailed: 1 },
					signal: controller.signal,
				};

				apiMarketData
					.get(
						`/exchanges/BVMF/instruments/${symbol}/quotes/last`,
						queryParams
					)
					.then(response => {
						setMarketValues(response.data);

						if (Object.keys(response.data).length > 0) {
							setUpdatedAt(format(new Date(), 'HH:mm:ss'));
						} else {
							setUpdatedAt(null);
						}
					})
					.catch(err => console.log(err));
			} else {
				setMarketValues({});
			}
		}

		getSymbolQuotes();

		const interval = setInterval(() => {
			getSymbolQuotes();
		}, 2000);

		return () => {
			controller.abort();
			clearInterval(interval);
		};
	}, [symbol, strategyInfos]);

	function getFormattedNumber(number, minDigits = null, maxDigits = null) {
		if (!number || isNaN(number)) {
			return '-';
		}

		return new Intl.NumberFormat('pt-BR', {
			minimumFractionDigits: minDigits,
			maximumFractionDigits: maxDigits ?? minDigits,
		}).format(number);
	}

	const formattedLastPrice = getFormattedNumber(
		marketValues.lastPx,
		strategyInfos?.minPriceIncrement < 1
			? strategyInfos?.tickSizeDenominator
			: 0
	);
	const formattedLowestPrice = getFormattedNumber(
		marketValues.lowPx,
		strategyInfos?.minPriceIncrement < 1
			? strategyInfos?.tickSizeDenominator
			: 0
	);
	const formattedHighestPrice = getFormattedNumber(
		marketValues.highPx,
		strategyInfos?.minPriceIncrement < 1
			? strategyInfos?.tickSizeDenominator
			: 0
	);
	const formattedOpenPrice = getFormattedNumber(
		marketValues.openPrice,
		strategyInfos?.minPriceIncrement < 1
			? strategyInfos?.tickSizeDenominator
			: 0
	);
	const formattedPreviousClosePrice = getFormattedNumber(
		marketValues.prevClosePx,
		strategyInfos?.minPriceIncrement < 1
			? strategyInfos?.tickSizeDenominator
			: 0
	);
	const formattedVariation = getFormattedNumber(marketValues.var, 2);

	const variationColor =
		marketValues.var >= 0 ? colors.green[500] : colors.red[500];

	return (
		<Grid
			item
			container
			direction="column"
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				py: 1,
				px: 2,
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					gap: 1,
				}}
			>
				<Box sx={{ m: 'auto 0' }}>
					<MarketPriceLogo asset={strategyInfos.asset} />
				</Box>

				<Box sx={{ alignSelf: 'flex-start' }}>
					<Typography variant="h6">
						{marketValues.symbol
							? String(marketValues.symbol).toUpperCase()
							: '-'}
					</Typography>
					<Typography variant="body2" color={colors.grey[600]}>
						{strategyInfos?.securityDesc ?? '-'}
					</Typography>
				</Box>
			</Box>

			<Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'center' }}>
				<Typography variant="h5" sx={{ ml: 4 }}>
					{formattedLastPrice}
				</Typography>
				<Typography variant="body1" color={variationColor}>
					{marketValues.var
						? `${formattedVariation} %`
						: formattedVariation}
				</Typography>
			</Box>

			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					width: '100%',
					gap: 1,
				}}
			>
				<Box>
					<Typography variant="h6">{formattedLowestPrice}</Typography>
					<Typography variant="body2" color={colors.grey[600]}>
						MIN (DIA)
					</Typography>
				</Box>

				<Box>
					<Typography variant="h6">
						{formattedHighestPrice}
					</Typography>
					<Typography variant="body2" color={colors.grey[600]}>
						MAX (DIA)
					</Typography>
				</Box>

				<Box>
					<Typography variant="h6">{formattedOpenPrice}</Typography>
					<Typography variant="body2" color={colors.grey[600]}>
						ABERT. (DIA)
					</Typography>
				</Box>

				<Box>
					<Typography variant="h6">
						{formattedPreviousClosePrice}
					</Typography>
					<Typography variant="body2" color={colors.grey[600]}>
						FECH. ANTERIOR
					</Typography>
				</Box>
			</Box>

			<Typography variant="body2" color={colors.grey[400]}>
				{updatedAt ? `Atualizado em ${updatedAt}` : '-'}
			</Typography>
		</Grid>
	);
};
