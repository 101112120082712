import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { Book, SwapVert } from '@mui/icons-material';

import paperValidator from 'utils/paperValidation';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import DecimalInput from 'components/MaterialComponents/DecimalInput';
import { ButtonBuySell } from 'components/MaterialComponents/MaterialStyles/style';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import {
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import SelectInput from 'components/MaterialComponents/SelectInput';
import { MarketPrice } from '../../../MarketPrice';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const PovLeg = ({
	onSuggestionsFetchRequested,
	handleChange,
	setPaperInfo,
	subUid,
	onlyCall,
	onlyOptions,
	stepFunction,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const changeLegSide = () => {
		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);
	};

	const setPriceLimit = type => {
		dispatch(
			updateCustomParameters({
				PriceLimit:
					type === 'ask'
						? bullet.StrategyInfos[0].askPx
						: bullet.StrategyInfos[0].bidPx,
			})
		);
	};

	return (
		<Grid item xs={7}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12} style={{ marginLeft: '2px' }}>
					<Typography variant="subtitle1">
						{subUid !== 4 ? 'Entrada' : 'Posição'}
					</Typography>
				</Grid>
				<Box pb={0.5} display='flex'>
					<Tooltip title="Abrir book">
						<IconButton
							size="small"
							onClick={() =>
								addWidgetFromButton(
									bullet.content.StrategyLegs[0].LegSymbol
								)
							}
							disabled={
								symbolOpenBook(
									bullet.StrategyInfos[0],
									bullet
								) || lockBook
							}
						>
							<Book />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid item xs>
					<ComboBoxInput
						name="paperField"
						label="Ativo"
						setPaperInfo={setPaperInfo}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								0
							)
						}
						value={
							bullet.content.StrategyLegs[0].LegSymbol
								? bullet.content.StrategyLegs[0].LegSymbol.toUpperCase()
								: ''
						}
						disabled={bullet.content.ClOrdID}
						isPaper
					/>
				</Grid>
				<Grid item xs>
					<ButtonBuySell
						name="singleSideButtom"
						variant="contained"
						fullWidth
						size="small"
						color={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? 'primary'
								: 'secondary'
						}
						startIcon={<SwapVert />}
						onClick={changeLegSide}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'singleSideButtom'
						)}
					>
						{subUid === 4
							? bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Comprei'
								: 'Vendi'
							: bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Compra'
								: 'Venda'}
					</ButtonBuySell>
				</Grid>
				<Grid item xs>
					<DecimalInput
						name="qtyField"
						label="Quantidade"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType={'Leg'} // type
						stepSubType={'LegQuantity'} // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={bullet.content.StrategyLegs[0].LegQuantity} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegQuantity}
						onChange={e =>
							handleChange(
								'Leg',
								'LegQuantity',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs>
					<DecimalInput
						name="clipField"
						label="Clip máximo"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						iconTooltip={t('bullets.general.clip')}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType={'Leg'} // type
						stepSubType={'LegMaxClipSize'} // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.StrategyLegs[0].LegMaxClipSize
						} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegMaxClipSize}
						onChange={e =>
							handleChange(
								'Leg',
								'LegMaxClipSize',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs>
					<SelectInput
						name="LegOrdType"
						label="Tipo"
						iconTooltip={t('bullets.participatory.strategy_type')}
						disabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							bullet.content.ClOrdID
						}
						onChange={e =>
							handleChange(
								'Leg',
								'LegOrdType',
								parseInt(e.target.value, 10),
								0
							)
						}
						defaultValue={1}
						value={
							parseInt(
								bullet.content.StrategyLegs[0].LegOrdType
							) ?? 1
						}
						selectOptions={[
							{
								value: 1,
								label: 'Mercado',
							},
							{
								value: 2,
								label: 'Limite',
							},
						]}
					/>
				</Grid>
				{parseInt(bullet.content.StrategyLegs[0].LegOrdType) === 2 && (
					<Grid item xs>
						<DecimalInput
							name="priceField"
							label="Preço Limite"
							iconTooltip={t(
								'bullets.participatory.pov.limit_price'
							)}
							disabled={
								paperValidator(
									bullet.StrategyInfos[0],
									bullet
								) ||
								(bullet.content.ClOrdID && subUid === 4)
							}
							minVal={0}
							maxVal={999999.99}
							minDecScale={
								bullet.StrategyInfos[0].tickSizeDenominator
							} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="PriceLimit" // subtype
							stepSize={
								bullet.StrategyInfos[0].minPriceIncrement ||
								bullet.StrategyInfos[0].Cst_MinPriceIncrement ||
								bullet.StrategyInfos[1]?.Cst_MinPriceIncrement
							} // incremento
							valueToStep={
								bullet.content.CustomParameters.PriceLimit
							} // valor a ser modificado
							value={
								bullet.content.ClOrdID
									? bullet.content.CustomParameters
										.PriceLimit ||
									  bullet.content.CustomParameters
									  	.EntranceExecPrice
									: bullet.content.CustomParameters.PriceLimit
							}
							onChange={e =>
								handleChange('Custom', 'PriceLimit', e.value, 0)
							}
						/>
					</Grid>
				)}
				<Grid item xs style={{ marginTop: '-24px' }}>
					<MarketPrice setPriceLimit={setPriceLimit} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PovLeg;
