import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Book, SwapVert } from '@mui/icons-material';

import { StrategyInit, StyledGrid, TittleGrid, TittleTypo } from '../style';
import SelectInput from 'components/MaterialComponents/SelectInput';
import paperValidator from 'utils/paperValidation';
import DecimalInput from 'components/MaterialComponents/DecimalInput';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import {
	ButtonBuySell,
	ClickableBox,
} from 'components/MaterialComponents/MaterialStyles/style';
import {
	additionalUpdate,
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import addWidgetFromButton from 'utils/addWidgetFromButton';

import { MarketPrice } from '../../../MarketPrice';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const GradientEntryParams = ({
	stepFunction,
	handleChange,
	setPaperInfo,
	onSuggestionsFetchRequested,
	onlyCall,
	onlyOptions,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const { t } = useTranslation();
	const edition = bullet.content.ClOrdID;
	const entrancePrice = bullet.content.CustomParameters.EntrancePrice;

	const changeLegSide = () => {
		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);
		dispatch(
			updateCustomParameters({
				EntrancePrice: null,
				StopByPrice1: null,
				StopByPrice2: null,
				SelectStopByPrice1: 0,
				SelectStopByPrice2: 0,
			})
		);
	};

	function setPriceLimit(type) {
		dispatch(
			updateCustomParameters({
				EntrancePrice:
					type === 'ask'
						? bullet.StrategyInfos[0].askPx
						: bullet.StrategyInfos[0].bidPx,
			})
		);
	}

	const setTicks = () => {
		const startWhenCrossValue =
			bullet.content.StrategyLegs[0].LegSide === '1'
				? ((entrancePrice - bullet.StrategyInfos[0].lastPx) /
						bullet.StrategyInfos[0].minPriceIncrement ||
						bullet.StrategyInfos[0].Cst_MinPriceIncrement) > 200
					? 200
					: (bullet.content.CustomParameters.EntrancePrice -
							bullet.StrategyInfos[0].lastPx) /
							bullet.StrategyInfos[0].minPriceIncrement ||
					  bullet.StrategyInfos[0].Cst_MinPriceIncrement
				: ((bullet.StrategyInfos[0].lastPx -
						bullet.content.CustomParameters.EntrancePrice) /
						bullet.StrategyInfos[0].minPriceIncrement ||
						bullet.StrategyInfos[0].Cst_MinPriceIncrement) > 200
					? 200
					: (bullet.StrategyInfos[0].lastPx -
						bullet.content.CustomParameters.EntrancePrice) /
						bullet.StrategyInfos[0].minPriceIncrement ||
				  bullet.StrategyInfos[0].Cst_MinPriceIncrement;
		if (edition) {
			dispatch(
				updateCustomParameters({
					NumberOfMinPriceIncrementsToStart:
						startWhenCrossValue.toFixed(0),
				})
			);
		} else {
			dispatch(
				updateCustomParameters({
					StartWhenCross: startWhenCrossValue.toFixed(0),
				})
			);
		}
	};

	const selectChange = input => {
		if (input === 'Y') {
			handleChange(
				'Custom',
				'StartWhenCross',
				bullet.StrategyInfos[0].minPriceIncrement ||
					bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				0
			);
		} else {
			handleChange('Custom', 'StartWhenCross', 0, 0);
		}
		handleChange('Custom', 'UseStartWhenCross', input, 0);
	};

	const calculateTicksValue = (bullet, entrancePrice) => {
		if (bullet.content.StrategyLegs[0].LegSide === '1') {
			return (
				(entrancePrice - bullet.StrategyInfos[0].lastPx) /
					bullet.StrategyInfos[0].minPriceIncrement ||
				bullet.StrategyInfos[0].Cst_MinPriceIncrement?.toLocaleString(
					'de-DE',
					{
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}
				)
			);
		} else {
			return (
				(bullet.StrategyInfos[0].lastPx - entrancePrice) /
					bullet.StrategyInfos[0].minPriceIncrement ||
				bullet.StrategyInfos[0].Cst_MinPriceIncrement?.toLocaleString(
					'de-DE',
					{
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}
				)
			);
		}
	};
	const ticksValue = calculateTicksValue(bullet, entrancePrice);

	const showTicksValue = (entrancePrice, ticksValue) => {
		if (!entrancePrice || !ticksValue) {
			return '--';
		} else {
			return Math.round(ticksValue);
		}
	};

	return (
		<>
			<StyledGrid
				item
				container
				p={1}
				spacing={1}
				xs={12}
				sx={{ mt: '0 !important' }}
			>
				<TittleGrid item xs={12} style={{ marginLeft: '8px' }}>
					<TittleTypo variant="subtitle1">
						Parâmetros de entrada
					</TittleTypo>
				</TittleGrid>
				<Grid item xs={2}>
					<Box display="flex">
						<Box pb={0.5}>
							<Tooltip title="Abrir book">
								<IconButton
									size="small"
									onClick={() =>
										addWidgetFromButton(
											bullet.content.StrategyLegs[0]
												.LegSymbol
										)
									}
									disabled={
										symbolOpenBook(
											bullet.StrategyInfos[0],
											bullet
										) || lockBook
									}
								>
									<Book />
								</IconButton>
							</Tooltip>
						</Box>
						<ComboBoxInput
							label="Ativo"
							setPaperInfo={setPaperInfo}
							name="paperField"
							tooltip={t('bullets.general.paper')}
							comboOptions={
								bullet.suggestions && bullet.suggestions.source
									? {
										value: bullet.suggestions.source.id,
										title: bullet.suggestions.source
											.symbol,
									  }
									: bullet.suggestions
							}
							onChange={e =>
								onSuggestionsFetchRequested(
									e.target.value,
									onlyOptions,
									onlyCall,
									0
								)
							}
							value={bullet.content.StrategyLegs[0].LegSymbol}
							disabled={edition}
							isPaper
						/>
					</Box>
				</Grid>
				<Grid item xs={2}>
					<ButtonBuySell
						name="sideButton"
						variant="contained"
						fullWidth
						size="small"
						color={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? 'primary'
								: 'secondary'
						}
						startIcon={<SwapVert />}
						onClick={changeLegSide}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'singleSideButtom'
						)}
					>
						{bullet.content.StrategyLegs[0].LegSide === '1'
							? 'Compra'
							: 'Venda'}
					</ButtonBuySell>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="entranceField"
						error={bullet.errorEntrancePrice}
						disabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							edition
						}
						label="Preço início do range"
						iconTooltip={t('bullets.gradient.entrance_price')}
						minVal={0}
						maxVal={999999.99}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="EntrancePrice" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={entrancePrice} // valor a ser modificado
						value={entrancePrice}
						onChange={e => {
							dispatch(
								additionalUpdate('errorEntrancePrice', false)
							);
							handleChange(
								'Custom',
								'EntrancePrice',
								parseFloat(e.value),
								0
							);
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<MarketPrice
						disabled={edition}
						setPriceLimit={setPriceLimit}
					/>
				</Grid>
				<Grid item xs={3} style={{ display: 'flex' }}>
					{!bullet.content.CustomParameters.UseStartWhenCross ||
					bullet.content.CustomParameters.UseStartWhenCross === 0 ? (
							<SelectInput
								name="initStrategy"
								disabled={
									paperValidator(
										bullet.StrategyInfos[0],
										bullet
									) || edition
								}
								label="Iniciar a estratégia"
								onChange={e => selectChange(e.target.value)}
								value={
									bullet.content.CustomParameters
										.UseStartWhenCross ?? 0
								}
								iconTooltip={t('bullets.gradient.init_strategy')}
								selectOptions={[
									{
										value: 0,
										label: 'Selecione...',
									},
									{
										value: 'Y',
										label: 'Iniciar ao cruzar',
									},
									{
										value: 'N',
										label: 'Antecipar Início em (Ticks)',
									},
								]}
							/>
						) : (
							bullet.content.CustomParameters.UseStartWhenCross !==
							0 &&
						(edition ? (
							<DecimalInput
								name="startField"
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								label={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? 'Iniciar ao cruzar'
										: 'Antecipar início em (Ticks)'
								}
								iconTooltip={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? t('bullets.gradient.start_cross')
										: t('bullets.gradient.start_ticks')
								}
								minVal={0}
								maxVal={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? 999999.99
										: 200
								}
								minDecScale={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.StrategyInfos[0]
											.tickSizeDenominator
										: 0
								} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? 'StartWhenCross'
										: 'NumberOfMinPriceIncrementsToStart'
								} // subtype
								stepSize={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.StrategyInfos[0]
											.minPriceIncrement ||
										  bullet.StrategyInfos[0]
										  	.Cst_MinPriceIncrement
										: 1
								} // incremento
								valueToStep={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.content.CustomParameters
											.StartWhenCross
										: bullet.content.CustomParameters
											.NumberOfMinPriceIncrementsToStart
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.content.CustomParameters
											.StartWhenCross
										: bullet.content.CustomParameters
											.NumberOfMinPriceIncrementsToStart
								}
								onChange={e =>
									handleChange(
										'Custom',
										bullet.content.CustomParameters
											.UseStartWhenCross === 'Y'
											? 'StartWhenCross'
											: 'NumberOfMinPriceIncrementsToStart',
										parseFloat(e.value),
										0
									)
								}
							/>
						) : (
							<DecimalInput
								name="startField"
								startButton
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								label={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? 'Iniciar ao cruzar'
										: 'Antecipar início em (Ticks)'
								}
								iconTooltip={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? t('bullets.gradient.start_cross')
										: t('bullets.gradient.start_ticks')
								}
								minVal={0}
								maxVal={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? 999999.99
										: 200
								}
								minDecScale={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.StrategyInfos[0]
											.tickSizeDenominator
										: 0
								} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="StartWhenCross" // subtype
								stepSize={
									bullet.content.CustomParameters
										.UseStartWhenCross === 'Y'
										? bullet.StrategyInfos[0]
											.minPriceIncrement ||
										  bullet.StrategyInfos[0]
										  	.Cst_MinPriceIncrement
										: 1
								} // incremento
								valueToStep={
									bullet.content.CustomParameters
										.StartWhenCross
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters
										.StartWhenCross ||
									bullet.content.CustomParameters
										.NumberOfMinPriceIncrementsToStart ||
									0
								}
								onChange={e =>
									handleChange(
										'Custom',
										'StartWhenCross',
										parseFloat(e.value),
										0
									)
								}
								finantialClick={() => {
									handleChange(
										'Custom',
										'UseStartWhenCross',
										0,
										0
									);
									handleChange(
										'Custom',
										'StartWhenCross',
										0,
										0
									);
								}}
							/>
						))
						)}
					{bullet.content.CustomParameters.UseStartWhenCross ===
						'N' && (
						<StrategyInit
							flexDirection="column"
							item
							style={{ marginTop: '-8px' }}
						>
							<Typography variant="subtitle2">Ticks</Typography>
							<Tooltip title="Valor de mercado de ticks">
								<ClickableBox
									textAlign="center"
									onClick={ticksValue && setTicks}
									color="success.main"
								>
									<Typography variant="subtitle1">
										{showTicksValue(
											entrancePrice,
											ticksValue
										)}
									</Typography>
								</ClickableBox>
							</Tooltip>
						</StrategyInit>
					)}
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="numEntrancesField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						label="Quantidade de níveis de entrada"
						iconTooltip={t('bullets.gradient.quantity_entrance')}
						minVal={1}
						maxVal={
							['CASH', 'CS', 'PS', 'OPT'].includes(
								bullet.StrategyInfos[0]?.securityType
							)
								? 50
								: 30
						}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="NumberOfEntrances" // subtype
						stepSize={1} // incremento
						valueToStep={
							bullet.content.CustomParameters.NumberOfEntrances
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters.NumberOfEntrances ??
							0
						}
						onChange={e =>
							handleChange(
								'Custom',
								'NumberOfEntrances',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="qtyByLevelField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						label="Quantidade por nível"
						iconTooltip={t('bullets.gradient.quantity_level')}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={
							bullet.StrategyInfos[0].minOrderQty * 50 || 99999999
						}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="OrderQuantity" // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.CustomParameters.OrderQuantity
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters.OrderQuantity ?? 0
						}
						onChange={e =>
							handleChange(
								'Custom',
								'OrderQuantity',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="stepEntranceField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						label="Espaçamento entre as entradas"
						iconTooltip={t('bullets.gradient.step_entrance')}
						minVal={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						maxVal={99999999}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="IncrementPriceStepEntrance" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.IncrementPriceStepEntrance
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.IncrementPriceStepEntrance ?? 0
						}
						onChange={e =>
							handleChange(
								'Custom',
								'IncrementPriceStepEntrance',
								parseFloat(e.value),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="incrementPriceField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						label="Objetivo de Gain/Trade Mercado"
						iconTooltip={t('bullets.gradient.increment_price')}
						minVal={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						maxVal={99999999}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="IncrementPriceStopGain" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.IncrementPriceStopGain
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.IncrementPriceStopGain ?? 0
						}
						onChange={e =>
							handleChange(
								'Custom',
								'IncrementPriceStopGain',
								parseFloat(e.value),
								0
							)
						}
					/>
				</Grid>
			</StyledGrid>
		</>
	);
};

export default GradientEntryParams;
