export const types = {
	1: [
		{
			id: 1,
			name: 'Limite',
			code: 'sorder',
		},
		{
			id: 2,
			name: 'Mercado',
			code: 'sorder',
		},
		{
			id: 3,
			name: 'Start',
			code: 'fxorder',
		},
		{
			id: 4,
			name: 'Saída',
			code: 'fxorder',
		},
		// {
		//     id: 5,
		//     name: 'Leilão de Abertura',
		//     code: 'fxorder',
		// },

		// {
		//     id: 6,
		//     name: 'Leilão de Fechamento',
		//     code: 'fxorder',
		// },
		{
			id: 7,
			name: 'Best Offer',
			code: 'boffer',
		},
		{
			id: 8,
			name: 'Sniper',
			code: 'sniper',
		},
		{
			id: 9,
			name: 'MIT',
			code: 'mit',
		},
	],
	2: [
		{
			id: 1,
			name: '2 pernas',
			code: 'spread',
			legs: 2,
		},
		{
			id: 2,
			name: '3 pernas',
			code: 'spread3p',
			legs: 3,
		},
		{
			id: 3,
			name: '4 pernas',
			code: 'spread4p',
			legs: 4,
		},
		{
			id: 4,
			name: '5 pernas',
			code: 'spread5p',
			legs: 5,
		},
		{
			id: 5,
			name: '6 pernas',
			code: 'spread6p',
			legs: 6,
		},
		{
			id: 6,
			name: 'Financiamento',
			code: 'financ',
			legs: 4,
		},
		{
			id: 7,
			name: 'Troca Continua',
			code: 'cont',
			legs: 2,
		},
	],
	3: [
		{
			id: 1,
			name: 'Long&Short',
			code: 'lgshort',
		},
	],
	4: [
		{
			id: 1,
			name: 'Padrão',
			code: 'grdlin',
		},
		{
			id: 3,
			name: 'Esgotamento',
			code: 'grddin',
		},
	],
	5: [
		// {
		// 	id: 1,
		// 	name: 'Iceberg',
		// 	code: 'iceberg',
		// },
		{
			id: 2,
			name: 'TWAP',
			code: 'twap',
		},
		// {
		// 	id: 3,
		// 	name: 'POV',
		// 	code: 'pov',
		// },
	],
	6: [
		{
			id: 1,
			name: 'Compra/Venda de Vol',
			code: 'cvvol',
		},
		// {
		// 	id: 2,
		// 	name: 'Skew',
		// 	code: 'skew',
		// },
	],
	7: [
		{
			id: 1,
			name: 'MarketMaker',
			code: 'marketmaker',
		},
	],
	8: [
		{
			id: 1,
			name: 'Balanceamento de carteira',
			code: 'portbalance',
		},
	],
	9: [
		{
			id: 1,
			name: 'Day trade',
			code: 'gap',
		},
	],
};

export const categories = [
	{
		id: 1,
		name: 'Single',
		code: 'sorder',
	},
	{
		id: 2,
		name: 'Spread',
		code: 'spread',
	},
	{
		id: 3,
		name: 'Long&Short',
		code: 'lgshort',
	},
	{
		id: 4,
		name: 'Gradiente',
		code: 'grddin',
	},
	{
		id: 5,
		name: 'Participativas',
		code: 'part',
	},
	{
		id: 6,
		name: 'Volatilidade',
		code: 'cvvol',
	},
	{
		id: 7,
		name: 'MarketMaker',
		code: 'marketmaker',
	},
	{
		id: 8,
		name: 'Balanceamento de carteira',
		code: 'portbalance',
	},
	{
		id: 9,
		name: 'Trade Certo',
		code: 'trade-certo',
	},
];

export function filterTypesByCode(productStrategies) {
	const formattedStrategiesType = {};

	if (productStrategies.includes('all')) {
		return types;
	}

	for (const key in types) {
		const filteredTypes = types[key].filter(item =>
			productStrategies.includes(item.code)
		);

		if (filteredTypes.length > 0) {
			formattedStrategiesType[key] = filteredTypes;
		}
	}

	return formattedStrategiesType;
}

export function filterCategoriesByTypes(filteredTypes) {
	const typeKeys = Object.keys(filteredTypes).map(type => Number(type));

	return categories.filter(category => typeKeys.includes(category.id));
}
