import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Book } from '@mui/icons-material';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';

import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import { LegSideButton } from 'components/Bullet/LegSideButton';
import { NumericInput } from 'components/Bullet/NumericInput';

import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import {
	additionalUpdate,
	setMarketPriceLoading,
	strategyUpdate,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import { addClipProportion } from 'utils/addClipProportion';
import { getFinatialValue } from 'utils/strategies';
import paperValidator from 'utils/paperValidation';
import { getMaxClipSize } from 'utils/getMaxClipSize';
import { EXM } from 'utils/bulletContext';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { symbolOpenBook } from 'utils/symbolOpenBook';

export const ContinousLeg = ({
	currentIndex,
	setPaperInfo,
	isAdvancedBullet,
}) => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const widgetIsLoading = useSelector(state => state.configs.widgetIsLoading);

	const paperFieldRef = useRef(null); /* LegSymbol (Ativo) */
	const restingFieldRef = useRef(); /* LegResting (Clip) */

	const { suggestions, StrategyInfos } = bullet;

	const bulletLeg = bullet.content.StrategyLegs[currentIndex];

	const legSymbol =
		bullet.content.StrategyLegs[currentIndex]?.LegSymbol ?? '';

	const clipMinVal = getMaxClipSize(
		StrategyInfos[currentIndex]?.minOrderQty ?? 0,
		bulletLeg.LegQuantity
	);

	function getPaperOptions() {
		if (suggestions?.source) {
			return {
				value: suggestions.source.id,
				title: suggestions.source.symbol,
			};
		}

		return suggestions;
	}

	/* Função que vai ser usada apenas para trocar campos relacionados ao StrategyLegs */
	function handleStrategyLegsChange(field, value) {
		bullet.content.StrategyLegs[currentIndex][field] = value;

		if (field === 'LegQuantity') {
			const lastPx = bullet.StrategyInfos[currentIndex].lastPx;
			const legQuantity =
				bullet.content.StrategyLegs[currentIndex].LegQuantity;
			const asset = bullet.StrategyInfos[currentIndex].asset;

			const finantialValue = getFinatialValue(lastPx, legQuantity, asset);

			if (!isNaN(finantialValue)) {
				bullet.StrategyInfos[currentIndex].finantial = finantialValue;
			}
		}

		/*  Calculo de Bid, Mid e Ask */
		if (
			(field === 'LegSymbol' || field === 'LegQuantity') &&
			!bullet.editing
		) {
			bullet.content.CustomParameters.EntryTriggerValue = null;
			bullet.content.CustomParameters.ExitTriggerValue = null;

			addClipProportion(
				bullet.content.StrategyLegs,
				bullet.StrategyInfos
			);
			dispatch(setMarketPriceLoading(true));

			/* Forçando o calculo do novo preço de Bid, Mid e Ask */
			dispatch(additionalUpdate('refreshNeeded', true));
		}

		dispatch(additionalUpdate('StrategyInfos', bullet.StrategyInfos));
		dispatch(strategyUpdate(bullet.content));
	}

	function handleChangeLegSymbol(_, data) {
		setPaperInfo(currentIndex, data);
		handleStrategyLegsChange('LegSymbol', data.symbol);
	}

	function changeLegSide() {
		const newLegSide = bulletLeg.LegSide === '1' ? '2' : '1';

		bullet.content.CustomParameters.TriggerValue = null;

		dispatch(updateStrategyLeg({ LegSide: newLegSide }, currentIndex));
		dispatch(setMarketPriceLoading(true));

		/* Forçando o calculo do novo preço de Bid, Mid e Ask */
		dispatch(additionalUpdate('refreshNeeded', true));
	}

	const paperOptions = getPaperOptions();

	/* Verificar a possibilidade de colocar isso em um hook */
	useEffect(() => {
		const legSymbol = bulletLeg.LegRefSymbol ?? bulletLeg.LegSymbol;

		if (
			bullet.context === EXM &&
			(legSymbol?.startsWith('WDO') || legSymbol?.startsWith('WIN'))
		) {
			paperFieldRef.current?.focus();
			paperFieldRef.current?.blur();
			dispatch(additionalUpdate('refreshNeeded', false));
		}
	}, [bullet.refreshNeeded]);

	return (
		<>
			<Grid container item xs={12}>
				<Grid
					container
					xs
					item
					spacing={1}
					gap={isAdvancedBullet ? 0 : 1}
				>
					<Grid
						item
						xs={isAdvancedBullet ? 3 : 4}
						sx={{
							display: 'flex',
							gap: 0.125,
							alignItems: 'center',
						}}
					>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(bulletLeg.LegSymbol)
								}
								disabled={
									symbolOpenBook(suggestions, bullet) ||
									widgetIsLoading
								}
							>
								<Book />
							</IconButton>
						</Tooltip>

						<ComboBoxInput
							label="Ativo"
							name={`paper-${currentIndex}`}
							index={currentIndex}
							value={legSymbol}
							inputRef={paperFieldRef}
							comboOptions={paperOptions}
							setPaperInfo={handleChangeLegSymbol}
							disabled={bullet.content.ClOrdID}
							onChange={e =>
								onSuggestionsFetchRequested(
									e.target.value,
									false,
									false,
									currentIndex
								)
							}
							isPaper
						/>
					</Grid>
					<Grid
						item
						xs={isAdvancedBullet ? 3 : 4}
						sx={{ display: 'flex', alignItems: 'center' }}
					>
						<LegSideButton
							sx={{ height: 25 }}
							legSide={bulletLeg.LegSide}
							onClick={changeLegSide}
							disabled={
								paperValidator(
									StrategyInfos[currentIndex],
									bullet,
									'singleSideButtom'
								) || bullet.content.ClOrdID
							}
						/>
					</Grid>

					<Grid
						container
						xs={isAdvancedBullet ? 4.5 : 3.5}
						gap={2}
						sx={{
							mt: isAdvancedBullet ? -0.75 : -1.5,
							ml: isAdvancedBullet ? 0.75 : 0,
						}}
					>
						<Grid item xs>
							<NumericInput
								label="Quantidade"
								value={bulletLeg.LegQuantity}
								stepSize={
									StrategyInfos[currentIndex].roundLot ||
									StrategyInfos[currentIndex]
										.Cst_MinOrderQuantity
								}
								minValue={
									StrategyInfos[currentIndex].minOrderQty ?? 0
								}
								maxVal={
									bullet.StrategyInfos[currentIndex]
										.maxOrderQty === 99
										? 9999999
										: bullet.StrategyInfos[currentIndex]
											.maxOrderQty ?? 9999999
								}
								decimalScale={0}
								disabled={
									paperValidator(
										StrategyInfos[currentIndex],
										bullet
									) || bullet.content.ClOrdID
								}
								onChangeCallback={value =>
									handleStrategyLegsChange(
										'LegQuantity',
										value
									)
								}
								roundOnBlur
							/>
						</Grid>

						{isAdvancedBullet && (
							<Grid item xs>
								<NumericInput
									label="Clip máximo"
									stepSize={
										StrategyInfos[currentIndex].roundLot ||
										StrategyInfos[currentIndex]
											.Cst_MinOrderQuantity
									}
									value={bulletLeg.LegMaxClipSize}
									minValue={clipMinVal}
									maxValue={
										StrategyInfos[currentIndex]
											.maxOrderQty ?? 9999999
									}
									decimalScale={0}
									disabled={
										paperValidator(
											bullet.StrategyInfos[currentIndex],
											bullet
										) || bullet.content.ClOrdID
									}
									onChangeCallback={value =>
										handleStrategyLegsChange(
											'LegMaxClipSize',
											value
										)
									}
									roundOnBlur
								/>
							</Grid>
						)}
					</Grid>

					{isAdvancedBullet && (
						<Grid item xs={1}>
							<FormControlLabel
								label="Apregoar"
								ref={restingFieldRef}
								disabled={
									paperValidator(
										StrategyInfos[currentIndex],
										bullet
									) || bullet.content.ClOrdID
								}
								checked={bulletLeg.LegResting === 'Y'}
								onChange={e => {
									const checked = e.target.checked;

									const newValueResting = checked ? 'Y' : 'N';

									handleStrategyLegsChange(
										'LegResting',
										newValueResting
									);
								}}
								control={<Checkbox />}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};
