import { combineReducers } from 'redux';

import auth from 'store/modules/auth/reducer';
import notifications from 'store/modules/notifications/reducers';
import strategy from 'store/modules/strategy/reducers';
import simulator from 'store/modules/simulator/reducer';
import ui from 'store/modules/ui/reducers';
import routes from 'store/modules/routes/reducers';
import configs from 'store/modules/panelConfig/reducer';
import scanner from 'store/modules/scanner/reducers';
import userPreferences from 'store/modules/userPreferences/reducers';
import strategyFloating from 'store/modules/strategyFloating/reducer';
import bottomBullet from 'store/modules/bottomBullet/reducer';
import tables from 'store/modules/tables/reducer';
import globalStatus from 'store/modules/globalStatus/reducer';
import widgets from 'store/modules/widgets/reducer';
import cache from 'store/modules/cache/reducers';
import externalStorage from 'store/modules/externalStorage/reducer';
import risk from 'store/modules/Risk/reducer';
import positions from 'store/modules/positions/reducer';
import market from 'store/modules/market/reducer';
import websocket from 'store/modules/websocket/reducers.js';
import portfolio from 'store/modules/portfolio/reducer';
import idxLiveCharts from 'store/modules/idxLiveCharts/reducers';
import { recurrence } from './recurrence/reducer';
import { history } from 'store/modules/history/reducer';
import { executions } from 'store/modules/executions/reducer';
import { strategiesUpload } from 'store/modules/strategiesUpload/reducer';
import { currentStrategies } from 'store/modules/currentStrategies/reducer';
import { consolidatedPositions } from './consolidatedPositions/reducer';
import { products } from './products/reducer';
import { radarModule } from './radar/reducer';
import { chatBotReducer as chatbot } from './chatbot/reducer';
import { historicalDataReducer as historicalData } from './historicalData/reducer';

const rootReducer = combineReducers({
	auth,
	cache,
	routes,
	strategy,
	simulator,
	ui,
	configs,
	scanner,
	userPreferences,
	strategyFloating,
	tables,
	globalStatus,
	notifications,
	widgets,
	bottomBullet,
	externalStorage,
	risk,
	positions,
	market,
	websocket,
	portfolio,
	strategiesUpload,
	history,
	executions,
	currentStrategies,
	recurrence,
	consolidatedPositions,
	products,
	idxLiveCharts,
	radarModule,
	chatbot,
	historicalData,
});

export default rootReducer;
