import { apiAWS } from 'services/api';
import { PANEL_CONFIG } from './actionTypes';

import { toast } from 'react-toastify';

import { updateWidgetList } from 'store/modules/widgets/actions';

export const fetchData = () => {
	return async (_dispatch, getState) => {
		const { auth, configs } = getState();
		if (!auth.username) {
			return;
		}
		const res = await apiAWS.get(
			`/userpreferences/${auth.username}?service=table_view`,
			{
				headers: {
					Authorization: `Bearer ${auth.token}`,
				},
			}
		);
		if (res.data.localWidgets) {
			res.data.localWidgets.map(item => {
				if (!item.id) {
					item.id = Math.random();
				}
			});
		}
		return res.status === 200 ? res.data : configs;
	};
};

export const fetchConfigs = () => {
	return async dispatch => {
		let data = await dispatch(fetchData());
		//Toda vez que o usuario logar forçar que o widget venha fechado por padrão
		data.widgetBar = false;
		//formatar colunas monitor, adicionado as funções que não podem ser salvas em JSON

		if (data?.localWidgets) {
			dispatch(updateWidgetList(data.localWidgets));
		}
	};
};

export const parseStringToFloat = number => {
	if (typeof number === 'string') {
		if (number === '--') return 0;
		return parseFloat(number.replace(/\./g, '').replace(',', '.'));
	}
	return number;
};

export const saveConfig = (data, success) => {
	return async (_dispatch, getState) => {
		const { auth } = getState();

		if (auth.username) {
			const headers = {
				headers: { Authorization: `Bearer ${auth.token}` },
			};

			apiAWS
				.post(
					`/userpreferences/${auth.username}?service=table_view`,
					data,
					headers
				)
				.then(response => {
					if (success) success();
					return response;
				})
				.catch(error => console.log(error));
		}
	};
};

export const saveToDynamo = (serviceName, data) => {
	return async (_dispatch, getState) => {
		const { token, username } = getState().auth;

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiAWS
			.post(
				`/userpreferences/${username}?service=${serviceName}`,
				data,
				headers
			)
			.then(response => {
				return response.data;
			})
			.catch(error => {
				console.log(error);
				toast.error('Erro ao salvar dados');
			});
	};
};

export const updateConfig = (target, value) => {
	return dispatch => {
		const config = { [target]: value };

		dispatch(saveConfig(config));
		dispatch(setConfig(config));
	};
};

export const updatePreferences = prefs => {
	return async dispatch => {
		const config = { preferences: { ...prefs } };

		dispatch(saveConfig(config));
		dispatch(setConfig(config));
	};
};

export const updateTables = data => ({
	type: PANEL_CONFIG.TABLES_SET,
	payload: data,
});

export const setConfig = data => ({
	type: PANEL_CONFIG.CONFIG_SET,
	payload: data,
});

export const setRequests = data => ({
	type: PANEL_CONFIG.REQUEST_SET,
	payload: data,
});

export const setExpandedState = (table, data) => ({
	type: PANEL_CONFIG.SET_EXPANDED,
	payload: { table, data },
});

export const setTargetEdit = editTarget => ({
	type: PANEL_CONFIG.EDIT_TARGET,
	payload: { editTarget },
});

export const setResizedHeader = (table, header) => ({
	type: PANEL_CONFIG.SET_WIDTH,
	payload: { table, header },
});

export const setIsGlobalSettingsOpen = (isOpen, module = 'bullet') => ({
	type: PANEL_CONFIG.SET_IS_GLOBAL_SETTINGS_OPEN,
	payload: { isOpen, module },
});

export const setIsRefreshStrategies = (isRefresh, module = 'bullet') => ({
	type: PANEL_CONFIG.SET_IS_REFRESH_STRATEGIES,
	payload: { isRefresh, module },
});

export const setGlobalSettingsModule = module => ({
	type: PANEL_CONFIG.SET_GLOBAL_SETTINGS_MODULE,
	payload: { module },
});

export const setGlobalSettings = newSettings => ({
	type: PANEL_CONFIG.SET_GLOBAL_SETTINGS,
	payload: { newSettings },
});

export const setPartialGlobalSettings = (module, newSettings) => ({
	type: PANEL_CONFIG.SET_PARTIAL_GLOBAL_SETTING,
	payload: { module, newSettings },
});

export const resetChanges = settings => ({
	type: PANEL_CONFIG.SET_PARTIAL_GLOBAL_SETTING,
	payload: { settings },
});

export const setStrategiesTabIndex = strategiesTabIndex => ({
	type: PANEL_CONFIG.SET_STRATEGIES_TAB_INDEX,
	payload: { strategiesTabIndex },
});

export const setHolidays = holidays => ({
	type: PANEL_CONFIG.SET_HOLIDAYS,
	payload: { holidays },
});

export const setPositionsTabIndex = positionsTabIndex => ({
	type: PANEL_CONFIG.SET_POSITIONS_TAB_INDEX,
	payload: { positionsTabIndex },
});

export const resetPanelConfig = () => ({
	type: PANEL_CONFIG.RESET_PANEL_CONFIG,
});
