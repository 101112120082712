import { store } from '../store';
import {
	additionalUpdate,
	handleStrategy,
	setMarketPriceLoading,
} from '../store/modules/bottomBullet/actions';
import { toast } from 'react-toastify';

export const dispatchMenuAction = (action, options) => {
	if (['EDIT', 'PARAMS', 'CLONE', 'REVERT'].includes(action)) {
		const { handleStrategyOptions } = options;
		const { account } = options.accountStrategy;
		const { accounts, userType } = store.getState().auth;
		const { defaultAccount } =
			store.getState().configs.globalSettings.userSettings.bullet;

		if (handleStrategyOptions.values) {
			if (
				handleStrategyOptions.values.CustomParameters &&
				Object.keys(handleStrategyOptions.values.CustomParameters)
					.length > 0 &&
				handleStrategyOptions.values.StrategyLegs &&
				handleStrategyOptions.values.StrategyLegs.length > 0
			) {
				store.dispatch(handleStrategy(handleStrategyOptions));
				if (accounts?.length > 2) {
					store.dispatch(
						additionalUpdate('account', account ?? defaultAccount)
					);
				} else if (userType.id === 5 || userType.id === 2) {
					store.dispatch(additionalUpdate('account', account ?? ''));
				} else {
					store.dispatch(
						additionalUpdate(
							'account',
							store.getState().auth.accounts[0].code
						)
					);
				}

				if (
					['spread', 'cont'].includes(
						handleStrategyOptions.values.StrategyCode
					)
				) {
					store.dispatch(setMarketPriceLoading(true));
				}
			} else {
				toast.error(
					'Não é possível abrir a boleta para estratégia selecionada. Para ver a boleta, vá em boletas recentes ou salvas.'
				);
			}
		} else {
			toast.error(
				'Não é possível abrir a boleta para estratégia selecionada. Para ver a boleta, vá em boletas recentes ou salvas.'
			);
		}
	}
};
