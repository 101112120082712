import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';

import { getComparationActives, handleBullet } from 'utils/tablesColumns/radar';

export const ChartBarLayer = ({ loading }) => {
	const { layersData, searchApplied } = useSelector(
		state => state.radarModule
	);

	const initDateFormatted = format(searchApplied.init_date, 'dd-MM-yyyy');
	const endDateFormatted = format(searchApplied.end_date, 'dd-MM-yyyy');

	const symbol1 = searchApplied.symbol1 ?? '';
	const symbol2 = searchApplied.symbol2 ?? '';

	const percentSymbol1 = searchApplied.percentSymbol1 ?? 0;
	const percentSymbol2 = searchApplied.percentSymbol2 ?? 0;

	const colorRadarText = '#ffa500';

	const { maxActive, maxFrequency, minActive, minFrequency } =
		getComparationActives(symbol1, symbol2, percentSymbol1, percentSymbol2);

	function openBullet(item) {
		const dataIndex = item.dataIndex;

		const currentDataLayer = layersData[dataIndex].label;

		const layers = currentDataLayer.split('::').map(Number);

		let entryTriggerValue = '';
		let exitTriggerValue = '';

		if (layers.length > 0) {
			entryTriggerValue = layers[0];
			exitTriggerValue = layers[1];
		}

		const strategyData = {
			symbol1: searchApplied.symbol1,
			symbol2: searchApplied.symbol2,
			symbol1_prop: searchApplied.symbol1_prop,
			symbol2_prop: searchApplied.symbol2_prop,
			entryTriggerValue,
			exitTriggerValue,
		};

		handleBullet(strategyData);
	}

	return (
		<Box width="100%" my={5}>
			<Typography variant="h4" fontWeight="bold">
				Cálculo Camadas
			</Typography>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '50vh',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5">
					Comparação:{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol1}
					</strong>{' '}
					VS{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol2}
					</strong>{' '}
					com crédito de{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.layer.toFixed(2)}
					</strong>
				</Typography>

				<BarChart
					loading={loading}
					grid={{ vertical: true, horizontal: true }}
					dataset={layersData}
					series={[
						{
							dataKey: 'quantity',
							label: 'Quantidade',
							color: colorRadarText,
						},
					]}
					xAxis={[
						{
							scaleType: 'band',
							dataKey: 'label',
							categoryGapRatio: 0.5,
						},
					]}
					yAxis={[
						{
							label: 'Quantidades',
						},
					]}
					sx={{
						[`& .${axisClasses.left} .${axisClasses.label}`]: {
							transform: 'translateX(-10px)',
						},
						[`& .${chartsGridClasses.line}`]: {
							strokeDasharray: '5 3',
							strokeWidth: 2,
						},
					}}
					margin={{
						bottom: 85,
					}}
					bottomAxis={{
						disableTicks: true,
						labelStyle: {
							fontSize: 14,
							transform: `translateY(${
								5 * Math.abs(Math.sin((Math.PI * 45) / 180))
							}px)`,
						},
						tickLabelStyle: {
							angle: 45,
							textAnchor: 'start',
							fontSize: 12,
						},
					}}
					slotProps={{ legend: { hidden: true } }}
					onItemClick={(_, item) => openBullet(item)}
				/>
			</Box>
			<Box display="flex" flexDirection="column" gap={1}>
				<Typography variant="h6" mt={2}>
					Informações coletadas durante o período de{' '}
					<strong style={{ color: colorRadarText }}>
						{initDateFormatted}
					</strong>{' '}
					até{' '}
					<strong style={{ color: colorRadarText }}>
						{endDateFormatted}
					</strong>
				</Typography>
				<Typography variant="h6">
					Maior ativo:{' '}
					<span style={{ color: 'green' }}>{maxActive}</span> -
					frequência:{' '}
					<span style={{ color: 'green' }}>
						{maxFrequency.toFixed(2)}%
					</span>
				</Typography>
				<Typography variant="h6">
					Menor ativo:{' '}
					<span style={{ color: 'red' }}>{minActive}</span> -
					frequência:{' '}
					<span style={{ color: 'red' }}>
						{minFrequency.toFixed(2)}%
					</span>
				</Typography>
			</Box>
		</Box>
	);
};
