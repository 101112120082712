import React from 'react';

import {
	Button,
	colors,
	FormControl,
	FormLabel,
	Grid,
	OutlinedInput,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import NumberFormat from 'react-number-format';

import { FinantialButton } from '../FinantialButton';

import { COMPONENT_NUMERIC_INPUT_HEIGHT } from 'utils/constants';

const ButtonWithIcon = ({ children, sx = {}, disabled = false, ...rest }) => {
	return (
		<Button
			size="small"
			fullWidth
			variant="outlined"
			color="inherit"
			disabled={disabled}
			sx={{
				minWidth: 0,
				height: '100%',
				borderColor: colors.grey[600],
				cursor: disabled ? 'not-allowed' : 'pointer',
				...sx,
			}}
			{...rest}
		>
			{children}
		</Button>
	);
};

const StyledOutlinedInput = props => {
	return (
		<OutlinedInput
			{...props}
			size="small"
			fullWidth
			inputProps={{ style: { textAlign: 'right' } }}
			sx={{
				borderRadius: 0,
				height: props.height ?? COMPONENT_NUMERIC_INPUT_HEIGHT,
			}}
		/>
	);
};

export const NumericInput = ({
	value = 0,
	label = null,
	decimalScale,
	disabled,
	stepSize,
	minValue = null,
	maxValue = null,
	roundOnBlur,
	onChangeCallback,
	height = null,
	isShowFinantialButton = false,
	isCreditFinantialButton = false,
	loadingCreditFinantialButton = false,
	onChangeFinantialButton = null,
	...rest
}) => {
	const getAbsoluteValue = value => {
		if (['', undefined, null].includes(value)) {
			return '';
		}

		return Math.abs(value);
	};

	function getCalculatedValue(operation, currentValue, stepSize) {
		if (operation === 'add') {
			return Math.round((currentValue + stepSize) * 100) / 100;
		}

		if (operation === 'subtract') {
			return Math.round((currentValue - stepSize) * 100) / 100;
		}

		if (operation === 'round') {
			return Math.round(currentValue / stepSize) * stepSize;
		}
	}

	function handleAdjustValue(operation) {
		if (['', null, undefined].includes(value)) {
			if (minValue <= 0) {
				return 0;
			}

			return minValue;
		}

		const parsedCurrentValue = parseFloat(value);
		const parsedStepSize = parseFloat(stepSize);

		if (!parsedStepSize) {
			return null;
		}

		const calculatedValue = getCalculatedValue(
			operation,
			parsedCurrentValue,
			parsedStepSize
		);

		if (
			![null, undefined].includes(minValue) &&
			calculatedValue < minValue
		) {
			return minValue;
		}

		if (maxValue && calculatedValue > maxValue) {
			return maxValue;
		}

		if (!isNaN(calculatedValue) && calculatedValue !== value) {
			return calculatedValue;
		}

		return null;
	}

	function interceptorValueChange(newValue) {
		if (isShowFinantialButton) {
			const formattedValue = isCreditFinantialButton
				? newValue
				: newValue * -1;

			onChangeCallback(formattedValue);
		} else {
			onChangeCallback(newValue);
		}
	}

	function handleIncreaseValue() {
		const adjustedValue = handleAdjustValue('add');

		if (![null, undefined].includes(adjustedValue)) {
			onChangeCallback(adjustedValue);
		}
	}

	function handleDecreaseValue() {
		const adjustedValue = handleAdjustValue('subtract');

		if (![null, undefined].includes(adjustedValue)) {
			interceptorValueChange(adjustedValue);
		}
	}

	function handleBlur() {
		const roundedValue = handleAdjustValue('round');

		if (roundOnBlur && roundedValue) {
			interceptorValueChange(roundedValue);
		}
	}

	const finalValue = isShowFinantialButton ? getAbsoluteValue(value) : value;

	return (
		<>
			<FormControl fullWidth disabled={disabled}>
				{label && (
					<FormLabel
						sx={{ '&.Mui-focused': { color: 'white' }, mb: 0.5 }}
					>
						{label}
					</FormLabel>
				)}
				<Grid container item>
					{isShowFinantialButton && (
						<FinantialButton
							height={height}
							disabled={disabled}
							isLoading={loadingCreditFinantialButton}
							isCreditFinantialButton={isCreditFinantialButton}
							onChangeFinantialButton={onChangeFinantialButton}
						/>
					)}

					<Grid xs={2}>
						<ButtonWithIcon
							onClick={handleDecreaseValue}
							disabled={disabled}
							sx={{
								borderRadius: isShowFinantialButton
									? 0
									: '4px 0 0 4px',
								height:
									height ?? COMPONENT_NUMERIC_INPUT_HEIGHT,
							}}
						>
							<Remove size="small" />
						</ButtonWithIcon>
					</Grid>

					<Grid xs={isShowFinantialButton ? 6 : 8}>
						<NumberFormat
							customInput={StyledOutlinedInput}
							size="small"
							value={finalValue}
							disabled={disabled}
							decimalScale={decimalScale}
							thousandSeparator="."
							decimalSeparator=","
							onValueChange={event =>
								interceptorValueChange(event.floatValue)
							}
							onBlur={handleBlur}
							height={height}
							{...rest}
						/>
					</Grid>

					<Grid xs={2}>
						<ButtonWithIcon
							onClick={handleIncreaseValue}
							disabled={disabled}
							sx={{
								borderRadius: '0 4px 4px 0',
								height:
									height ?? COMPONENT_NUMERIC_INPUT_HEIGHT,
							}}
						>
							<Add size="small" />
						</ButtonWithIcon>
					</Grid>
				</Grid>
			</FormControl>
		</>
	);
};
