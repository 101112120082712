import styled from 'styled-components';
import { Button as ButtonMaterial, Box } from '@mui/material';
import {
	Cancel,
	CheckBox,
	CheckBoxOutlineBlank,
	Delete,
	Save,
} from '@mui/icons-material';

import Split from 'react-split';

export const Container = styled.div`
	position: relative;
	display: flex;
	height: 100%;

	.gutter-vertical {
		cursor: ns-resize;
		background: #4d4d4d;
		height: 1rem !important;
		width: 10%;
		margin: 0 auto;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		z-index: 100;
		position: relative;
	}
`;

export const Wrapper = styled.div`
	height: 100%;
`;
export const PositionListContainer = styled.div`
	height: 100%;
	overflow-y: auto;
	.ReactTable,
	.rt-table,
	.sc-itybZL {
		height: 90% !important;
	}
	.icon {
		margin-top: 3px;
		color: #cccccc;
		font-size: 16px;
		align-self: center;
	}
	.small {
		font-size: 15px;
	}
	.arrow {
		color: ${({ theme }) => theme.colors.background};
		margin: -5px -4px;
	}
	width: 100%;
`;
export const ToolsTable = styled.div`
	display: flex;
	border-bottom: 0.2px solid #707070;
	padding: 7px 0;
	margin-bottom: 10px;
`;
export const Border = styled.div`
	border: 0.5px solid #707070;
`;
export const Price = styled.span`
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	text-align: center;
	color: #ce8100;
	align-self: center;
	margin: 0px 8px;
`;
export const UnderSymbol = styled.span`
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	margin-right: 5px;
	text-align: center;
	color: #888;
	align-self: center;
`;
export const TextGreen = styled.span`
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #31c593;
	${({ borderRight }) =>
		borderRight &&
		`
  padding: 1px 5px;
  border-right: 1px solid #707070;
  `}
`;
export const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 10px;
	border-right: 1px solid #707070;
`;
export const ContentRow = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 0 10px;
	border-right: 1px solid #707070;
`;
export const ContentIncrement = styled.div`
	display: flex;
	flex-direction: column;
	background: #707070;
	color: #30353f;

	.icon-up {
		cursor: pointer;
		margin-top: 2px;
		margin-bottom: 2px;
		height: 5px;
	}
	.icon-down {
		cursor: pointer;
		height: 5px;
		margin-top: 2px;
		margin-bottom: 2px;
	}
`;
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}
`;
export const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const Button = styled(ButtonMaterial).attrs({
	variant: 'contained',
})`
	background-color: ${props => props.bgcolor || '#3984C5'} !important;
	margin-right: 10px !important;
	height: 25px;
	border: none;
	color: white !important;
	font-size: 10px;
	font-weight: bold;
	${props =>
		props.disabled &&
		`
    opacity: 0.7;
    &:hover{
      cursor:not-allowed;
    }
    `}
	${props =>
		props.width &&
		`
    width: ${props.width};
  `}
`;
export const Label = styled.span`
	font-family: Roboto;
	font-style: normal;
	font-size: 13px;
	margin-bottom: 5px;
	color: ${({ theme }) => theme.textLabel};
	position: absolute;
	margin-top: -45px;
`;
export const MetricsTd = styled.td`
	padding: 2px;
	font-size: 12px;
`;
export const MetricsAlign = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const MetricsGraphic = styled(Box)`
	border-radius: 3px;
	padding: 0px 5px;
`;
export const MetricsDivision = styled.tr`
	border-bottom: 1px #707070 solid;
`;

export const TableStyles = styled.div`
	table {
		color: ${({ theme }) => theme.colors.inputSimulator};
		border-spacing: 0;

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th {
			margin: 0;
			border-bottom: 1px solid #808080;
			:last-child {
				border-right: 0;
			}
		}
		td {
			margin: 0;
			padding: 0;
			border: none;
		}

		tfoot {
			tr:first-child {
				td {
					margin-top: 10px;
					border-top: 1px solid #808080;
					text-align: right;
					> div {
						padding-right: 5px;
					}
				}
			}
		}
		.-odd {
			background-color: ${({ theme }) =>
		theme.title === 'dark' ? '#202030' : '#eee'} !important;
		}
		.border-right {
			border-right: 1px solid #808080 !important;
		}
		.tittle-right {
			text-align: right;
		}
		.border-left {
			border-left: 1px solid #808080;
		}
		.titleCenter {
			text-align: center !important;
		}
		.cell-center {
			text-align: center;
		}
		.cell-left {
			text-align: left;
		}
		.cell-right {
			text-align: right;
			padding-right: 5px;
			div {
				text-align: right;
			}
		}
	}
`;

export const TitleStyled = styled.div`
	text-align: right;
	padding-right: 45px;
`;

export const PriceVariation = styled.span`
	font-size: 10px;
	color: ${props => props.color};
`;
export const MenuVolProj = styled.div`
	position: absolute;
	z-index: 2;
	background: ${({ theme }) => theme.colors.background};
	border: 1px solid #888;
`;
export const InputEditing = styled.input`
	width: ${({ width }) => width || '100%'};
	border: none;
	color: ${({ edited }) => (edited ? '#ff5a5a' : '#FF8C04')} !important;
	background: ${({ edited }) => (edited ? '#FFEE00' : 'transparent')};
	text-align: right;
	padding-right: 5px;
`;
export const Input = styled.input`
	border: none;
	color: ${({ color }) => color} !important;
	background: ${({ bgColor }) => bgColor || 'transparent'};
	text-align: right;
	padding-right: 5px;
	width: 75px;
	font-weight: bold;
`;
export const ItemVolProj = styled.div`
	padding: 3px 5px;
	&:hover {
		opacity: 0.8;
	}
`;

export const ButtonCancel = styled(Cancel)`
	color: #ff5a5a;
	&:hover {
		cursor: pointer;
	}
`;
export const Checked = styled(CheckBox)`
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
`;
export const Check = styled(CheckBoxOutlineBlank)`
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
`;
export const IconSave = styled(Save)`
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
	opacity: ${({ active = true }) => (active ? 1 : 0.3)};
	&:hover {
		cursor: pointer;
	}
`;
export const IconDelete = styled(Delete)`
	margin-left: 10px;
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
`;
export const InputSelect = styled.select`
	-webkit-appearance: auto !important;
	background: transparent;
	padding-left: 5px;
	width: 100%;
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
	border-radius: 3px;
	border-color: ${({ theme }) => theme.colors.inputSimulator} !important;
`;
export const OptionSelect = styled.option`
	background: ${({ theme }) => theme.colors.background} !important;
`;
export const InputIR = styled.div`
	display: flex;
	align-items: center;
	background: transparent;
	width: 100%;
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
	border-radius: 3px;
	border: 1px solid ${({ theme }) => theme.colors.inputSimulator} !important;
`;
export const InputPaperStrike = styled.div`
	background: transparent;
	color: ${({ theme }) => theme.colors.inputSimulator} !important;
	padding-left: 5px;
	margin-left: 5px;
	margin-right: 5px;
	text-align: left;
`;

export const MetricsContainer = styled.div`
	width: 20%;
	padding-top: 64px;
	padding-right: 16px;
	padding-left: 16px;
	background: #212121;
`;

export const ChartContainer = styled.div`
	display: flex;
	width: 80%;
`;

export const CustomSplitPane = styled(Split)`
	width: 100%;
	position: initial !important;
	.Pane1 {
		overflow-y: scroll;
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const MediumGrayBox = styled(Box)`
	padding: 8px;
	display: flex;
	/* background-color: #424242; */
	align-items: center;
	justify-content: space-between;
`;

export const ContainerBlackBox = styled(Box)`
	/* padding-top: 16px; */
	/* padding: 8px; */
	div {
		border-radius: 3px;
	}
`;

export const StatusSimulator = styled.div`
	border-radius: 50%;
	animation: background-change 2s infinite;
	animation-timing-function: ease;
	height: 11px;
	width: 11px;
	margin-left: 8px;
	align-self: center;
`;
