import React, { useEffect, useState } from 'react';

import { Box, colors, IconButton } from '@mui/material';
import {
	AutoStories,
	Cancel,
	AccountBalance,
	Phone,
	WhatsApp,
	YouTube,
} from '@mui/icons-material';

import { LightTooltip } from 'components/LightTooltip';
import { apiExec } from 'services/api';

import cmCapitalLogo from 'assets/svg/partnersLogos/cm-capital-logo.svg';
import investFlexLogo from 'assets/images/investflex_icon.png';
import genialLogo from 'assets/svg/partnersLogos/genial-icon.png';
import btgLogo from 'assets/svg/partnersLogos/btg-icon.svg';
import xpLogo from 'assets/svg/partnersLogos/xp-icon.png';
import futLogo from 'assets/svg/partnersLogos/nova-fut-icon.svg';

const PARTNERS_LOGOS = {
	cmc: cmCapitalLogo,
	flx: investFlexLogo,
	gen: genialLogo,
	btg: btgLogo,
	xpi: xpLogo,
	fut: futLogo,
};

export const ChatActionsBar = ({ onClose }) => {
	const [isAlreadyCopied, setIsAlreadyCopied] = useState(false);
	const [sponsorData, setSponsorData] = useState({});

	const youtubeUrl = 'https://www.youtube.com/@flexscan';
	const documentationUrl =
		'https://investflex.atlassian.net/wiki/spaces/TCF/';
	const whatsAppUrl = `https://wa.me/${sponsorData.contactWhatsapp}`;

	useEffect(() => {
		apiExec
			.get('/sponsors')
			.then(response => setSponsorData(response.data))
			.catch(error => console.log(error));
	}, []);

	function handleCopyContactNumber() {
		if (navigator.clipboard && sponsorData.contactPhone) {
			navigator.clipboard.writeText(sponsorData.contactPhone);

			setIsAlreadyCopied(true);
			setTimeout(() => {
				setIsAlreadyCopied(false);
			}, 1000);
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				p: 1,
				bgcolor: colors.grey[700],
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 4,
					flex: 1,
					p: 1,
				}}
			>
				<LightTooltip title="Fechar">
					<IconButton onClick={onClose}>
						<Cancel fontSize="large" />
					</IconButton>
				</LightTooltip>

				<LightTooltip title="Documentação">
					<IconButton onClick={() => window.open(documentationUrl)}>
						<AutoStories fontSize="large" />
					</IconButton>
				</LightTooltip>

				<LightTooltip title="YouTube">
					<IconButton onClick={() => window.open(youtubeUrl)}>
						<YouTube fontSize="large" />
					</IconButton>
				</LightTooltip>

				{sponsorData.website && (
					<LightTooltip title="Acessar corretora">
						<IconButton
							onClick={() => window.open(sponsorData.website)}
						>
							{PARTNERS_LOGOS[sponsorData.shortName] ? (
								<img
									height={24}
									src={PARTNERS_LOGOS[sponsorData.shortName]}
									alt="CM Capital"
								/>
							) : (
								<AccountBalance fontSize="large" />
							)}
						</IconButton>
					</LightTooltip>
				)}

				{sponsorData.contactPhone && (
					<LightTooltip
						title={
							isAlreadyCopied
								? 'Copiado!'
								: sponsorData.contactPhone
						}
					>
						<IconButton onClick={handleCopyContactNumber}>
							<Phone fontSize="large" />
						</IconButton>
					</LightTooltip>
				)}

				{sponsorData.contactWhatsapp && (
					<LightTooltip title="WhatsApp">
						<IconButton onClick={() => window.open(whatsAppUrl)}>
							<WhatsApp fontSize="large" />
						</IconButton>
					</LightTooltip>
				)}
			</Box>
		</Box>
	);
};
