import styled from 'styled-components';

import { TextField, Button, Autocomplete } from '@mui/material';

import {
	Checkbox,
	Box,
	Tab,
	Grid,
	FormControlLabel,
	FormControl,
	TableRow,
} from '@mui/material';

export const BottomContainer = styled(Grid)`
	display: flex;
	justify-content: ${props => props.execType === 'B' && 'flex-end'};
`;

export const ProjectedCoustContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-left: 20px;
	padding: 0 5px;
	border-radius: 5px;
	color: ${props =>
		props.disabled ? '#ffffff' : props.gainCr ? 'lime' : '#ff7777'};
	opacity: ${props => (props.disabled ? 0.7 : 1)};
	border: 2px solid
		${props =>
		props.disabled ? '#ffffff' : props.gainCr ? '#388E3C' : '#d32f2f'};

	p {
		color: #fff;
	}
`;

export const SizedTextField = styled(TextField)`
	.MuiInputBase-input {
		padding: 0.3rem 0.75rem !important;
	}

	.MuiOutlinedInput-inputMarginDense {
		padding-right: 18px !important;
	}
	.MuiSelect-iconOutlined {
		right: 3px;
	}
	input::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}
	.MuiOutlinedInput-inputMarginDense {
		padding: ${({ padding }) => padding || '5px 0px 3px 6px'};
	}
	.MuiOutlinedInput-adornedStart {
		padding-left: 6px;
	}
	.MuiInputAdornment-positionStart {
		margin-right: 0px;
	}
	.MuiSelect-icon {
		top: 2px;
	}
`;

export const PasswordTextField = styled(TextField)`
	input {
		font-family: ${props => {
		if (props.visible) {
			return 'Roboto';
		}
		return 'text-security-disc';
	}};
		-webkit-text-security: ${props => {
		if (props.visible) {
			return 'initial';
		}
		return 'disc';
	}};
	}
	input:read-only {
		cursor: not-allowed;
	}
	/* .MuiOutlinedInput-inputMarginDense {
		padding: ${({ padding }) => padding || '5px 0px 3px 6px'};
	} */
	.MuiOutlinedInput-adornedStart {
		padding-left: 6px;
	}
	.MuiInputAdornment-positionStart {
		margin-right: 0px;
	}
	.MuiSelect-icon {
		top: 2px;
	}
	.MuiInputBase-input {
		padding: 0.3rem 0.75rem !important;
	}
`;

export const FinantialText = styled(TextField)`
	.MuiOutlinedInput-inputMarginDense {
		padding: 5px 5px 3px 6px;
	}
	.MuiInputBase-input {
		color: ${props => (props.finantialColor ? '#f44336' : '#388e3c')};
		text-align: right;
	}
`;

export const SizedAutocomplete = styled(Autocomplete)`
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
		padding: 0px !important;
	}
	.MuiInputBase-input {
		padding: 5px 5px 4px 6px !important;
	}

	/* .MuiAutocomplete-endAdornment {
		top: 0;
	} */
`;

export const SizedCheckBoxContainer = styled(FormControlLabel)`
	margin-left: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	cursor: not-allowed !important;
	.MuiTypography-body1 {
		font-size: 8px;
		cursor: text;
	}
	span {
		font-size: ${props =>
		props.smallLabel ? '0.83rem !important' : '1rem !important'};
	}
`;

export const SizedCheckBox = styled(Checkbox)`
	padding: 4px !important;
`;

export const SizedFormControl = styled(FormControl)`
	.MuiOutlinedInput-inputMarginDense {
		padding: 5px 0px 3px 6px;
	}
`;

export const ClickableBox = styled(Box)`
	cursor: pointer;
	min-width: 45px;
	word-break: break-word;
	max-width: 72px;
`;

export const FirstTab = styled(Tab)`
	display: none !important;
`;

export const ButtonCurrency = styled(Button)`
	width: 15px !important;
	min-width: 0px !important;
	height: 20px !important;
	min-height: 0px !important;
	padding: 0px !important;
	border-radius: 3px 0px 0px 3px !important;
	box-shadow: 0px !important;
`;

export const FinanticalBtn = styled(Button)`
	padding: 2px 7px !important;
	min-width: 0px !important;
	min-height: 0px !important;
	border-radius: 3px 0px 0px 3px !important;
	box-shadow: 0px !important;
`;

export const ButtonBuySell = styled(Button)`
	padding: 1px 10px !important;

	.MuiButton-iconSizeSmall {
		font-size: 11px !important;
	}
`;

export const SendButton = styled(Button)`
	background-color: #388e3c !important;
	color: #fff !important;
`;

export const GeneralButton = styled(Button)`
	padding: 2px 8px !important;
	transform: 0.5s;
	color: ${props =>
		props.buttonColor === 'scanner'
			? '#303030 !important'
			: 'white !important'};

	background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336a1 !important'
			: props.buttonColor === 'success'
				? '#206123a1 !important'
				: props.buttonColor === 'warning'
					? '#f57c00a1 !important'
					: props.buttonColor === 'info'
						? '#2196f3a1 !important'
						: props.buttonColor === 'scanner'
							? '#fffffff2 !important'
							: '#777777a1 !important'};

	:hover {
		transform: 0.5s;
		background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336f2 !important'
			: props.buttonColor === 'success'
				? '#206123f2 !important'
				: props.buttonColor === 'warning'
					? '#f57c00f2 !important'
					: props.buttonColor === 'info'
						? '#2196f3f2 !important'
						: props.buttonColor === 'scanner'
							? '#ffffffa1 !important'
							: '#777777f2 !important'};
	}
`;

// Decimal Styles

export const DecimalTextField = styled(TextField)`
	.MuiInput-underline:before {
		position: static;
		content: normal;
		display: none;
	}

	.MuiOutlinedInput-input {
		padding: 5px 5px 3px 6px;
	}

	/* Classe antiga do Material UI V4 */
	/* .MuiOutlinedInput-inputMarginDense {
		padding: 5px 5px 3px 6px;
	} */
`;

export const DecimalUp = styled(Button)`
	min-width: 20px !important;
	padding: 0px !important;
	height: 12px !important;
	border-radius: 0px 3px 0px 0px !important;
	box-shadow: none !important;
	background-color: #e0e0e0 !important;
	color: rgba(0, 0, 0, 0.87) !important;
`;

export const DecimalDown = styled(Button)`
	min-width: 20px !important;
	padding: 0px !important;
	height: 12px !important;
	border-radius: 0px 0px 3px 0px !important;
	box-shadow: none !important;
	background-color: #e0e0e0 !important;
	color: rgba(0, 0, 0, 0.87) !important;
`;

// Fim

// Recentes de Salvas
export const StyledTableRow = styled(TableRow)`
	:hover {
		transition: 0.3s;
		background-color: #616161;
	}
	cursor: pointer;
`;
