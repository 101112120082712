import { store } from 'store';
import { differenceInDays, isBefore, parse } from 'date-fns';

export function changeRangeDate(
	rangeDate,
	field,
	updateFormValues,
	validateDateBefore = false,
	dateBefore = '',
	messageDateBefore = '',
	setSnackbarState = null,
	maximumRange = 31
) {
	const [startDate, endDate] = rangeDate;

	if (startDate) {
		if (validateDateBefore) {
			const isStartDateValid = isBefore(
				startDate,
				parse(dateBefore, 'yyyy-MM-dd', new Date())
			);

			if (isStartDateValid) {
				if (setSnackbarState) {
					setSnackbarState({
						isOpen: true,
						message: messageDateBefore,
					});
				}

				return;
			}
		}
	}

	if (startDate && endDate) {
		const daysDifference = differenceInDays(endDate, startDate);

		if (daysDifference > maximumRange) {
			setSnackbarState({
				type: 'warning',
				message:
					'As datas devem estar no maximo em um range de 31 dias!',
				isOpen: true,
				duration: 5000,
			});

			return;
		}

		store.dispatch(updateFormValues({ [field]: [startDate, endDate] }));
	}
}
