import { ExecutionsActionTypes } from './actionTypes';

export const executionsInitialState = {
	rows: [],
	filtersTransactionTabs: false,
	updatedAt: '',
	searchForm: {
		strategyId: '',
		active: '',
		cblc: '',
		dates: [null, null],
		status: 'F',
	},
	searchPaginate: {
		strategyId: '',
		active: '',
		cblc: '',
		dates: [null, null],
	},
	paginationInfo: {
		page: 0,
		limit: 26,
		num_records: 0,
		total: 50,
		offset: 0,
	},
	tablePreferences: {
		density: 'compact',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		sorting: {
			sortModel: [
				{
					field: 'SendingTime',
					sort: 'desc',
				},
			],
		},
		columns: {
			orderedFields: [
				'StrategyId',
				'Name',
				'OrderID',
				'Account',
				'Symbol',
				'Side',
				'OrderQty',
				'Price',
				'MarketOrderID',
				'OrderType',
				'ExecType',
				'LastQty',
				'LastPx',
				'ExecutedQty',
				'RemainingQty',
				'StopPrice',
				'rlp',
				'ExpireDate',
				'TimeInForce',
				'SendingTime',
				'ExecID',
			],
			columnsSize: [],
			columnVisibilityModel: {
				StrategyId: false,
				OrderID: false,
				MarketOrderID: false,
				rlp: false,
				ExecID: false,
				StopPrice: false,
				LastQty: false,
			},
		},
	},
};

export function executions(state = executionsInitialState, { type, payload }) {
	switch (type) {
		case ExecutionsActionTypes.UPDATE_STATE_VALUES:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ExecutionsActionTypes.SET_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};

		case ExecutionsActionTypes.UPDATE_ORDERS_FORM_VALUES:
			return {
				...state,
				searchForm: {
					...state.searchForm,
					...payload.newValues,
				},
			};

		case ExecutionsActionTypes.CLEAR_STATE_VALUES:
			return executionsInitialState;

		case ExecutionsActionTypes.CLEAR_FORM_VALUES: {
			const hasSingleAccount = payload.accounts?.length === 1;
			const formattedAccount = hasSingleAccount
				? { cblc: payload.accounts[0] }
				: {};

			return {
				...state,
				searchForm: {
					...executionsInitialState.searchForm,
					...formattedAccount,
				},
			};
		}
		case ExecutionsActionTypes.CLEAR_SEARCH_PAGINATE:
			return {
				...state,
				searchPaginate: executionsInitialState.searchPaginate,
			};

		case ExecutionsActionTypes.UPDATE_PAGINATION_INFO:
			return {
				...state,
				paginationInfo: {
					...state.paginationInfo,
					...payload.paginationInfo,
				},
			};
		case ExecutionsActionTypes.UPDATE_USER_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					...payload.userPreferences,
				},
			};
		case ExecutionsActionTypes.UPDATE_SEARCH_PAGINATE:
			return {
				...state,
				searchPaginate: {
					...state.searchPaginate,
					...payload.searchPaginate,
				},
			};

		default:
			return state;
	}
}
