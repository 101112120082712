import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';

import DecimalInput from 'components/MaterialComponents/DecimalInput';
import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';
import SelectInput from 'components/MaterialComponents/SelectInput';
import paperValidator from 'utils/paperValidation';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from 'components/MaterialComponents/IconTooltip';

const TwapExit = ({ handleChange, stepFunction }) => {
	const { t } = useTranslation();
	
	const { bullet } = useSelector(state => state.bottomBullet);
	const widgetOpen = useSelector(state => state.configs.widgetBar);

	return (
		<>
			<Grid item xs={4}>
				<Grid container spacing={1} direction="row">
					<Grid item xs={12} style={{ marginLeft: '3px' }}>
						<Typography variant="subtitle1">
							Parâmetros de execução
						</Typography>
					</Grid>

					<Grid item xs={3}>
						<DecimalInput
							name="clipField"
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet
							)}
							label="Clip máximo"
							iconTooltip={t('bullets.general.clip')}
							minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
							maxVal={
								bullet.StrategyInfos[0].maxOrderQty ?? 9999999
							}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegMaxClipSize" // subtype
							stepSize={
								bullet.StrategyInfos[0].roundLot ||
								bullet.StrategyInfos[0].Cst_MinOrderQuantity
							} // incremento
							valueToStep={
								bullet.content.StrategyLegs[0].LegMaxClipSize
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[0].LegMaxClipSize
							}
							onChange={e =>
								handleChange(
									'Leg',
									'LegMaxClipSize',
									parseInt(e.value, 10),
									0
								)
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectInput
							name="AgressionLevel"
							label="Modo de agressão"
							iconTooltip={t(
								'bullets.participatory.agression_mode'
							)}
							onChange={e =>
								handleChange(
									'Custom',
									'AgressionLevel',
									e.target.value,
									0
								)
							}
							defaultValue={0}
							value={
								bullet.content.CustomParameters
									.AgressionLevel ?? 0
							}
							selectOptions={[
								{
									value: 0,
									label: 'Contra-Parte',
								},
								{
									value: 2,
									label: 'Parte',
								},
							]}
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet
							)}
						/>
					</Grid>
					<Grid item xs={4} mt={widgetOpen ? -1.3 : -0.5}>
						<Box
							display="flex"
							alignItems="center"
							mt={0.5}
							style={{ width: '120px' }}
						>
							<MaterialCheckBox
								name="limitByVolume"
								label="Limitar por volume"
								disabled={
									bullet.content.ClOrdID
										? true
										: paperValidator(
											bullet.StrategyInfos[0],
											bullet
										  )
								}
								onChange={() =>
									handleChange(
										'Custom',
										'LimitByVolume',
										bullet.content.CustomParameters
											.LimitByVolume === 'Y'
											? 'N'
											: 'Y',
										0
									)
								}
								checked={
									bullet.content.CustomParameters
										.LimitByVolume === 'Y'
								}
								smallLabel
							/>
							<Box ml={0.5} mt={0.5}>
								<IconWithTooltip
									sizeFont="12px"
									title={t(
										'bullets.participatory.twap.limit_by_volum'
									)}
								/>
							</Box>
						</Box>
					</Grid>
					{bullet.content.CustomParameters.LimitByVolume === 'Y' && (
						<>
							<Grid
								container
								xs={7}
								direction="row"
								style={{ marginTop: '7px', marginLeft: '7px' }}
							>
								<Grid item xs={6}>
									<DecimalInput
										name="TargetPercent"
										label="Participação(%)"
										iconTooltip={t(
											'bullets.participatory.twap.target_percent'
										)}
										disabled={paperValidator(
											bullet.StrategyInfos[0],
											bullet
										)}
										minVal={0}
										maxVal={999999.99}
										minDecScale={2} // casas decimais
										stepFunction={stepFunction}
										stepType="Custom" // type
										stepSubType="TargetPercent" // subtype
										stepSize={
											bullet.StrategyInfos[0]
												.minPriceIncrement ||
											bullet.StrategyInfos[0]
												.Cst_MinPriceIncrement
										} // incremento
										valueToStep={
											bullet.content.CustomParameters
												.TargetPercent
										} // valor a ser modificado
										value={
											bullet.content.CustomParameters
												.TargetPercent
										}
										onChange={e =>
											handleChange(
												'Custom',
												'TargetPercent',
												e.value,
												0
											)
										}
									/>
								</Grid>
								<Grid item xs style={{ marginLeft: '4px' }}>
									<Box
										display="flex"
										alignItems="center"
										mt={-0.3}
										style={{ width: '100px' }}
									>
										<MaterialCheckBox
											name="considerCrossOrders"
											label="Cons. diretas"
											disabled={paperValidator(
												bullet.StrategyInfos[0],
												bullet
											)}
											onChange={() =>
												handleChange(
													'Custom',
													'ConsiderCrossOrders',
													bullet.content
														.CustomParameters
														.ConsiderCrossOrders ===
														'Y'
														? 'N'
														: 'Y',
													0
												)
											}
											checked={
												bullet.content.CustomParameters
													.ConsiderCrossOrders === 'Y'
											}
											smallLabel
										/>
										<Box ml={0.5} mt={0.5}>
											<IconWithTooltip
												sizeFont="12px"
												title={t(
													'bullets.participatory.consider_cross_orders'
												)}
											/>
										</Box>
									</Box>
								</Grid>
							</Grid>

							{bullet.content.ClOrdID && (
								<Grid item xs={2}>
									<MaterialCheckBox
										label="Completar a mercado"
										onChange={() =>
											handleChange(
												'Custom',
												'CompleteToMarket',
												bullet.content.CustomParameters
													.CompleteToMarket === 'Y'
													? 'N'
													: 'Y',
												0
											)
										}
										checked={
											bullet.content.CustomParameters
												.CompleteToMarket === 'Y'
										}
										smallLabel
									/>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default TwapExit;
