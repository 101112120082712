import { format } from 'date-fns';

import { store } from 'store';
import { updateRecentOrders } from 'services/fetchDynamoOrders';

export function addRecentOrder(strategy) {
	const strategyToHandle = {
		content: { ...strategy.content },
		StrategyInfos: strategy.StrategyInfos.map(info => ({
			...info,
		})),
	};

	const recentOrders = store.getState().bottomBullet.recentOrders;
	const currentFullDate = new Date();

	const date = format(currentFullDate, 'yyyy-MM-dd');
	const time = format(currentFullDate, 'HH:mm');

	let formattedStrategyLegs = {};
	if (strategy.content.StrategyCode !== 'portbalance') {
		formattedStrategyLegs = {
			StrategyLegs: strategy.content.StrategyLegs.map(leg => ({
				...leg,
			})),
		};
	}

	const formattedId = `${strategy.content.StrategyCode}-${strategy.content.Name}-${strategy.content.ExpireDate}`;

	const formattedStrategy = {
		...strategyToHandle.content,
		...formattedStrategyLegs,
		StrategyInfos: strategyToHandle.StrategyInfos,
		id: formattedId,
		date,
		time,
	};

	const newRecentOrders = [formattedStrategy, ...recentOrders].slice(0, 10);

	updateRecentOrders(newRecentOrders);
}
