import React from 'react';
import { useDispatch } from 'react-redux';

import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { store } from 'store';

import SelectInput from 'components/MaterialComponents/SelectInput';

import { setPartialGlobalSettings } from 'store/modules/panelConfig/actions';

export const BulletSettings = () => {
	const dispatch = useDispatch();

	const accounts = store.getState().auth.accounts;

	const userSignatures = store.getState().products.userSignatures;

	const execTypeInitialValue =
		store.getState().configs.globalSettings?.userSettings?.bullet.execType;

	const defaultAccountValue =
		store.getState().configs.globalSettings?.userSettings?.bullet
			.defaultAccount;

	const viewContinousStrategiesValue =
		store.getState().configs.globalSettings?.userSettings?.bullet
			.viewContinousStrategies;

	const radarProductIsAvailable = userSignatures.find(
		item => item.product.family === 'Radar Dividendos'
	);

	function handleChangeBulletSettings(field, value) {
		dispatch(setPartialGlobalSettings('bullet', { [field]: value }));
	}

	const seenCodes = new Set();

	const options = accounts.filter(accountItem => {
		if (seenCodes.has(accountItem.code)) {
			return false;
		}

		seenCodes.add(accountItem.code);
		return true;
	});

	return (
		<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 2 }}>
			<FormControl>
				<FormLabel
					sx={{
						color: '#ffffff',
						'&.Mui-focused': {
							color: '#ffffff',
						},
					}}
				>
					<Typography variant="h6">
						Tipo de boleta de Spread
					</Typography>
				</FormLabel>

				<Typography variant="body1">
					Habilitar/desabilitar a opção de boleta básica ou avançada
					para as estratégias de Spread e Long&Short.
				</Typography>

				<RadioGroup
					defaultValue={execTypeInitialValue}
					sx={{ '& label': { mb: 0 } }}
				>
					<FormControlLabel
						value="B"
						control={<Radio sx={{ color: '#d1d1d1' }} />}
						label="Básica"
						onChange={event =>
							handleChangeBulletSettings(
								'execType',
								event.target.value
							)
						}
					/>

					<FormControlLabel
						value="A"
						control={<Radio sx={{ color: '#d1d1d1' }} />}
						label="Avançada"
						onChange={event =>
							handleChangeBulletSettings(
								'execType',
								event.target.value
							)
						}
					/>
				</RadioGroup>

				<FormLabel
					sx={{
						color: '#ffffff',
						'&.Mui-focused': {
							color: '#ffffff',
						},
					}}
				>
					<Typography variant="h6">
						Definir conta padrão nas boletas
					</Typography>
				</FormLabel>

				<Typography variant="body1">
					Caso você tenha mais de uma conta vinculada ao seu usuário,
					é possível definir uma conta padrão que sempre virá
					selecionada na abertura de novas boletas.
				</Typography>

				<SelectInput
					name="DefaultAccount"
					label="Conta padrão"
					style={{
						marginTop: '20px',
						width: '50%',
						marginBottom: '10px',
					}}
					defaultValue={defaultAccountValue ?? 'Nenhuma'}
					onChange={event =>
						handleChangeBulletSettings(
							'defaultAccount',
							event.target.value
						)
					}
					selectOptions={[
						{
							value: 'Nenhuma',
							label: 'Nenhuma',
						},
						...options.map(account => ({
							value: account.code,
							label: account.code,
						})),
					]}
				/>

				{radarProductIsAvailable && (
					<>
						<FormLabel
							sx={{
								color: '#ffffff',
								'&.Mui-focused': {
									color: '#ffffff',
								},
							}}
						>
							<Typography variant="h6">Troca Continua</Typography>
						</FormLabel>

						<Typography variant="body1">
							Habilitar/desabilitar a opção de visualização de
							estrátegias filhas nos modulos de Acompanhamento de
							Estrátegias, Histórico e Ordens.
						</Typography>

						<RadioGroup
							defaultValue={
								viewContinousStrategiesValue
									? 'enabled'
									: 'disabled'
							}
							sx={{ '& label': { mb: 0 } }}
						>
							<FormControlLabel
								label="Habilitado"
								control={
									<Radio
										value="enabled"
										sx={{ color: '#d1d1d1' }}
										onChange={() => {
											handleChangeBulletSettings(
												'viewContinousStrategies',
												true
											);
										}}
									/>
								}
							/>

							<FormControlLabel
								label="Desabilitado"
								control={
									<Radio
										value="disabled"
										sx={{ color: '#d1d1d1' }}
										onChange={() => {
											handleChangeBulletSettings(
												'viewContinousStrategies',
												false
											);
										}}
									/>
								}
							/>
						</RadioGroup>
					</>
				)}
			</FormControl>
		</Box>
	);
};
