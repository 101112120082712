import {
	SET_MODALS,
	SET_MODAL_PREFERENCE,
	SET_GOT_NOTIFICATIONS,
	RESET_STATE,
} from './types';

const initialState = {
	modals: {},
	utils: {
		gotNotifications: false,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_MODALS:
			return { ...state, modals: payload.modals };

		case SET_MODAL_PREFERENCE:
			return {
				...state,
				modals: {
					...state.modals,
					[payload.modalOption]: payload.hasSeen,
				},
			};

		case SET_GOT_NOTIFICATIONS:
			return {
				...state,
				utils: {
					...state.utils,
					gotNotifications: payload.hasGottenNotifications,
				},
			};

		case RESET_STATE:
			return initialState;

		default:
			return state;
	}
};
