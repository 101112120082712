import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine, PiecewiseColorLegend } from '@mui/x-charts';

export const ChartMaxHistory = ({ loading }) => {
	const { historyData, searchApplied } = useSelector(
		state => state.radarModule
	);

	const colorRadarText = '#ffa500';

	const getValuePriceFormatted = value =>
		new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(value);

	return (
		<>
			<Box width="100%" my={5}>
				<Typography variant="h4" fontWeight="bold">
					Comparação entre ativos
				</Typography>
				<Typography variant="h6">
					O gráfico abaixo apresenta{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol1_prop} lote(s)
					</strong>{' '}
					de{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol1}
					</strong>{' '}
					comparado com{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol2_prop} lote(s)
					</strong>{' '}
					de{' '}
					<strong style={{ color: colorRadarText }}>
						{searchApplied.symbol2}
					</strong>
				</Typography>

				<Box
					sx={{
						width: '100%',
						height: '50vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography variant="h5" mt={2}>
						Máxima histórica de {searchApplied.symbol1} &{' '}
						{searchApplied.symbol2}
					</Typography>

					<LineChart
						loading={loading}
						grid={{ horizontal: true }}
						slotProps={{ legend: { hidden: true } }}
						dataset={historyData}
						series={[
							{
								dataKey: 'maxSymbol1',
								label: `${historyData[0]?.symbol1}:`,
								valueFormatter: getValuePriceFormatted,
								showMark: false,
							},
							{
								dataKey: 'maxSymbol2',
								label: historyData[0]?.symbol2,
								valueFormatter: getValuePriceFormatted,
								showMark: false,
							},
							{
								dataKey: 'creditsLayers',
								label: 'Crédito',
								valueFormatter: getValuePriceFormatted,
								showMark: false,
							},
						]}
						xAxis={[
							{
								scaleType: 'time',
								dataKey: 'date',
								disableLine: true,
								valueFormatter: value => {
									const formattedDate = format(
										value,
										'MMM yyyy',
										{
											locale: ptBR,
										}
									);

									return formattedDate;
								},
							},
						]}
						yAxis={[{ disableLine: true, disableTicks: true }]}
						margin={{
							bottom: 85,
						}}
						bottomAxis={{
							labelStyle: {
								fontSize: 14,
								transform: `translateY(${
									5 * Math.abs(Math.sin((Math.PI * 45) / 180))
								}px)`,
							},
							tickLabelStyle: {
								angle: 45,
								textAnchor: 'start',
								fontSize: 12,
							},
						}}
					>
						<PiecewiseColorLegend
							axisDirection="x"
							position={{ vertical: 'top', horizontal: 'right' }}
							direction="column"
						/>
						<ChartsReferenceLine y={0} />
					</LineChart>
				</Box>
			</Box>
		</>
	);
};
