import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { format, isValid, parse } from 'date-fns';

import {
	ClickAwayListener,
	Fade,
	List,
	MenuItem,
	Paper,
	Popper,
} from '@mui/material';

import { parseMenuAction } from 'services/parseMenuAction';
import { dispatchMenuAction } from 'utils/dispatchMenuAction';
import { api, apiExec, showMessageErroWithoutStatusCode } from 'services/api';

export const RecurrenceStrategyMenu = ({
	isOpen,
	anchorEl,
	onClose,
	row,
	handleSearch,
	setSnackbarState,
}) => {
	const algos = useSelector(state => state.auth.algos);
	const paginationInfo = useSelector(
		state => state.recurrence.paginationInfo
	);

	const menuActions = [
		{
			label: 'Ver recorrência',
			isVisible: true,
			action: () => handleDispatchMenuAction('PARAMS'),
		},
		{
			label: 'Clonar',
			isVisible: true,
			action: () => {
				handleDispatchMenuAction('CLONE');

				const strategyType =
					row.StrategyCode?.toUpperCase() ?? row.TargetStrategy;

				ReactGA.event({
					category: 'Recurrence',
					action: `CLONE_RECURRENCE_${strategyType}`,
				});
			},
		},
		{
			label: 'Cancelar',
			isVisible: !row.ScheduledJobs?.every(job => job.Status !== 1),
			action: () => handleCancelJob(row.id),
		},
	];

	function handleCancelJob(schedulerId) {
		apiExec
			.delete(`/schedulers/${schedulerId}`)
			.then(() => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 2000,
					severity: 'success',
					message: 'Recorrência cancelada com sucesso.',
				}));

				setTimeout(
					() => handleSearch(paginationInfo, null, true),
					3000
				);
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => onClose());
	}

	function getFormattedHour(hour) {
		let hourFormat = 'HH:mm:ss';

		if (hour.length > 8) {
			hourFormat = 'yyyy-MM-dd HH:mm:ss';
		}

		const parsedHour = parse(hour, hourFormat, new Date());

		if (isValid(parsedHour)) {
			const formattedHour = format(parsedHour, 'HH:mm:ss');

			return formattedHour;
		}

		return hour;
	}

	async function handleDispatchMenuAction(actionType) {
		/* Retirando as pernas duplicadas da estrategia continua */
		if (
			row?.StrategyCode === 'cont' &&
			row.StrategyTemplate.StrategyLegs.length === 4
		) {
			row.StrategyTemplate.StrategyLegs =
				row.StrategyTemplate.StrategyLegs.splice(2, 4);
		}

		const formattedPositions = row.StrategyTemplate.StrategyLegs.map(
			leg => ({
				...row.StrategyTemplate,
				...leg,
				recurrenceInfo: {
					isRecurrence: true,
					ActiveStartDate: row.ActiveStartDate,
					ActiveEndType: row.ActiveEndType,
					ActiveEndDate: row.ActiveEndDate,
					FreqType: row.FreqType,
				},
				InitTime: getFormattedHour(row.StrategyTemplate.InitTime),
				EndTime: getFormattedHour(row.StrategyTemplate.EndTime),
				CustomParameters: {
					...row.StrategyTemplate.CustomParameters,
					Trigger: Number(
						row.StrategyTemplate.CustomParameters.Trigger
					),
					TriggerValue: Number(
						row.StrategyTemplate.CustomParameters.TriggerValue
					),
				},
			})
		);

		const optionsAction = await parseMenuAction(
			row.StrategyTemplate.ClOrdID,
			actionType,
			{ positions: formattedPositions, algos },
			api,
			apiExec,
			showMessageErroWithoutStatusCode
		);

		dispatchMenuAction(actionType, optionsAction);
		onClose();
	}

	return (
		<>
			<Popper
				open={isOpen}
				anchorEl={anchorEl}
				placement="right"
				transition
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={onClose}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List
									sx={{
										display: 'flex',
										py: 0,
										borderRadius: 1,
									}}
								>
									{menuActions
										.filter(item => item.isVisible)
										.map(item => (
											<MenuItem
												key={item.label}
												onClick={item.action}
											>
												{item.label}
											</MenuItem>
										))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
