import { store } from 'store';

import { setStrategiesTabIndex } from 'store/modules/panelConfig/actions';
import {
	setStateValues,
	updateOrdersFormValues,
} from 'store/modules/executions/actions';

export function viewExecutionStrategy(row, fromModule) {
	const [leg] = row.Legs;
	const account = leg.ILegAllocAccount;

	store.dispatch(
		updateOrdersFormValues({
			strategyId: row.StrategyId,
			cblc: account,
			dates: [null, null],
			status: fromModule === 'currentStrategies' ? 'all' : 'F',
		})
	);

	store.dispatch(setStateValues('filtersTransactionTabs', true));
	store.dispatch(setStrategiesTabIndex(2));
}
