import React from 'react';
import { Grid, colors } from '@mui/material';

import SpreadBodyBullet from './SpreadBodyBullet';
import SingleBodyBullet from './SingleBodyBullet';
import LongShortBodyBullet from './LongShortBodyBullet';
import FinancBodyBullet from './FinancBodyBullet';
import GradientBodyBullet from './GradientBodyBullet';
import IcebergBodyBullet from './IcebergBodyBullet';
import TwapBodyBullet from './TwapBodyBullet';
import PovBodyBullet from './PovBodyBullet';
import CvvolBodyBullet from './CvvolBodyBullet';
import SkewBodyBullet from './SkewBodyBullet';
import MarketMakerBodyBullet from './MarketMakerBodyBullet';
import PortBalanceBodyBullet from './PortBalanceBodyBullet';
import { GapBullet } from './GapBullet';

const BodyBullet = props => {
	const getBody = bodyProps => {
		switch (bodyProps.StrategyCode) {
			case 'spread':
			case 'spread2p':
			case 'spread3p':
			case 'spread4p':
			case 'spread5p':
			case 'spread6p':
			case 'cont':
				return <SpreadBodyBullet {...bodyProps} />;
			case 'sorder':
			case 'fxoder':
			case 'boffer':
			case 'sniper':
			case 'mit':
				return <SingleBodyBullet {...bodyProps} />;
			case 'lgshort':
				return <LongShortBodyBullet {...bodyProps} />;
			case 'financ':
				return <FinancBodyBullet {...bodyProps} />;
			case 'grddin':
			case 'grdlin':
				return <GradientBodyBullet {...bodyProps} />;
			case 'iceberg':
				return <IcebergBodyBullet {...bodyProps} />;
			case 'twap':
				return <TwapBodyBullet {...bodyProps} />;
			case 'pov':
				return <PovBodyBullet {...bodyProps} />;
			case 'cvvol':
				return <CvvolBodyBullet {...bodyProps} />;
			case 'skew':
				return <SkewBodyBullet {...bodyProps} />;
			case 'marketmaker':
				return <MarketMakerBodyBullet {...bodyProps} />;
			case 'portbalance':
				return <PortBalanceBodyBullet {...bodyProps} />;
			case 'gap':
				return <GapBullet {...bodyProps} />;

			default:
				return <SingleBodyBullet {...bodyProps} />;
		}
	};

	return (
		<>
			<Grid
				spacing={2}
				container
				sx={{ background: colors.grey[800], p: 1, pt: 0 }}
			>
				{getBody({ ...props })}
			</Grid>
		</>
	);
};

export default BodyBullet;
