import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import SelectInput from '../SelectInput';
import { ClickableBox } from '../MaterialStyles/style';
import DecimalInput from '../DecimalInput';
import {
	getDecimalPlacesByTrigger,
	getStepByTrigger,
} from '../../../utils/strategies';
import { useTranslation } from 'react-i18next';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';

const FinancExecutionParams = ({ handleChange, stepFunction, execType }) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const { marketPrices } = useSelector(state => state.bottomBullet);
	const { t } = useTranslation();

	const handleStopChanges = (value, customParam) => {
		handleChange('Custom', customParam, value);
	};

	const handleOnClickMarketPrices = (value, customParam) => {
		if (!bullet.paramsView) {
			if (value > 0.0) {
				handleChange('Custom', customParam, value);
			}
		}
	};

	const isFieldDisableBySymbol = () => {
		const legsLength = bullet.content.StrategyLegs.length - 1;
		return isDisableBySymbol(bullet, legsLength);
	};

	return (
		<Grid item xs={execType === 'B' ? 8 : 6}>
			<Grid container spacing={1.5} direction="row">
				<Grid item xs={12} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">
						Parâmetros de execução
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={12}
					spacing={1}
					style={{ marginBottom: '2px' }}
				>
					<Grid item xs={3}>
						<SelectInput
							name="executionType"
							disabled={true}
							label="Modo"
							iconTooltip={t(
								'bullets.spread.financ.execution_type'
							)}
							defaultValue={0}
							onChange={e => {
								handleChange(
									'Custom',
									'ExecutionType',
									e.target.value
								);
								handleChange(
									'Custom',
									'ToMarketTriggerValueEnabled',
									e.target.value === 0 ? 'N' : 'Y'
								);
							}}
							value={0}
							selectOptions={[
								{
									value: 0,
									label: 'Entrada',
								},
							]}
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectInput
							disabled={true}
							name="executionTrigger"
							label="Gatilho"
							iconTooltip={t('bullets.spread.financ.trigger')}
							defaultValue={16}
							value={16}
							selectOptions={[
								{
									value: 16,
									label: 'Taxa',
								},
							]}
						/>
					</Grid>
					<Grid item xs={3}>
						<DecimalInput
							name="entryFinanc"
							disabled={isFieldDisableBySymbol()}
							label={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) !== 0
									? 'Financ. Gain(%)'
									: 'Financ. Entrada(%)'
							}
							allowNegatives={false}
							iconTooltip={t('bullets.general.trigger_value')}
							minVal={0.0}
							maxVal={999999.99}
							minDecScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="TriggerValue" // subtype
							stepSize={getStepByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // incremento
							valueToStep={
								bullet.content.CustomParameters.TriggerValue
							} // valor a ser modificado
							value={bullet.content.CustomParameters.TriggerValue}
							onChange={e =>
								handleStopChanges(
									parseFloat(e.value),
									'TriggerValue'
								)
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<Box mt={-1.5} ml={1.3} data-foo="1">
							<Typography variant="subtitle2">Taxa(%)</Typography>
							<Box display="flex">
								<Box pr={1}>
									<ClickableBox
										onClick={() =>
											handleOnClickMarketPrices(
												marketPrices,
												'TriggerValue'
											)
										}
									>
										<Typography variant="subtitle1">
											{marketPrices && marketPrices > 0.0
												? marketPrices.toLocaleString(
													'de-DE',
													{
														minimumFractionDigits: 2,
														maximumFractionDigits:
																getDecimalPlacesByTrigger(
																	bullet
																		.content
																		.CustomParameters
																		.Trigger
																),
													}
												  )
												: '--'}
										</Typography>
									</ClickableBox>
								</Box>
							</Box>
						</Box>
					</Grid>
					{parseInt(
						bullet.content.CustomParameters.ExecutionType,
						10
					) === 1 && (
						<>
							<Grid item xs={6} />
							<Grid item xs={3}>
								<DecimalInput
									disabled={isFieldDisableBySymbol()}
									label="Financ. Loss(%)"
									minVal={0.0}
									maxVal={999999.99}
									minDecScale={getDecimalPlacesByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // casas decimais
									allowNegatives={false}
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="ToMarketTriggerValue" // subtype
									stepSize={getStepByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // incremento
									valueToStep={
										bullet.content.CustomParameters
											.ToMarketTriggerValue
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.ToMarketTriggerValue
									}
									onChange={e =>
										handleStopChanges(
											parseFloat(e.value),
											'ToMarketTriggerValue'
										)
									}
								/>
							</Grid>

							<Grid item xs={3}>
								<Box mt={-1.5} ml={1.3}>
									<Typography variant="subtitle2">
										Taxa(%)
									</Typography>

									<Box display="flex">
										<Box pr={1}>
											<ClickableBox
												onClick={() =>
													handleOnClickMarketPrices(
														marketPrices,
														'ToMarketTriggerValue'
													)
												}
											>
												<Typography variant="subtitle1">
													{marketPrices &&
													marketPrices > 0.0
														? marketPrices.toLocaleString(
															'de-DE',
															{
																minimumFractionDigits: 2,
																maximumFractionDigits:
																		getDecimalPlacesByTrigger(
																			bullet
																				.content
																				.CustomParameters
																				.Trigger
																		),
															}
														  )
														: '--'}
												</Typography>
											</ClickableBox>
										</Box>
									</Box>
								</Box>
								{/* Componentizar */}
							</Grid>
						</>
					)}
				</Grid>

				{execType !== 'B' && (
					<>
						<Grid container item xs={12} spacing={1}>
							<Grid item xs={3}>
								<SelectInput
									name="bookDepth"
									disabled={isFieldDisableBySymbol()}
									label="Profundidade do Book"
									iconTooltip={t(
										'bullets.general.book_depth'
									)}
									defaultValue={1}
									value={
										bullet.content.CustomParameters
											.BookDepth ?? 1
									}
									selectOptions={[
										{
											value: 1,
											label: '1º Nível',
										},
										{
											value: 2,
											label: '2º Nível',
										},
										{
											value: 3,
											label: '3º Nível',
										},
									]}
									onChange={e =>
										handleChange(
											'Custom',
											'BookDepth',
											+e.target.value
										)
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<SelectInput
									name="compensateExec"
									disabled={
										isFieldDisableBySymbol() ||
										bullet.content.ClOrdID
									}
									label="Compensação"
									iconTooltip={t(
										'bullets.spread.compensation'
									)}
									defaultValue={2}
									value={
										bullet.content.CustomParameters
											.CompensateExec ?? 2
									}
									selectOptions={[
										{
											value: 0,
											label: 'Nenhum',
										},
										{
											value: 1,
											label: 'Clip Seguinte',
										},
										{
											value: 2,
											label: 'Gradual',
										},
									]}
									onChange={e =>
										handleChange(
											'Custom',
											'CompensateExec',
											e.target.value
										)
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<DecimalInput
									name="ignoreOffer"
									disabled={isFieldDisableBySymbol()}
									label="Ignorar múltiplo de lote"
									iconTooltip={t(
										'bullets.spread.financ.ignore_offers_lt'
									)}
									minVal={0}
									maxVal={10}
									minDecScale={0} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="IgnoreOffersLT" // subtype
									stepSize={1} // incremento
									valueToStep={
										bullet.content.CustomParameters
											.IgnoreOffersLT
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.IgnoreOffersLT
									}
									onChange={e =>
										handleChange(
											'Custom',
											'IgnoreOffersLT',
											parseInt(e.value)
										)
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default FinancExecutionParams;
