import React from 'react';

import { Grid, Typography, Button, createSvgIcon } from '@mui/material';
import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

import { PRODUCT_TYPES } from 'utils/constants';

const ExportIcon = createSvgIcon(
	<path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
	'SaveAlt'
);

export const ViewExecutionsToolbar = (
	updatedAt,
	handleExportsExecutions,
	activeProduct,
	isLoading,
	exportType
) => {
	const isButtonsDisabled =
		isLoading || activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC;

	return (
		<GridToolbarContainer>
			<Grid xs={8}>
				<GridToolbarColumnsButton
					color="inherit"
					disabled={isButtonsDisabled}
				/>

				<GridToolbarFilterButton
					color="inherit"
					title="Exibir filtros da página atual"
					disabled={isButtonsDisabled}
				/>

				<GridToolbarDensitySelector
					color="inherit"
					disabled={isButtonsDisabled}
				/>

				<Button
					color="inherit"
					size="small"
					startIcon={<ExportIcon />}
					disabled={isButtonsDisabled}
					onClick={handleExportsExecutions}
				>
					{exportType === 'reports'
						? 'Exportar tudo'
						: 'Exportar pág. atual'}
				</Button>
			</Grid>

			{updatedAt !== '' && (
				<Grid
					xs={4}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Typography variant="body2">
						Atualizado em {updatedAt}
					</Typography>
				</Grid>
			)}
		</GridToolbarContainer>
	);
};
