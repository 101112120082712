import React, { useState } from 'react';
import { mask, unMask } from 'remask';

import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormContainer } from 'styles/global';

export const ForgotPasswordForm = ({ onSubmit, isLoading }) => {
	const [username, setUsername] = useState('');
	const [error, setError] = useState('');

	const handleChange = event => {
		const value = mask(unMask(event.target.value), [
			'999.999.999-99',
			'99.999.999/9999-99',
		]);
		setUsername(value);
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (!username) {
			setError('Campo obrigatório');
			return;
		}

		setError('');
		onSubmit({ username });
	};

	return (
		<FormContainer onSubmit={handleSubmit}>
			<TextField
				id="cpfTextBox"
				label="CPF/CNPJ"
				type="text"
				margin="normal"
				fullWidth
				variant="outlined"
				value={username}
				onChange={handleChange}
				error={!!error}
				helperText={error}
			/>

			<LoadingButton
				variant="contained"
				color="primary"
				fullWidth
				type="submit"
				id="buttonSubmit"
				loading={isLoading}
			>
				Enviar código de verificação
			</LoadingButton>
		</FormContainer>
	);
};
