import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Box, LinearProgress } from '@mui/material';

import { Container, PriceButton } from '../styles';
import { getDecimalPlacesByTrigger } from 'utils/strategies';

// Componente utilizado nas boletas de tipo: spread, long-short e volatilidade.
export const SpreadMarketPrice = ({
	title,
	lastVol,
	disabled,
	setGainCr,
	setLossCr,
	bulletType,
	setStopGain,
	setStopLoss,
	marketPrice,
	handleChange,
	stopSelected,
	isCvvol = false,
}) => {
	const { bullet } = useSelector((state) => state.bottomBullet);
	const isMarketPriceLoading = useSelector((state) => state.bottomBullet.loadingMarketPrice);

	const isGainLoss = parseInt(bullet.content.CustomParameters.ExecutionType, 10) === 1;
	const isDisabled = disabled || bullet.paramsView;

	function handleOnClickMarketPrices(value, customParam, refSetStopFunction, refSetCr) {
		if (lastVol && !bullet.paramsView) {
			if (value) {
				handleChange('Custom', customParam, value);
			}
		} else if (!bullet.paramsView) {
			handleChange('Custom', customParam, value);
			refSetStopFunction(Math.abs(parseFloat(value)));
			refSetCr(value >= 0);
		}
	}

	function getCrDbLabelValue(value) {
		if (bulletType === 'lgshort') {
			if ([4, 5].includes(parseInt(bullet.content.CustomParameters.Trigger, 10))) {
				return value ? (parseFloat(value) >= 0.0 ? ' ' : ' -') : '';
			} else if (parseInt(bullet.content.CustomParameters.Trigger, 10) === 11) {
				return value ? (parseFloat(value) >= 0.0 ? 'Cr ' : 'Db ') : '';
			} else {
				return '';
			}
		} else if (bulletType === 'spread') {
			return value ? (parseFloat(value) >= 0.0 ? 'Cr ' : 'Db ') : '';
		}
	}

	function priceSubmit(type) {
		if (lastVol) {
			handleOnClickMarketPrices(lastVol[type + 'Vol'], 'TriggerValue');
		} else if (isGainLoss) {
			handleOnClickMarketPrices(
				marketPrice[type],
				stopSelected === 'gain' ? 'TriggerValue' : 'ToMarketTriggerValue',
				stopSelected === 'gain' ? setStopGain : setStopLoss,
				stopSelected === 'gain' ? setGainCr : setLossCr,
			);
		} else {
			handleOnClickMarketPrices(marketPrice[type], 'TriggerValue', setStopGain, setGainCr);
		}
	}

	function getMarketPrice(type) {
		if (
			(marketPrice && (marketPrice[type] || marketPrice[type] === 0))
		) {
			return getCrDbLabelValue(marketPrice[type]) +
				new Intl.NumberFormat('de-DE', {
					minimumFractionDigits: getDecimalPlacesByTrigger(bullet.content.CustomParameters.Trigger),
					maximumFractionDigits: getDecimalPlacesByTrigger(bullet.content.CustomParameters.Trigger),
				}).format(Math.abs(marketPrice[type]));
		}

		return '--';
	}

	const bidPrice = lastVol ? lastVol.bidVol : getMarketPrice('bid');

	const midPrice = lastVol ? lastVol.midVol : getMarketPrice('mid');

	const askPrice = lastVol ? lastVol.askVol : getMarketPrice('ask');

	return (
		<Container xs>
			<Typography variant="subtitle2">
				{title}
			</Typography>

			<Box display="flex">
				<PriceButton
					name="bid-spread"
					onClick={() => priceSubmit('bid')}
					disabled={isDisabled || (!lastVol?.bidVol && (marketPrice?.bid === null || marketPrice?.bid === undefined))}
					marketPrice={marketPrice && marketPrice.bid}
					volatility={lastVol ? lastVol.bidVol : false}
					isCvvol={isCvvol}
				>
					<Typography variant="caption">BID</Typography>
					<Typography variant="body2">
						{isDisabled ? '--' : (
							isMarketPriceLoading ? <LinearProgress style={{ width: '50px', marginTop: '5px' }} /> : bidPrice
						)}
					</Typography>
				</PriceButton>
				<PriceButton
					name="mid-spread"
					onClick={() => priceSubmit('mid')}
					disabled={isDisabled || (!lastVol?.midVol && (marketPrice?.mid === null || marketPrice?.mid === undefined))}
					marketPrice={marketPrice && marketPrice.mid}
					volatility={lastVol ? lastVol.midVol : false}
					isCvvol={isCvvol}
				>
					<Typography variant="caption">MID</Typography>
					<Typography variant="body2">
						{isDisabled ? '--' : (
							isMarketPriceLoading ? <LinearProgress style={{ width: '50px', marginTop: '5px' }} /> : midPrice
						)}
					</Typography>
				</PriceButton>
				<PriceButton
					name="ask-spread"
					onClick={() => priceSubmit('ask')}
					disabled={isDisabled || (!lastVol?.askVol && (marketPrice?.ask === null || marketPrice?.ask === undefined))}
					marketPrice={marketPrice && marketPrice.ask}
					volatility={lastVol ? lastVol.askVol : false}
					isCvvol={isCvvol}
				>
					<Typography variant="caption">ASK</Typography>
					<Typography variant="body2">
						{isDisabled ? '--' : (
							isMarketPriceLoading ? <LinearProgress style={{ width: '50px', marginTop: '5px' }} /> : askPrice
						)}
					</Typography>
				</PriceButton>
			</Box>
		</Container>
	);
};
