export const symbolOpenBook = (suggestions, bullet) => {
	if (bullet.content.StrategyLegs[0].LegSymbol && bullet.content.ClOrdID) {
		return false;
	}

	if (
		!suggestions ||
		suggestions.length === 0 ||
		Object.keys(suggestions).length === 0
	) {
		return true;
	}

	if (!Array.isArray(suggestions) && suggestions.symbol === undefined) {
		return true;
	}

	return false;
};
