import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from 'store';
import i18n from '../../i18n';
import { showMessageErroWithoutStatusCode } from 'services/api';
import { setUserBlocked } from 'store/modules/auth/actions';

const { accountType } = store;

const headers = {
	post: {
		'Content-Type': 'application/json;charset=utf-8',
	},
	get: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

export const api = axios.create({
	baseURL:
		accountType === 'real'
			? process.env.REACT_APP_USR_DMA_API_URL
			: process.env.REACT_APP_USR_SIM_API_URL,
	timeout: 20 * 1000,
	headers: {
		...headers,
		'x-api-key': process.env.REACT_APP_API_KEY_FLEXSCAN,
	},
});

function getLoginErrorMessage(errorCode) {
	switch (errorCode) {
		case 3400:
			return i18n.t('login.passwordBlocked');
		case 3401:
			return i18n.t('login.trialExpired');
		case 3402:
			return i18n.t('login.invalidLogin');
		case 3403:
			return i18n.t('login.invalidLogin');
		case 3404:
			return i18n.t('login.userInactive');
		case 3405:
			return i18n.t('login.passwordExpired');
		default:
			return i18n.t('login.defaultCase');
	}
}

api.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;

		if (
			response &&
			response.status &&
			response.status >= 400 &&
			response.status <= 500
		) {
			if (response.status === 400 || response.status === 404) {
				if (response.data?.code === 3403) {
					toast.error(getLoginErrorMessage(response.data?.code));
				} else {
					toast.error(response.data.title);
				}
			} else if (response.status === 401) {
				toast.error(getLoginErrorMessage(response.data?.code));
			} else if (response.status === 403) {
				if (response.data.code === 3400) {
					store.dispatch(setUserBlocked(true));
				} else {
					toast.error(getLoginErrorMessage(response.data?.code));
				}
			} else if (response.status === 500) {
				toast.error('Problema ao processar requisição!');
			}
		} else {
			showMessageErroWithoutStatusCode(error);
		}

		return Promise.reject(error);
	}
);
