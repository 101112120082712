import React, { useRef, useState } from 'react';

import { Box, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ResetTokenValidator({ timeout, onSubmit, isLoading }) {
	const codeRef = useRef();

	const [error, setError] = useState('');

	function handleSubmit(event) {
		event.preventDefault();

		if (['', null, undefined].includes(codeRef.current.value)) {
			setError('Campo obrigatório');
			return;
		}

		onSubmit({ token: codeRef.current.value });
	}

	return (
		<Box>
			<Typography variant="subtitle1">
				Seu código vai expirar em {timeout}
			</Typography>

			<form onSubmit={handleSubmit}>
				<TextField
					label="Código"
					name="token"
					type="text"
					margin="normal"
					fullWidth
					variant="outlined"
					error={!!error}
					helperText={error}
					inputRef={codeRef}
				/>

				<LoadingButton
					variant="contained"
					color="primary"
					fullWidth
					type="submit"
					loading={isLoading}
					disabled={isLoading}
				>
					Validar código
				</LoadingButton>
			</form>
		</Box>
	);
}
