import React from 'react';

import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

export const RadarToolbar = () => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton color="inherit" />

			<GridToolbarDensitySelector color="inherit" />
		</GridToolbarContainer>
	);
};
