import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Box } from '@mui/material';

import { Container, PriceButton } from './styles';

// Componente utilizado nas boletas de tipo: single, gradiente e participativas.
export const MarketPrice = ({ setPriceLimit, disabled }) => {
	const { bullet } = useSelector(state => state.bottomBullet);

	function assetValue(value) {
		if (value) {
			const decimalPlaces =
				bullet.StrategyInfos[0].minPriceIncrement < 1
					? bullet.StrategyInfos[0].tickSizeDenominator
					: 0;

			value = +value.toFixed(decimalPlaces);

			value = new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(value);

			return value;
		}

		return null;
	}

	const bidPrice = assetValue(bullet.StrategyInfos[0].bidPx);

	const askPrice = assetValue(bullet.StrategyInfos[0].askPx);

	return (
		<Container xs>
			<Typography variant="subtitle2">Preço de mercado</Typography>

			<Box display="flex">
				<PriceButton
					data-cy="bid-single"
					onClick={() => !bullet.paramsView && setPriceLimit('bid')}
					disabled={bullet.paramsView || disabled || !bidPrice}
					marketPrice={bullet.StrategyInfos[0].bidPx}
				>
					<Typography variant="caption">BID</Typography>
					<Typography variant="body2">{bidPrice ?? '--'}</Typography>
				</PriceButton>
				<PriceButton
					data-cy="ask-single"
					onClick={() => !bullet.paramsView && setPriceLimit('ask')}
					marketPrice={bullet.StrategyInfos[0].askPx}
					disabled={bullet.paramsView || disabled || !askPrice}
				>
					<Typography variant="caption">ASK</Typography>
					<Typography variant="body2">{askPrice ?? '--'}</Typography>
				</PriceButton>
			</Box>
		</Container>
	);
};
