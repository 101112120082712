import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { HistoricalDataExportForm } from './HistoricalDataExportForm';

export const HistoricalData = () => {
	document.title = 'Dados históricos | FlexScan';

	return (
		<Grid
			container
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 0.5,
				p: '1.5rem 1rem 0.5rem',
			}}
		>
			<Typography fontWeight="bold" variant="h5">
				Dados históricos
			</Typography>

			<Typography variant="subtitle1">
				Com o serviço de dados históricos, você pode exportar candles de
				até um ano retroativo para até 10 instrumentos por solicitação.
			</Typography>

			<Divider sx={{ my: 1 }} />

			<HistoricalDataExportForm />
		</Grid>
	);
};
