import { store } from 'store';
import { apiAWS } from 'services/api';
import {
	handleSavedOrders,
	handleRecentOrders,
} from 'store/modules/bottomBullet/actions';
import { getFormattedDate } from 'utils/getParsedDate';

function formatOrders(orders) {
	return orders.map(order => {
		const id = `${order.StrategyCode}-${order.Name}-${order.date}`;

		const formattedDate =
			getFormattedDate(order.date, 'yyyy-MM-dd') ?? order.date;

		if (order.TimeInForce) {
			return {
				...order,
				id,
				date: formattedDate,
			};
		} else {
			return {
				...order,
				id,
				TimeInForce: '0',
				ExpireDate: formattedDate,
				date: formattedDate,
			};
		}
	});
}

export function fetchSavedOrders() {
	const { username } = store.getState().auth;

	apiAWS
		.get(
			`/userpreferences/${username}?service=saved_orders&version=${process.env.REACT_APP_BULLET_VERSION}`
		)
		.then(response => {
			const formattedOrders = formatOrders(response.data.strategies);
			store.dispatch(handleSavedOrders(formattedOrders));
		})
		.catch(error => console.error(error));
}

export function fetchRecentOrders() {
	const { username } = store.getState().auth;

	apiAWS
		.get(
			`/userpreferences/${username}?service=recent_orders&version=${process.env.REACT_APP_BULLET_VERSION}`
		)
		.then(response => {
			const formattedOrders = formatOrders(response.data.strategies);
			store.dispatch(handleRecentOrders(formattedOrders));
		})
		.catch(error => console.error(error));
}

export function updateRecentOrders(strategies) {
	if (!strategies || strategies.length === 0) {
		throw new Error('Error on updating recent orders.');
	}

	const { username, token } = store.getState().auth;

	const body = { strategies };

	const configs = {
		headers: { Authorization: `Bearer ${token}` },
		params: {
			service: 'recent_orders',
			version: process.env.REACT_APP_BULLET_VERSION,
		},
	};

	apiAWS
		.post(`/userpreferences/${username}`, body, configs)
		.then(() => store.dispatch(handleRecentOrders(strategies)))
		.catch(error => console.error(error));
}
