import { isValid, parse } from 'date-fns';

export function validateGapCustomParameters(customParameters) {
	const { ExitUnit, ExitOnEndTime, ExitOnEndDayAuction } = customParameters;

	if (!ExitUnit) {
		customParameters.ExitUnit = 'I';
	}

	if (ExitOnEndTime || ExitOnEndDayAuction) {
		if (ExitOnEndTime === 'Y') {
			customParameters.ExitOnEndTime = 'Y';
			customParameters.ExitOnEndDayAuction = 'N';
		} else if (ExitOnEndDayAuction === 'Y') {
			customParameters.ExitOnEndTime = 'N';
			customParameters.ExitOnEndDayAuction = 'Y';
		}
	}

	validateGapTimeFields(customParameters);
}

function validateGapTimeFields(customParameters) {
	const { PxInitTimeMonitoring, PxEndTimeMonitoring } = customParameters;

	if (PxInitTimeMonitoring) {
		if (PxEndTimeMonitoring) {
			if (PxEndTimeMonitoring === PxInitTimeMonitoring) {
				customParameters._relativePxInitTimeOption = 'specified';
			} else {
				customParameters._relativePxInitTimeOption = 'interval';
			}

			if (!isValid(PxEndTimeMonitoring)) {
				const parsedEndTime = parse(
					PxEndTimeMonitoring,
					'HH:mm:ss',
					new Date()
				);

				customParameters.PxEndTimeMonitoring = parsedEndTime;
			}
		}

		if (!isValid(PxInitTimeMonitoring)) {
			const parsedInitTime = parse(
				PxInitTimeMonitoring,
				'HH:mm:ss',
				new Date()
			);

			customParameters.PxInitTimeMonitoring = parsedInitTime;
		}
	} else {
		customParameters._relativePxInitTimeOption = 'immediate';
	}
}

export function validateGapApparentQuantity(
	strategyLegs = [],
	customParameters
) {
	if (strategyLegs[0]?.LegMinClipSize) {
		customParameters._useApparentQuantity = true;
	} else {
		customParameters._useApparentQuantity = false;
	}
}
