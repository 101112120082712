import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { format, parse } from 'date-fns';

import { SupportAgent } from '@mui/icons-material';
import { Box, colors, Skeleton, Typography } from '@mui/material';

const USER_MESSAGE_STYLE = {
	background: colors.blue[800],
	borderTopRightRadius: 0,
	alignSelf: 'flex-end',
};

const BOT_MESSAGE_STYLE = {
	background: colors.grey[700],
	borderTopLeftRadius: 0,
	alignSelf: 'flex-start',
};

function formatMessageHour(datetime) {
	const parsedDate = parse(datetime, 'yyyy-MM-dd HH:mm:ss.SSS', new Date());

	return format(parsedDate, 'HH:mm');
}

const ErrorMessage = ({ message }) => {
	return (
		<Typography
			key={message.timestamp}
			sx={{
				p: 1,
				borderRadius: 2,
				width: 'fit-content',
				...BOT_MESSAGE_STYLE,
				bgcolor: `${colors.red[900]}40`,
				color: colors.red[300],
			}}
		>
			Não estou conseguindo me conectar no momento. Tente novamente mais
			tarde.
			<br />
			<span
				style={{
					fontSize: '9px',
					textAlign: 'right',
					width: '100%',
					display: 'inline-block',
					color: colors.red[100],
				}}
			>
				{formatMessageHour(message.timestamp)}
			</span>
		</Typography>
	);
};

export const ChatLog = () => {
	const { messages, botIsTyping } = useSelector(state => state.chatbot);

	const messagesEndRef = useRef(null);
	const chatContainerRef = useRef(null);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [messages]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					bgcolor: colors.grey[700],
					p: '1rem 0 0 1rem',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						bgcolor: colors.grey[800],
						borderRadius: '8px 8px 0 0',
						p: 1,
						gap: 1,
						alignItems: 'center',
					}}
				>
					<SupportAgent sx={{ fontSize: 32 }} />
					<Typography variant="h5">Assistente Virtual</Typography>
				</Box>

				<Box
					ref={chatContainerRef}
					sx={{
						display: 'flex',
						bgcolor: colors.grey[900],
						borderRadius: '0 0 8px 8px',
						p: 2,
						flexDirection: 'column',
						gap: 1,
						flexGrow: 1,
						height: '33rem',
						overflowY: 'auto',
						'&::-webkit-scrollbar': { width: '8px' },
						'&::-webkit-scrollbar-thumb': {
							backgroundColor:
								'rgba(240, 240, 240, 0.8) !important',
							borderRadius: '4px',
						},
						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor:
								'rgba(240, 240, 240, 0.9) !important',
						},
					}}
				>
					{messages.map(message =>
						message.isError ? (
							<ErrorMessage
								key={message.timestamp}
								message={message}
							/>
						) : (
							<Typography
								key={message.timestamp}
								sx={{
									p: 1,
									borderRadius: 2,
									width: 'fit-content',
									...(message.from === 'user'
										? USER_MESSAGE_STYLE
										: BOT_MESSAGE_STYLE),
								}}
							>
								{message.content}
								<br />
								<span
									style={{
										fontSize: '9px',
										textAlign: 'right',
										width: '100%',
										display: 'inline-block',
									}}
								>
									{formatMessageHour(message.timestamp)}
								</span>
							</Typography>
						)
					)}

					{botIsTyping && (
						<Box
							sx={{
								display: 'flex',
								gap: 1,
								background: 'rgba(255,255,255, 0.2)',
								width: 'fit-content',
								p: 1,
								borderRadius: '0 8px 8px',
							}}
						>
							<Skeleton
								sx={{ bgcolor: colors.grey[400] }}
								variant="circular"
								width={8}
								height={8}
							/>

							<Skeleton
								sx={{ bgcolor: colors.grey[400] }}
								variant="circular"
								width={8}
								height={8}
							/>

							<Skeleton
								sx={{ bgcolor: colors.grey[400] }}
								variant="circular"
								width={8}
								height={8}
							/>
						</Box>
					)}

					{/* Elemento invisível para rolar automaticamente */}
					<div ref={messagesEndRef} />
				</Box>
			</Box>
		</>
	);
};
