import { ChatBotActionTypes } from './actionTypes';

export const resetChatBotState = () => ({
	type: ChatBotActionTypes.RESET_STATE,
});

export const setChatBotIsOpen = isOpen => ({
	type: ChatBotActionTypes.SET_IS_CHAT_OPEN,
	payload: { isOpen },
});

export const addChatBotMessage = message => ({
	type: ChatBotActionTypes.ADD_BOT_MESSAGE,
	payload: { message },
});

export const addUserMessage = message => ({
	type: ChatBotActionTypes.ADD_USER_MESSAGE,
	payload: { message },
});

export const setBotIsTyping = botIsTyping => ({
	type: ChatBotActionTypes.SET_BOT_IS_TYPING,
	payload: { botIsTyping },
});

export const addBotErrorMessage = () => ({
	type: ChatBotActionTypes.ADD_BOT_ERROR_MESSAGE,
});
