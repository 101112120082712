import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Box,
	Grid,
	Typography,
} from '@mui/material';

import Stop from './Stop';
import SelectInput from '../SelectInput';
import DecimalInput from '../DecimalInput';
import MaterialCheckBox from '../MaterialCheckBox';
import MaterialTriggerSwitch from '../MaterialTriggerSwitch';
import IconWithTooltip from 'components/MaterialComponents/IconTooltip';

import {
	additionalUpdate,
	replaceCustomParameters,
	strategyUpdate,
} from 'store/modules/bottomBullet/actions';
import {
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';

import paperValidator from 'utils/paperValidation';
import { getStrategyTemplate } from 'utils/templates';

const Exit = ({ handleChange, subUid, stepFunction }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { bullet } = useSelector(state => state.bottomBullet);
	const permittedBullets = useSelector(
		state => state.products.permittedBullets
	);
	const accounts = useSelector(state => state.auth.accounts);
	const defaultAccount = useSelector(
		state =>
			state.configs.globalSettings?.userSettings?.bullet.defaultAccount
	);

	const [triggerCurrency, setTriggerCurrency] = useState('%');
	const triggerStep = 0.01;

	const { TriggerEntryType, ReversionType } = bullet.content.CustomParameters;

	function changeTrigger() {
		const triggerPercent = TriggerEntryType === 2;

		setTriggerCurrency(triggerPercent ? '%' : 'Pts');

		dispatch(
			updateCustomParameters({
				TriggerEntryType: triggerPercent ? 1 : 2,
				StopLoss: 0.0,
				StopGain: 0.0,
				StopGainL1: 0.0,
				StopGainL2: 0.0,
				StopGainL3: 0.0,
			})
		);
	}

	function changeType(selected) {
		const defaultTemplate = getStrategyTemplate(
			bullet.content.CustomParameters.stop ? 'fxorder' : 'sorder'
		);
		// setTriggerSelect(selected);
		const price = bullet.content.CustomParameters.PriceLimit;
		const qty = bullet.content.CustomParameters.OrderQuantity;
		const ordType = bullet.content.CustomParameters.CustomOrdType;
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));
		dispatch(
			updateCustomParameters({
				PriceLimit: price,
				StopType: selected,
				OrderQuantity: qty,
				CustomOrdType: ordType,
			})
		);
	}

	const setReversionType = reversionType => {
		dispatch(updateCustomParameters({ ReversionType: reversionType }));
	};

	const handleStop = () => {
		const price = bullet.content.CustomParameters.PriceLimit;
		const useAuction = bullet.content.CustomParameters.UseAuctionOpenPrice;
		const qty = bullet.content.CustomParameters.OrderQuantity;
		if (bullet.uid === 'fxorder') {
			dispatch(additionalUpdate('uid', 'sorder'));
			const hash = { ...bullet.content };
			hash.StrategyCode = 'sorder';
			dispatch(strategyUpdate(hash));
		}
		const defaultTemplate = getStrategyTemplate(
			bullet.content.CustomParameters.stop ? 'sorder' : 'fxorder'
		);
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));
		dispatch(updateCustomParameters({ PriceLimit: price }));
		if (subUid === 1) {
			dispatch(updateCustomParameters({ CustomOrdType: 4 }));
		}
		if (!bullet.content.CustomParameters.stop) {
			dispatch(
				updateCustomParameters({
					OrderQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				})
			);
		} else {
			dispatch(updateStrategyLeg({ LegQuantity: qty }, 0));
		}
		if (subUid === 5) {
			dispatch(
				updateCustomParameters({
					UseAuctionOpenPrice: useAuction,
					OrderQuantity: qty,
				})
			);
		}
	};

	function getLegQuantity(value) {
		if (value) {
			value = +value.toFixed(bullet.StrategyInfos[0].tickSizeDenominator);
		}

		return value;
	}

	const legQuantity = getLegQuantity(
		bullet.content.StrategyLegs[0].LegQuantity ||
			bullet.content.CustomParameters.OrderQuantity
	);
	const translatedLegSide =
		bullet.content.StrategyLegs[0].LegSide === '2' ? 'vender' : 'comprar';
	const legSymbol = bullet.content.StrategyLegs[0].LegSymbol;

	const singleExitMessage = `Objetivo: Tentar ${translatedLegSide} ${legQuantity} de ${legSymbol}`;

	const validSubIds = [1, 2, 3, 4, 5];

	function getCurrentAccount(bulletAccount, authAccounts, defaultAccount) {
		if (bulletAccount) {
			return bulletAccount;
		}

		if (authAccounts.length > 0) {
			if (![null, undefined, '', 'Nenhuma'].includes(defaultAccount)) {
				return defaultAccount;
			}

			return authAccounts[0].code;
		}

		return;
	}

	const currentAccount = getCurrentAccount(
		bullet.account,
		accounts,
		defaultAccount
	);

	function hasExitOnEndDayAuctionToBeDisabled(
		currentAccount,
		closingCallStart
	) {
		if (paperValidator(bullet.StrategyInfos[0], bullet)) {
			return true;
		}

		if (!bullet.content.CustomParameters.stop) {
			return true;
		}

		if (['', null, undefined].includes(closingCallStart)) {
			return true;
		}

		if (currentAccount) {
			if (String(currentAccount).startsWith('D-')) {
				return true;
			}

			return false;
		}

		return true;
	}

	const isExitOnEndDayAuctionDisabled = hasExitOnEndDayAuctionToBeDisabled(
		currentAccount,
		bullet.StrategyInfos[0].closingCallStart
	);

	if (isExitOnEndDayAuctionDisabled && ReversionType === 2) {
		setReversionType(1);
	}

	return (
		<>
			{validSubIds.includes(subUid) && (
				<Grid item xs={6} container direction="column" gap={1}>
					<Typography
						variant="subtitle1"
						color={subUid === 4 ? 'primary' : 'inherit'}
						style={{
							fontWeight: subUid === 4 ? 'bold' : 'normal',
						}}
					>
						{subUid === 4 ? singleExitMessage : 'Saída'}
					</Typography>

					<Grid item container spacing={1}>
						{subUid !== 4 && (
							<Grid item xs>
								<Box
									display="flex"
									alignItems="center"
									ml={-1.2}
									mt={-0.2}
								>
									<MaterialCheckBox
										name="stopBox"
										label="Habilitar Stop"
										onChange={handleStop}
										checked={
											bullet.content.CustomParameters
												.stop ?? false
										}
										disabled={
											!permittedBullets.permittedStrategyCodes?.includes(
												'fxorder'
											) ||
											paperValidator(
												bullet.StrategyInfos[0],
												bullet,
												'marketQty'
											) ||
											bullet.content.CustomParameters
												.CustomOrdType === 3 ||
											bullet.content.ClOrdID
										}
									/>

									<Box ml={0.5} mt={0.5}>
										<IconWithTooltip
											sizeFont="12px"
											title={t(
												'bullets.single.exit_single'
											)}
										/>
									</Box>
								</Box>
							</Grid>
						)}

						<Grid item xs style={{ marginLeft: '-35px' }}>
							<SelectInput
								name="triggerSelect"
								iconTooltip={t('bullets.single.trigger_type')}
								disabled={
									paperValidator(
										bullet.StrategyInfos[0],
										bullet
									) ||
									!bullet.content.CustomParameters.stop ||
									bullet.content.ClOrdID
								}
								label="Tipo de gatilho"
								onChange={e =>
									changeType(parseInt(e.target.value))
								}
								defaultValue={1}
								value={
									bullet.content.CustomParameters.StopType ??
									1
								}
								selectOptions={[
									{
										value: 1,
										label: 'Gain/Loss',
									},
									{
										value: 3,
										label: 'Parcial',
									},
									{
										value: 2,
										label: 'Móvel',
									},
								]}
							/>
						</Grid>

						<Grid item xs style={{ marginLeft: '2px' }}>
							<Box justifyContent="center" display="flex">
								<MaterialTriggerSwitch
									namePts="ptsButton"
									namePerc="percButton"
									disabled={
										paperValidator(
											bullet.StrategyInfos[0],
											bullet
										) ||
										!bullet.content.CustomParameters.stop ||
										bullet.content.ClOrdID
									}
									isEdit={bullet.content.ClOrdID}
									triggerType={
										bullet.content.CustomParameters
											.TriggerEntryType ?? 2
									}
									changeTrigger={changeTrigger}
									isSingle
								/>
							</Box>
						</Grid>

						{bullet.content.CustomParameters.StopType == 1 && (
							<Grid item xs>
								<DecimalInput
									name="gainField"
									iconTooltip={t('bullets.general.stop_gain')}
									disabled={
										paperValidator(
											bullet.StrategyInfos[0],
											bullet
										) ||
										!bullet.content.CustomParameters.stop
									}
									label="Stop Gain"
									minVal={0}
									maxVal={999999}
									minDecScale={2} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="StopGain" // subtype
									stepSize={
										bullet.content.CustomParameters
											.TriggerEntryType === 1
											? bullet.StrategyInfos[0]
												.minPriceIncrement ||
											  bullet.StrategyInfos[0]
											  	.Cst_MinPriceIncrement
											: triggerStep
									} // incremento
									valueToStep={
										isNaN(
											bullet.content.CustomParameters
												.StopGain
										) ||
										bullet.content.CustomParameters
											.StopGain === undefined
											? 0
											: bullet.content.CustomParameters
												.StopGain
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.StopGain ?? 0
									}
									onChange={e =>
										handleChange(
											'Custom',
											'StopGain',
											parseFloat(e.value),
											0
										)
									}
								/>
							</Grid>
						)}

						<Grid item xs style={{ marginLeft: '2px' }}>
							<DecimalInput
								name="lossField"
								iconTooltip={t('bullets.general.stop_loss')}
								disabled={
									paperValidator(
										bullet.StrategyInfos[0],
										bullet
									) || !bullet.content.CustomParameters.stop
								}
								label="Stop Loss"
								minVal={0}
								maxVal={999999}
								minDecScale={2} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="StopLoss" // subtype
								stepSize={
									bullet.content.CustomParameters
										.TriggerEntryType === 1
										? bullet.StrategyInfos[0]
											.minPriceIncrement ||
										  bullet.StrategyInfos[0]
										  	.Cst_MinPriceIncrement
										: triggerStep
								} // incremento
								valueToStep={
									isNaN(
										bullet.content.CustomParameters.StopLoss
									) ||
									bullet.content.CustomParameters.StopLoss ===
										undefined
										? 0
										: bullet.content.CustomParameters
											.StopLoss
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters.StopLoss ??
									0
								}
								onChange={e =>
									handleChange(
										'Custom',
										'StopLoss',
										parseFloat(e.value),
										0
									)
								}
							/>
						</Grid>

						<Grid
							item
							xs
							style={{
								marginTop: '-22px',
								marginLeft: '2px',
							}}
						>
							<FormControl
								disabled={
									paperValidator(
										bullet.StrategyInfos[0],
										bullet
									) || !bullet.content.CustomParameters.stop
								}
							>
								<FormLabel
									iconTooltip={t('bullets.general.stop_loss')}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Zeragem:
								</FormLabel>
								<RadioGroup
									row
									value={ReversionType}
									sx={{ flexWrap: 'nowrap' }}
									defaultValue={1}
								>
									<FormControlLabel
										value={1}
										control={<Radio />}
										label="Hora fim"
										checked={ReversionType === 1}
										onChange={() => setReversionType(1)}
									/>

									<FormControlLabel
										value={2}
										control={<Radio />}
										label="Leilão fech."
										checked={ReversionType === 2}
										disabled={isExitOnEndDayAuctionDisabled}
										onChange={() => setReversionType(2)}
									/>

									<FormControlLabel
										value={3}
										control={<Radio />}
										label="Não zerar"
										checked={ReversionType === 3}
										onChange={() => setReversionType(3)}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>

						<Stop
							enableStop={!bullet.content.CustomParameters.stop}
							triggerStep={triggerStep}
							handleChange={handleChange}
							stepFunction={stepFunction}
							triggerCurrency={triggerCurrency}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Exit;
