import { RadarActionsTypes } from './actionsTypes';

export const setRows = rows => ({
	type: RadarActionsTypes.SET_ROWS,
	payload: { rows },
});

export const setStateValues = (field, value) => ({
	type: RadarActionsTypes.UPDATE_STATE_VALUES,
	payload: { field, value },
});

export const updateFormValues = (field, value) => ({
	type: RadarActionsTypes.UPDATE_FORM_VALUES,
	payload: { field, value },
});
