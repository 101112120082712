import {
	DateRange,
	EventNote,
	ImportExport,
	Today,
	TrackChanges,
} from '@mui/icons-material';

import { EXTERNAL_PRODUCTS } from './constants';

const commonTradeCertoModules = [
	{
		path: '/external-product/back-tests/b3-daily',
		value: 'external-product_b3-daily',
		label: 'B3 - Timeframe Diário',
		icon: Today,
	},
	{
		path: '/external-product/back-tests/b3-intraday',
		value: 'external-product_b3-intraday',
		label: 'B3 - Timeframe Intraday',
		icon: DateRange,
	},
	{
		path: '/external-product/back-tests/bmf-intraday',
		value: 'external-product_bmf-intraday',
		label: 'BMF - Timeframe Intraday',
		icon: EventNote,
	},
	{
		path: '/historical-data',
		value: 'historical_data',
		label: 'Dados históricos',
		icon: ImportExport,
	},
];

const commonRadarModules = [
	{
		path: '/radar-dividendos',
		label: 'Radar dividendos',
		value: 'radar-dividendos',
		icon: TrackChanges,
	},
];

export const allExternalProducts = {
	[EXTERNAL_PRODUCTS.TRADE_CERTO]: commonTradeCertoModules,
	[EXTERNAL_PRODUCTS.TC_STANDARD]: commonTradeCertoModules,

	[EXTERNAL_PRODUCTS.RADAR_DIV]: commonRadarModules,
	[EXTERNAL_PRODUCTS.RADAR_STANDARD]: commonRadarModules,
	[EXTERNAL_PRODUCTS.RADAR_PRO]: commonRadarModules,
};
