import React from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldPortBalance } from './TextFieldPortBalance';
import DecimalInput from '../DecimalInput';
import MaterialCheckBox from '../MaterialCheckBox';
import IconWithTooltip from '../IconTooltip';

export const PortBalanceLeg = (props) => {
	const { bullet, stepFunction, handleChange } = props;
	const { t } = useTranslation();

	return (
		<Grid item xs={12}>
			<Grid container spacing={1} direction="row" mb='7px'>
				<Grid item xs={12}>
					<TextFieldPortBalance
						bullet={bullet}
						symbolList={bullet.content.CustomParameters.SymbolList}
						initialList={bullet.content.CustomParameters.InitialPosList}
						desiredList={bullet.content.CustomParameters.DesiredPosPercList}
						handleChange={handleChange}
					/>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center" width="100%">
							<Box width="100%" mr={4}>
								<DecimalInput
									name="TriggerValue"
									label="Financeiro"
									iconTooltip={t('bullets.portbalance.trigger_value')}
									minVal={0.0}
									maxVal={999999.99}
									minDecScale={2} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="TriggerValue" // subtype
									stepSize={0.01} // incremento
									valueToStep={bullet.content.CustomParameters.TriggerValue} // valor a ser modificado
									value={bullet.content.CustomParameters.TriggerValue}
									onChange={(e) => handleChange('Custom', 'TriggerValue', parseFloat(e.value, 10), 0)}
									disabled={bullet.paramsView}
									fixedTooltip="top-start"
								/>
							</Box>
							<Box width="100%" mr={4}>
								<DecimalInput
									name="IgnoreOffersLT"
									label="Ignorar múltiplo de lote"
									iconTooltip={t('bullets.portbalance.ignore_offers')}
									minVal={0}
									maxVal={99999999}
									minDecScale={0} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="IgnoreOffersLT" // subtype
									stepSize={1} // incremento
									valueToStep={bullet.content.CustomParameters.IgnoreOffersLT} // valor a ser modificado
									value={bullet.content.CustomParameters.IgnoreOffersLT}
									onChange={(e) => handleChange('Custom', 'IgnoreOffersLT', parseFloat(e.value, 10), 0)}
									disabled={bullet.paramsView}
								/>
							</Box>
							<Box display="flex" alignItems="center" width="90%">
								<MaterialCheckBox
									name="UseFractionary"
									label="Usar fracionário"
									onChange={(e) =>
										handleChange(
											'Custom',
											'UseFractionary',
											bullet.content.CustomParameters.UseFractionary === 'Y' ? 'N' : 'Y',
											e.target.value,
										)
									}
									checked={bullet.content.CustomParameters.UseFractionary === 'Y'}
									disabled={bullet.paramsView}
									smallLabel
								/>
								<Box ml={1}>
									<IconWithTooltip sizeFont="12px" title={t('bullets.portbalance.use_fractionary')} />
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
