import React, { useState, useRef } from 'react';

import { ClickAwayListener, Grid, Tooltip } from '@mui/material';

import { Help } from '@mui/icons-material';

const IconWithTooltip = ({ title, sizeFont, fixedTooltip }) => {
	const tooltipTimerRef = useRef(null);

	const [open, setOpen] = useState(false);

	function handleTooltipClose() {
		setOpen(false);
	}

	function handleTooltipOpen() {
		setOpen(true);
	}
	

	return (
		<Grid item>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<Tooltip
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableTouchListener
					title={
						<div
							style={{
								fontSize: sizeFont ? sizeFont : '12px',
								minWidth: '200px',
								fontWeight: 'normal',
								textShadow: 'none',
								lineHeight: '1.5em',
							}}
						>
							{title}
						</div>
					}
					placement={fixedTooltip}
				>
					<Help
						onMouseOver={() => {
							tooltipTimerRef.current = setTimeout(() => {
								handleTooltipOpen();
							}, 1000);
						}}
						onMouseOut={() => {
							if (tooltipTimerRef.current) {
								handleTooltipClose();
								clearTimeout(tooltipTimerRef.current);
							}
						}}
						style={{ width: '12px' }}
					/>
				</Tooltip>
			</ClickAwayListener>
				
		</Grid>
		
	);
};

export default IconWithTooltip;
