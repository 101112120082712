import { RadarActionsTypes } from './actionsTypes';

export const radarInitialState = {
	historyData: [],
	layersData: null,
	searchForm: {
		symbol1: '',
		symbol2: '',
		symbol1_prop: 1,
		symbol2_prop: 1,
		layer: 0.3,
		init_date: new Date(),
		end_date: new Date(),
	},
	searchApplied: {
		symbol1: '--',
		symbol2: '--',
		symbol1_prop: '',
		symbol2_prop: '',
		layer: 0,
		init_date: new Date(),
		end_date: new Date(),
		percentSymbol1: 0,
		percentSymbol2: 0,
	},
};

export function radarModule(state = radarInitialState, { type, payload }) {
	switch (type) {
		case RadarActionsTypes.UPDATE_STATE_VALUES:
			return {
				...state,
				[payload.field]: payload.value,
			};

		case RadarActionsTypes.SET_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};

		case RadarActionsTypes.UPDATE_FORM_VALUES:
			return {
				...state,
				searchForm: {
					...state.searchForm,
					[payload.field]: payload.value,
				},
			};
		default:
			return state;
	}
}
