import { format, subDays, subYears } from 'date-fns';

import { historicalDataActionTypes } from './actionTypes';

const FORMATTED_ONE_YEAR_AGO = format(
	subYears(new Date(), 1),
	'yyyy-MM-dd',
	new Date()
);
const FORMATTED_ONE_DAY_AGO = format(
	subDays(new Date(), 1),
	'yyyy-MM-dd',
	new Date()
);

const historicalDataInitialState = {
	exportForm: {
		symbols: [],
		timeframe: 'D',
		format: 1,
		dates: [FORMATTED_ONE_YEAR_AGO, FORMATTED_ONE_DAY_AGO],
		startDate: FORMATTED_ONE_YEAR_AGO,
		endDate: FORMATTED_ONE_DAY_AGO,
	},
};

export function historicalDataReducer(
	state = historicalDataInitialState,
	{ type, payload }
) {
	switch (type) {
		case historicalDataActionTypes.RESET_STATE:
			return historicalDataInitialState;
		case historicalDataActionTypes.SET_HISTORICAL_DATA:
			return { ...state, ...payload.historicalData };
		case historicalDataActionTypes.UPDATE_EXPORT_FORM: {
			return {
				...state,
				exportForm: {
					...state.exportForm,
					...payload.newValues,
				},
			};
		}
		default:
			return state;
	}
}
