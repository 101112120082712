import axios from 'axios';
import { store } from 'store';
import { toast } from 'react-toastify';

import { notAuthError } from 'store/modules/auth/actions';
import { additionalUpdate } from 'store/modules/bottomBullet/actions';

const { accountType } = store;

const headers = {
	post: {
		'Content-Type': 'application/json;charset=utf-8',
	},
	get: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};
export const apiExec = axios.create({
	baseURL:
		accountType === 'real'
			? process.env.REACT_APP_EXE_DMA_API_URL
			: process.env.REACT_APP_EXE_SIM_API_URL,
	timeout: 30 * 1000,
	headers: {
		...headers,
		'x-api-key': process.env.REACT_APP_API_KEY_FLEXSCAN,
	},
});

apiExec.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;

		if (response && response.status >= 400 && response.status <= 504) {
			if ([401, 403].includes(response.status)) {
				switch (response.data.code) {
					case 3408 /* ERROR_CODE_TOKEN_EXPIRED */:
						store.dispatch(notAuthError());
						break;
					case 3409 /* ERROR_CODE_TOKEN_INVALID */:
						store.dispatch(notAuthError());
						break;
					case 3406 /* ERROR_CODE_CONCURRENTLY_RUNNING_STRATEGY_LIMIT_EXCEEDED */:
						toast.error(
							'Quantidade máxima de estratégias executando simultaneamente alcançado'
						);
						store.dispatch(
							additionalUpdate('disabledSubmit', false)
						);
						break;
					case 3407 /* ERROR_CODE_STRATEGY_CODE_NOT_AUTHORIZED */:
						toast.error(
							'A Criação dessa estratégia não está disponível.'
						);
						store.dispatch(
							additionalUpdate('disabledSubmit', false)
						);
						break;
					case 3412 /* ERROR_CODE_ALGOS_USER_CACHE_NOT_FOUND */:
						toast.error(
							'Ocorreu um problema ao processar a requisição! Favor fazer login novamente para continuar.'
						);
						store.dispatch(notAuthError());
						break;
					case 3413 /* ERROR_CODE_STRATEGY_CREATION_BLOCKED_BY_BLACKLIST */:
						toast.error(
							'Criação de estratégias temporariamente indisponível! Tente novamente em instantes.'
						);
						break;
					case 3423 /* ERROR_CODE_INVALID_SIGNATURE */:
						toast.error('Assinatura inválida.');
						break;
					case 3425:
						toast.error(
							'Contrato bloqueado para negociação. Em caso de dúvidas, entre em contato com a mesa de operações.'
						);
						break;
					case 4002:
						break;
					default:
						toast.error(
							'Problema ao enviar solicitação. Entre em contato com a equipe de suporte'
						);
				}
			} else if ([400].includes(response.status)) {
				switch (response.data.code) {
					case 3403 /* ERROR_CODE_MIGRATION_USER_NOT_FOUND */:
						break;
					case 3414 /* ERROR_CODE_ACCOUNT_NOT_EXISTS */:
						toast.error('Conta não cadastrada.');
						break;
					case 3415 /* ERROR_CODE_INVALID_ACCOUNT_SPONSOR */:
					case 3418 /* ERROR_CODE_INVALID_ACCOUNT */:
						toast.error('Conta informada inválida.');
						break;
					case 4005 /* ERROR_CODE_MISSING_DOC_NUMBER */:
						toast.error(
							'Documento associado ao cblc inválido! Favor entrar em contato com o suporte!'
						);
						break;
					default:
						toast.error(response.data.title);
				}
			} else if (response.data.title !== 'Invalid strategy id') {
				toast.error(response.data.title);
				store.dispatch(additionalUpdate('disabledSubmit', false));
			} else if (response.status === 503) {
				if (response.data.code === 3424) {
					toast.error(
						'A criação de estratégias deve ser feita entre: 07:00 e 21:00.'
					);
				}
			}
		}

		return Promise.reject(error);
	}
);
