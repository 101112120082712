import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import SelectInput from '../SelectInput';
import {
	ProjectedCoustContainer,
	BottomContainer,
} from '../MaterialStyles/style';
import DecimalInput from '../DecimalInput';
import {
	getDecimalPlacesByTrigger,
	getStepByTrigger,
	showProjectedCoust,
} from '../../../utils/strategies';
import { useTranslation } from 'react-i18next';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import ProjectedCoust from '../ProjectedCoust';
import { SpreadMarketPrice } from '../../MarketPrice/SpreadMarketPrice';

const ExecutionParams = ({
	handleChange,
	stepFunction,
	marketPrice,
	execType,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const [haveInterval, setHaveInterval] = useState(false);
	const [gainCr, setGainCr] = useState(true);
	const [lossCr, setLossCr] = useState(true);
	const [stopGain, setStopGain] = useState(null);
	const [stopLoss, setStopLoss] = useState(null);
	const [stopSelected, setStopSelected] = useState('gain');
	const { t } = useTranslation();

	const isSpread =
		parseInt(bullet.content.CustomParameters.Trigger, 10) === 1;

	if (marketPrice.reset && !bullet.clone) {
		if (stopGain != null && stopGain !== 0) {
			setStopGain(0);
			setGainCr(true);
		}

		if (stopLoss != null && stopLoss !== 0) {
			setStopLoss(0);
			setLossCr(true);
		}

		if (haveInterval !== false) {
			setHaveInterval(false);
		}
	}

	const handleChangeTriggerInterceptor = (type, field, value) => {
		handleChange(type, field, value);
		handleChange('Custom', 'TriggerValue', null);
		if (
			bullet.content.CustomParameters.ToMarketTriggerValueEnabled === 'Y'
		) {
			handleChange('Custom', 'ToMarketTriggerValue', 0.0);
		}
	};

	if (bullet.editing || bullet.clone) {
		const newGain = bullet.content.CustomParameters.TriggerValue;
		if (stopGain !== Math.abs(newGain)) {
			setGainCr(newGain >= 0);

			setStopGain(Math.abs(newGain));
		}

		const lossEnabled =
			bullet.content.CustomParameters.ToMarketTriggerValueEnabled === 'Y';

		if (lossEnabled) {
			const newLoss =
				bullet.content.CustomParameters.ToMarketTriggerValue ?? 0;
			if (stopLoss !== Math.abs(newLoss)) {
				setLossCr(newLoss >= 0);

				setStopLoss(Math.abs(newLoss));
			}
		}

		const useBandRef = bullet.content.CustomParameters.BandPriceRef === 'Y';

		if (haveInterval !== useBandRef) {
			setHaveInterval(useBandRef);
		}

		// workaround limpeza de boleta
		if (bullet.content.StrategyLegs[0].LegSymbol === '') {
			marketPrice = {};
		}
	}

	const calcProjectedCoust = () => {
		const sinal = gainCr ? 1 : -1;
		if (parseInt(bullet.content.CustomParameters.Trigger, 10) === 1) {
			const qttyToCalc = Math.abs(
				Math.min(
					...bullet.content.StrategyLegs.map(leg => {
						return leg.LegQuantity;
					})
				)
			);

			return stopGain * qttyToCalc * sinal;
		}
		return stopGain * sinal;
	};

	const getStopValue = (value, isCr) => {
		const tempValue = Math.abs(parseFloat(value));
		const valueWithoutSignal = isNaN(tempValue) ? null : tempValue;

		if (valueWithoutSignal !== null) {
			return isCr ? valueWithoutSignal : -1 * valueWithoutSignal;
		}

		return null;
	};

	const handleStopChanges = (
		value,
		customParam,
		isCr,
		refSetStopFunction
	) => {
		const tempValue = getStopValue(value, isCr);
		handleChange('Custom', customParam, tempValue);
		refSetStopFunction(Math.abs(tempValue));
	};

	const callbackHandleCreditDebit = isCredit => {
		setGainCr(isCredit);
		const tempGainValue = getStopValue(stopGain, isCredit);
		handleChange('Custom', 'TriggerValue', tempGainValue);
	};

	const callbackHandleCreditDebitLoss = isCredit => {
		setLossCr(isCredit);
		const tempLossValue = getStopValue(stopLoss, isCredit);
		handleChange('Custom', 'ToMarketTriggerValue', tempLossValue);
	};

	const isDisabledBySymbol = isDisableBySymbol(bullet, 1);

	return (
		<Grid item xs={execType === 'B' ? 8 : 6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={6} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">
						Parâmetros de execução
					</Typography>
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={3}>
						<SelectInput
							name="executionType"
							disabled={
								isDisabledBySymbol || bullet.content.ClOrdID
							}
							label="Modo"
							iconTooltip={t('bullets.spread.execution_type')}
							defaultValue={0}
							onChange={e => {
								handleChange(
									'Custom',
									'ExecutionType',
									+e.target.value
								);
								handleChange(
									'Custom',
									'ToMarketTriggerValueEnabled',
									+e.target.value === 0 ? 'N' : 'Y'
								);
								handleChange('Custom', 'TriggerValue', null);
								handleChange(
									'Custom',
									'ToMarketTriggerValue',
									0.0
								);
								setStopSelected('gain');
							}}
							value={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) ?? 0
							}
							selectOptions={[
								{
									value: 0,
									label: 'Entrada',
								},
								{
									value: 1,
									label: 'Gain/Loss',
								},
							]}
						/>
					</Grid>

					<Grid item xs={execType === 'B' ? 3 : 2}>
						<SelectInput
							name="executionTrigger"
							disabled={
								isDisabledBySymbol || bullet.content.ClOrdID
							}
							label="Gatilho"
							iconTooltip={t('bullets.spread.trigger')}
							defaultValue={1}
							onChange={e =>
								handleChangeTriggerInterceptor(
									'Custom',
									'Trigger',
									+e.target.value
								)
							}
							value={
								parseInt(
									bullet.content.CustomParameters.Trigger,
									10
								) ?? 1
							}
							selectOptions={[
								{
									value: 1,
									label: 'Spread',
								},
								{
									value: 11,
									label: 'Dif. Financeiro',
								},
							]}
						/>
					</Grid>

					<Grid item xs={3}>
						<DecimalInput
							name="triggerValue"
							disabled={isDisabledBySymbol}
							label={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) !== 0
									? 'Gain'
									: 'Entrada'
							}
							tooltip={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) !== 0
									? t('bullets.general.stop_gain')
									: t('bullets.general.trigger_value')
							}
							allowNegatives={false}
							minVal={-999999.99}
							maxVal={999999.99}
							minDecScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="TriggerValue" // subtype
							stepSize={getStepByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // incremento
							valueToStep={
								bullet.content.CustomParameters.TriggerValue
							} // valor a ser modificado
							value={bullet.content.CustomParameters.TriggerValue}
							onChange={e =>
								handleStopChanges(
									e.value,
									'TriggerValue',
									gainCr,
									setStopGain
								)
							}
							isFinantial={gainCr}
							finantialClick={callbackHandleCreditDebit}
						/>

						{parseInt(
							bullet.content.CustomParameters.ExecutionType,
							10
						) === 1 && (
							<Grid item xs style={{ margin: '15px 0 10px 0' }}>
								<DecimalInput
									name="lossValue"
									tooltip={t('bullets.general.stop_loss')}
									disabled={isDisabledBySymbol}
									label="Loss"
									allowNegatives={false}
									minVal={-999999.99}
									maxVal={999999.99}
									minDecScale={getDecimalPlacesByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="ToMarketTriggerValue" // subtype
									stepSize={getStepByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // incremento
									valueToStep={
										bullet.content.CustomParameters
											.ToMarketTriggerValue
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.ToMarketTriggerValue ?? 0
									}
									onChange={e =>
										handleStopChanges(
											e.value,
											'ToMarketTriggerValue',
											lossCr,
											setStopLoss,
											setLossCr
										)
									}
									isFinantial={lossCr}
									finantialClick={
										callbackHandleCreditDebitLoss
									}
								/>
							</Grid>
						)}
					</Grid>

					<Grid item xs={2} style={{ marginTop: '-24px' }}>
						<SpreadMarketPrice
							title={
								isSpread
									? 'Spread do Mercado'
									: 'Dif. Financeiro Mercado'
							}
							disabled={isDisabledBySymbol}
							setGainCr={setGainCr}
							setLossCr={setLossCr}
							bulletType={'spread'}
							setStopGain={setStopGain}
							setStopLoss={setStopLoss}
							marketPrice={marketPrice}
							handleChange={handleChange}
							stopSelected={stopSelected}
						/>
					</Grid>
				</Grid>

				<BottomContainer
					container
					item
					xs={12}
					spacing={1}
					execType={execType}
				>
					{execType !== 'B' && (
						<>
							<Grid item xs={3}>
								<SelectInput
									name="bookDepth"
									disabled={isDisabledBySymbol}
									label="Profundidade do Book"
									iconTooltip={t(
										'bullets.general.book_depth'
									)}
									defaultValue={1}
									value={
										bullet.content.CustomParameters
											.BookDepth ?? 1
									}
									selectOptions={[
										{
											value: 1,
											label: '1º Nível',
										},
										{
											value: 2,
											label: '2º Nível',
										},
										{
											value: 3,
											label: '3º Nível',
										},
									]}
									onChange={e =>
										handleChange(
											'Custom',
											'BookDepth',
											+e.target.value
										)
									}
								/>
							</Grid>
							<Grid item xs={2}>
								<SelectInput
									name="compensateExec"
									disabled={
										isDisabledBySymbol ||
										bullet.content.ClOrdID
									}
									label="Compensação"
									iconTooltip={t(
										'bullets.spread.compensation'
									)}
									defaultValue={2}
									value={
										bullet.content.CustomParameters
											.CompensateExec ?? 2
									}
									selectOptions={[
										{
											value: 0,
											label: 'Nenhum',
										},
										{
											value: 1,
											label: 'Clip Seguinte',
										},
										{
											value: 2,
											label: 'Gradual',
										},
									]}
									onChange={e =>
										handleChange(
											'Custom',
											'CompensateExec',
											+e.target.value
										)
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<DecimalInput
									disabled={isDisabledBySymbol}
									name="ignoreOffer"
									label="Ignorar múltiplo de lote"
									iconTooltip={t(
										'bullets.long_and_short.ignore_offers_lt'
									)}
									minVal={0}
									maxVal={10}
									minDecScale={0} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="IgnoreOffersLT" // subtype
									stepSize={1} // incremento
									valueToStep={
										bullet.content.CustomParameters
											.IgnoreOffersLT
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.IgnoreOffersLT
									}
									onChange={e =>
										handleChange(
											'Custom',
											'IgnoreOffersLT',
											parseInt(e.value)
										)
									}
								/>
							</Grid>
						</>
					)}
					{bullet.content.CustomParameters.ExecutionType === 0 &&
						showProjectedCoust(bullet) && (
						<Grid item xs={execType === 'A' ? 4 : 3}>
							<ProjectedCoustContainer
								gainCr={gainCr}
								disabled={
									isDisabledBySymbol ||
										bullet.content.ClOrdID
								}
							>
								{parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) === 0 && (
									<ProjectedCoust
										value={calcProjectedCoust()}
									/>
								)}
							</ProjectedCoustContainer>
						</Grid>
					)}
				</BottomContainer>
			</Grid>
		</Grid>
	);
};

export default ExecutionParams;
