import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	colors,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import paperValidator from 'utils/paperValidation';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';
import {
	updateContent,
	updateCustomParameters,
} from 'store/modules/bottomBullet/actions';
import { NumericInput } from 'components/Bullet/NumericInput';
import { findShortestTime } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';

export const GapExitParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);
	const accounts = useSelector(state => state.auth.accounts);
	const defaultAccount = useSelector(
		state =>
			state.configs.globalSettings?.userSettings?.bullet.defaultAccount
	);

	const { content, StrategyInfos } = bullet;

	const {
		ExitUnit,
		ExitOnEndDayAuction,
		ExitOnEndTime,
		ExitPxRelGain,
		ExitPxRelLoss,
		ExitPxSlippagePts,
		_useStopGain,
		_useStopLoss,
	} = content.CustomParameters;

	const { minPriceIncrement, tickSizeDenominator, closeHour } =
		StrategyInfos[0];

	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	const exitOnSelection = ExitOnEndTime === 'Y' ? 'endTime' : 'endDayAuction';

	function handleToggleExitOnSelection() {
		dispatch(
			updateCustomParameters({
				ExitOnEndTime: ExitOnEndTime === 'Y' ? 'N' : 'Y',
				ExitOnEndDayAuction: ExitOnEndDayAuction === 'Y' ? 'N' : 'Y',
			})
		);

		if (ExitOnEndDayAuction === 'N') {
			dispatch(updateContent({ EndTime: '20:00:00' }));
		} else {
			dispatch(
				updateContent({ EndTime: findShortestTime([closeHour], 1) })
			);
		}
	}

	function handleToggleExitUnit() {
		dispatch(
			updateCustomParameters({
				ExitUnit: ExitUnit === 'I' ? 'P' : 'I',
				ExitPxRelGain: '',
				ExitPxRelLoss: '',
			})
		);
	}

	function handleUpdateCustomParameters(field, value) {
		dispatch(
			updateCustomParameters({
				[field]: value,
			})
		);
	}

	function getCurrentAccount(bulletAccount, authAccounts, defaultAccount) {
		if (bulletAccount) {
			return bulletAccount;
		}

		if (authAccounts.length > 0) {
			if (![null, undefined, '', 'Nenhuma'].includes(defaultAccount)) {
				return defaultAccount;
			}

			return authAccounts[0].code;
		}

		return;
	}

	const currentAccount = getCurrentAccount(
		bullet.account,
		accounts,
		defaultAccount
	);

	function hasExitOnEndDayAuctionToBeDisabled(
		currentAccount,
		closingCallStart
	) {
		if (['', null, undefined].includes(closingCallStart)) {
			return true;
		}

		if (currentAccount) {
			if (String(currentAccount).startsWith('D-')) {
				return true;
			}

			return false;
		}

		return true;
	}

	const isExitOnEndDayAuctionDisabled = hasExitOnEndDayAuctionToBeDisabled(
		currentAccount,
		StrategyInfos[0].closingCallStart
	);

	function handleToggleStopGain() {
		const newValue = _useStopGain ? false : true;

		handleUpdateCustomParameters('_useStopGain', newValue);

		if (!newValue) {
			handleUpdateCustomParameters('ExitPxRelGain', null);
		}
	}

	function handleToggleStopLoss() {
		const newValue = _useStopLoss ? false : true;

		handleUpdateCustomParameters('_useStopLoss', newValue);

		if (!newValue) {
			handleUpdateCustomParameters('ExitPxRelLoss', null);
			handleUpdateCustomParameters('ExitPxSlippagePts', null);
		}
	}

	const isGainError =
		_useStopGain && ['', null, undefined].includes(ExitPxRelGain);

	const isLossError =
		_useStopLoss && ['', null, undefined].includes(ExitPxRelLoss);

	const isSlippageError =
		isLossError || ['', null, undefined].includes(ExitPxSlippagePts);

	return (
		<Grid
			item
			container
			direction="column"
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				p: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">Parâmetros de Saída</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Parâmetros de Saída"
						description={t(
							'bullets.gap.descriptions.gap_exit_params'
						)}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container direction="column" gap={1}>
				<Grid item container gap={2}>
					<Grid
						item
						xs
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
							mt: 1,
						}}
					>
						<ButtonGroup
							fullWidth
							size="small"
							disabled={isBulletDisabled}
							sx={{
								mb: 0.25,
								height: 25,
								cursor: isBulletDisabled
									? 'not-allowed'
									: 'auto',
							}}
							onClick={handleToggleExitUnit}
						>
							<Button
								color={ExitUnit === 'I' ? 'primary' : 'inherit'}
								variant={
									ExitUnit === 'I' ? 'contained' : 'outlined'
								}
								sx={
									ExitUnit === 'P'
										? notSelectedButtonStyle
										: {}
								}
							>
								Pontos
							</Button>

							<Button
								color={ExitUnit === 'P' ? 'primary' : 'inherit'}
								variant={
									ExitUnit === 'P' ? 'contained' : 'outlined'
								}
								sx={
									ExitUnit === 'I'
										? notSelectedButtonStyle
										: {}
								}
							>
								%
							</Button>
						</ButtonGroup>
					</Grid>

					<Grid item xs>
						<NumericInput
							id="ExitPxRelGain"
							label={
								<>
									<FormControlLabel
										control={<Checkbox />}
										sx={{
											m: 0,
											p: 0,
											'.MuiCheckbox-root': {
												p: '0 4px 0 0 !important',
											},
										}}
										checked={_useStopGain}
										onChange={handleToggleStopGain}
										disabled={isBulletDisabled}
									/>
									{`Gain (${
										ExitUnit === 'I' ? 'pontos' : '%'
									})`}
								</>
							}
							suffix={ExitUnit === 'I' ? '' : '%'}
							value={ExitPxRelGain ?? ''}
							onChangeCallback={value => {
								handleUpdateCustomParameters(
									'ExitPxRelGain',
									value
								);
							}}
							error={isGainError}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled || !_useStopGain}
							fixedDecimalScale
						/>
					</Grid>
				</Grid>

				<Grid item container gap={2}>
					<Grid item xs>
						<NumericInput
							value={ExitPxRelLoss ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'ExitPxRelLoss',
									value
								)
							}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							error={isLossError}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled || !_useStopLoss}
							fixedDecimalScale
							label={
								<>
									<FormControlLabel
										control={<Checkbox />}
										sx={{
											m: 0,
											p: 0,
											'.MuiCheckbox-root': {
												p: '0 4px 0 0 !important',
											},
										}}
										checked={_useStopLoss}
										onChange={handleToggleStopLoss}
										disabled={isBulletDisabled}
									/>
									{`Loss (${
										ExitUnit === 'I' ? 'pontos' : '%'
									})`}
								</>
							}
							suffix={ExitUnit === 'I' ? '' : '%'}
						/>
					</Grid>

					<Grid
						item
						xs
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
						}}
					>
						<NumericInput
							id="ExitPxSlippagePts"
							label="Slippage (pontos)"
							value={ExitPxSlippagePts ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'ExitPxSlippagePts',
									value
								)
							}
							error={isSlippageError}
							roundOnBlur
							decimalScale={tickSizeDenominator}
							stepSize={minPriceIncrement}
							minValue={0}
							disabled={isBulletDisabled || !_useStopLoss}
							fixedDecimalScale
						/>
					</Grid>
				</Grid>

				<Grid item container spacing={1}>
					<Grid item xs sx={{ mt: 1 }}>
						<FormControl disabled={isBulletDisabled}>
							<FormLabel>Termina em:</FormLabel>
							<RadioGroup
								row
								value={exitOnSelection}
								sx={{ flexWrap: 'nowrap' }}
								onChange={handleToggleExitOnSelection}
							>
								<FormControlLabel
									value="ExitOnEndTime"
									control={<Radio />}
									label="Hora fim"
									checked={exitOnSelection === 'endTime'}
								/>

								<FormControlLabel
									value="ExitOnEndDayAuction"
									control={<Radio />}
									label="Leilão de fechamento"
									checked={
										exitOnSelection === 'endDayAuction'
									}
									disabled={
										isBulletDisabled ||
										isExitOnEndDayAuctionDisabled
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
