import React from 'react';
import { ButtonGroup } from '@mui/material';

import { ButtonBuySell } from '../MaterialStyles/style';

export default function TriggerSwitch(props) {
	const { disabled, triggerType, changeTrigger, isEdit, isTwap, isSingle, namePts, namePerc } = props;

	const colorsButtonPts = triggerType === 2 ? 
		{ bgcolor: 'text.secondary', color: 'background.paper' } : 
		{ bgcolor: 'info.main', color: 'info.contrastText' }
	;

	const colorsButtonPerc = triggerType === 2 ? 
		{ bgcolor: 'error.main', color: 'error.contrastText' } : 
		{ bgcolor: 'text.secondary', color: 'background.paper' }
	;

	return (
		<ButtonGroup disabled={disabled} variant="contained" style={{ width: '100%', height: '19px' }}>
			<ButtonBuySell
				name={namePts}
				sx={{ 
					...colorsButtonPts, 
					'&.MuiButton-root:hover': {
						bgcolor: 'info.main',
					} 
				}}
				onClick={isEdit !== undefined ? null : triggerType === 2 ? changeTrigger : null}
				style={{ width: isTwap || isSingle ? '50%' : '100%' }}
				size="small"
			>
				{isTwap ? 'Vol Fin' : 'Pts'}
			</ButtonBuySell>
			<ButtonBuySell
				name={namePerc}
				sx={{ 
					...colorsButtonPerc,
					'&.MuiButton-root:hover': {
						bgcolor: 'error.main'
					}
				}}
				onClick={isEdit !== undefined ? null : triggerType === 2 ? null : changeTrigger}
				style={{ width: isTwap || isSingle ? '50%' : '100%' }}
				size="small"
			>
				{isTwap ? 'Qtd' : '%'}
			</ButtonBuySell>
		</ButtonGroup>
	);
}
