import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Autocomplete, TextField } from '@mui/material';

import { apiEls } from 'services/api';

const tradeableSecurityTypes = [
	'CASH',
	'CS',
	'ETF',
	'FUT',
	'PS',
	'SPOT',
	'FOPT',
	'INDEXOPT',
	'OPT',
	'SOPT',
];

export const InstrumentsSearch = ({
	handleChangeExportFormValues,
	isFormLoading,
}) => {
	const exportForm = useSelector(state => state.historicalData.exportForm);

	const [isLoading, setIsLoading] = useState(false);
	const [suggestions, setSuggestions] = useState([]);

	const debounceRef = useRef(null);

	const escapeRegexCharacters = value => {
		return String(value).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};

	const fetchSuggestions = useCallback(async value => {
		const formattedValue = escapeRegexCharacters(value);

		if (formattedValue.length < 3) {
			return;
		}

		clearTimeout(debounceRef.current);

		debounceRef.current = setTimeout(async () => {
			setIsLoading(true);

			try {
				const { data } = await apiEls.get('/search', {
					params: { symbol: formattedValue },
				});

				const tradeableSuggestions =
					data.suggest.suggest_symbol[0].options.filter(s =>
						tradeableSecurityTypes.includes(s._source.securityType)
					);

				setSuggestions(
					tradeableSuggestions.map(s => ({
						id: s._id,
						label: s.text,
					}))
				);
			} catch (error) {
				console.error('Erro ao buscar sugestões:', error);
			} finally {
				setIsLoading(false);
			}
		}, 500);
	}, []);

	function onAutoCompleteChange(symbols, reason) {
		if (symbols.length > 10) {
			return;
		}

		if (reason === 'clear') {
			handleChangeExportFormValues('symbols', []);
			return;
		}

		handleChangeExportFormValues('symbols', symbols);
	}

	return (
		<Autocomplete
			label="Instrumentos"
			size="small"
			value={exportForm.symbols}
			options={suggestions}
			loading={isLoading}
			multiple
			disableCloseOnSelect
			disabled={isFormLoading}
			getOptionLabel={option => option.label}
			onChange={(_, selectedSymbols, reason) =>
				onAutoCompleteChange(selectedSymbols, reason)
			}
			disablePortal
			renderInput={params => (
				<TextField
					{...params}
					label="Ativos"
					inputProps={{
						...params.inputProps,
						style: { textTransform: 'uppercase' },
					}}
				/>
			)}
			onInput={event =>
				fetchSuggestions(event.target.value.toUpperCase())
			}
		/>
	);
};
