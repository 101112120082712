import { store } from 'store';
import { parse } from 'date-fns';

import { EXM } from 'utils/bulletContext';

import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { getSubUid } from 'utils/strategies';

export const getRadarColumns = searchApplied => [
	{
		flex: 1,
		field: 'maxSymbol1',
		headerName: `Max.${searchApplied.symbol1} x${searchApplied.symbol1_prop}`,
		type: 'string',
	},
	{
		flex: 1,
		field: 'closeSymbol1',
		headerName: `Fech.${searchApplied.symbol1}`,
		type: 'string',
	},
	{
		flex: 1,
		field: 'maxSymbol2',
		headerName: `Max.${searchApplied.symbol2} x${searchApplied.symbol2_prop}`,
		type: 'string',
	},
	{
		flex: 1,
		field: 'closeSymbol2',
		headerName: `Fech.${searchApplied.symbol2}`,
		type: 'string',
	},
	{
		flex: 1,
		field: 'creditsLayers',
		headerName: 'Créd. Camadas',
		type: 'string',
		renderCell: params => Number(params.value).toFixed(2),
	},
	{
		flex: 1,
		field: 'layers',
		headerName: 'Camadas',
		type: 'string',
	},
];

export const getFormmatedData = data => {
	const formattedHistoryData = data.hist_data.map((item, index) => ({
		...item,
		id: index,
		date: parse(item.date, 'yyyy-MM-dd', new Date()),
	}));

	const formattedData = {
		layers: data.layers,
		historyData: formattedHistoryData,
		percentSymbol1: data.frequency_symbol1,
		percentSymbol2: data.frequency_symbol2,
	};

	return formattedData;
};

export const getComparationActives = (
	symbol1,
	symbol2,
	percentSymbol1,
	percentSymbol2
) => {
	const result =
		percentSymbol1 > percentSymbol2
			? {
				maxActive: symbol1,
				maxFrequency: percentSymbol1,
				minActive: symbol2,
				minFrequency: percentSymbol2,
			  }
			: {
				maxActive: symbol2,
				maxFrequency: percentSymbol2,
				minActive: symbol1,
				minFrequency: percentSymbol1,
			  };

	return result;
};

export function handleBullet(strategyData) {
	const legStrategy = {
		LegSymbol: '',
		LegSecurityExchange: 'XBSP',
		LegSide: '1',
		LegQuantity: 100,
		LegResting: 'N',
		LegMaxClipSize: 100,
	};

	const strategy = {
		StrategyCode: 'cont',
		context: EXM,
		CustomParameters: {
			BookDepth: 1,
			EntryTriggerType: 1,
			ExitTriggerType: 1,
			CompensateExec: 2,
			Trigger: 1,
			IgnoreOffersLT: 1,
			ExecutionType: 0,
			ToMarketTriggerValueEnabled: 'N',
			EntryTriggerValue: strategyData.entryTriggerValue ?? '', // Entrada
			ExitTriggerValue: strategyData.exitTriggerValue ?? '', // Saida
		},
		StrategyLegs: [
			{
				...legStrategy,
				LegSymbol: strategyData.symbol1,
				LegQuantity: strategyData.symbol1_prop * 100,
			},
			{
				...legStrategy,
				LegSymbol: strategyData.symbol2,
				LegSide: '2',
				LegQuantity: strategyData.symbol2_prop * 100,
			},
		],
	};

	store.dispatch(orderEntry(strategy, getSubUid(strategy)));
	store.dispatch(updateStrategyInfosFromApi(strategy.StrategyLegs));

	store.dispatch(additionalUpdate('isOpen', true));
	store.dispatch(additionalUpdate('refreshNeeded', true));
}
