import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { Book, SwapVert } from '@mui/icons-material';

import paperValidator from 'utils/paperValidation';
import {
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import DecimalInput from 'components/MaterialComponents/DecimalInput';
import { ButtonBuySell } from 'components/MaterialComponents/MaterialStyles/style';
import MaterialTriggerSwitch from 'components/MaterialComponents/MaterialTriggerSwitch';
import SelectInput from 'components/MaterialComponents/SelectInput';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { useTranslation } from 'react-i18next';
import { MarketPrice } from '../../../MarketPrice';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const TwapLeg = props => {
	const {
		onSuggestionsFetchRequested,
		setPaperInfo,
		handleChange,
		subUid,
		onlyCall,
		onlyOptions,
		stepFunction,
	} = props;

	const { bullet } = useSelector(state => state.bottomBullet);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const changeLegSide = () => {
		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);
	};

	const setPriceLimit = type => {
		dispatch(
			updateCustomParameters({
				PriceLimit: type === 'ask' ? bullet.StrategyInfos[0].askPx : bullet.StrategyInfos[0].bidPx,
			})
		);
	};

	function changeTrigger() {
		dispatch(
			updateCustomParameters({
				UsingFinancialVolume:
					bullet.content.CustomParameters.UsingFinancialVolume === 'Y'
						? 'N'
						: 'Y',
			})
		);
	}

	return (
		<Grid item xs={8}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12}>
					<Typography variant="subtitle1">
						{subUid !== 4 ? 'Entrada' : 'Posição'}
					</Typography>
				</Grid>
				<span>
					<Box pb={0.5}>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(
										bullet.content.StrategyLegs[0].LegSymbol
									)
								}
								disabled={
									symbolOpenBook(
										bullet.StrategyInfos[0],
										bullet
									) || lockBook
								}
							>
								<Book />
							</IconButton>
						</Tooltip>
					</Box>
				</span>
				<Grid item xs={2}>
					<ComboBoxInput
						name="paperField"
						label="Ativo"
						setPaperInfo={setPaperInfo}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								0
							)
						}
						value={bullet.content.StrategyLegs[0].LegSymbol}
						disabled={bullet.content.ClOrdID}
						isPaper
					/>
				</Grid>
				<Grid item xs={1.5}>
					<ButtonBuySell
						name="singleSideButtom"
						variant="contained"
						fullWidth
						size="small"
						color={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? 'primary'
								: 'secondary'
						}
						startIcon={<SwapVert />}
						onClick={changeLegSide}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'singleSideButtom'
						)}
						/* Possivel alteração */
						/* sx={{
							padding: '5px 5px 0px 6px !important',
						}} */
					>
						{subUid === 4
							? bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Comprei'
								: 'Vendi'
							: bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Compra'
								: 'Venda'}
					</ButtonBuySell>
				</Grid>
				<Grid item xs={0} style={{ width: '125px' }}>
					<Box justifyContent="center" display="flex">
						<MaterialTriggerSwitch
							disabled={
								!bullet.content.StrategyLegs[0].LegSymbol ||
								bullet.content.ClOrdID
							}
							isEdit={bullet.content.ClOrdID}
							triggerType={
								bullet.content.CustomParameters
									.UsingFinancialVolume === 'Y'
									? 1
									: 2 ?? 2
							}
							isTwap
							changeTrigger={changeTrigger}
						/>
					</Box>
				</Grid>
				<Grid item xs>
					<DecimalInput
						name="qtyField"
						disabled={
							!bullet.content.StrategyLegs[0].LegSymbol ||
							bullet.paramsView
						}
						label={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 'Volume financeiro'
								: 'Quantidade'
						}
						minVal={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 0
								: bullet.StrategyInfos[0].minOrderQty ?? 0
						}
						maxVal={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 99999
								: bullet.StrategyInfos[0].maxOrderQty ?? 9999999
						}
						minDecScale={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 2
								: 0
						} // casas decimais
						stepFunction={stepFunction}
						stepType={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 'Custom'
								: 'Leg'
						} // type
						stepSubType={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 'MaxFinancialVolume'
								: 'LegQuantity'
						} // subtype
						stepSize={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? 0.01
								: bullet.StrategyInfos[0].roundLot ||
								  bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? bullet.content.CustomParameters
									.MaxFinancialVolume
								: bullet.content.StrategyLegs[0].LegQuantity
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? bullet.content.CustomParameters
									.MaxFinancialVolume
								: bullet.content.StrategyLegs[0].LegQuantity
						}
						onChange={e => {
							bullet.content.CustomParameters
								.UsingFinancialVolume === 'Y'
								? handleChange(
									'Custom',
									'MaxFinancialVolume',
									parseFloat(e.value),
									0
								  )
								: handleChange(
									'Leg',
									'LegQuantity',
									parseInt(e.value, 10),
									0
								  );
						}}
					/>
				</Grid>

				<Grid item xs={2}>
					<SelectInput
						name="strategyType"
						disabled={
							!bullet.content.StrategyLegs[0].LegSymbol ||
							bullet.content.ClOrdID
						}
						label="Tipo"
						iconTooltip={t('bullets.participatory.strategy_type')}
						onChange={e =>
							handleChange('Leg', 'LegOrdType', e.target.value, 0)
						}
						defaultValue={2}
						value={bullet.content.StrategyLegs[0].LegOrdType ?? 2}
						selectOptions={[
							{
								value: 2,
								label: 'Limite',
							},
							{
								value: 1,
								label: 'Mercado',
							},
						]}
					/>
				</Grid>

				{bullet.content.ClOrdID &&
				!bullet.content.CustomParameters.PriceLimit ? (
						''
					) : !bullet.content.StrategyLegs[0].LegOrdType ||
				  bullet.content.StrategyLegs[0].LegOrdType == 2 ? (
							<Grid item xs>
								<DecimalInput
									name="priceField"
									disabled={paperValidator(
										bullet.StrategyInfos[0],
										bullet,
										'isPrice'
									)}
									label="Preço Limite"
									iconTooltip={t('bullets.general.limit_price')}
									minVal={0}
									maxVal={999999.99}
									minDecScale={
										bullet.StrategyInfos[0].tickSizeDenominator
									} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="PriceLimit" // subtype
									stepSize={
										bullet.StrategyInfos[0].minPriceIncrement ||
								bullet.StrategyInfos[0].Cst_MinPriceIncrement
									} // incremento
									valueToStep={
										bullet.content.CustomParameters.PriceLimit
									} // valor a ser modificado
									value={bullet.content.CustomParameters.PriceLimit}
									onChange={e =>
										handleChange('Custom', 'PriceLimit', e.value, 0)
									}
								/>
							</Grid>
						) : (
							''
						)}

				<Grid item xs style={{ marginTop: '-24px' }}>
					<MarketPrice setPriceLimit={setPriceLimit} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TwapLeg;
