import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Tooltip, colors, Box } from '@mui/material';
import { useParams } from 'react-router';
import { apiAWS } from 'services/api';
import { jwtDecode } from 'jwt-decode';

import { LoginForm } from 'components/forms/LoginForm';
import ForgotPassword from 'components/ForgotPassword';
import { NewUserForm } from 'components/forms/NewUserForm';
import LogoText from '../../assets/images/flexscan_logo.png';
import tradeCertoLogo from '../../assets/images/trade-certo-escuro.png';
import radarLogo from '../../assets/images/logo_radar.png';
import background from '../../assets/images/waves.png';
import {
	CallToActionContainer,
	CallToAction,
	Logo,
	Title,
	FormInstruction,
	FormChangeButton,
	IconHelp,
	AlignForgotPassword,
} from './styles';
import { doLogout } from 'store/modules/auth/actions';
import Contract from '../../components/Contract';
import { updateConfig } from 'store/modules/panelConfig/actions';
import { Authentication } from 'components/Authentication';
import { UserBlockedModal } from 'components/Modals/UserBlockedModal';
import {
	EXTERNAL_PRODUCTS,
	PRODUCT_TYPES,
	VALID_PRODUCTS_URL,
} from 'utils/constants';

const PRODUCTS_LOGOS_SRC = {
	RADAR_DIVIDENDOS: radarLogo,
	TRADE_CERTO: tradeCertoLogo,
	FLEXSCAN: LogoText,
	RADAR_DIV: radarLogo,
};

function Login({ newUser }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { newUserToken, pathProduct } = useParams();
	const { t, i18n } = useTranslation();

	const { auth } = useSelector(state => state);
	const { accountType } = useSelector(state => state.auth);

	const [step, setStep] = useState(1);
	const [loginStep, setLoginStep] = useState('loginForm');
	const [authToken, setAuthToken] = useState('');
	const [authMethod, setAuthMethod] = useState('');
	const [mfaCodeInfo, setMfaCodeInfo] = useState({});
	const [loginValues, setLoginValues] = useState({});
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [versionChecked, setVersionChecked] = useState(false);
	const [isTimerFinished, setIsTimerFinished] = useState(true);

	function adjustLoginInformation(stepValue) {
		setStep(stepValue);
	}

	function resetLocalStates() {
		setAuthToken('');
		setLoginStep('loginForm');
		setIsTimerFinished(true);
		setMfaCodeInfo({});
	}

	/**
	 * Retornar usuário para rota que estava tentando acessar
	 * Caso acesse '/login' diretamente, redireciona para o Dashboard
	 */
	const { from } = location.state || { from: { pathname: '/' } };

	const getPageDescription = isResetPass => {
		if (isResetPass) {
			return t('login.passwordRecover');
		}
	};

	const getPageInformationByStep = (isResetPass, stepValue) => {
		if (isResetPass) {
			switch (stepValue) {
				case 1:
					return t('login.passwordInstruction');
				case 2:
					return t('login.passwordInstruction2');
				case 3:
					return t('login.passwordInstruction3');
				case 4:
					return t('login.passwordInstruction4');
				default:
					return '';
			}
		}

		return '';
	};

	useEffect(() => {
		if (pathProduct && !(pathProduct in VALID_PRODUCTS_URL)) {
			if (auth.token) {
				dispatch(doLogout(history));
			}

			history.push('/login');
			return;
		}

		if (newUser) {
			if (auth.token) {
				dispatch(doLogout(history));
				history.push(`/novo-usuario/${newUserToken}`);
			}

			return;
		}

		if (auth.token) {
			history.push('/');
		}
	}, []);

	useEffect(() => {
		if (!versionChecked && process.env.NODE_ENV === 'production') {
			setVersionChecked(true);

			apiAWS
				.get(
					`/services/flexscan/status?v=${process.env.REACT_APP_VERSION}`,
					{
						headers: {
							'x-authorization':
								process.env.REACT_APP_AWS_API_TOKEN,
						},
					}
				)
				.then(response => {
					// verifico se a versao existe e se tem algum valor
					if (response.data.need_reload) {
						const today = new Date();
						const todayCode = `${today.getMonth()}${today.getDate()}`;
						const hasReload = sessionStorage.getItem('_lslg');
						if (
							!hasReload ||
							(hasReload && hasReload !== todayCode)
						) {
							sessionStorage.setItem('_lslg', todayCode);
							window.location.reload(true);
						}
					}
				});
		}
	}, [versionChecked]);

	useEffect(() => {
		dispatch(updateConfig('widgetIsLoading', false));
	}, []);

	useEffect(() => {
		document.title = t('pageTitle.login');
	}, [i18n.language]);

	function handleComponentChange(value) {
		if (value !== undefined) {
			setForgotPassword(value);
		} else {
			setForgotPassword(!forgotPassword);
		}
	}

	function handleUserBlockedSubmit() {
		setModalIsOpen(false);
		setForgotPassword(true);
	}

	function handleUserBlockedClose() {
		setModalIsOpen(false);
	}

	function getLogoSource() {
		if (pathProduct) {
			return PRODUCTS_LOGOS_SRC[pathProduct.toUpperCase()];
		}

		if (newUser) {
			const decodedToken = jwtDecode(newUserToken);

			console.log({ decodedToken });

			const validProduct = decodedToken.products.find(
				product =>
					product in PRODUCT_TYPES || product in EXTERNAL_PRODUCTS
			);

			if (validProduct && validProduct in PRODUCT_TYPES) {
				return PRODUCTS_LOGOS_SRC.FLEXSCAN;
			}

			if (validProduct && validProduct in EXTERNAL_PRODUCTS) {
				return PRODUCTS_LOGOS_SRC[validProduct];
			}
		}

		return PRODUCTS_LOGOS_SRC.FLEXSCAN;
	}

	const logoSource = getLogoSource();

	return (
		<>
			<UserBlockedModal
				isOpen={modalIsOpen}
				onClose={handleUserBlockedClose}
				onSubmit={handleUserBlockedSubmit}
			/>

			<Grid
				sx={{
					height: '100dvh',
					bgcolor: colors.grey[50],
					display: 'flex',
				}}
			>
				{!newUser && !pathProduct && (
					<CallToActionContainer backgroundImage={background}>
						<CallToAction>
							<Typography
								variant="h4"
								dangerouslySetInnerHTML={{
									__html: t('login.title'),
								}}
							/>
							<Typography
								variant="h6"
								dangerouslySetInnerHTML={{
									__html: t('login.subtitle'),
								}}
							/>
						</CallToAction>
					</CallToActionContainer>
				)}

				<Grid
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flex: 1,
						px: 2,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
							width: '100%',
							maxWidth: 500,
						}}
					>
						<Logo src={logoSource} alt="Logo" />
						<Title>
							{loginStep !== 'contractValidation'
								? getPageDescription(forgotPassword)
								: ''}
						</Title>
						<FormInstruction
							variant="h6"
							dangerouslySetInnerHTML={{
								__html: getPageInformationByStep(
									forgotPassword,
									step
								),
							}}
						/>
						{forgotPassword && (
							<ForgotPassword
								handleComponentChange={handleComponentChange}
								adjustLoginInformation={adjustLoginInformation}
							/>
						)}
						{newUser && <NewUserForm />}

						{!forgotPassword &&
							!newUser &&
							loginStep === 'loginForm' && (
							<LoginForm
								setLoginStep={setLoginStep}
								setAuthMethod={setAuthMethod}
								setIsTimerFinished={setIsTimerFinished}
								setMfaCodeInfo={setMfaCodeInfo}
								setAuthToken={setAuthToken}
								setModalIsOpen={setModalIsOpen}
								setLoginValues={setLoginValues}
								resetLocalStates={resetLocalStates}
							/>
						)}
						{loginStep === 'auth' && (
							<Authentication
								authMethod={authMethod}
								setLoginStep={setLoginStep}
								setAuthMethod={setAuthMethod}
								handleCancel={resetLocalStates}
								authToken={authToken}
								emailAuthProps={{
									mfaCodeInfo: mfaCodeInfo,
									credentials: loginValues,
									isTimerFinished: isTimerFinished,
									setMfaCodeInfo: setMfaCodeInfo,
									setIsTimerFinished: setIsTimerFinished,
								}}
							/>
						)}
						{!newUser && loginStep === 'loginForm' && (
							<AlignForgotPassword>
								<FormChangeButton
									onClick={() => {
										setForgotPassword(!forgotPassword);
									}}
								>
									{forgotPassword
										? t('login.makeLogin')
										: t('login.passwordForget')}
								</FormChangeButton>
								<FormChangeButton>
									{forgotPassword ? (
										''
									) : (
										<Tooltip
											title={
												<div
													style={{ fontSize: '12px' }}
												>
													Ajuda
												</div>
											}
										>
											<IconHelp
												onClick={() =>
													window.open(
														'https://investflex.freshdesk.com/support/solutions/articles/61000295545-cria%C3%A7%C3%A3o-de-senha-e-desbloqueio-de-usu%C3%A1rio'
													)
												}
											/>
										</Tooltip>
									)}
								</FormChangeButton>
							</AlignForgotPassword>
						)}
						{loginStep === 'contractValidation' && (
							<Contract
								authToken={authToken}
								userName={loginValues.user.trim()}
								accountType={accountType}
								handleCancelContractSign={resetLocalStates}
								history={history}
								from={from}
								product={pathProduct}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

export default Login;
