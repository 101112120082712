import React, { useState } from 'react';

import { Avatar } from '@mui/material';

import { store } from 'store';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { LightTooltip } from 'components/LightTooltip';

export const MarketPriceLogo = ({ asset }) => {
	const [isError, setIsError] = useState(false);

	function getFallbackLabel() {
		return asset ? String(asset).charAt(0).toUpperCase() : ' ';
	}

	function getImageSource() {
		if (asset) {
			const formattedAsset = String(asset).toUpperCase();
			return `https://instr-logo.flexscan.com.br/${formattedAsset}.svg`;
		}

		return null;
	}

	const fallbackLabel = getFallbackLabel();
	const imageSource = getImageSource();

	if (isError && imageSource) {
		setIsError(false);
	}

	function handleOpenBook() {
		const bullet = store.getState().bottomBullet.bullet;
		const widgetIsLoading = store.getState().configs.widgetIsLoading;

		const isDisabled =
			symbolOpenBook(bullet.StrategyInfos[0], bullet) || widgetIsLoading;

		if (!isDisabled && !widgetIsLoading) {
			addWidgetFromButton(bullet.content.StrategyLegs[0].LegSymbol);
		}
	}

	return (
		<LightTooltip title={asset ? 'Abrir book' : null}>
			<Avatar
				sx={{
					width: 26,
					height: 26,
					borderRadius: 1,
					cursor: asset ? 'pointer' : 'auto',
				}}
				variant="square"
				{...(!isError && { src: imageSource })}
				onError={() => setIsError(true)}
				onClick={handleOpenBook}
			>
				{fallbackLabel}
			</Avatar>
		</LightTooltip>
	);
};
