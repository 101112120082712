import React from 'react';
import { parse, format, isValid } from 'date-fns';

import { colors } from '@mui/material';

import { ChipCell } from 'components/ChipCell';

import { getFormattedDate } from 'utils/getParsedDate';

function getFormattedNumber(number, minDigits = null, isZeroValid = false) {
	if (!number && (number !== 0 || !isZeroValid || isNaN(number))) {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: minDigits,
	}).format(number);
}

export const sideFields = {
	1: {
		label: 'Compra',
		color: colors.blue[500],
	},
	2: {
		label: 'Venda',
		color: colors.red[600],
	},
};

function formatDate(dateString) {
	let dateFormat = null;

	if (dateString.includes('.')) {
		dateString = dateString.split('.')[0]; // Remove milliseconds
	}

	if (dateString.includes(' ')) {
		dateFormat = 'yyyy-MM-dd HH:mm:ss';
	} else {
		dateFormat = 'yyyyMMdd-HH:mm:ss';
	}

	const parsedDate = parse(dateString, dateFormat, new Date());

	if (isValid(parsedDate)) {
		return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
	}

	return null;
}

const fieldsTypes = {
	orderType: {
		1: {
			label: 'Mercado',
			color: colors.blue[500],
		},
		2: {
			label: 'Limitada',
			color: colors.grey[400],
		},
		3: {
			label: 'Stop',
			color: colors.green[500],
		},
		4: {
			label: 'Stop limitada',
			color: colors.green[900],
		},
		K: {
			label: 'Mercado (K)',
			color: colors.blue[900],
		},
		k: {
			label: 'Mercado (K)',
			color: colors.blue[900],
		},
	},
	status: {
		0: {
			label: 'Nova',
			color: colors.teal.A400,
		},
		1: {
			label: 'Exec. Parcial',
			color: colors.lightBlue[200],
		},
		2: {
			label: 'Executada',
			color: colors.blue[400],
		},
		4: {
			label: 'Cancelada',
			color: colors.orange[500],
		},
		5: {
			label: 'Editada',
			color: colors.purple[300],
		},
		8: {
			label: 'Rejeitada',
			color: colors.red[400],
		},
		C: {
			label: 'Expirada',
			color: colors.grey[300],
		},
	},
	timeInForce: {
		0: {
			label: 'DIA',
			color: colors.green.A400,
		},
		1: {
			label: 'VAC',
			color: colors.purple[300],
		},
		6: {
			label: 'DES',
			color: colors.blue[500],
		},
	},
};

const getColorSide = side => sideFields[side]?.color ?? '';

export const executionsColumns = [
	{
		flex: 1,
		field: 'strategyId',
		headerName: 'ID da estratégia',
		type: 'string',
	},
	{
		flex: 1,
		field: 'name',
		headerName: 'Nome da estratégia',
		type: 'string',
	},
	{
		flex: 1,
		field: 'orderId',
		headerName: 'Order ID',
		type: 'string',
	},
	{
		flex: 1,
		field: 'account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'symbol',
		headerName: 'Instrumento',
		type: 'string',
	},
	{
		flex: 1,
		field: 'side',
		headerName: 'C / V',
		type: 'string',
		renderCell: params => (
			<ChipCell
				label={sideFields[params.value]?.label}
				color={getColorSide(params.value)}
				isOutlined={true}
				sx={{ width: '100%' }}
			/>
		),
	},
	{
		flex: 1,
		field: 'quantity',
		headerName: 'Quantidade',
		type: 'number',
	},
	{
		flex: 1,
		field: 'price',
		headerName: 'Preço',
		type: 'number',
		renderCell: params =>
			new Intl.NumberFormat('pt-BR').format(params.value),
	},
	{
		flex: 1,
		field: 'marketOrderId',
		headerName: 'Market Order ID',
		type: 'string',
	},
	{
		flex: 1,
		field: 'orderType',
		headerName: 'Tipo',
		type: 'string',
		renderCell: params => (
			<span
				style={{
					color: fieldsTypes.orderType[params.value]?.color,
				}}
			>
				{fieldsTypes.orderType[params.value]?.label}
			</span>
		),
	},
	{
		flex: 1,
		field: 'orderStatus',
		headerName: 'Status',
		type: 'string',
		renderCell: params => (
			<ChipCell
				color={fieldsTypes.status[Number(params.value)]?.color}
				label={fieldsTypes.status[Number(params.value)]?.label}
			/>
		),
	},
	{
		flex: 1,
		field: 'lastQuantity',
		headerName: 'Última qtd.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'lastPrice',
		headerName: 'Último preço',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value),
	},
	{
		flex: 1,
		field: 'quantityExecuted',
		headerName: 'Qtd. exec.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'quantityRemaining',
		headerName: 'Qtd. rest.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'stopPrice',
		headerName: 'Preço Stop',
		type: 'number',
	},
	{
		flex: 1,
		field: 'rlp',
		headerName: 'RLP',
		type: 'boolean',
	},
	{
		flex: 1,
		field: 'expireDate',
		headerName: 'Validade',
		type: 'date',
		renderCell: params =>
			params.value && (
				<span>{getFormattedDate(params.value, 'dd/MM/yyyy')}</span>
			),
	},
	{
		flex: 1,
		field: 'timeInForce',
		headerName: 'Tipo validade',
		type: 'string',
		renderCell: params =>
			params.value && (
				<ChipCell
					color={fieldsTypes.timeInForce[Number(params.value)]?.color}
					label={fieldsTypes.timeInForce[Number(params.value)]?.label}
					isOutlined
				/>
			),
	},
	{
		flex: 1.5,
		field: 'sendingTime',
		headerName: 'Envio',
		type: 'dateTime',
		renderCell: params => formatDate(params.value),
	},
	{
		flex: 1,
		field: 'execId',
		headerName: 'Exec ID',
		type: 'string',
	},
];
