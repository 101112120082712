import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	Grid,
	TextField,
} from '@mui/material';

import { NumericInput } from 'components/Bullet/NumericInput';
import { ContinousMarketPrice } from './ContinousMarketPrice';

import { EXM } from 'utils/bulletContext';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { isDisableBySymbol } from 'utils/isDisableBySymbol';
import { getDecimalPlacesByTrigger, getStepByTrigger } from 'utils/strategies';

export const ContinousExecutionParams = ({ isAdvancedBullet }) => {
	const dispatch = useDispatch();

	const [isCreditButton, setIsCreditButton] = useState({
		entry: true /* Indicando se a entrada é Credito ou Debito */,
		exit: true /* Indicando se a saida é Credito ou Debito */,
	});

	const [loadingEntryButton, setLoadingEntryButton] =
		useState(
			false
		); /* Indicando o loading do botão de credito de entrada */

	const [loadingExitButton, setLoadingExitButton] =
		useState(false); /* Indicando o loading do botão de credito de saida */

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const { content, context } = bullet;

	const isDisabledBySymbol = isDisableBySymbol(bullet, 1);

	const bookOptions = [
		{
			value: 1,
			label: '1º Nível',
		},
		{
			value: 2,
			label: '2º Nível',
		},
		{
			value: 3,
			label: '3º Nível',
		},
	];

	const compensateOptions = [
		{
			value: 0,
			label: 'Nenhum',
		},
		{
			value: 1,
			label: 'Clip Seguinte',
		},
		{
			value: 2,
			label: 'Gradual',
		},
	];

	/* Coletando o valor com base se é credito ou debito */
	/* Caso credito o valor vai para o redux com sinal positivo, caso contrario, vai com valor negativo */
	const getTriggerValue = (value, isCredit) => {
		const tempValue = Math.abs(parseFloat(value));
		const valueWithoutSignal = isNaN(tempValue) ? null : tempValue;

		if (![undefined, null].includes(valueWithoutSignal)) {
			return isCredit ? valueWithoutSignal : valueWithoutSignal * -1;
		}

		return null;
	};

	function handleChangeCustomParameters(fieldName, value) {
		dispatch(updateCustomParameters({ [fieldName]: value }));
	}

	function handleChangeTriggerValue(fieldName, value) {
		const isCreditTriggerValue = value >= 0;

		const triggerValue = getTriggerValue(value, isCreditTriggerValue);

		handleChangeCustomParameters(fieldName, triggerValue);
	}

	function handleCreditButton(fieldName, newValueCredit, triggerValue) {
		setIsCreditButton(prev => ({
			...prev,
			[fieldName]: newValueCredit,
		}));

		const fieldNameTrigger =
			fieldName === 'entry' ? 'EntryTriggerValue' : 'ExitTriggerValue';

		/* Pode ser tanto entrada ou saida */
		const newTriggerValue = getTriggerValue(triggerValue, newValueCredit);

		handleChangeCustomParameters(fieldNameTrigger, newTriggerValue);
	}

	function getStepSize(triggerType, isCreditButton) {
		const stepSize = getStepByTrigger(triggerType);

		return isCreditButton ? stepSize : stepSize * -1;
	}

	useEffect(() => {
		if (context !== EXM) {
			const entryTriggerValue =
				bullet.content.CustomParameters.EntryTriggerValue;

			const exitTriggerValue =
				bullet.content.CustomParameters.ExitTriggerValue;

			if (
				!['', null].includes(entryTriggerValue) &&
				!['', null].includes(exitTriggerValue)
			) {
				const isCreditEntryTriggerValue = entryTriggerValue >= 0;
				const isCreditExitTriggerValue = exitTriggerValue >= 0;

				setIsCreditButton({
					entry: isCreditEntryTriggerValue,
					exit: isCreditExitTriggerValue,
				});
			}
		}
	}, []);

	return (
		<>
			{/* Primeira linha de parametros de entrada */}
			<Grid container xs={isAdvancedBullet ? 4 : 3}>
				<Grid container item xs={12} spacing={1} mt={2}>
					{isAdvancedBullet && (
						<Grid item xs={5.5}>
							<FormLabel
								sx={{ '&.Mui-focused': { color: 'white' } }}
								htmlFor="trigger-value"
							>
								Profundidade do Book
							</FormLabel>
							<TextField
								id="trigger"
								name="trigger"
								labelId="trigger-value-label"
								size="small"
								disabled={
									isDisabledBySymbol || bullet.content.ClOrdID
								}
								defaultValue={1}
								value={content.CustomParameters.BookDepth ?? 1}
								SelectProps={{ native: 'native' }}
								InputProps={{ style: { height: 25 } }}
								InputLabelProps={{ shrink: true }}
								onChange={e =>
									handleChangeCustomParameters(
										'BookDepth',
										Number(e.target.value)
									)
								}
								fullWidth
								select
							>
								{bookOptions.map(account => (
									<option
										key={account.value}
										value={account.value}
									>
										{account.label}
									</option>
								))}
							</TextField>
						</Grid>
					)}

					<Grid
						item
						xs={isAdvancedBullet ? 2.5 : 4.5}
						mt={isAdvancedBullet ? 1.5 : 2}
					>
						<FormControlLabel
							label="Modo Simultâneo"
							disabled={
								isDisabledBySymbol ||
								bullet.content.ClOrdID ||
								bullet.paramsView
							}
							checked={
								bullet.content.CustomParameters
									.SimultaneousTriggerMode === 'Y'
							}
							onChange={e => {
								const checked = e.target.checked;

								const newValueChecked = checked ? 'Y' : 'N';

								handleChangeCustomParameters(
									'SimultaneousTriggerMode',
									newValueChecked
								);
							}}
							control={<Checkbox />}
						/>
					</Grid>

					<Grid item xs={isAdvancedBullet ? 4 : true}>
						<NumericInput
							label="Entrada"
							value={
								content.CustomParameters.EntryTriggerValue ?? ''
							}
							minValue={-999999.99}
							maxValue={999999.99}
							disabled={isDisabledBySymbol}
							stepSize={getStepSize(
								content.CustomParameters.EntryTriggerType,
								isCreditButton.entry
							)}
							decimalScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.EntryTriggerType
							)}
							onChangeCallback={value =>
								handleChangeTriggerValue(
									'EntryTriggerValue',
									value
								)
							}
							isShowFinantialButton
							isCreditFinantialButton={isCreditButton.entry}
							loadingCreditFinantialButton={loadingEntryButton}
							onChangeFinantialButton={newValue =>
								handleCreditButton(
									'entry',
									newValue,
									content.CustomParameters.EntryTriggerValue
								)
							}
							allowNegative
							fixedDecimalScale
						/>
					</Grid>
				</Grid>

				{/* Segunda linha de parametros de saida */}
				<Grid container item xs={12} spacing={1}>
					{isAdvancedBullet && (
						<>
							<Grid
								container
								xs={5.5}
								gap={1}
								sx={{ mt: 1, pl: 1 }}
							>
								<Grid item xs={5} mt={0.25}>
									<FormLabel
										htmlFor="compensate-value-label"
										sx={{
											'&.Mui-focused': { color: 'white' },
										}}
									>
										Compensação
									</FormLabel>
									<TextField
										id="compensate"
										name="compensate"
										labelId="compensate-value-label"
										SelectProps={{ native: 'native' }}
										size="small"
										disabled={
											isDisabledBySymbol ||
											content.ClOrdID
										}
										defaultValue={2}
										value={
											content.CustomParameters
												.CompensateExec ?? 2
										}
										InputProps={{ style: { height: 25 } }}
										InputLabelProps={{ shrink: true }}
										onChange={e =>
											handleChangeCustomParameters(
												'CompensateExec',
												Number(e.target.value)
											)
										}
										fullWidth
										select
									>
										{compensateOptions.map(account => (
											<option
												key={account.value}
												value={account.value}
											>
												{account.label}
											</option>
										))}
									</TextField>
								</Grid>

								<Grid item xs>
									<NumericInput
										label="Ignorar múltiplo de lote"
										value={
											bullet.content.CustomParameters
												.IgnoreOffersLT
										}
										minValue={0}
										maxValue={10}
										disabled={
											isDisabledBySymbol ||
											bullet.content.ClOrdID
										}
										decimalScale={0}
										stepSize={1}
										onChangeCallback={value =>
											handleChangeCustomParameters(
												'IgnoreOffersLT',
												value
											)
										}
									/>
								</Grid>
							</Grid>
						</>
					)}

					<Grid
						item
						xs={isAdvancedBullet ? 2.5 : 4.5}
						mt={isAdvancedBullet ? 1.5 : 2}
					>
						<FormControlLabel
							label="Zerar no final"
							disabled={
								isDisabledBySymbol ||
								bullet.content.ClOrdID ||
								bullet.paramsView
							}
							checked={
								bullet.content.CustomParameters
									.ClosePositionsOnEnd === 'Y'
							}
							onChange={e => {
								const checked = e.target.checked;

								const newValueChecked = checked ? 'Y' : 'N';

								handleChangeCustomParameters(
									'ClosePositionsOnEnd',
									newValueChecked
								);
							}}
							control={<Checkbox />}
						/>
					</Grid>

					<Grid item xs={isAdvancedBullet ? 4 : true}>
						<NumericInput
							label="Saida"
							value={
								content.CustomParameters.ExitTriggerValue ?? ''
							}
							minValue={-999999.99}
							maxValue={999999.99}
							disabled={isDisabledBySymbol}
							stepSize={getStepSize(
								content.CustomParameters.ExitTriggerType,
								isCreditButton.exit
							)}
							decimalScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.ExitTriggerType
							)}
							onChangeCallback={value =>
								handleChangeTriggerValue(
									'ExitTriggerValue',
									value
								)
							}
							isShowFinantialButton
							isCreditFinantialButton={isCreditButton.exit}
							loadingCreditFinantialButton={loadingExitButton}
							onChangeFinantialButton={newValue =>
								handleCreditButton(
									'exit',
									newValue,
									content.CustomParameters.ExitTriggerValue
								)
							}
							allowNegative
							fixedDecimalScale
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid container xs={isAdvancedBullet ? 1.7 : 2} mt={1}>
				<ContinousMarketPrice
					disabledButtonCredit={isDisabledBySymbol}
					handleCreditButton={handleCreditButton}
					setLoadingEntryButton={setLoadingEntryButton}
					setLoadingExitButton={setLoadingExitButton}
				/>
			</Grid>
		</>
	);
};
