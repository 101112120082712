import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ButtonGroup,
	colors,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import { NumericInput } from 'components/Bullet/NumericInput';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

import paperValidator from 'utils/paperValidation';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { getEntranceMoment } from 'components/MaterialComponents/MaterialBullet/submitFunctions/gapClear';

export const GapConfigParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const { content, StrategyInfos } = bullet;

	const {
		RelativeDirection,
		RelativeType,
		RelativeUnit,
		RelativePxRel,
		PxRelSlippagePts,
		EntranceMoment,
	} = content.CustomParameters;

	const { minPriceIncrement, tickSizeDenominator } = StrategyInfos[0];

	const relativeDirectionOptions = [
		{ value: 'U', label: 'Acima' },
		{ value: 'D', label: 'Abaixo' },
	];

	const relativeTypeOptions = [
		{ value: 'O', label: 'Preço abertura dia atual' },
		{ value: 'C', label: 'Preço fechamento dia anterior ' },
		{ value: 'L', label: 'Mínima dia atual' },
		{ value: 'H', label: 'Máxima dia atual' },
	];

	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	function handleUpdateCustomParameters(field, value) {
		const entranceMomentFields = ['RelativeType', 'RelativeDirection'];

		let finalEntranceMoment = {};

		if (entranceMomentFields.includes(field)) {
			const entranceMoment = getEntranceMoment(
				{ ...content.CustomParameters, [field]: value },
				content.StrategyLegs
			);

			finalEntranceMoment = { EntranceMoment: entranceMoment };
		}

		dispatch(
			updateCustomParameters({
				[field]: value,
				...finalEntranceMoment,
			})
		);
	}

	function handleToggleRelativeUnit() {
		if (!isBulletDisabled) {
			dispatch(
				updateCustomParameters({
					RelativeUnit: RelativeUnit === 'I' ? 'P' : 'I',
					RelativePxRel: '',
				})
			);
		}
	}

	return (
		<Grid container gap={1}>
			<Grid
				item
				container
				sx={{
					p: 1,
					gap: 1,
					flex: 1,
					borderRadius: 1,
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					bgcolor: colors.grey[900],
				}}
			>
				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Typography variant="subtitle1">
						Configuração de entrada
					</Typography>

					<LightHTMLTooltip
						element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
					>
						<DescriptionTooltip
							label="Configuração de entrada"
							description={t(
								'bullets.gap.descriptions.gap_config_params'
							)}
						/>
					</LightHTMLTooltip>
				</Box>

				<Grid item container xs spacing={1}>
					<Grid
						item
						xs
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
						}}
					>
						<NumericInput
							label="Valor (%/pontos)"
							suffix={RelativeUnit === 'P' ? '%' : ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'RelativePxRel',
									value
								)
							}
							value={RelativePxRel ?? ''}
							stepSize={
								RelativeUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={RelativeUnit === 'P' ? 0.01 : 0}
							disabled={isBulletDisabled}
							roundOnBlur
							decimalScale={
								RelativeUnit === 'P' ? 2 : tickSizeDenominator
							}
							fixedDecimalScale
							allowNegative={false}
						/>
					</Grid>

					<Grid
						item
						xs
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							gap: 1,
						}}
					>
						<ButtonGroup
							fullWidth
							size="small"
							sx={{
								mt: -4,
								height: 25,
								cursor: isBulletDisabled
									? 'not-allowed'
									: 'auto',
							}}
							onClick={handleToggleRelativeUnit}
							disabled={isBulletDisabled}
						>
							<Button
								color={
									RelativeUnit === 'I' ? 'primary' : 'inherit'
								}
								variant={
									RelativeUnit === 'I'
										? 'contained'
										: 'outlined'
								}
								sx={
									RelativeUnit === 'P'
										? notSelectedButtonStyle
										: {}
								}
								disabled={isBulletDisabled}
							>
								Pontos
							</Button>

							<Button
								color={
									RelativeUnit === 'P' ? 'primary' : 'inherit'
								}
								variant={
									RelativeUnit === 'P'
										? 'contained'
										: 'outlined'
								}
								sx={
									RelativeUnit === 'I'
										? notSelectedButtonStyle
										: {}
								}
								disabled={isBulletDisabled}
							>
								%
							</Button>
						</ButtonGroup>

						<Box sx={{ width: '100%' }}>
							<label htmlFor="RelativeDirection">Direção</label>
							<TextField
								id="RelativeDirection"
								name="RelativeDirection"
								labelId="RelativeDirection-label"
								value={RelativeDirection}
								SelectProps={{ native: 'native' }}
								disabled={isBulletDisabled}
								fullWidth
								size="small"
								defaultValue="U"
								InputProps={{ style: { height: 25 } }}
								InputLabelProps={{ shrink: true }}
								select
								onChange={event =>
									handleUpdateCustomParameters(
										event.target.name,
										event.target.value
									)
								}
							>
								{relativeDirectionOptions.map(account => (
									<option
										key={account.value}
										value={account.value}
									>
										{account.label}
									</option>
								))}
							</TextField>
						</Box>
					</Grid>

					<Grid
						item
						xs
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							gap: 0.5,
						}}
					>
						<label htmlFor="RelativeType">Tipo</label>
						<TextField
							id="RelativeType"
							name="RelativeType"
							labelId="RelativeType-label"
							value={RelativeType}
							disabled={isBulletDisabled}
							SelectProps={{ native: 'native' }}
							fullWidth
							size="small"
							defaultValue="O"
							InputLabelProps={{ shrink: true }}
							select
							InputProps={{ style: { height: 25 } }}
							onChange={event =>
								handleUpdateCustomParameters(
									event.target.name,
									event.target.value
								)
							}
						>
							{relativeTypeOptions.map(account => (
								<option
									key={account.value}
									value={account.value}
								>
									{account.label}
								</option>
							))}
						</TextField>
					</Grid>

					<Grid
						item
						xs
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
						}}
					>
						<NumericInput
							value={PxRelSlippagePts ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'PxRelSlippagePts',
									value
								)
							}
							roundOnBlur
							decimalScale={tickSizeDenominator}
							stepSize={minPriceIncrement}
							minValue={0}
							disabled={
								isBulletDisabled || EntranceMoment === 'I'
							}
							fixedDecimalScale
							label="Slippage (pontos)"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
