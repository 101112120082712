import styled from 'styled-components';
import { Button, Tabs } from '@mui/material';

export const Container = styled.div`
    display: block;
`;

export const Content = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    border-radius: 3px 0px 0px 3px;
    width: 100%;
`;

export const ContentMain = styled.div`
    display: block;
`;

export const CenterButton = styled.div`
    margin-top: 5px;
    position: initial;
    z-index: 9;
`;

export const SizedFab = styled(Button)`
    height: 20px;
    border-radius: 11px 11px 0px 0px !important;
    min-height: 0px;
    background-color: grey !important;

    .MuiSvgIcon-root {
        color: white !important;
    }
`;

export const SizedTabs = styled(Tabs)`
    min-height: 0px !important;
    height: 32px;
    .MuiTabs-flexContainer {
        justify-content: flex-end;
    }
    .MuiTab-root {
        min-height: 0px !important;
        height: 32px;
    }
`;

export const MaterialBulletContainer = styled.div`
    align-self: flex-end;
    width: 100%;
`;
