import { ExecutionsActionTypes } from './actionTypes';

export const setStateValues = (field, value) => ({
	type: ExecutionsActionTypes.UPDATE_STATE_VALUES,
	payload: { field, value },
});

export const setRows = rows => ({
	type: ExecutionsActionTypes.SET_ROWS,
	payload: { rows },
});

export const updateOrdersFormValues = newValues => ({
	type: ExecutionsActionTypes.UPDATE_ORDERS_FORM_VALUES,
	payload: { newValues },
});

export const clearStateValues = () => ({
	type: ExecutionsActionTypes.CLEAR_STATE_VALUES,
});

export const clearFormValues = (accounts = []) => ({
	type: ExecutionsActionTypes.CLEAR_FORM_VALUES,
	payload: { accounts },
});

export const updatePaginationInfo = paginationInfo => ({
	type: ExecutionsActionTypes.UPDATE_PAGINATION_INFO,
	payload: { paginationInfo },
});

export const updateUserPreferences = userPreferences => ({
	type: ExecutionsActionTypes.UPDATE_USER_PREFERENCES,
	payload: { userPreferences },
});

export const updateSearchPaginate = searchPaginate => ({
	type: ExecutionsActionTypes.UPDATE_SEARCH_PAGINATE,
	payload: { searchPaginate },
});

export const clearSearchPaginate = () => ({
	type: ExecutionsActionTypes.CLEAR_SEARCH_PAGINATE,
});
