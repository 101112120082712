import {
	BusinessCenter,
	CloudUploadOutlined,
	SpaceDashboardRounded,
	CandlestickChart,
} from '@mui/icons-material';
import {
	LeftSimulator,
	TemporaryGraph,
	ScannerIcon,
	OptionTrader,
	RiskIcon,
} from 'assets/icons/IconPack';

import { store } from 'store';
import { allExternalProducts } from './externalProducts';

export function getApplicationModulesList() {
	const activeProduct = store.getState().products?.activeProduct ?? {};

	let activeModules = activeProduct?.attributes?.modules ?? [];

	const riskEnabled = store.getState().auth?.riskEnabled ?? false;

	const defaultModules = [
		{
			path: '/dashboard',
			label: 'Acompanhamento',
			value: 'current_strategies',
			icon: SpaceDashboardRounded,
		},
		{
			path: '/posicoes',
			label: 'Posições',
			value: 'positions',
			icon: BusinessCenter,
		},
		{
			path: '/simulator',
			label: 'Simulador',
			value: 'simulator',
			icon: TemporaryGraph,
		},
		{
			path: '/cadeia-opcoes',
			label: 'Options Trader',
			value: 'options_trader',
			icon: OptionTrader,
		},
		{
			path: '/volstudio',
			label: 'Vol. Studio',
			value: 'volstudio',
			icon: LeftSimulator,
		},
		{
			path: '/scanner',
			label: 'Scanner',
			value: 'scanner',
			icon: ScannerIcon,
		},
		{
			path: '/riscos',
			label: 'Limite Operacional',
			value: 'risk',
			icon: RiskIcon,
		},
		{
			path: '/upload-estrategias',
			label: 'Upload de estratégias',
			value: 'strategies_upload',
			icon: CloudUploadOutlined,
		},
		{
			path: '/idxlivecharts',
			label: 'Super GL',
			value: 'idx_live_charts',
			icon: CandlestickChart,
		},
	];

	let finalModulesList = [...defaultModules];

	const productExists = allExternalProducts[activeProduct.code];

	if (productExists) {
		const productModules = [...productExists];

		finalModulesList = [...finalModulesList, ...productModules];

		activeModules = [
			...activeModules,
			...productModules.map(item => item.value),
		];
	}

	if (activeModules.includes('all')) {
		if (!riskEnabled) {
			return finalModulesList.filter(module => module.value !== 'risk');
		}
		return finalModulesList;
	}

	const filteredModules = finalModulesList.filter(module => {
		if (module.value === 'risk' && !riskEnabled) {
			return false;
		} else {
			return activeModules.includes(module.value);
		}
	});

	return filteredModules;
}
