import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import MaterialCheckBox from '../MaterialCheckBox';
import DecimalInput from '../DecimalInput';

const Stop = ({ enableStop, triggerStep, handleChange, stepFunction }) => {
	const { bullet } = useSelector(state => state.bottomBullet);

	const { t } = useTranslation();

	const handleLevel3 = () => {
		handleChange('Custom', 'UseLevel3', bullet.content.CustomParameters.UseLevel3 === 'Y' ? 'N' : 'Y', 0);
	};

	return (
		<>
			{bullet.content.CustomParameters.StopType == 3 && (
				<Grid container spacing={1} direction="row">
					<Grid container item direction="row" alignItems='center'>
						<Grid item xs={4} pl='4px'>
							<Typography variant="subtitle1">Objetivo de Lucro 1</Typography>
						</Grid>
						<Grid item xs={4} pl='6px'>
							<Typography variant="subtitle1">Objetivo de Lucro 2</Typography>
						</Grid>
						<Grid item xs={4} pl='7px'>
							<Box>
								<MaterialCheckBox
									label="Objetivo de Lucro 3"
									disabled={enableStop || bullet.content.ClOrdID}
									checked={bullet.content.CustomParameters.UseLevel3 === 'Y'}
									onChange={handleLevel3}
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={enableStop}
							label="Gain"
							iconTooltip={t('bullets.general.stop_gain')}
							minVal={0}
							maxVal={bullet.content.CustomParameters.TriggerEntryType === 1 ? 999999 : 100.0}
							minDecScale={2} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="StopGainL1" // subtype
							stepSize={
								bullet.content.CustomParameters.TriggerEntryType === 1
									? bullet.StrategyInfos[0].minPriceIncrement
										? bullet.StrategyInfos[0].minPriceIncrement
										: bullet.StrategyInfos[0].Cst_MinPriceIncrement
									: triggerStep
							} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.StopGainL1) || bullet.content.CustomParameters.StopGainL1 === undefined
									? 0
									: bullet.content.CustomParameters.StopGainL1
							} // valor a ser modificado
							value={bullet.content.CustomParameters.StopGainL1 ?? 0}
							onChange={e => handleChange('Custom', 'StopGainL1', parseFloat(e.value), 0)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={enableStop}
							label="Quantidade"
							minVal={0}
							maxVal={bullet.content.CustomParameters.OrderQuantity || bullet.content.StrategyLegs[0].LegQuantity}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="OrderQuantityL1" // subtype
							stepSize={bullet.StrategyInfos[0].roundLot || bullet.StrategyInfos[0].Cst_MinOrderQuantity} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.OrderQuantityL1) || bullet.content.CustomParameters.OrderQuantityL1 === undefined
									? 0
									: bullet.content.CustomParameters.OrderQuantityL1
							} // valor a ser modificado
							value={bullet.content.CustomParameters.OrderQuantityL1 ?? 0}
							onChange={e => handleChange('Custom', 'OrderQuantityL1', parseInt(e.value), 0)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={enableStop}
							label="Gain"
							iconTooltip={t('bullets.general.stop_gain')}
							minVal={0}
							maxVal={bullet.content.CustomParameters.TriggerEntryType === 1 ? 999999 : 100.0}
							minDecScale={2} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="StopGainL2" // subtype
							stepSize={
								bullet.content.CustomParameters.TriggerEntryType === 1
									? bullet.StrategyInfos[0].minPriceIncrement || bullet.StrategyInfos[0].Cst_MinPriceIncrement
									: triggerStep
							} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.StopGainL2) || bullet.content.CustomParameters.StopGainL2 === undefined
									? 0
									: bullet.content.CustomParameters.StopGainL2
							} // valor a ser modificado
							value={bullet.content.CustomParameters.StopGainL2 ?? 0}
							onChange={e => handleChange('Custom', 'StopGainL2', parseFloat(e.value), 0)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={enableStop}
							label="Quantidade"
							minVal={0}
							maxVal={bullet.content.CustomParameters.OrderQuantity || bullet.content.StrategyLegs[0].LegQuantity}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="OrderQuantityL2" // subtype
							stepSize={bullet.StrategyInfos[0].roundLot || bullet.StrategyInfos[0].Cst_MinOrderQuantity} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.OrderQuantityL2) || bullet.content.CustomParameters.OrderQuantityL2 === undefined
									? 0
									: bullet.content.CustomParameters.OrderQuantityL2
							} // valor a ser modificado
							value={bullet.content.CustomParameters.OrderQuantityL2 ?? 0}
							onChange={e => handleChange('Custom', 'OrderQuantityL2', parseInt(e.value), 0)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={bullet.content.CustomParameters.UseLevel3 === 'N' || !bullet.content.CustomParameters.UseLevel3}
							label="Gain"
							iconTooltip={t('bullets.general.stop_gain')}
							minVal={0}
							maxVal={bullet.content.CustomParameters.TriggerEntryType === 1 ? 999999 : 100.0}
							minDecScale={2} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="StopGainL3" // subtype
							stepSize={
								bullet.content.CustomParameters.TriggerEntryType === 1
									? bullet.StrategyInfos[0].minPriceIncrement || bullet.StrategyInfos[0].Cst_MinPriceIncrement
									: triggerStep
							} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.StopGainL3) || bullet.content.CustomParameters.StopGainL3 === undefined
									? 0
									: bullet.content.CustomParameters.StopGainL3
							} // valor a ser modificado
							value={bullet.content.CustomParameters.StopGainL3 ?? 0}
							onChange={e => handleChange('Custom', 'StopGainL3', parseFloat(e.value), 0)}
						/>
					</Grid>
					<Grid item xs={2}>
						<DecimalInput
							disabled={bullet.content.CustomParameters.UseLevel3 === 'N' || !bullet.content.CustomParameters.UseLevel3}
							label="Quantidade"
							minVal={0}
							maxVal={bullet.content.CustomParameters.OrderQuantity || bullet.content.StrategyLegs[0].LegQuantity}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="OrderQuantityL3" // subtype
							stepSize={bullet.StrategyInfos[0].roundLot || bullet.StrategyInfos[0].Cst_MinOrderQuantity} // incremento
							valueToStep={
								isNaN(bullet.content.CustomParameters.OrderQuantityL3) || bullet.content.CustomParameters.OrderQuantityL3 === undefined
									? 0
									: bullet.content.CustomParameters.OrderQuantityL3
							} // valor a ser modificado
							value={bullet.content.CustomParameters.OrderQuantityL3 ?? 0}
							onChange={e => handleChange('Custom', 'OrderQuantityL3', parseInt(e.value), 0)}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Stop;
