import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store';
import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { RangeGrid, TittleGrid, TittleTypo } from './style';

const GradientStrategyRisk = () => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const [risk, setRisk] = useState(0);
	const [isByPrice, setIsByPrice] = useState(true);
	const [isWithinRange, setIsWithinRange] = useState(true);

	function formatNumber(value, decimal) {
		value = +value;
		if (value) {
			value = +value.toFixed(decimal);
			value = value.toLocaleString('de-DE', {
				minimumFractionDigits: decimal,
				maximumFractionDigits: decimal,
			});
			return value;
		}
	}

	const calcFinancStopLoss = () => {
		const initialRange = bullet.content.CustomParameters.EntrancePrice;
		const rangesEnd =
            bullet.content.StrategyLegs[0].LegSide === '1'
            	? initialRange -
                  (bullet.content.CustomParameters.NumberOfEntrances - 1) *
                      bullet.content.CustomParameters.IncrementPriceStepEntrance
            	: initialRange +
                  (bullet.content.CustomParameters.NumberOfEntrances - 1) *
                      bullet.content.CustomParameters
                      	.IncrementPriceStepEntrance;
		const stopLossByPrice = bullet.content.CustomParameters.StopByPrice1;
		const maxExpo =
            bullet.content.CustomParameters.OrderQuantity *
            bullet.content.CustomParameters.NumberOfEntrances;
		const tickValue = bullet.StrategyInfos[0].contractMultiplier;
		const stopLossFinance = bullet.content.CustomParameters.StopLossFinance;

		const stopFinance =
            (initialRange - (+initialRange - rangesEnd) / 2 - stopLossByPrice) *
                (maxExpo * tickValue) <
            0
            	? (initialRange -
                      (+initialRange - rangesEnd) / 2 -
                      stopLossByPrice) *
                  (maxExpo * tickValue) *
                  -1
            	: (initialRange -
                      (+initialRange - rangesEnd) / 2 -
                      stopLossByPrice) *
                  (maxExpo * tickValue);
		if (stopFinance < stopLossFinance) {
			if (!isByPrice) {
				setIsByPrice(true);
			}
			return stopFinance;
		}
		if (isByPrice) {
			setIsByPrice(false);
		}
		return stopLossFinance;
	};

	const calcAvg = () => {
		let total = 0;
		if (bullet.content.StrategyLegs[0].LegSide === '1') {
			for (
				var i = 0;
				i < bullet.content.CustomParameters.NumberOfEntrances;
				i++
			) {
				total +=
                    bullet.content.CustomParameters.EntrancePrice -
                    bullet.content.CustomParameters.IncrementPriceStepEntrance *
                        i;
			}
		} else {
			for (
				var x = 0;
				x < bullet.content.CustomParameters.NumberOfEntrances;
				x++
			) {
				total +=
                    bullet.content.CustomParameters.EntrancePrice +
                    bullet.content.CustomParameters.IncrementPriceStepEntrance *
                        x;
			}
		}
		return total / bullet.content.CustomParameters.NumberOfEntrances;
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			const newBullet = store.getState().bottomBullet.bullet;
			if (newBullet.content.CustomParameters.IncrementPriceStepEntrance) {
				const maxExpo =
                    newBullet.content.CustomParameters.OrderQuantity *
                    newBullet.content.CustomParameters.NumberOfEntrances;
				const initialRange =
                    newBullet.content.CustomParameters.EntrancePrice;
				const rangesEnd =
                    newBullet.content.StrategyLegs[0].LegSide === '1'
                    	? initialRange -
                          (newBullet.content.CustomParameters
                          	.NumberOfEntrances -
                              1) *
                              newBullet.content.CustomParameters
                              	.IncrementPriceStepEntrance
                    	: initialRange +
                          (newBullet.content.CustomParameters
                          	.NumberOfEntrances -
                              1) *
                              newBullet.content.CustomParameters
                              	.IncrementPriceStepEntrance;
				const stopLossPrice =
                    newBullet.content.StrategyLegs[0].LegSide === '1'
                    	? rangesEnd -
                          newBullet.content.CustomParameters
                          	.IncrementPriceStepEntrance *
                              2
                    	: rangesEnd +
                          newBullet.content.CustomParameters
                          	.IncrementPriceStepEntrance *
                              2;
				if (newBullet.content.StrategyLegs[0].LegSide === '1') {
					setRisk(
						(initialRange -
                            (+initialRange - rangesEnd) / 2 -
                            stopLossPrice) *
                            (maxExpo *
                                newBullet.StrategyInfos[0].contractMultiplier) *
                            -1,
					);
				} else {
					setRisk(
						(stopLossPrice -
                            (initialRange + (+rangesEnd - initialRange) / 2)) *
                            (maxExpo *
                                newBullet.StrategyInfos[0].contractMultiplier) *
                            -1,
					);
				}
			}
		}, 500);
		return () => clearInterval(intervalId);
	}, []);

	const selectColor = () => {
		if (isByPrice) {
			if (bullet.content.StrategyLegs[0].LegSide === '1')
				if (
					formatNumber(
						Math.round(
							parseFloat(
								bullet.content.CustomParameters.EntrancePrice -
                                    (bullet.content.CustomParameters
                                    	.NumberOfEntrances -
                                        1) *
                                        bullet.content.CustomParameters
                                        	.IncrementPriceStepEntrance,
							) / bullet.StrategyInfos[0].minPriceIncrement ||
                                bullet.StrategyInfos[0].Cst_MinPriceIncrement,
						) * bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
						bullet.StrategyInfos[0].tickSizeDenominator,
					) <=
                    formatNumber(
                    	bullet.content.CustomParameters.StopByPrice1,
                    	bullet.StrategyInfos[0].tickSizeDenominator,
                    )
				) {
					if (!isWithinRange) setIsWithinRange(true);
					return '#ff5a5a';
				} else if (
					formatNumber(
						Math.round(
							parseFloat(
								bullet.content.CustomParameters.EntrancePrice -
                                    (bullet.content.CustomParameters
                                    	.NumberOfEntrances -
                                        1) *
                                        bullet.content.CustomParameters
                                        	.IncrementPriceStepEntrance -
                                    4 *
                                        bullet.content.CustomParameters
                                        	.IncrementPriceStepEntrance,
							) / bullet.StrategyInfos[0].minPriceIncrement ||
                                bullet.StrategyInfos[0].Cst_MinPriceIncrement,
						) * bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
						bullet.StrategyInfos[0].tickSizeDenominator,
					) <=
                    formatNumber(
                    	bullet.content.CustomParameters.StopByPrice1,
                    	bullet.StrategyInfos[0].tickSizeDenominator,
                    )
				) {
					if (isWithinRange) setIsWithinRange(false);
					return '#388E3C';
				} else {
					if (isWithinRange) setIsWithinRange(false);
					return '#F57C00';
				}
			else if (
				formatNumber(
					Math.round(
						parseFloat(
							bullet.content.CustomParameters.EntrancePrice +
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances -
                                    1) *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance,
						) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					bullet.StrategyInfos[0].tickSizeDenominator,
				) >=
                formatNumber(
                	bullet.content.CustomParameters.StopByPrice1,
                	bullet.StrategyInfos[0].tickSizeDenominator,
                )
			) {
				if (!isWithinRange) setIsWithinRange(true);
				return '#ff5a5a';
			} else if (
				formatNumber(
					Math.round(
						parseFloat(
							bullet.content.CustomParameters.EntrancePrice +
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances -
                                    1) *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance +
                                4 *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance,
						) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					bullet.StrategyInfos[0].tickSizeDenominator,
				) >=
                formatNumber(
                	bullet.content.CustomParameters.StopByPrice1,
                	bullet.StrategyInfos[0].tickSizeDenominator,
                )
			) {
				if (isWithinRange) setIsWithinRange(false);
				return '#388E3C';
			} else {
				if (isWithinRange) setIsWithinRange(false);
				return '#F57C00';
			}
		} else if (bullet.content.StrategyLegs[0].LegSide === '1')
			if (
				formatNumber(
					Math.round(
						parseFloat(
							bullet.content.CustomParameters.EntrancePrice -
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances -
                                    1) *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance,
						) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					bullet.StrategyInfos[0].tickSizeDenominator,
				) <=
                formatNumber(
                	Math.round(
                		parseFloat(
                			calcAvg() -
                                (-calcFinancStopLoss(bullet) * -1) /
                                    (bullet.content.CustomParameters
                                    	.NumberOfEntrances *
                                        bullet.content.CustomParameters
                                        	.OrderQuantity *
                                        bullet.StrategyInfos[0]
                                        	.contractMultiplier),
                		) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
                	) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
                	bullet.StrategyInfos[0].tickSizeDenominator,
                )
			) {
				if (!isWithinRange) setIsWithinRange(true);
				return '#ff5a5a';
			} else if (
				formatNumber(
					Math.round(
						parseFloat(
							bullet.content.CustomParameters.EntrancePrice -
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances -
                                    1) *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance -
                                4 *
                                    bullet.content.CustomParameters
                                    	.IncrementPriceStepEntrance,
						) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
					bullet.StrategyInfos[0].tickSizeDenominator,
				) <=
                formatNumber(
                	Math.round(
                		parseFloat(
                			calcAvg() -
                                (-calcFinancStopLoss(bullet) * -1) /
                                    (bullet.content.CustomParameters
                                    	.NumberOfEntrances *
                                        bullet.content.CustomParameters
                                        	.OrderQuantity *
                                        bullet.StrategyInfos[0]
                                        	.contractMultiplier),
                		) / bullet.StrategyInfos[0].minPriceIncrement ||
                            bullet.StrategyInfos[0].Cst_MinPriceIncrement,
                	) * bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
                	bullet.StrategyInfos[0].tickSizeDenominator,
                )
			) {
				if (isWithinRange) setIsWithinRange(false);
				return '#388E3C';
			} else {
				if (isWithinRange) setIsWithinRange(false);
				return '#F57C00';
			}
		else if (
			formatNumber(
				Math.round(
					parseFloat(
						bullet.content.CustomParameters.EntrancePrice +
                            (bullet.content.CustomParameters.NumberOfEntrances -
                                1) *
                                bullet.content.CustomParameters
                                	.IncrementPriceStepEntrance,
					) / bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				) * bullet.StrategyInfos[0].minPriceIncrement ||
                    bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				bullet.StrategyInfos[0].tickSizeDenominator,
			) >=
            formatNumber(
            	Math.round(
            		parseFloat(
            			calcAvg() +
                            (-calcFinancStopLoss(bullet) * -1) /
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances *
                                    bullet.content.CustomParameters
                                    	.OrderQuantity *
                                    bullet.StrategyInfos[0].contractMultiplier),
            		) / bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
            	) * bullet.StrategyInfos[0].minPriceIncrement ||
                    bullet.StrategyInfos[0].Cst_MinPriceIncrement,
            	bullet.StrategyInfos[0].tickSizeDenominator,
            )
		) {
			if (!isWithinRange) setIsWithinRange(true);
			return '#ff5a5a';
		} else if (
			formatNumber(
				Math.round(
					parseFloat(
						bullet.content.CustomParameters.EntrancePrice +
                            (bullet.content.CustomParameters.NumberOfEntrances -
                                1) *
                                bullet.content.CustomParameters
                                	.IncrementPriceStepEntrance +
                            4 *
                                bullet.content.CustomParameters
                                	.IncrementPriceStepEntrance,
					) / bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				) * bullet.StrategyInfos[0].minPriceIncrement ||
                    bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				bullet.StrategyInfos[0].tickSizeDenominator,
			) >=
            formatNumber(
            	Math.round(
            		parseFloat(
            			calcAvg() +
                            (-calcFinancStopLoss(bullet) * -1) /
                                (bullet.content.CustomParameters
                                	.NumberOfEntrances *
                                    bullet.content.CustomParameters
                                    	.OrderQuantity *
                                    bullet.StrategyInfos[0].contractMultiplier),
            		) / bullet.StrategyInfos[0].minPriceIncrement ||
                        bullet.StrategyInfos[0].Cst_MinPriceIncrement,
            	) * bullet.StrategyInfos[0].minPriceIncrement ||
                    bullet.StrategyInfos[0].Cst_MinPriceIncrement,
            	bullet.StrategyInfos[0].tickSizeDenominator,
            )
		) {
			if (isWithinRange) setIsWithinRange(false);
			return '#388E3C';
		} else {
			if (isWithinRange) setIsWithinRange(false);
			return '#F57C00';
		}
	};

	return (
		<>
			<RangeGrid item container xs={12}>
				<TittleGrid item xs={12}>
					<TittleTypo variant="subtitle1">
                        Estimativa de risco inicial
					</TittleTypo>
				</TittleGrid>

				<Grid item xs={7}>
					<Tooltip title=''>
						<Box pl={1}>
							<Typography>Stop Loss financeiro (R$)</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Grid item xs={5}>
					<Tooltip title=''>
						<Box
							pr={1}
							textAlign="right"
							style={{
								background: selectColor(),
								borderRadius: '3px',
								padding: '0px 5px',
							}}
						>
							<Typography>
								{formatNumber(-calcFinancStopLoss(bullet), 2) ??
                                    0}
							</Typography>
						</Box>
					</Tooltip>
				</Grid>

				<Tooltip title=''>
					<Grid item xs={7}>
						<Box pl={1}>
							<Typography>Risco do range (R$)</Typography>
						</Box>
					</Grid>
				</Tooltip>
				<Grid item xs={5}>
					<Tooltip title=''>
						<Box color="error.main" pr={1} textAlign="right">
							<Typography>{formatNumber(risk, 2)}</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Tooltip title="">
					<Grid item xs={7}>
						<Box pl={1}>
							<Typography>
                                Exposição máxima de contratos
							</Typography>
						</Box>
					</Grid>
				</Tooltip>
				<Grid item xs={5}>
					<Tooltip title="">
						<Box textAlign="right" pr={1}>
							<Typography>
								{bullet.content.CustomParameters
									.IncrementPriceStepEntrance
									? isNaN(
										bullet.content.CustomParameters
											.NumberOfEntrances *
                                              bullet.content.CustomParameters
                                              	.OrderQuantity,
									)
										? 0
										: formatNumber(
											bullet.content.CustomParameters
												.NumberOfEntrances *
                                                  bullet.content
                                                  	.CustomParameters
                                                  	.OrderQuantity,
											0,
										)
									: 0}
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Tooltip title="">
					<Grid item xs={7}>
						<Box pl={1}>
							<Typography>Preço de início do range</Typography>
						</Box>
					</Grid>
				</Tooltip>
				<Grid item xs={5}>
					<Tooltip title="">
						<Box pr={1} textAlign="right">
							<Typography>
								{formatNumber(
									bullet.content.CustomParameters
										.EntrancePrice,
									bullet.StrategyInfos[0].tickSizeDenominator,
								) ?? 0}
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Tooltip title="">
					<Grid item xs={7}>
						<Box pl={1}>
							<Typography>Preço de fim do range</Typography>
						</Box>
					</Grid>
				</Tooltip>
				<Grid item xs={5}>
					<Tooltip title="">
						<Box pr={1} textAlign="right">
							<Typography>
								{bullet.content.CustomParameters
									.IncrementPriceStepEntrance
									? bullet.content.StrategyLegs[0].LegSide ===
                                      '1'
										? formatNumber(
											bullet.content.CustomParameters
												.EntrancePrice -
                                                  bullet.content
                                                  	.CustomParameters
                                                  	.IncrementPriceStepEntrance *
                                                      (bullet.content
                                                      	.CustomParameters
                                                      	.NumberOfEntrances -
                                                          1),
											bullet.StrategyInfos[0]
												.tickSizeDenominator,
										)
										: formatNumber(
											bullet.content.CustomParameters
												.EntrancePrice +
                                                  bullet.content
                                                  	.CustomParameters
                                                  	.IncrementPriceStepEntrance *
                                                      (bullet.content
                                                      	.CustomParameters
                                                      	.NumberOfEntrances -
                                                          1),
											bullet.StrategyInfos[0]
												.tickSizeDenominator,
										)
									: 0}
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
				<Tooltip title=''>
					<Grid item xs={7}>
						<Box pl={1}>
							<Typography>Preço de Stop Loss</Typography>
						</Box>
					</Grid>
				</Tooltip>

				<Grid item xs={5}>
					<Tooltip title=''>
						<Box pr={1} textAlign="right" color="error.main">
							<Typography>
								{bullet.content.CustomParameters
									.IncrementPriceStepEntrance
									? bullet.content.StrategyLegs[0].LegSide ===
                                      '1'
										? formatNumber(
											bullet.content.CustomParameters
												.StopByPrice1,
											bullet.StrategyInfos[0]
												.tickSizeDenominator,
										) >
                                          formatNumber(
                                          	Math.round(
                                          		parseFloat(
                                          			calcAvg() -
                                                          (-calcFinancStopLoss() *
                                                              -1) /
                                                              (bullet.content
                                                              	.CustomParameters
                                                              	.NumberOfEntrances *
                                                                  bullet.content
                                                                  	.CustomParameters
                                                                  	.OrderQuantity *
                                                                  bullet
                                                                  	.StrategyInfos[0]
                                                                  	.contractMultiplier),
                                          		) /
                                                      bullet.StrategyInfos[0]
                                                      	.minPriceIncrement ||
                                                      bullet.StrategyInfos[0]
                                                      	.Cst_MinPriceIncrement,
                                          	) *
                                                  bullet.StrategyInfos[0]
                                                  	.minPriceIncrement ||
                                                  bullet.StrategyInfos[0]
                                                  	.Cst_MinPriceIncrement,
                                          	bullet.StrategyInfos[0]
                                          		.tickSizeDenominator,
                                          )
											? formatNumber(
												bullet.content
													.CustomParameters
													.StopByPrice1,
												bullet.StrategyInfos[0]
													.tickSizeDenominator,
											)
											: formatNumber(
												Math.round(
													parseFloat(
														calcAvg() -
                                                              (-calcFinancStopLoss() *
                                                                  -1) /
                                                                  (bullet
                                                                  	.content
                                                                  	.CustomParameters
                                                                  	.NumberOfEntrances *
                                                                      bullet
                                                                      	.content
                                                                      	.CustomParameters
                                                                      	.OrderQuantity *
                                                                      bullet
                                                                      	.StrategyInfos[0]
                                                                      	.contractMultiplier),
													) /
                                                          bullet
                                                          	.StrategyInfos[0]
                                                          	.minPriceIncrement ||
                                                          bullet
                                                          	.StrategyInfos[0]
                                                          	.Cst_MinPriceIncrement,
												) *
                                                      bullet.StrategyInfos[0]
                                                      	.minPriceIncrement ||
                                                      bullet.StrategyInfos[0]
                                                      	.Cst_MinPriceIncrement,
												bullet.StrategyInfos[0]
													.tickSizeDenominator,
											)
										: !bullet.content.CustomParameters
											.StopByPrice1
											? formatNumber(
												Math.round(
													parseFloat(
														calcAvg() +
                                                          (-calcFinancStopLoss() *
                                                              -1) /
                                                              (bullet.content
                                                              	.CustomParameters
                                                              	.NumberOfEntrances *
                                                                  bullet.content
                                                                  	.CustomParameters
                                                                  	.OrderQuantity *
                                                                  bullet
                                                                  	.StrategyInfos[0]
                                                                  	.contractMultiplier),
													) /
                                                      bullet.StrategyInfos[0]
                                                      	.minPriceIncrement ||
                                                      bullet.StrategyInfos[0]
                                                      	.Cst_MinPriceIncrement,
												) *
                                                  bullet.StrategyInfos[0]
                                                  	.minPriceIncrement ||
                                                  bullet.StrategyInfos[0]
                                                  	.Cst_MinPriceIncrement,
												bullet.StrategyInfos[0]
													.tickSizeDenominator,
											)
											: formatNumber(
												bullet.content.CustomParameters
													.StopByPrice1,
												bullet.StrategyInfos[0]
													.tickSizeDenominator,
											) <
                                          formatNumber(
                                          	Math.round(
                                          		parseFloat(
                                          			calcAvg() +
                                                          (-calcFinancStopLoss() *
                                                              -1) /
                                                              (bullet.content
                                                              	.CustomParameters
                                                              	.NumberOfEntrances *
                                                                  bullet.content
                                                                  	.CustomParameters
                                                                  	.OrderQuantity *
                                                                  bullet
                                                                  	.StrategyInfos[0]
                                                                  	.contractMultiplier),
                                          		) /
                                                      bullet.StrategyInfos[0]
                                                      	.minPriceIncrement ||
                                                      bullet.StrategyInfos[0]
                                                      	.Cst_MinPriceIncrement,
                                          	) *
                                                  bullet.StrategyInfos[0]
                                                  	.minPriceIncrement ||
                                                  bullet.StrategyInfos[0]
                                                  	.Cst_MinPriceIncrement,
                                          	bullet.StrategyInfos[0]
                                          		.tickSizeDenominator,
                                          )
												? formatNumber(
													bullet.content.CustomParameters
														.StopByPrice1,
													bullet.StrategyInfos[0]
														.tickSizeDenominator,
												)
												: formatNumber(
													Math.round(
														parseFloat(
															calcAvg() +
                                                          (-calcFinancStopLoss() *
                                                              -1) /
                                                              (bullet.content
                                                              	.CustomParameters
                                                              	.NumberOfEntrances *
                                                                  bullet.content
                                                                  	.CustomParameters
                                                                  	.OrderQuantity *
                                                                  bullet
                                                                  	.StrategyInfos[0]
                                                                  	.contractMultiplier),
														) /
                                                      bullet.StrategyInfos[0]
                                                      	.minPriceIncrement ||
                                                      bullet.StrategyInfos[0]
                                                      	.Cst_MinPriceIncrement,
													) *
                                                  bullet.StrategyInfos[0]
                                                  	.minPriceIncrement ||
                                                  bullet.StrategyInfos[0]
                                                  	.Cst_MinPriceIncrement,
													bullet.StrategyInfos[0]
														.tickSizeDenominator,
												)
									: 0}
							</Typography>
						</Box>
					</Tooltip>
				</Grid>
				{isWithinRange && (
					<Grid item xs={7}>
						<Box color="warning.main">
							<Typography>
                                Preço de Stop Loss dentro do range
							</Typography>
						</Box>
					</Grid>
				)}
			</RangeGrid>
		</>
	);
};

export default GradientStrategyRisk;
