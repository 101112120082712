import { createChart, TickMarkType, LineStyle } from 'lightweight-charts';
import { useSelector, useDispatch } from 'react-redux';
import {
	setVisibleRange,
	setPriceRange,
	setChartData,
	updateChartData,
	setScrollPosition,
} from 'store/modules/idxLiveCharts/actions';
import React, {
	useEffect,
	useRef,
	useState,
	useImperativeHandle,
	forwardRef,
} from 'react';
import { format, addDays, isWeekend } from 'date-fns';
import { api, apiMarketData } from 'services/api';
import { handleSubscription } from 'utils/actionListener';
import { IconButton } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { LightTooltip } from 'components/LightTooltip';
import { store } from 'store';

const DATE_FORMAT = 'yyyy-MM-dd';

const getCurrentDateTime = date => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

function setLastBusinessDay(date) {
	const holidays = store.getState().configs.holidays;
	let newdate = addDays(date, -1);

	let isHoliday = holidays.includes(format(newdate, DATE_FORMAT));

	while (isWeekend(newdate) || isHoliday) {
		newdate = addDays(newdate, -1);
		isHoliday = holidays.includes(format(newdate, DATE_FORMAT));
	}

	return newdate;
}

function valueLabelFormat(value) {
	if (!value) {
		value = 0.0;
	}
	return `${value.toLocaleString('pt-BR', {
		maximumFractionDigits: 2,
	})}`;
}

function candlesQueryParams(symbol, dateInit, dateEnd) {
	return {
		params: {
			symbol: symbol,
			startDateTime: getCurrentDateTime(dateInit),
			endDateTime: getCurrentDateTime(dateEnd),
			timeframe: '15m',
			isContinuos: true,
		},
	};
}

const IdxChart = forwardRef(
	(
		{
			id,
			symbol,
			bandUpperPerc,
			bandLowerPerc,
			toggleCount,
			fractionDigits,
			setContinuousSymbol,
			setLastInteractSymbol,
			registerCallback,
			unRegisterCallback,
		},
		ref
	) => {
		const dispatch = useDispatch();

		const visibleRange = useSelector(
			state => state.idxLiveCharts.visibleRange[id]
		);
		const priceRange = useSelector(
			state => state.idxLiveCharts.priceRange[id]
		);
		const scrollPosition = useSelector(
			state => state.idxLiveCharts.scrollPosition[id] || 5
		);
		const chartData = useSelector(
			state => state.idxLiveCharts.chartData[id] || []
		);

		const chartContainerRef = useRef();

		const crossLegendRef = useRef();
		const [crossBar, setCrossBar] = useState();

		const resetChartRef = useRef();
		const chart = useRef();
		const resizeObserver = useRef();
		const candleSeries = useRef();
		const prevClosePriceRef = useRef();
		const stepSizeRef = useRef();

		const upperBandSeriesRef = useRef();
		const lowerBandSeriesRef = useRef();
		const candleFramesRef = useRef('15m');
		const priceLinesRef = useRef({});

		const askPx = useRef(0.0);
		const bidPx = useRef(0.0);
		const contractMultiplier = useRef(0.0);
		const continuosRef = useRef();

		const dateInit = useRef(new Date());
		const dateEnd = useRef(new Date());

		const resetting = useRef(false);

		const {
			isOpen: isBulletOpen,
			subUid,
			category,
		} = useSelector(state => state.bottomBullet.bullet);

		const widgetOpen = useSelector(state => state.configs.widgetBar);

		async function getCandles(adjust) {
			api.get(
				'/candles',
				candlesQueryParams(symbol, dateInit.current, dateEnd.current)
			).then(response => {
				if (
					response.data['records'] &&
					response.data['records'].length > 0
				) {
					let combinedData = {};
					if (!resetting.current) {
						combinedData = chartData.concat(
							response.data['records']
						);
					} else {
						combinedData = response.data['records'];
					}

					const sortedData = combinedData.sort((a, b) => {
						if (a.time === b.time) {
							return b.volume - a.volume;
						}
						return a.time - b.time;
					});

					const uniqueData = sortedData.reduce((acc, current) => {
						const found = acc.find(
							item => item.time === current.time
						);
						if (!found) {
							acc.push(current);
						}
						return acc;
					}, []);
					dispatch(setChartData(id, uniqueData));

					candleSeries.current.setData(uniqueData);

					if (adjust) {
						const numberOfCandlesToShow = Math.min(
							response.data['records'].length,
							50
						);

						// ajustando range de candles para quando for realizada a carga inicial
						chart.current.timeScale().setVisibleRange({
							from: response.data['records'][
								response.data['records'].length -
									numberOfCandlesToShow
							].time, // inicio do range
							to: response.data['records'][
								response.data['records'].length - 1
							].time, // fim do range
						});
						// }

						if (priceRange) {
							candleSeries.current.applyOptions({
								autoscaleInfoProvider: () => {
									return {
										priceRange: {
											minValue: priceRange.minValue,
											maxValue: priceRange.maxValue,
										},
									};
								},
							});
						}

						chart.current
							.timeScale()
							.scrollToPosition(scrollPosition, false);

						setTimeout(() => {
							// refaz as escalas de preço/tempo a partir do redux
							if (visibleRange) {
								chart.current
									.timeScale()
									.setVisibleLogicalRange({
										from: visibleRange.from,
										to: visibleRange.to,
									});
							}

							if (priceRange) {
								chart.current.priceScale('right').applyOptions({
									autoScale: false,
								});
								candleSeries.current.applyOptions({
									priceScaleId: 'right',
									priceScale: {
										autoScale: false,
									},
									autoscaleInfoProvider: () => {
										return {
											priceRange: {
												minValue: priceRange.minValue,
												maxValue: priceRange.maxValue,
											},
										};
									},
								});
							}
						}, 1000);

						resetting.current = false;
					}

					const upperBandData = uniqueData.map(point => ({
						time: point.time,
						value:
							Math.round(
								(prevClosePriceRef.current +
									bandUpperPerc * prevClosePriceRef.current) /
									stepSizeRef.current
							) * stepSizeRef.current,
					}));
					upperBandSeriesRef.current.setData(upperBandData);

					const lowerBandData = uniqueData.map(point => ({
						time: point.time,
						value:
							Math.round(
								(prevClosePriceRef.current -
									bandLowerPerc * prevClosePriceRef.current) /
									stepSizeRef.current
							) * stepSizeRef.current,
					}));
					lowerBandSeriesRef.current.setData(lowerBandData);
				}
			});
		}

		function subscribeMarketData(symbol) {
			const props = {
				action: 'subscribe',
				type: 'book',
				key: symbol,
				callbackFunction: msg => {
					if (['M', 'T'].includes(msg?.msgType)) return;
					askPx.current = msg.askPrices[0];
					bidPx.current = msg.bidPrices[0];
				},
			};

			return handleSubscription(
				props,
				registerCallback,
				unRegisterCallback
			);
		}

		function subscribeCandle(symbol, timeframe) {
			const props = {
				action: 'subscribe',
				type: 'candle',
				key: `${symbol}_${timeframe}`,
				callbackFunction: data => {
					candleSeries.current.update(data);

					dispatch(updateChartData(id, data));

					const lowerBandPoint = {
						time: data.time,
						value:
							Math.round(
								(prevClosePriceRef.current -
									bandLowerPerc * prevClosePriceRef.current) /
									stepSizeRef.current
							) * stepSizeRef.current,
					};

					const upperBandPoint = {
						time: data.time,
						value:
							Math.round(
								(prevClosePriceRef.current +
									bandUpperPerc * prevClosePriceRef.current) /
									stepSizeRef.current
							) * stepSizeRef.current,
					};

					lowerBandSeriesRef.current.update(lowerBandPoint);
					upperBandSeriesRef.current.update(upperBandPoint);
				},
			};

			return handleSubscription(
				props,
				registerCallback,
				unRegisterCallback
			);
		}

		function unsubscribe(id) {
			const props = {
				action: 'unsubscribe',
				id: id,
			};

			handleSubscription(props, registerCallback, unRegisterCallback);
		}

		useEffect(() => {
			chart.current = createChart(chartContainerRef.current);

			chart.current.applyOptions({
				width: chartContainerRef.current.clientWidth,
				height: chartContainerRef.current.clientHeight,
				layout: {
					background: {
						color: '#222',
					},
					textColor: '#DDD',
				},
				grid: {
					vertLines: {
						visible: false,
					},
					horzLines: {
						visible: false,
					},
				},
				watermark: {
					visible: true,
					fontSize: 70,
					horzAlign: 'center',
					vertAlign: 'center',
					color: 'rgba(171, 71, 188, 0.2)',
					text: '[' + symbol + ']',
				},
				localization: {
					locale: 'pt-BR',
					timeFormatter: timestamp => {
						const date = new Date(timestamp * 1000);

						const day = String(date.getDate()).padStart(2, '0');
						const year = String(date.getFullYear()).slice(-2);

						const month = date.toLocaleString('pt-BR', {
							month: 'short',
						});

						const time = date.toLocaleString('pt-BR', {
							hour: '2-digit',
							hour12: false,
							minute: '2-digit',
							second: '2-digit',
						});

						return `${day}-${month}-${year} ${time}`;
					},
				},
			});

			chart.current.priceScale('right').applyOptions({
				borderColor: 'red',
			});

			chart.current.timeScale().applyOptions({
				borderColor: 'red',
				rightOffset: 5,
				fixLeftEdge: true,
				timeVisible: true,
				secondsVisible: true,
				tickMarkFormatter: (time, tickMarkType, locale) => {
					const date = new Date(time * 1000);

					switch (tickMarkType) {
						case TickMarkType.Year:
							return date.getFullYear();

						case TickMarkType.Month: {
							const monthFormatter = new Intl.DateTimeFormat(
								locale,
								{
									month: 'short',
								}
							);

							return monthFormatter.format(date);
						}

						case TickMarkType.DayOfMonth: {
							const day = String(date.getDate()).padStart(2, '0');
							const month = date.toLocaleString(locale, {
								month: 'short',
							});

							const time = date.toLocaleString(locale, {
								hour: '2-digit',
								hour12: false,
								minute: '2-digit',
							});

							return `${day}-${month} ${time}`;
						}

						case TickMarkType.Time: {
							const timeFormatter = new Intl.DateTimeFormat(
								locale,
								{
									hour: 'numeric',
									minute: 'numeric',
								}
							);

							return timeFormatter.format(date);
						}

						case TickMarkType.TimeWithSeconds: {
							const timeWithSecondsFormatter =
								new Intl.DateTimeFormat(locale, {
									hour: 'numeric',
									minute: 'numeric',
									second: 'numeric',
								});

							return timeWithSecondsFormatter.format(date);
						}

						default:
							console.log('No options for TickMarkType format');
					}
				},
			});

			chart.current.timeScale().fitContent();

			candleSeries.current = chart.current.addCandlestickSeries({
				priceScaleId: 'right',
				wickUpColor: 'rgb(54, 116, 217)',
				upColor: 'rgb(54, 116, 217)',
				wickDownColor: 'rgb(225, 50, 85)',
				downColor: 'rgb(225, 50, 85)',
				borderVisible: false,
				priceFormat: {
					type: 'custom',
					formatter: price => {
						return new Intl.NumberFormat('pt-BR', {
							minimumFractionDigits: fractionDigits,
							maximumFractionDigits: fractionDigits,
						}).format(price);
					},
				},
			});

			upperBandSeriesRef.current = chart.current.addLineSeries();
			upperBandSeriesRef.current.applyOptions({
				color: '#DB9A23',
				lineWidth: 1,
			});
			lowerBandSeriesRef.current = chart.current.addLineSeries();
			lowerBandSeriesRef.current.applyOptions({
				color: '#DB9A23',
				lineWidth: 1,
			});

			let newDate = new Date();
			dateEnd.current.setFullYear(
				newDate.getFullYear(),
				newDate.getMonth(),
				newDate.getDate()
			);

			newDate = setLastBusinessDay(newDate);

			dateInit.current.setFullYear(
				newDate.getFullYear(),
				newDate.getMonth(),
				newDate.getDate()
			);

			const currentUTCHours = dateInit.current.getUTCHours();
			dateInit.current.setUTCHours(currentUTCHours - 3);
			dateInit.current.setHours(7, 0, 0);
			dateEnd.current.setUTCHours(currentUTCHours - 3);
			dateEnd.current.setHours(20, 0, 0);

			getCandles(true);

			Object.keys(priceLinesRef.current).forEach(strategyId => {
				const currentPriceLines = priceLinesRef.current[strategyId];
				Object.keys(currentPriceLines).forEach(existingPrice => {
					const priceLineOptions =
						currentPriceLines[existingPrice].options();
					currentPriceLines[existingPrice] =
						candleSeries.current.createPriceLine(priceLineOptions);
				});
			});

			let candleId;
			let marketId;

			apiMarketData
				.get(`/exchanges/BVMF/instruments/${symbol}?detailed=1`)
				.then(instrument => {
					contractMultiplier.current =
						instrument.data.contractMultiplier;

					prevClosePriceRef.current = instrument.data.prevClosePx;
					stepSizeRef.current = instrument.data.minPriceIncrement;

					candleId = subscribeCandle(
						instrument.data.continuosRef,
						candleFramesRef.current
					);

					marketId = subscribeMarketData(
						instrument.data.continuosRef
					);

					setContinuousSymbol(
						instrument.data.continuosRef,
						symbol.slice(0, 3)
					);

					continuosRef.current = instrument.data.continuosRef;
				});

			chart.current.subscribeCrosshairMove(updateCrossLegend);

			return () => {
				dispatchChartRanges();

				unsubscribe(candleId);
				unsubscribe(marketId);
				chart.current.remove();
			};
		}, [
			symbol,
			toggleCount,
			isBulletOpen,
			subUid,
			category,
			widgetOpen,
			chartContainerRef?.current?.clientWidth,
			chartContainerRef?.current?.clientHeight,
		]);

		function dispatchChartRanges() {
			if (chartContainerRef.current !== null) {
				const containerHeight = chartContainerRef.current.clientHeight;

				const minPrice =
					candleSeries.current.coordinateToPrice(containerHeight);
				const maxPrice = candleSeries.current.coordinateToPrice(0);

				const price = {
					minValue: minPrice,
					maxValue: maxPrice,
				};
				dispatch(setPriceRange(id, price));

				const range = chart.current
					.timeScale()
					.getVisibleLogicalRange();

				if (range) {
					dispatch(setVisibleRange(id, range));

					if (range.from < 10) {
						dateInit.current = setLastBusinessDay(dateInit.current);

						dateEnd.current.setFullYear(
							dateInit.current.getFullYear(),
							dateInit.current.getMonth(),
							dateInit.current.getDate()
						);

						getCandles(false);
					}
				}

				const position = chart.current.timeScale().scrollPosition();

				if (position) {
					dispatch(setScrollPosition(id, position));
				}
			}
		}

		function adjustPositions() {
			const chartRect =
				chartContainerRef?.current.getBoundingClientRect();

			const top =
				chartRect.top +
				chartContainerRef?.current?.clientHeight -
				15 -
				resetChartRef.current.offsetHeight / 2;

			resetChartRef.current.style.top = `${
				top <= 0 ? chartRect.top : top
			}px`;

			resetChartRef.current.style.left = `${
				chartRect.right - resetChartRef.current.offsetWidth - 7
			}px`;

			crossLegendRef.current.style.top = `${chartRect.top + 10}px`;

			crossLegendRef.current.style.left = `${chartRect.left}px`;
		}

		const getLastBar = series => {
			return series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
		};

		const updateCrossLegend = param => {
			const validCrosshairPoint = !(
				param === undefined ||
				param.time === undefined ||
				param.point.x < 0 ||
				param.point.y < 0
			);

			const bar = validCrosshairPoint
				? param.seriesData.get(candleSeries.current)
				: getLastBar(candleSeries.current);

			setCrossBar(bar);
		};

		useEffect(() => {
			adjustPositions();

			return () => {};
		}, [
			chartContainerRef?.current?.clientWidth,
			chartContainerRef?.current?.clientHeight,
		]);

		// Resize chart on container resizes.
		useEffect(() => {
			resizeObserver.current = new ResizeObserver(entries => {
				const { width, height } = entries[0].contentRect;
				chart.current.applyOptions({ width, height });
			});

			resizeObserver.current.observe(chartContainerRef.current);

			return () => {
				resizeObserver.current.disconnect();
			};
		}, []);

		useImperativeHandle(ref, () => ({
			updatePriceLines(strategyData) {
				if (strategyData.symbol !== continuosRef.current) return;

				if (candleSeries.current && strategyData?.strategyId) {
					const { strategyId, prices } = strategyData;

					if (!priceLinesRef.current[strategyId]) {
						priceLinesRef.current[strategyId] = {};
					}

					const currentPriceLines = priceLinesRef.current[strategyId];
					const newPricesSet = new Set(prices.map(p => p.price));

					prices.forEach(({ quantity, price, side, type }) => {
						if (!currentPriceLines[price]) {
							currentPriceLines[price] =
								candleSeries.current.createPriceLine({
									price: 0,
									lineWidth: 1,
									lineStyle: LineStyle.LargeDashed,
								});
						}

						const typeStr =
							type === 'TP' ? `(${type})` : `  (${type})`;

						currentPriceLines[price].applyOptions({
							title: `${quantity} ${typeStr}`,
							price: price,
							color:
								side === '1'
									? type === 'TP'
										? '#2ED78F'
										: 'green'
									: type === 'TP'
										? '#D64F00'
										: 'red',
						});
					});

					Object.keys(currentPriceLines).forEach(existingPrice => {
						if (!newPricesSet.has(Number(existingPrice))) {
							candleSeries.current.removePriceLine(
								currentPriceLines[existingPrice]
							);
							delete currentPriceLines[existingPrice];
						}
					});
				}
			},
		}));

		function onClickMe() {
			// chart.current.priceScale('right').applyOptions({
			// 	autoScale: false,
			// });
			// candleSeries.current.applyOptions({
			// 	priceScaleId: 'right',
			// 	priceScale: {
			// 		autoScale: false,
			// 	},
			// 	autoscaleInfoProvider: () => {
			// 		return {
			// 			priceRange: {
			// 				minValue: priceRange.minValue,
			// 				maxValue: priceRange.maxValue,
			// 			},
			// 		};
			// 	},
			// });
			if (!resetting.current) {
				setLastInteractSymbol(continuosRef.current);
				dispatchChartRanges();
			}
		}

		function resetChartSetttings() {
			resetting.current = true;

			let newDate = new Date();
			dateEnd.current.setFullYear(
				newDate.getFullYear(),
				newDate.getMonth(),
				newDate.getDate()
			);

			newDate = setLastBusinessDay(newDate);

			dateInit.current.setFullYear(
				newDate.getFullYear(),
				newDate.getMonth(),
				newDate.getDate()
			);

			dispatch(setScrollPosition(id, null));
			dispatch(setPriceRange(id, null));
			dispatch(setVisibleRange(id, null));

			getCandles(true);

			chart.current.timeScale().fitContent();
		}

		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
				}}
				onClick={() => onClickMe()}
			>
				<div
					ref={chartContainerRef}
					className="chart-container"
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						backgroundColor: '#222',
					}}
				/>
				<div
					ref={crossLegendRef}
					style={{
						position: 'absolute',
						zIndex: 1,
						padding: '1px 4px',
						display: 'flex',
						gap: 5,
					}}
				>
					<div
						style={{
							padding: '1px 4px',
							display: 'flex',
							flexDirection: 'column',
							gap: 10,
						}}
					>
						<span style={{ color: 'white' }}>
							open: {valueLabelFormat(crossBar?.open)}
						</span>
						<span style={{ color: 'white' }}>
							close: {valueLabelFormat(crossBar?.close)}
						</span>
					</div>
					<div
						style={{
							padding: '1px 4px',
							display: 'flex',
							flexDirection: 'column',
							gap: 10,
						}}
					>
						<span style={{ color: 'white' }}>
							high: {valueLabelFormat(crossBar?.high)}
						</span>
						<span style={{ color: 'white' }}>
							low: {valueLabelFormat(crossBar?.low)}
						</span>
					</div>
				</div>
				<div
					ref={resetChartRef}
					style={{
						position: 'absolute',
						zIndex: 1,
						borderRadius: 2,
						padding: 0,
						width: '15px',
						height: '15px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<LightTooltip
						arrow
						title="Reset de configurações do gráfico"
					>
						<IconButton
							onClick={() => resetChartSetttings()}
							color="inherit"
						>
							<RestartAltIcon />
						</IconButton>
					</LightTooltip>
				</div>
			</div>
		);
	}
);

IdxChart.displayName = 'IdxChart';

export { IdxChart };
