import React, { useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { Book, SwapVert } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
	setMarketPriceLoading,
	strategyUpdate,
} from 'store/modules/bottomBullet/actions';
import ComboBoxInput from '../ComboBoxInput';
import MaterialCheckBox from '../MaterialCheckBox';
import { ButtonBuySell } from '../MaterialStyles/style';
import { additionalUpdate } from '../../../store/modules/bottomBullet/actions';
import DecimalInput from '../DecimalInput';
import { getFinatialValue } from '../../../utils/strategies';
import paperValidator from 'utils/paperValidation';
import { useTranslation } from 'react-i18next';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import { getMaxClipSize } from 'utils/getMaxClipSize';
import { addClipProportion } from 'utils/addClipProportion';

const Spread = ({
	onSuggestionsFetchRequested,
	setPaperInfo,
	index,
	onlyCall,
	onlyOptions,
	callbackMarketPrice,
	stepFunction,
	execType,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const [legResting, setLegResting] = useState(false);
	const [updateMarketPrice, setUpdateMarketPrice] = useState(false);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);

	const { t } = useTranslation();

	if (bullet.editing || bullet.clone) {
		if (!updateMarketPrice) {
			setUpdateMarketPrice(true);
			callbackMarketPrice(
				bullet.content.StrategyLegs,
				bullet.content.CustomParameters.Trigger
			);
		}

		const tempLegResting =
			(bullet.content.StrategyLegs[index].LegResting ?? 'N') === 'Y';
		if (legResting !== tempLegResting) {
			setLegResting(tempLegResting);
		}
	}

	const handleChange = (type, subType, value, indexLeg) => {
		const hash = { ...bullet };

		if (type === 'Leg') {
			hash.content.StrategyLegs[indexLeg][subType] = value;
		} else if (type === 'Custom') {
			hash.content.CustomParameters[subType] = value;
		}

		if (subType === 'LegQuantity') {
			const finantialValue = getFinatialValue(
				hash.StrategyInfos[indexLeg].lastPx,
				hash.content.StrategyLegs[indexLeg].LegQuantity,
				hash.StrategyInfos[indexLeg].asset
			);

			if (!isNaN(finantialValue)) {
				hash.StrategyInfos[indexLeg].finantial = finantialValue;
			}
		}

		if (
			(subType === 'LegQuantity' || subType === 'LegSymbol') &&
			!bullet.editing
		) {
			hash.content.CustomParameters.TriggerValue = null;

			callbackMarketPrice(
				hash.content.StrategyLegs,
				hash.content.CustomParameters.Trigger
			);

			dispatch(setMarketPriceLoading(true));
			addClipProportion(hash.content.StrategyLegs, hash.StrategyInfos);
		}

		dispatch(additionalUpdate('StrategyInfos', hash.StrategyInfos));
		dispatch(strategyUpdate(hash.content));
	};

	const changeLegSide = () => {
		dispatch(setMarketPriceLoading(true));

		const hash = bullet.content;
		hash.CustomParameters.TriggerValue = null;

		hash.StrategyLegs[index].LegSide =
			hash.StrategyLegs[index].LegSide === '1' ? '2' : '1';

		if (hash.StrategyLegs[index]) {
			dispatch(strategyUpdate(hash));
		}

		callbackMarketPrice(hash.StrategyLegs, hash.CustomParameters.Trigger);
	};

	const setPaperInfoInterceptor = (tempIndex, data) => {
		setPaperInfo(tempIndex, data);
		handleChange('Leg', 'LegSymbol', data.symbol, tempIndex);
	};

	const handleOnChangeLegResting = () => {
		handleChange('Leg', 'LegResting', !legResting ? 'Y' : 'N', index);
		setLegResting(!legResting);
	};

	const clipMinVal = getMaxClipSize(
		bullet.StrategyInfos[index]?.minOrderQty ?? 0,
		bullet.content.StrategyLegs[index].LegQuantity
	);

	return (
		<Grid container item xs={12} spacing={1}>
			<Grid item xs={execType === 'B' ? 4 : 3}>
				<Box display="flex">
					<Box pb={0.5}>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(
										bullet.content.StrategyLegs[index]
											.LegSymbol
									)
								}
								disabled={
									symbolOpenBook(
										bullet.StrategyInfos[0],
										bullet
									) || lockBook
								}
							>
								<Book />
							</IconButton>
						</Tooltip>
					</Box>
					<ComboBoxInput
						name={'paper' + index}
						label="Ativo"
						tooltip={t('bullets.general.paper')}
						setPaperInfo={setPaperInfoInterceptor}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								index
							)
						}
						index={index}
						disabled={bullet.content.ClOrdID}
						value={bullet.content.StrategyLegs[index].LegSymbol}
						isPaper
					/>
				</Box>
			</Grid>
			<Grid item xs={execType === 'B' ? 4 : 2}>
				<ButtonBuySell
					variant="contained"
					fullWidth
					name={'btn-side' + index}
					size="small"
					color={
						bullet.content.StrategyLegs[index].LegSide === '1'
							? 'primary'
							: 'secondary'
					}
					startIcon={<SwapVert />}
					disabled={
						paperValidator(bullet.StrategyInfos[index], bullet) ||
						bullet.content.ClOrdID
					}
					onClick={changeLegSide}
				>
					{bullet.content.StrategyLegs[index].LegSide === '1'
						? 'Compra'
						: 'Venda'}
				</ButtonBuySell>
			</Grid>
			<Grid item xs={execType === 'B' ? 4 : 3}>
				<DecimalInput
					disabled={
						paperValidator(bullet.StrategyInfos[index], bullet) ||
						bullet.content.ClOrdID
					}
					name={'quantity' + index}
					label="Quantidade"
					minVal={bullet.StrategyInfos[index].minOrderQty ?? 0}
					maxVal={bullet.StrategyInfos[index].maxOrderQty ?? 9999999}
					minDecScale={0} // casas decimais
					stepFunction={stepFunction}
					stepType="Leg" // type
					stepSubType="LegQuantity" // subtype
					stepSize={
						bullet.StrategyInfos[index].roundLot ||
						bullet.StrategyInfos[index].Cst_MinOrderQuantity
					} // incremento
					valueToStep={bullet.content.StrategyLegs[index].LegQuantity} // valor a ser modificado
					value={bullet.content.StrategyLegs[index].LegQuantity}
					legIndex={index}
					onChange={e =>
						handleChange(
							'Leg',
							'LegQuantity',
							parseInt(e.value),
							index
						)
					}
				/>
			</Grid>
			{execType !== 'B' && (
				<>
					<Grid item xs={2}>
						<DecimalInput
							disabled={paperValidator(
								bullet.StrategyInfos[index],
								bullet
							)}
							name={'clip' + index}
							label="Clip máximo"
							iconTooltip={t('bullets.general.clip')}
							minVal={clipMinVal}
							maxVal={
								bullet.StrategyInfos[index].maxOrderQty ??
								9999999
							}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegMaxClipSize" // subtype
							stepSize={
								bullet.StrategyInfos[index].roundLot ||
								bullet.StrategyInfos[index].Cst_MinOrderQuantity
							} // incremento
							valueToStep={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							}
							legIndex={index}
							onChange={e =>
								handleChange(
									'Leg',
									'LegMaxClipSize',
									parseInt(e.value),
									index
								)
							}
						/>
					</Grid>
					<Grid item xs={2} container alignItems="center">
						<Box mt={-1}>
							<MaterialCheckBox
								name={'resting' + index}
								labelSide="top"
								label="Apregoar"
								disabled={paperValidator(
									bullet.StrategyInfos[index],
									bullet
								)}
								checked={
									bullet.content.StrategyLegs[index]
										.LegResting === 'Y'
								}
								onChange={() => handleOnChangeLegResting()}
							/>
						</Box>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default Spread;
