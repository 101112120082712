import { toast } from 'react-toastify';

function verifyFieldsStrategyLegs(strategyLegs, execType) {
	const verifyDuplicateSymbols = () => {
		const symbols = strategyLegs.map(item => item.LegSymbol);
		const uniqueValues = new Set(symbols); // Coletando só valores iguais
		return uniqueValues.size !== symbols.length;
	};

	const isAdvancedBullet = execType === 'A';

	const hasDuplicateSymbol = verifyDuplicateSymbols();

	if (hasDuplicateSymbol) {
		toast.error('Papéis duplicados!');

		return true;
	}

	const isInvalidQuantity = strategyLegs.some(item => item.LegQuantity === 0);

	if (isInvalidQuantity) {
		toast.error('A quantidade precisa ser maior que 0!');

		return true;
	}

	const isInvalidMaxClipSize = strategyLegs.some(
		item => item.LegMaxClipSize === 0
	);

	if (isAdvancedBullet && isInvalidMaxClipSize) {
		toast.error('O clip máximo precisa ser maior que 0!');

		return true;
	}

	const isInvalidResting = strategyLegs.some(item => item.LegResting === 'Y');

	if (isAdvancedBullet && !isInvalidResting) {
		toast.error('Selecione ao menos uma perna para apregoar!');

		return true;
	}

	return false;
}

function verifyFieldsCustomParamers(customParameters) {
	const possibleMandatoryFieldsMapping = {
		EntryTriggerValue: 'Entrada',
		ExitTriggerValue: 'Saida',
	};

	let emptyFieldForMessage;

	const mandatoryCustomParametersFields = [
		'EntryTriggerValue',
		'ExitTriggerValue',
	];

	const hasCustomParameterFieldEmpty = mandatoryCustomParametersFields.some(
		field => {
			if ([null, undefined, ''].includes(customParameters[field])) {
				emptyFieldForMessage = field;
				return true;
			}
			return false;
		}
	);

	if (hasCustomParameterFieldEmpty) {
		toast.error(
			`Campo obrigatório "${possibleMandatoryFieldsMapping[emptyFieldForMessage]}" não preenchido`
		);

		return true;
	}

	return false;
}

function getFormattedStrategyLegs(strategyLegs, account, execType) {
	const formattedStrategyLegs = strategyLegs.map(leg => {
		const currentStrategyLegs = {
			ILegAllocAccount: account,
			LegMaxClipSize: leg.LegMaxClipSize,
			LegQuantity: leg.LegQuantity,
			LegSecurityExchange: leg.LegSecurityExchange ?? 'XBSP',
			LegSide: leg.LegSide,
			LegSymbol: leg.LegSymbol,
			LegResting: leg.LegResting,
		};

		/* Caso seja boleta basica eliminando os campos de apregoar e clip maximo */
		if (execType !== 'A') {
			delete currentStrategyLegs.LegResting;
			currentStrategyLegs.LegMaxClipSize = 0;
		}

		return currentStrategyLegs;
	});

	return formattedStrategyLegs;
}

const getFormattedCustomParameters = (customParameters, execType) => {
	const defaultValuesCustomParameters = {
		ExecutionType: 0,
		Trigger: 1,
		EntryTriggerType: 1,
		ExitTriggerType: 1,
		ContinuousExecMode: 'Y',
	};

	const formattedCustomParameters = {
		...defaultValuesCustomParameters,
		BookDepth: parseInt(customParameters.BookDepth) ?? 1,
		CompensateExec: parseInt(customParameters.CompensateExec),
		EntryTriggerType: parseInt(customParameters.EntryTriggerType),
		ExitTriggerType: parseInt(customParameters.ExitTriggerType),
		IgnoreOffersLT: parseInt(customParameters.IgnoreOffersLT),
		EntryTriggerValue: customParameters.EntryTriggerValue,
		ExitTriggerValue: customParameters.ExitTriggerValue,
		ClosePositionsOnEnd: customParameters.ClosePositionsOnEnd ?? 'N',
		SimultaneousTriggerMode:
			customParameters.SimultaneousTriggerMode ?? 'N',
	};

	// Fazendo a validação do gatilho de entrada para tipo Dif.Financeiro que não aceita valores fracionados
	if (formattedCustomParameters.EntryTriggerType === 11) {
		formattedCustomParameters.EntryTriggerValue = parseInt(
			customParameters.EntryTriggerValue
		);
	}

	// Fazendo a validação do gatilho de saida para tipo Dif.Financeiro que não aceita valores fracionados
	if (formattedCustomParameters.ExitTriggerType === 11) {
		formattedCustomParameters.ExitTriggerValue = parseInt(
			customParameters.ExitTriggerValue
		);
	}

	/* Caso seja boleta basica eliminando os campos de compensação, profundidade e ignorar multiplo de lote */
	if (execType !== 'A') {
		delete formattedCustomParameters.BookDepth;
		delete formattedCustomParameters.CompensateExec;
		delete formattedCustomParameters.IgnoreOffersLT;

		formattedCustomParameters.ExecStrategy = 1;
	}

	return formattedCustomParameters;
};

export function continousClear(bullet, account, execType) {
	const { content } = bullet;

	const strategyLegs = content.StrategyLegs;
	const customParameters = content.CustomParameters;

	const strategyLegsIsInvalid = verifyFieldsStrategyLegs(
		strategyLegs,
		execType
	);

	if (strategyLegsIsInvalid) {
		return;
	}

	const customParametersIsInvalid =
		verifyFieldsCustomParamers(customParameters);

	if (customParametersIsInvalid) {
		return;
	}

	const accountToBeUsed = account();

	const strategyLegsFormmatted = getFormattedStrategyLegs(
		strategyLegs,
		accountToBeUsed,
		execType
	);

	const customParametersFormatted = getFormattedCustomParameters(
		customParameters,
		execType
	);

	return {
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		TimeInForce: content.TimeInForce,
		Text: content.Text,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: customParametersFormatted,
		StrategyLegs: strategyLegsFormmatted,
		Signature: content.Signature,
	};
}
