import React from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Typography,
} from '@mui/material';

import { getFormattedDate } from 'utils/getParsedDate';
import { categories, types } from 'utils/categories';
import { setPartialGlobalSettings } from 'store/modules/panelConfig/actions';
import { store } from 'store';
import { postGlobalSettings } from 'services/userPreferences/globalSettings';
import { getCategoryFromStrategyCode } from 'utils/strategies';
import { gapClear } from 'components/MaterialComponents/MaterialBullet/submitFunctions/gapClear';

export const OperationSummary = ({
	isOpen,
	onClose,
	account,
	handleSubmit,
}) => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const { content, isRecurrence, recurrenceInfo } = bullet;

	const strategyLeg = content.StrategyLegs[0];

	const formattedLegSide = strategyLeg.LegSide === '1' ? 'Compra' : 'Venda';

	const useApparentQuantity = content.CustomParameters._useApparentQuantity;

	function handleCancel() {
		onClose();
	}

	function getExpireDate() {
		if (isRecurrence) {
			const formattedDate = getFormattedDate(
				recurrenceInfo.ActiveEndDate,
				'dd/MM/yyyy'
			);

			return `Repete diariamente até o dia ${formattedDate}`;
		}

		return getFormattedDate(content.ExpireDate, 'dd/MM/yyyy');
	}

	function getSendTime() {
		const customParameters = content.CustomParameters;

		if (customParameters._relativePxInitTimeOption) {
			if (customParameters._relativePxInitTimeOption === 'immediate') {
				return `A partir das ${content.InitTime}`;
			}

			if (customParameters._relativePxInitTimeOption === 'specified') {
				const formattedHour = format(
					customParameters.PxInitTimeMonitoring,
					'HH:mm:ss'
				);

				return `Às ${formattedHour}`;
			}

			if (customParameters._relativePxInitTimeOption === 'interval') {
				const formattedInitTime = format(
					customParameters.PxInitTimeMonitoring,
					'HH:mm:ss'
				);

				const formattedEndTime = format(
					customParameters.PxEndTimeMonitoring,
					'HH:mm:ss'
				);

				return `Entre ${formattedInitTime} e ${formattedEndTime}`;
			}
		}
	}

	function getStrategyName() {
		const strategyCategory = getCategoryFromStrategyCode(
			content.StrategyCode
		);

		const strategyCategoryName = categories.find(
			category => category.id === strategyCategory
		).name;

		const strategyType = types[strategyCategory].find(
			type => type.code === content.StrategyCode
		).name;

		return `${strategyCategoryName} - ${strategyType}`;
	}

	function getExitMessage() {
		const cleanedBullet = gapClear(bullet, () => {});
		const customParameters = cleanedBullet.CustomParameters;

		let message = 'Caso entre, reverte';

		const exitValueType =
			customParameters.ExitUnit === 'P' ? '%' : ' pontos';

		if (customParameters.ExitPxRelGain) {
			const formattedGain = new Intl.NumberFormat('pt-BR', {
				maximumFractionDigits: 2,
			}).format(customParameters.ExitPxRelGain);

			message += ` com gain de ${formattedGain}${exitValueType}, `;
		}

		if (customParameters.ExitPxRelLoss) {
			const orString = customParameters.ExitPxRelGain ? 'ou' : 'com';
			const formattedLoss = new Intl.NumberFormat('pt-BR', {
				maximumFractionDigits: 2,
			}).format(customParameters.ExitPxRelLoss);

			message += ` ${orString} loss de ${formattedLoss}${exitValueType}, `;
		}

		if (customParameters.ExitPxSlippagePts) {
			message += ` e slippage de ${customParameters.ExitPxSlippagePts} pontos, `;
		}

		if (customParameters.ExitOnEndTime === 'Y') {
			const haveNoGainOrLoss =
				!customParameters.ExitPxRelGain &&
				!customParameters.ExitPxRelLoss;

			message += `${haveNoGainOrLoss ? '' : 'ou'} às ${content.EndTime}.`;
		}

		if (customParameters.ExitOnEndDayAuction === 'Y') {
			const haveNoGainOrLoss =
				!customParameters.ExitPxRelGain &&
				!customParameters.ExitPxRelLoss;

			message += `${
				haveNoGainOrLoss ? '' : 'ou'
			} no leilão de fechamento.`;
		}

		return message;
	}

	function getFormattedEntryValue() {
		const cleanedBullet = gapClear(bullet, () => {});
		const customParameters = cleanedBullet.CustomParameters;

		const pxRelSuffix =
			customParameters.RelativeUnit === 'P' ? '%' : ' pontos';

		const relativeTypeOptions = {
			O: 'do preço de abertura do dia atual',
			C: 'do preço de fechamento do dia anterior ',
			L: 'da mínima do dia atual',
			H: 'da máxima do dia atual',
		};

		const relativeDirectionOptions = { U: 'Acima', D: 'Abaixo' };

		const formattedRelativePx = new Intl.NumberFormat('pt-BR', {
			maximumFractionDigits: 2,
		}).format(customParameters.RelativePxRel);

		if (customParameters.EntranceMoment === 'I') {
			return `
				Envia uma ordem limitada de
				${formattedRelativePx}${pxRelSuffix}
				${relativeDirectionOptions[customParameters.RelativeDirection]}
				${relativeTypeOptions[customParameters.RelativeType]}.
			`;
		}

		if (customParameters.EntranceMoment === 'S') {
			const legSide = cleanedBullet.StrategyLegs[0].LegSide;
			const formattedPriceVariationLabel =
				legSide === '2' ? 'caia' : 'suba';

			const slippageMessage = customParameters.PxRelSlippagePts
				? `e slippage de ${customParameters.PxRelSlippagePts} pontos`
				: '';

			return `
				Caso o preço ${formattedPriceVariationLabel}
				${customParameters.RelativePxRel}${pxRelSuffix}
				${relativeDirectionOptions[customParameters.RelativeDirection]}
				${relativeTypeOptions[customParameters.RelativeType]},
				envia uma ordem com o preço do último negócio
				${slippageMessage}
				.
			`;
		}
	}

	const strategyName = getStrategyName();
	const formattedSendTime = getSendTime();
	const exitMessage = getExitMessage();
	const formattedExpireDate = getExpireDate();
	const formattedEntryValue = getFormattedEntryValue();

	const isSummaryNeededSelector = useSelector(
		state =>
			state.configs.globalSettings?.settingsToChange?.bullet
				.isSummaryNeeded
	);

	function verifyIsSummaryNeeded() {
		if ([undefined, null].includes(isSummaryNeededSelector)) {
			return false;
		}

		return isSummaryNeededSelector;
	}

	const isSummaryNeeded = verifyIsSummaryNeeded();

	function handleToggleNeedConfirmation(isChecked) {
		dispatch(
			setPartialGlobalSettings('bullet', {
				isSummaryNeeded: !isChecked,
			})
		);

		const username = store.getState().auth.username;
		const globalSettings =
			store.getState().configs.globalSettings.settingsToChange;

		const formattedGlobalSettings = {
			...globalSettings,
			bullet: {
				...globalSettings.bullet,
				isSummaryNeeded: !isChecked,
			},
		};

		postGlobalSettings(username, formattedGlobalSettings);
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Confirmação de operação</DialogTitle>

			<DialogContent
				sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
			>
				<Divider />

				<Typography variant="subtitle1">Geral</Typography>

				<Box
					sx={{
						display: 'grid',
						gap: 1,
						gridTemplateColumns: '1fr 1fr',
					}}
				>
					<DialogContentText>
						Estratégia: {strategyName}
					</DialogContentText>

					<DialogContentText sx={{ textAlign: 'right' }}>
						Hora início: {content.InitTime}
					</DialogContentText>

					<DialogContentText>Conta: {account}</DialogContentText>

					<DialogContentText sx={{ textAlign: 'right' }}>
						Hora fim: {content.EndTime}
					</DialogContentText>

					<DialogContentText>
						Validade: {formattedExpireDate}
					</DialogContentText>
				</Box>

				<Divider />

				<Typography variant="subtitle1">Entrada</Typography>

				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
					<DialogContentText>
						Ativo: {strategyLeg.LegSymbol}
					</DialogContentText>

					<DialogContentText>
						Side: {formattedLegSide}
					</DialogContentText>

					<DialogContentText>
						Quantidade: {strategyLeg.LegQuantity}
					</DialogContentText>

					{useApparentQuantity && (
						<DialogContentText>
							Qtd. Aparente: {strategyLeg.LegMinClipSize}
						</DialogContentText>
					)}

					<DialogContentText>
						Envio da ordem: {formattedSendTime}
					</DialogContentText>

					<DialogContentText>{formattedEntryValue}</DialogContentText>
				</Box>

				<Divider />

				<Typography variant="subtitle1">Saída</Typography>

				<DialogContentText>{exitMessage}</DialogContentText>

				<FormControl>
					<FormControlLabel
						control={<Checkbox />}
						label="Não solicitar confirmação nas próximas operações"
						onChange={(_, checked) =>
							handleToggleNeedConfirmation(checked)
						}
						checked={isSummaryNeeded ? false : true}
					/>
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button
					color="inherit"
					variant="outlined"
					onClick={handleCancel}
				>
					Cancelar
				</Button>

				<Button
					color="success"
					variant="contained"
					onClick={handleSubmit}
				>
					Enviar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
