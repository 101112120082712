import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';

import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { EXM } from 'utils/bulletContext';
import { getMarketPrice } from 'services/getMarketPrice';
import { getDecimalPlacesByTrigger } from 'utils/strategies';
import {
	additionalUpdate,
	setMarketPriceLoading,
} from 'store/modules/bottomBullet/actions';

const PriceBox = ({ label, value, color, loading, ...rest }) => {
	return (
		<Button
			color="inherit"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				flex: 1,
				minWidth: 0,
			}}
			fullWidth
			{...rest}
		>
			<Typography sx={{ fontSize: '0.75rem' }}>{label}</Typography>

			{loading ? (
				<CircularProgress size={16} />
			) : (
				<Typography sx={{ fontSize: '0.9rem' }} color={color}>
					{value}
				</Typography>
			)}
		</Button>
	);
};

export const ContinousMarketPrice = ({
	disabledButtonCredit,
	handleCreditButton,
	setLoadingEntryButton = null,
	setLoadingExitButton = null,
}) => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const isMarketPriceLoading = useSelector(
		state => state.bottomBullet.loadingMarketPrice
	);

	const { content, paramsView, context } = bullet;

	const entryTriggerType = content.CustomParameters.EntryTriggerType;
	const exitTriggerType = content.CustomParameters.ExitTriggerType;

	const buttonCreditDisabled = disabledButtonCredit || paramsView;

	let controller = null;

	const containerLengthPriceBox = 3.5;

	const marketPricesRef = useRef({
		entry: { bid: null, mid: null, ask: null },
		exit: { bid: null, mid: null, ask: null },
	});

	const marketPricesValid = useRef(false);

	const getCrDbLabelValue = value => {
		if (!value) {
			return '';
		}

		const parsedValue = parseFloat(value);

		const label = parsedValue >= 0.0 ? 'CR' : 'DB';

		return label;
	};

	const getColorPriceButton = marketPrice => {
		if (!marketPrice) {
			return '#ffffff';
		}

		if (marketPrice >= 0) {
			return '#4cac4b';
		}

		return '#ff6961';
	};

	const getMaxValueIfCredit = (value1, value2, compareValue, isCredit) => {
		if (!isCredit) {
			return null;
		}

		const maxValue = Math.max(value1, value2);

		return maxValue > compareValue ? maxValue : null;
	};

	function updateMarketEntryPrices(newBuyPrices) {
		marketPricesRef.current.entry = {
			...marketPricesRef.current.entry,
			...newBuyPrices,
		};
	}

	function updateMarketExitPrices(newSellPrices) {
		marketPricesRef.current.exit = {
			...marketPricesRef.current.exit,
			...newSellPrices,
		};
	}

	function updateLoadingEntryButton(newLoading) {
		if (setLoadingEntryButton) {
			setLoadingEntryButton(newLoading);
		}
	}

	function updateLoadingExitButton(newLoading) {
		if (setLoadingExitButton) {
			setLoadingExitButton(newLoading);
		}
	}

	/* fieldName (entry ou exit) Entrada e Saida */
	/* typeMarketPrice (bid, mid ou ask) Valores de mercado */
	function handleClickPriceButton(fieldName, typeMarketPrice) {
		/* Novo valor do trigger em questão */
		const selectedValueMarketPrice =
			marketPricesRef.current[fieldName][typeMarketPrice];

		const newValueCredit = selectedValueMarketPrice >= 0;

		handleCreditButton(fieldName, newValueCredit, selectedValueMarketPrice);
	}

	function resetCancelAxiosToken() {
		controller = null;
	}

	const getFormatMarketPrice = (value, trigger) => {
		if (!value) {
			return '-';
		}

		const formattedValue = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: getDecimalPlacesByTrigger(trigger),
			maximumFractionDigits: getDecimalPlacesByTrigger(trigger),
		}).format(Math.abs(value));

		const currentLabel = getCrDbLabelValue(value);

		return `${currentLabel} ${formattedValue}`;
	};

	async function setSpreadMarketPrices(
		strategyLegs,
		entryTriggerType,
		exitTriggerType
	) {
		const strategyLegsIsInvalid = strategyLegs.some(
			leg =>
				['', undefined].includes(leg.LegSymbol) ||
				[0, undefined].includes(leg.LegQuantity)
		);

		if (strategyLegsIsInvalid) {
			const defaultMarketPrices = {
				bid: null,
				mid: null,
				ask: null,
			};

			dispatch(setMarketPriceLoading(false));
			updateMarketEntryPrices(defaultMarketPrices);
			updateMarketExitPrices(defaultMarketPrices);

			return;
		}

		const newStrategyLegs = strategyLegs.map(leg => ({ ...leg }));

		controller = new AbortController();

		const currentFirstLegSide = newStrategyLegs[0].LegSide;
		const currentSecundLegSide = newStrategyLegs[1].LegSide;

		// Invertendo os sides para trazer o calculo inverso feito acima
		newStrategyLegs[0].LegSide = currentFirstLegSide === '1' ? '2' : '1';
		newStrategyLegs[1].LegSide = currentSecundLegSide === '1' ? '2' : '1';

		await Promise.all([
			getMarketPrice(
				entryTriggerType,
				strategyLegs,
				updateMarketEntryPrices,
				controller,
				resetCancelAxiosToken
			),

			// Trazendo os valores de saida
			getMarketPrice(
				exitTriggerType,
				newStrategyLegs,
				updateMarketExitPrices,
				controller,
				resetCancelAxiosToken
			),
		]);

		if (
			Object.values(marketPricesRef.current.entry).every(item => item) &&
			Object.values(marketPricesRef.current.exit).every(item => item)
		) {
			marketPricesValid.current = true;
		}
	}

	async function updateMarketPrices() {
		const newBullet = store.getState().bottomBullet.bullet;

		await setSpreadMarketPrices(
			newBullet.content.StrategyLegs,
			newBullet.content.CustomParameters.EntryTriggerType,
			newBullet.content.CustomParameters.ExitTriggerType
		);
	}

	useEffect(() => {
		const intervalId = setInterval(() => {
			updateMarketPrices();
		}, 2000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (bullet.refreshNeeded) {
			updateMarketPrices();

			dispatch(setMarketPriceLoading(true));
			dispatch(additionalUpdate('refreshNeeded', false));
		}
	}, [bullet.refreshNeeded]);

	useEffect(() => {
		if (context === EXM) {
			updateLoadingEntryButton(true);
			updateLoadingExitButton(true);
		}
	}, [isMarketPriceLoading]);

	useEffect(() => {
		if (
			context === EXM &&
			marketPricesValid.current &&
			!isMarketPriceLoading
		) {
			const entryValues = Object.values(marketPricesRef.current.entry);
			const exitValues = Object.values(marketPricesRef.current.exit);

			const {
				EntryTriggerValue: currentEntryTriggerValue,
				ExitTriggerValue: currentExitTriggerValue,
			} = content.CustomParameters;

			if (currentEntryTriggerValue && currentExitTriggerValue) {
				const hasDebitEntryValues = entryValues.some(
					value => value < 0
				);

				const hasDebitExitValues = exitValues.some(value => value < 0);

				let entryTriggerValue = currentEntryTriggerValue;

				let exitTriggerValue = currentExitTriggerValue;

				const maxEntryValue = getMaxValueIfCredit(
					currentEntryTriggerValue,
					currentExitTriggerValue,
					currentEntryTriggerValue,
					!hasDebitEntryValues
				);

				if (maxEntryValue) {
					entryTriggerValue = maxEntryValue;
					exitTriggerValue = currentEntryTriggerValue;
				}

				const maxExitValue = getMaxValueIfCredit(
					currentEntryTriggerValue,
					currentExitTriggerValue,
					currentExitTriggerValue,
					!hasDebitExitValues
				);

				if (maxExitValue) {
					exitTriggerValue = maxExitValue;
					entryTriggerValue = currentExitTriggerValue;
				}

				handleCreditButton(
					'entry',
					!hasDebitEntryValues,
					entryTriggerValue
				);

				handleCreditButton(
					'exit',
					!hasDebitExitValues,
					exitTriggerValue
				);
			}

			updateLoadingEntryButton(false);
			updateLoadingExitButton(false);
		}
	}, [marketPricesValid.current, isMarketPriceLoading]);

	return (
		<>
			<Grid
				container
				item
				xs={12}
				spacing={1}
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="subtitle1">Spread mercado</Typography>

				<Grid
					container
					item
					xs={12}
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							label="BID"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.entry.bid
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.entry.bid,
								entryTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('entry', 'bid')
							}
						/>
					</Grid>

					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							label="MID"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.entry.mid
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.entry.mid,
								entryTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('entry', 'mid')
							}
						/>
					</Grid>

					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							label="ASK"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.entry.ask
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.entry.ask,
								entryTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('entry', 'ask')
							}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs={12}
					mt={1}
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.exit.bid
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.exit.bid,
								exitTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('exit', 'bid')
							}
						/>
					</Grid>

					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.exit.mid
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.exit.mid,
								exitTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('exit', 'mid')
							}
						/>
					</Grid>

					<Grid item xs={containerLengthPriceBox}>
						<PriceBox
							size="small"
							loading={isMarketPriceLoading}
							disabled={buttonCreditDisabled}
							color={getColorPriceButton(
								marketPricesRef.current?.exit.ask
							)}
							value={getFormatMarketPrice(
								marketPricesRef.current?.exit.ask,
								exitTriggerType
							)}
							onClick={() =>
								handleClickPriceButton('exit', 'ask')
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
