import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';

import {
	Button,
	Divider,
	FormControl,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

import { NumericInput } from 'components/Bullet/NumericInput';
import { AutoCompleteSymbolSearchForm } from './AutoCompleteSymbolSearchForm';

import { updateFormValues } from 'store/modules/radar/actions';
import { handleBullet } from 'utils/tablesColumns/radar';

export const RadarSearchForm = ({ isLoading, handleSearch }) => {
	const dispatch = useDispatch();

	const { searchForm, searchApplied, historyData } = useSelector(
		state => state.radarModule
	);

	const [suggestionsSymbol1, setSuggestionsSymbol1] = useState([]);
	const [suggestionsSymbol2, setSuggestionsSymbol2] = useState([]);

	const buttonOpenBulletDisabled =
		historyData.length === 0 ||
		(['--', ''].includes(searchApplied.symbol1) &&
			['--', ''].includes(searchApplied.symbol2));

	function handleChangeSearchFormValues(field, value) {
		dispatch(updateFormValues(field, value));
	}

	function openBullet() {
		const strategyData = {
			symbol1: searchApplied.symbol1,
			symbol2: searchApplied.symbol2,
			symbol1_prop: searchApplied.symbol1_prop,
			symbol2_prop: searchApplied.symbol2_prop,
		};

		handleBullet(strategyData);
	}

	return (
		<>
			<form
				onSubmit={event => handleSearch(event, searchForm)}
				style={{ width: '100%', display: 'flex', margin: '10px 0' }}
			>
				<Grid
					container
					spacing={1}
					xs={12}
					display="flex"
					flexDirection="column"
				>
					<Grid container item alignItems="center" gap={1}>
						<Grid item xs={2.5} mb={0.75}>
							<Typography
								variant="h5"
								style={{ fontWeight: 'bold' }}
							>
								Selecione os ativos
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography
								variant="h5"
								style={{ fontWeight: 'bold' }}
							>
								Composição
							</Typography>
						</Grid>
						<Grid item xs={2.5}>
							<Typography
								variant="h5"
								style={{ fontWeight: 'bold' }}
							>
								Data
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography
								variant="h5"
								style={{ fontWeight: 'bold' }}
							>
								Créditos Camadas
							</Typography>
						</Grid>
					</Grid>
					<Grid container item alignItems="center" gap={1}>
						<Grid item xs={2.5} sx={{ mt: 2.75 }}>
							<AutoCompleteSymbolSearchForm
								fieldName="symbol1"
								inputValue={searchForm.symbol1}
								options={suggestionsSymbol1}
								setSuggestions={setSuggestionsSymbol1}
								handleChangeSearchForm={
									handleChangeSearchFormValues
								}
							/>
						</Grid>
						<Grid item xs={2} sx={{ mt: 2.75 }}>
							<NumericInput
								minValue={1}
								stepSize={1}
								height={32}
								value={searchForm.symbol1_prop}
								onChangeCallback={value =>
									handleChangeSearchFormValues(
										'symbol1_prop',
										value
									)
								}
							/>
						</Grid>
						<Grid item xs={2.5}>
							<FormControl
								size="small"
								variant="outlined"
								sx={{ mt: 0.75 }}
								fullWidth
							>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={ptBR}
								>
									<label htmlFor="init_date">Início</label>
									<DatePicker
										size="small"
										inputFormat="dd/MM/yyyy"
										value={searchForm.init_date}
										renderInput={params => (
											<>
												<TextField
													id="init_date"
													size="small"
													{...params}
												/>
											</>
										)}
										onChange={value =>
											handleChangeSearchFormValues(
												'init_date',
												value
											)
										}
										disableFuture
									/>
								</LocalizationProvider>
							</FormControl>
						</Grid>
						<Grid item xs={2} sx={{ mt: 2.75 }}>
							<NumericInput
								minValue={0.02}
								stepSize={0.01}
								decimalScale={2}
								height={32}
								value={searchForm.layer}
								onChangeCallback={value =>
									handleChangeSearchFormValues('layer', value)
								}
								fixedDecimalScale
							/>
						</Grid>
					</Grid>

					<Grid container item alignItems="center" gap={1}>
						<Grid item xs={2.5} sx={{ mt: 2.75 }}>
							<AutoCompleteSymbolSearchForm
								fieldName="symbol2"
								inputValue={searchForm.symbol2}
								options={suggestionsSymbol2}
								setSuggestions={setSuggestionsSymbol2}
								handleChangeSearchForm={
									handleChangeSearchFormValues
								}
							/>
						</Grid>
						<Grid item xs={2} sx={{ mt: 2.75 }}>
							<NumericInput
								minValue={1}
								stepSize={1}
								height={32}
								value={searchForm.symbol2_prop}
								onChangeCallback={value =>
									handleChangeSearchFormValues(
										'symbol2_prop',
										value
									)
								}
							/>
						</Grid>
						<Grid item xs={2.5}>
							<FormControl
								size="small"
								variant="outlined"
								sx={{ mt: 0.75 }}
								fullWidth
							>
								<label htmlFor="end_date">Fim</label>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={ptBR}
								>
									<DatePicker
										id="date"
										size="small"
										inputFormat="dd/MM/yyyy"
										value={searchForm.end_date}
										renderInput={params => (
											<TextField
												id="end_date"
												size="small"
												{...params}
											/>
										)}
										onChange={value =>
											handleChangeSearchFormValues(
												'end_date',
												value
											)
										}
										disableFuture
									/>
								</LocalizationProvider>
							</FormControl>
						</Grid>
						<Grid item xs={2} container sx={{ mt: 2.75 }} gap={1}>
							<Grid item xs>
								<LoadingButton
									type="submit"
									variant="contained"
									loading={isLoading}
									fullWidth
								>
									Filtrar Dados
								</LoadingButton>
							</Grid>
							<Grid item xs>
								<Button
									variant="contained"
									color="warning"
									disabled={
										isLoading || buttonOpenBulletDisabled
									}
									onClick={openBullet}
									fullWidth
								>
									Montar
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>

			<Divider sx={{ my: 1 }} />
		</>
	);
};
