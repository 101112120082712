import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isValid } from 'date-fns';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { apiAWS } from 'services/api';
import { formatHistoryStrategies } from 'utils/strategiesHelpers';
import { historyInitialState } from 'store/modules/history/reducer';
import { postHistoryPreferences } from 'services/userPreferences/history';
import {
	setRows,
	updatePaginationInfo,
	updateUserPreferences,
} from 'store/modules/history/actions';
import { historyColumns } from 'utils/tablesColumns/history';

import { HistoryToolbar } from './HistoryToolbar';
import { HistorySearchForm } from './HistorySearchForm';
import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { HistoryCustomPagination } from './HistoryCustomPagination';
import { StrategiesCustomDataTree } from '../StrategiesCustomDataTree';
import { setIsRefreshStrategies } from 'store/modules/panelConfig/actions';

export const History = ({ activeProduct }) => {
	document.title = 'Histórico | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const { rows, searchForm, paginationInfo, tablePreferences } = useSelector(
		state => state.history
	);

	const { username } = useSelector(state => state.auth);

	const viewContinousStrategies = useSelector(
		state =>
			state.configs.globalSettings.userSettings.bullet
				.viewContinousStrategies
	);

	const refreshStrategiesNeeded = useSelector(
		state => state.configs.globalSettings.refreshStrategiesNeeded
	);

	const [loadings, setLoadings] = useState({
		isTableLoading: false,
	});

	function updateLoadingState(newLoading) {
		setLoadings({
			...loadings,
			...newLoading,
		});
	}

	function getQueryParams(filters) {
		const queryParams = {
			...filters,
			viewContinousStrategies,
			detailed: 1,
		};

		Object.keys(queryParams).forEach(key => {
			if (['', 'all', 'Todos'].includes(queryParams[key])) {
				delete queryParams[key];
			}

			// Aqui sinaliza que nossa pesquisa vai ser realizada atraves do range de datas
			if (
				'transect_date' in queryParams &&
				Array.isArray(queryParams.transect_date) &&
				queryParams.transect_date.length === 2 &&
				isValid(queryParams.transect_date[0]) &&
				isValid(queryParams.transect_date[1])
			) {
				queryParams['transect_date_init'] = format(
					queryParams.transect_date[0],
					'yyyy-MM-dd'
				);
				queryParams['transect_date_end'] = format(
					queryParams.transect_date[1],
					'yyyy-MM-dd'
				);
				delete queryParams.transect_date;
			}
		});

		return queryParams;
	}

	async function handleSearch(
		paginationInfo = historyInitialState.paginationInfo,
		searchForm,
		event
	) {
		if (event) event.preventDefault();

		updateLoadingState({ isTableLoading: true });

		try {
			const queryParams = {
				params: {
					...getQueryParams(searchForm),
					limit: paginationInfo.limit,
					offset: paginationInfo.offset,
				},
			};

			const { data } = await apiAWS.get('/algoshistory', queryParams);

			const formattedStrategies = formatHistoryStrategies(data.records);

			const howManyRows = data.records.length;

			// Para sempre fazer com que apareça 25 na tabela
			if (howManyRows >= 26) {
				formattedStrategies.pop();
			}

			const newPageInfo = {
				...historyInitialState.paginationInfo,
				num_records: howManyRows,
				page: paginationInfo.page,
				offset: paginationInfo.offset,
				total: paginationInfo.total
					? paginationInfo.total + 25
					: historyInitialState.paginationInfo.total,
			};

			dispatch(setRows(formattedStrategies));
			dispatch(updatePaginationInfo(newPageInfo));
		} catch (error) {
			console.error(error);
		} finally {
			updateLoadingState({ isTableLoading: false });
		}
	}

	const groupingColDef = {
		headerName: 'Ações',
		type: 'actions',
		flex: 1,
		renderCell: params => (
			<StrategiesCustomDataTree
				module="history"
				isLoading={loadings.isTableLoading}
				{...params}
			/>
		),
	};

	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updateUserPreferences(density));
			postHistoryPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateUserPreferences({
				columns: { columnVisibilityModel: { ...event } },
			})
		);
		postHistoryPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateUserPreferences({
				columns: { orderedFields: [...columnsOrder] },
			})
		);
		postHistoryPreferences(username, { orderedFields: [...columnsOrder] });
	}

	function onFilterModelChange(event) {
		dispatch(
			updateUserPreferences({ filter: { filterModel: { ...event } } })
		);
		postHistoryPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };

		dispatch(updateUserPreferences(sorting));
		postHistoryPreferences(username, sorting);
	}

	useEffect(() => {
		if (refreshStrategiesNeeded) {
			handleSearch(paginationInfo, searchForm, null);

			dispatch(setIsRefreshStrategies(false));
		}
	}, [viewContinousStrategies]);

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 0 0.5rem',
			}}
		>
			<HistorySearchForm
				searchForm={searchForm}
				handleSearch={handleSearch}
				paginationInfo={paginationInfo}
				isLoading={loadings.isTableLoading}
				activeProduct={activeProduct}
			/>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<StyledDataGridPro
					rows={rows}
					apiRef={apiRef}
					treeData
					groupingColDef={groupingColDef}
					getTreeDataPath={row => row.hierarchy}
					loading={loadings.isTableLoading}
					columns={historyColumns}
					components={{
						Toolbar: () =>
							HistoryToolbar(
								loadings.isTableLoading,
								activeProduct
							),
						NoRowsOverlay: () => NoRowsOverlay(''),
						Pagination: () =>
							HistoryCustomPagination(apiRef, paginationInfo),
					}}
					initialState={{
						columns: tablePreferences?.columns,
						sorting: tablePreferences?.sorting,
						filter: tablePreferences?.filter,
						pagination: {
							paginationModel: { pageSize: 25, page: 0 },
						},
					}}
					density={tablePreferences?.density ?? 'standard'}
					getRowClassName={params =>
						params.row.hierarchy.length === 1
							? 'main-strategy'
							: 'strategy-legs'
					}
					pagination
					paginationMode="server"
					rowsPerPageOptions={[25]}
					page={paginationInfo.page}
					rowCount={paginationInfo.total}
					pageSize={25}
					onPageChange={newPage =>
						handleSearch(
							{
								...paginationInfo,
								page: newPage,
								offset: newPage * 25,
							},
							searchForm
						)
					}
					onFilterModelChange={event => onFilterModelChange(event)}
					onColumnOrderChange={event => onColumnOrderChange(event)}
					onColumnVisibilityModelChange={event =>
						onColumnVisibilityModelChange(event)
					}
					onSortModelChange={event => onSortModelChange(event)}
					onStateChange={event => onTableStateChange(event)}
				/>
			</Box>
		</Grid>
	);
};
