import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Send } from '@mui/icons-material';
import { Button, colors, TextField } from '@mui/material';

import { apiAWS } from 'services/api';
import {
	addBotErrorMessage,
	addChatBotMessage,
	addUserMessage,
	setBotIsTyping,
} from 'store/modules/chatbot/actions';
import { LightTooltip } from 'components/LightTooltip';

export const ChatUserInputForm = () => {
	const dispatch = useDispatch();

	const inputRef = useRef();

	function handleSubmit(event) {
		event.preventDefault();

		const userInput = inputRef.current.value;

		if (userInput && userInput !== '') {
			const body = {
				message: userInput,
			};

			dispatch(addUserMessage(userInput));
			inputRef.current.value = '';
			dispatch(setBotIsTyping(true));

			apiAWS
				.post('/chatbot/messages', body)
				.then(response => {
					dispatch(addChatBotMessage(response.data.response));
				})
				.catch(error => {
					console.error(error);
					dispatch(addBotErrorMessage());
				})
				.finally(() => dispatch(setBotIsTyping(false)));
		}
	}

	return (
		<>
			<form
				onSubmit={handleSubmit}
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '0.5rem 1rem',
					background: colors.grey[700],
				}}
			>
				<TextField
					placeholder="Digite uma messagem ou faça uma pergunta..."
					InputProps={{
						style: {
							borderRadius: '8px 0 0 8px',
						},
					}}
					sx={{ display: 'flex', flex: 1 }}
					inputRef={inputRef}
				/>

				<LightTooltip title="Enviar">
					<Button
						variant="contained"
						type="submit"
						startIcon={<Send />}
						color="primary"
						sx={{
							height: '95%',
							borderRadius: '0 8px 8px 0 !important',
							'.MuiButton-startIcon': { m: '0 !important' },
						}}
					/>
				</LightTooltip>
			</form>
		</>
	);
};
