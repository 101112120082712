import React from 'react';
import InputMask from 'react-input-mask';
import { QueryBuilder } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';

import { SizedTextField } from '../MaterialStyles/style';

const TimeInput = ({ value, onChange, label, type, name, tooltip, error, disabled }) => {
	const formatChars = {
		// time format
		h: '[0-2]',
		g: value[0] === '2' ? '[0-3]' : '[0-9]',
		n: '[0-5]',
		m: '[0-9]',
		// date format
		a: '[0-3]',
		b: value[0] === '3' ? '[0-1]' : '[0-9]',
		c: '[0-1]',
		d: value[3] === '1' ? '[0-2]' : '[0-9]',
	};

	return (
		<InputMask
			maskChar="_"
			value={value}
			onChange={onChange}
			formatChars={formatChars}
			mask={type === 'date' ? 'ab/cd/mmmm' : 'hg:nm'}
			disabled={disabled}
		>
			{inputProps => (
				<Tooltip title={tooltip}>
					<SizedTextField
						{...inputProps}
						name={name}
						type="tel"
						error={error}
						variant="outlined"
						size="small"
						label={label}
						fullWidth
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<QueryBuilder fontSize="small" />
								</InputAdornment>
							),
						}}
						disabled={disabled}
					/>
				</Tooltip>
			)}
		</InputMask>
	);
};

export default TimeInput;
