import {
	SET_MODALS,
	SET_MODAL_PREFERENCE,
	SET_GOT_NOTIFICATIONS,
	RESET_STATE,
} from './types';

export const setModals = modals => ({
	type: SET_MODALS,
	payload: { modals },
});

export const setModalPreference = (modalOption, hasSeen) => ({
	type: SET_MODAL_PREFERENCE,
	payload: { modalOption, hasSeen },
});

export const setGotNotifications = hasGottenNotifications => ({
	type: SET_GOT_NOTIFICATIONS,
	payload: { hasGottenNotifications },
});

export const resetState = () => ({
	type: RESET_STATE,
});
